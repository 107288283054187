export const codeStep = {
  header: 'Enter the 6-digit code sent to your mobile',
  verificationRequired: 'Verification code is required',
};
export const firstStep = {
  header: 'Sign in, or create a new account',
  subtitle: 'Enter your mobile number, or your work email below',
  placeholder: 'Email or mobile number',
};
export const passwordStep = {
  createHeader: 'Create your password',
  enterHeader: 'Enter your password',
};
