import React from 'react';
import { shape, bool } from 'prop-types';
import * as S from './styles';

const PaymentName = ({ paymentMethod, isXSmall }) =>
  paymentMethod?.card ? (
    <>
      <S.CardIcon
        $isXSmall={isXSmall}
        alt={paymentMethod.name}
        src={paymentMethod.card.icon}
      />
      <S.SmallText $isXSmall={isXSmall}>
        <b>{paymentMethod.card.last4}</b>
      </S.SmallText>
    </>
  ) : (
    <S.SmallText $isXSmall={isXSmall}>{paymentMethod?.name}</S.SmallText>
  );

PaymentName.propTypes = {
  paymentMethod: shape({}),
  isXSmall: bool,
};
PaymentName.defaultProps = {
  paymentMethod: {
    name: '',
    card: null,
  },
  isXSmall: false,
};
export default PaymentName;
