import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _orderBy from 'lodash/orderBy';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import {
  getAccountByIdRequest,
  getCurrentAccountRequest,
  getAccountsRequest,
  createAccountRequest,
  updateAccountRequest,
  getPaymentMethodsRequest,
  addPaymentMethodRequest,
  setDefaultPaymentRequest,
  deletePaymentMethodRequest,
  validateAccountRequest,
  searchMembersRequest,
} from 'api/accounts';
import {
  createNewPlaceRequest,
  getFavoritePlacesRequest,
  updateFavoritePlacesRequest,
  deleteFavoritePlacesRequest,
} from 'api/address';
import {
  getActiveMembersRequest,
  getPendingMembersRequest,
  addManagerRequest,
  deleteMembersRequest,
  inviteMemberRequest,
  csvImportRequest,
  editAccountMemberRequest,
} from 'api/members';
import {
  getCardsRequest,
  setCardForBusinessUseRequest,
  initCardRequest,
  addCardRequest,
  editCardRequest,
} from 'api/cards';
import { handleGetBookings } from './bookingsSlice';
import { updateName } from './userSlice';
import { setActivePrimaryTab } from './tabsSlice';
import { setAppSuccess, setAppError } from './appStatusSlice';
import { getGroups } from './groupsSlice';

import { ACTIVITY_TABS, TEAM } from 'utils/tabs';
import t from 'locales/en';
import {
  failed,
  loading,
  addOrRemove,
  fulfilled,
  unshiftOrSet,
} from '../helpers/sliceHelpers';

import {
  ACCOUNT_ROLE_FRONTDESK,
  ACCOUNT_ROLE_MANAGER,
  ROLE_OPTIONS,
} from 'constants/accountUserRoles';
import { PAYMENT_METHODS } from 'constants/paymentMethods';
import {
  checkAvailablePaymentMethodOptions,
  findCardPaymentMethod,
} from 'utils/payment';
import { ACCOUNT_TYPES } from 'constants/accountTypes';

const initialMetaData = {
  currentPage: 1,
  totalPages: 1,
  total: 0,
};
const initialRedirectTo3DAuthState = {
  visible: false,
  willContinueBooking: false,
  cardAuthSuccess: false,
  cardAuthFailed: false,
  willContinueAccountCreate: false,
  isCard: {
    data: null,
    accountId: null,
  },
  isBooking: {
    values: null,
    last4: '',
    timezone: '',
    preauthId: null,
    action: null,
  },
};
export const initialState = {
  isInvoiceAccountPending: null,
  allIsLoading: null,
  isLoading: null,
  isUpdating: null,
  isCreating: null,
  error: null,
  isCardAdded: null,
  data: {
    all: [],
    current: {},
    paymentMethods: {},
    cards: [],
  },
  members: {
    isLoading: null,
    isUpdated: null,
    isCreated: null,
    error: null,
    invited: null,
    pending: {
      idsByAccountId: {},
      byAccountId: {},
      metaByAccountId: {},
      isLoading: null,
    },
    active: {
      idsByAccountId: {},
      byAccountId: {},
      metaByAccountId: {},
      isLoading: null,
    },
    search: {
      query: null,
      isSearching: null,
      data: [],
      meta: initialMetaData,
    },
  },
  favoritePlaces: {
    isLoading: null,
    dataByAccountId: {},
  },
  pendingPaymentMethod: {},
  pendingMember: {
    id: null,
    first_name: null,
  },
  membersToDelete: [],
  csvDryRun: {
    isCompleted: null,
    isLoading: null,
    result: {
      total: null,
      success: null,
      failed: null,
      errors: {},
    },
  },
  deleteMembers: {
    isSuccess: null,
    isLoading: null,
    error: null,
  },
  deletePaymentMethod: {
    isSuccess: null,
    isLoading: null,
    error: null,
  },
  accountRoleFilter: ROLE_OPTIONS[0],
  cardPaymentMethodsResponse: [],
  redirectTo3DAuth: initialRedirectTo3DAuthState,
  newAccount: {},
};

export const cancelRedirectTo3DAuth = createAsyncThunk(
  'accounts/cancelRedirectTo3DAuth',
  async ({ inBookingForm, isAddingCard }, { dispatch }) => {
    const { addNewCard } = inBookingForm
      ? t.threeDAuth.createBooking
      : t.threeDAuth;
    let { error } = addNewCard;
    if (!isAddingCard) {
      error = t.threeDAuth.createBooking.error;
    }
    dispatch(clearRedirectTo3DAuth());
    dispatch(
      setAppError({
        title: error.cancelled.title,
        body: error.subtitle,
      })
    );
  }
);
export const addCard = createAsyncThunk(
  'accounts/addCard',
  async (
    { values, zipcode, accountId, inBookingForm, willContinueAccountCreate },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await addCardRequest(values, zipcode, accountId);
      if (data?.authenticatable === null) {
        dispatch(
          addPaymentMethod({
            paymentId: data?.id,
            accountId,
            name: `${data?.brand} ending ${data?.last4}`,
            type: data?.type,
          })
        );
      } else {
        dispatch(
          setRedirectTo3DAuth({
            isCard: {
              data,
              accountId,
            },
            visible: true,
            willContinueBooking: inBookingForm,
            willContinueAccountCreate,
          })
        );
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const editCard = createAsyncThunk(
  'accounts/editCard',
  async ({ id, values }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { data } = await editCardRequest(id, values);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const deletePaymentMethod = createAsyncThunk(
  'accounts/deletePaymentMethod',
  async ({ account, paymentId }, { getState, rejectWithValue, dispatch }) => {
    try {
      const { data } = await deletePaymentMethodRequest(account, paymentId);
      if (data) {
        dispatch(
          setAppSuccess({
            title: 'Payment method was succesfully deleted',
          })
        );
      }
      return paymentId;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAccounts = createAsyncThunk(
  'accounts/all',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const accounts = await getAccountsRequest();
      if (accounts?.length) {
        const currentAccount = getState().accounts.data.current;
        if (!currentAccount?.id) {
          const { payload } = await dispatch(getCurrentAccount());
          if (payload) {
            dispatch(getSelectedAccountDetails({ account: payload }));
          } else {
            dispatch(setCurrentAccount({}));
          }
        } else {
          dispatch(getSelectedAccountDetails({ account: currentAccount }));
        }
      } else {
        dispatch(setCurrentAccount({}));
      }
      return accounts;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSelectedAccountDetails = createAsyncThunk(
  'accounts/getSelectedAccountDetails',
  async (
    { account = {}, accountChanged = false },
    { getState, rejectWithValue, dispatch, signal }
  ) => {
    let axiosSource = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      axiosSource.cancel();
      axiosSource = axios.CancelToken.source();
    });
    try {
      const bookingTabIndex = ACTIVITY_TABS.indexOf(
        getState().tabs.activeSecondaryTab
      );
      if (bookingTabIndex > -1) {
        const tab = ACTIVITY_TABS[bookingTabIndex];
        dispatch(
          handleGetBookings[tab]({
            page: 1,
            accountId: account.id,
            source: axiosSource,
            accountChanged,
          })
        );
      } else {
        // activity tab is not selected
        dispatch(
          handleGetBookings['All']({
            page: 1,
            accountId: account.id,
            source: axiosSource,
            accountChanged,
          })
        );
      }

      dispatch(getGroups());
      if (account.type === ACCOUNT_TYPES.rfb) {
        dispatch(getPaymentMethods({ account, source: axiosSource }));
        dispatch(getFavoritePlaces());
      }
      dispatch(getCards(axiosSource));
      dispatch(
        getActiveMembers({
          accountId: account?.id,
          source: axiosSource,
        })
      );
      dispatch(
        getPendingMembers({
          accountId: account?.id,
          source: axiosSource,
        })
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAccountById = createAsyncThunk(
  'accounts/byId',
  async ({ account = {} }, { getState, rejectWithValue, signal, dispatch }) => {
    let axiosSource = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      axiosSource.cancel();
      axiosSource = axios.CancelToken.source();
    });
    const cancelToken = axiosSource.token;

    try {
      const data = await getAccountByIdRequest(account?.id, cancelToken);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCurrentAccount = createAsyncThunk(
  'accounts/current',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const current = await getCurrentAccountRequest();
      return current;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPaymentMethods = createAsyncThunk(
  'accounts/paymentMethods',
  async ({ account = {}, source }, { getState, rejectWithValue, signal }) => {
    const activeAccount = account?.id
      ? account
      : getState().accounts.data.current;

    const isRfb = !activeAccount?.rfbDefaultPayment?.isNotRfb;
    if (isRfb) {
      let axiosSource = source || axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        axiosSource.cancel();
        axiosSource = source || axios.CancelToken.source();
      });
      try {
        const { id } = activeAccount;
        const { data } = await getPaymentMethodsRequest(id, axiosSource.token);
        return data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  }
);

export const setDefaultPaymentMethod = createAsyncThunk(
  'accounts/setDefaultPaymentMethod',
  async ({ accountId, paymentId }, { getState, rejectWithValue, dispatch }) => {
    try {
      const id = accountId ?? getState().accounts.data.current?.id;
      await setDefaultPaymentRequest(id, paymentId);
      return paymentId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getActiveMembers = createAsyncThunk(
  'accounts/activeMembers',
  async (
    { accountId, page, source },
    { getState, rejectWithValue, signal }
  ) => {
    let axiosSource = source || axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      axiosSource.cancel();
      axiosSource = source || axios.CancelToken.source();
    });
    try {
      const id = accountId ?? getState().accounts.data.current?.id;
      const currentPage =
        page ??
        getState().accounts.members.active.metaByAccountId[id].currentPage;
      const { data, meta } = await getActiveMembersRequest(
        id,
        currentPage,
        axiosSource.token
      );
      return { data, meta };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPendingMembers = createAsyncThunk(
  'accounts/pendingMembers',
  async (
    { accountId, page, source },
    { getState, rejectWithValue, signal }
  ) => {
    let axiosSource = source || axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      axiosSource.cancel();
      axiosSource = source || axios.CancelToken.source();
    });
    try {
      const id = accountId ?? getState().accounts.data.current?.id;
      const currentPage =
        page ??
        getState().accounts.members.pending.metaByAccountId[id].currentPage;
      const { data, meta } = await getPendingMembersRequest(
        id,
        currentPage,
        axiosSource.token
      );
      return { data, meta };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addPaymentMethod = createAsyncThunk(
  'accounts/addPaymentMethod',
  async (
    { paymentId, accountId, name, type = PAYMENT_METHODS.card },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const id = accountId ?? getState().accounts.data.current?.id;

      const { data } = await addPaymentMethodRequest(paymentId, id, name, type);
      if (type === PAYMENT_METHODS.default) {
        dispatch(setDefaultPaymentMethod(id, data.id));
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createAccount = createAsyncThunk(
  'accounts/create',
  async (
    { rfbAccountId, values, type },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const createdAccount = await createAccountRequest(values, type);
      if (type === ACCOUNT_TYPES.rfb) {
        const all = getState().accounts.data.all;
        if (!all.length) {
          dispatch(setCurrentAccount(createdAccount));
        }
        dispatch(setActivePrimaryTab(TEAM));
        dispatch(getGroups(createdAccount.id));
      } else if (type === ACCOUNT_TYPES.invoice && createdAccount.id) {
        const accountId = rfbAccountId || getState().accounts.data.current?.id;
        dispatch(
          addPaymentMethod({
            paymentId: createdAccount.id,
            accountId,
            name: createdAccount.name,
            type: ACCOUNT_TYPES.account,
          })
        );
      }
      return createdAccount;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAccount = createAsyncThunk(
  'accounts/update',
  async (values, { getState, rejectWithValue, dispatch }) => {
    try {
      const accountId = values.id ?? getState().accounts.data.current?.id;
      return await updateAccountRequest(values, accountId);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editAccountMember = createAsyncThunk(
  'accounts/editAccountMember',
  async (
    { id, values, account, isAllowedToEditOwnProfile },
    { getState, dispatch, rejectWithValue }
  ) => {
    const { first_name, last_name } = values;

    // if user is editing their own profile, changing first and last name is enabled
    try {
      if (isAllowedToEditOwnProfile && first_name && last_name) {
        await dispatch(updateName({ first_name, last_name }));
      }
      const { data } = await editAccountMemberRequest(id, values, account);
      dispatch(
        setAppSuccess({
          title: 'User successfully updated',
        })
      );
      return { data, id, accountId: account.id };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addManager = createAsyncThunk(
  'accounts/addManager',
  async ({ values, account }, { getState, rejectWithValue, dispatch }) => {
    const currentAccount = account?.id
      ? account
      : getState().accounts.data.current;
    // request for last page
    const activePage =
      getState().accounts.members.active.metaByAccountId[currentAccount.id]
        ?.totalPages || 1;
    const pendingPage =
      getState().accounts.members.pending.metaByAccountId[currentAccount.id]
        ?.totalPages || 1;
    try {
      let pendingMember = {};
      await addManagerRequest(currentAccount, values);
      await dispatch(
        getPendingMembers({
          accountId: currentAccount.id,
          page: pendingPage,
        })
      );
      dispatch(
        getActiveMembers({
          accountId: currentAccount.id,
          page: activePage,
        })
      );
      const pendingMemberId = Object.values(
        getState().accounts.members.pending.byAccountId[currentAccount.id]
      )?.find(
        (item) =>
          item?.type === values.type &&
          item?.user?.phone_number === values.phone_number &&
          item?.user?.phone_country === values.phone_country
      )?.id;

      if (pendingMemberId) {
        pendingMember = getState().accounts.members.pending.byAccountId[
          currentAccount.id
        ][pendingMemberId];
      }
      return {
        pendingMember,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const inviteMember = createAsyncThunk(
  'accounts/inviteMember',
  async ({ values, account }, { getState, rejectWithValue, dispatch }) => {
    const currentAccount = account?.id
      ? account
      : getState().accounts.data.current;
    if (!currentAccount.id) return;

    // request for last page
    const activePage =
      getState().accounts.members.active.metaByAccountId?.[currentAccount.id]
        ?.totalPages || 1;
    const pendingPage =
      getState().accounts.members.pending.metaByAccountId?.[currentAccount.id]
        ?.totalPages || 1;
    try {
      let pendingMember = initialState.pendingMember;
      await inviteMemberRequest(currentAccount, values);
      await dispatch(
        getPendingMembers({
          accountId: currentAccount.id,
          page: pendingPage,
        })
      );
      dispatch(
        getActiveMembers({
          accountId: currentAccount.id,
          page: activePage,
        })
      );
      const pendingMemberId = Object.values(
        getState().accounts.members.pending.byAccountId?.[currentAccount.id]
      ).find(
        (item) =>
          item?.type === values.type &&
          item?.user?.phone_number === values.phone_number &&
          item?.user?.phone_country === values.phone_country
      )?.id;

      if (pendingMemberId) {
        pendingMember = getState().accounts.members.pending.byAccountId?.[
          currentAccount.id
        ]?.[pendingMemberId];
      }
      return {
        pendingMember,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteMembers = createAsyncThunk(
  'accounts/deleteMembers',
  async ({ members }, { getState, rejectWithValue, dispatch }) => {
    try {
      const accountId = getState().accounts.data.current?.id;
      const ids = members.map((item) => item.id);
      await deleteMembersRequest(ids);
      dispatch(
        setAppSuccess({
          title: 'Users deleted successfully',
        })
      );
      return { ids, accountId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const csvMemberImport = createAsyncThunk(
  'accounts/csvMemberImport',
  async (
    { accountId, file, dryRun = 1 },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const id = accountId ?? getState().accounts.data.current?.id;

      const { data } = await csvImportRequest(id, file, dryRun);
      if (dryRun === 0) {
        dispatch(getActiveMembers({ accountId: id }));
        dispatch(getPendingMembers({ accountId: id }));
      }
      return { data, dryRun };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCards = createAsyncThunk(
  'accounts/cards',
  async (source, { getState, rejectWithValue, dispatch, signal }) => {
    let axiosSource = source || axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      axiosSource.cancel();
      axiosSource = source || axios.CancelToken.source();
    });
    try {
      const { data } = await getCardsRequest(axiosSource.token);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const setCardForBusinessUse = createAsyncThunk(
  'accounts/setCardForBusinessUse',
  async (
    { cardId, account = {}, name },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const activeAccount = account?.id
        ? account
        : getState().accounts.data.current;
      const { id } = activeAccount;
      const paymentMethods = getState().accounts.data.paymentMethods[
        activeAccount.id
      ];
      const cardPaymentMethod = paymentMethods.find(
        (method) => method.type === PAYMENT_METHODS.card
      );
      const { data } = await setCardForBusinessUseRequest(
        cardId,
        id,
        name,
        cardPaymentMethod
      );
      dispatch(getPaymentMethods({ account: activeAccount }));
      dispatch(getCards());
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAccountCard = createAsyncThunk(
  'accounts/updateAccountCard',
  async ({ cardId, account }, { getState, rejectWithValue, dispatch }) => {
    try {
      const id = account?.id ?? getState().accounts.data.current?.id;
      const card = await updateAccountRequest({ card_id: cardId }, id);
      return { data: card, accountId: id };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const inviteMemberByRole = createAsyncThunk(
  'accounts/inviteMemberByRole',
  async ({ account, values, role }, { getState, dispatch }) => {
    const currentAccount = account?.id
      ? account
      : getState().accounts.data.current;
    const groupId = values.group_id
      ? values.group_id
      : getState().groups.idsByAccountId[currentAccount.id][0];

    if (role === ACCOUNT_ROLE_FRONTDESK || role === ACCOUNT_ROLE_MANAGER) {
      dispatch(
        addManager({
          account: currentAccount,
          values: {
            ...values,
            group_id: groupId,
            rfb_payment_method_id: currentAccount.rfbDefaultPayment?.id,
            type: role,
          },
        })
      );
    } else {
      dispatch(
        inviteMember({
          values: {
            ...values,
            group_id: groupId,
            rfb_payment_method_id: currentAccount.rfbDefaultPayment?.id,
            type: role,
          },
          account: currentAccount,
        })
      );
    }
  }
);

export const initCard = createAsyncThunk(
  'accounts/initCard',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const cardData = await initCardRequest();
      return cardData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const validateAccount = createAsyncThunk(
  'accounts/validate',
  async ({ fields }, { getState, rejectWithValue, dispatch }) => {
    try {
      const id = getState().accounts.data.current.id;
      const { data } = await validateAccountRequest(id, fields);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchMembers = createAsyncThunk(
  'accounts/searchMembers',
  async ({ search, page }, { getState, rejectWithValue, signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    try {
      const id = getState().accounts.data.current.id;
      const { data, meta } = await searchMembersRequest(
        id,
        search,
        page,
        source.token
      );
      return { data, meta };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFavoritePlaces = createAsyncThunk(
  'accounts/getFavoritePlaces',
  async (_, { getState, rejectWithValue, signal }) => {
    try {
      const { data } = await getFavoritePlacesRequest();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateFavoritePlace = createAsyncThunk(
  'accounts/updateFavoritePlace',
  async (payload, { getState, rejectWithValue, signal }) => {
    try {
      const { data } = await updateFavoritePlacesRequest(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteFavoritePlace = createAsyncThunk(
  'accounts/deleteFavoritePlace',
  async (id, { getState, rejectWithValue, signal }) => {
    try {
      const { data } = await deleteFavoritePlacesRequest(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createNewPlace = createAsyncThunk(
  'accounts/createNewPlace',
  async (
    { address, name, driver_notes, lat, lng },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await createNewPlaceRequest(
        address,
        name,
        driver_notes,
        lat,
        lng
      );
      dispatch(
        setAppSuccess({
          title: 'Address saved',
        })
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
function accountsLoading(state) {
  loading(state);
}
function accountsFailed(state, action) {
  failed(state, action);
  state.isUpdating = false;
}

const addCardLoading = (state) => {
  loading(state);
  state.isCardAdded = null;
};
const addCardFailed = (state, action) => {
  failed(state, action);
  state.isCardAdded = false;
};
const addCardFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  if (payload.authenticatable === null) {
    state.isCardAdded = true;
  }
};

const editCardLoading = (state) => {
  loading(state, 'isUpdating');
};
const editCardFailed = (state, action) => {
  failed(state, action, 'isUpdating');
};
const editCardFulfilled = (state, { payload }) => {
  fulfilled(state, 'isUpdating');
  const idx = state.data.cards.findIndex((item) => item.id === payload.id);
  if (idx > -1) {
    state.data.cards[idx] = payload;
  }
};

const updateAccountCardFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  const index = state.data.all.findIndex(
    (item) => item?.id === payload?.accountId
  );
  const isCurrent = state.data.current?.id === payload?.accountId;
  if (index > -1) {
    state.data.all[index] = payload.data;
  }
  if (isCurrent) {
    state.data.current = payload.data;
  }
};
const initCardFulfilled = (state, { payload }) => {
  state.cardPaymentMethodsResponse = payload;
  state.error = null;
};
const initCardFailed = (state, action) => {
  state.cardPaymentMethodsResponse = [];
  if (action.payload) {
    state.error = action.payload.message;
  } else {
    state.error = action.error.message;
  }
};
const createAccountLoading = (state, { meta }) => {
  loading(state, 'isCreating');
  if (meta?.arg?.type === ACCOUNT_TYPES.rfb) {
    state.newAccount = {};
  } else {
    state.isInvoiceAccountPending = null;
  }
};
const createAccountFulfilled = (state, { meta, payload }) => {
  fulfilled(state, 'isCreating');
  if (payload.type === ACCOUNT_TYPES.rfb) {
    state.newAccount = payload;
    const index = state.data.all.findIndex((item) => item?.id === payload?.id);
    if (index === -1) {
      state.data.all.push(payload);
    }
  } else if (
    meta.arg?.type === ACCOUNT_TYPES.invoice &&
    payload.status === 202
  ) {
    state.isInvoiceAccountPending = true;
  }
};
const createAccountFailed = (state, action) => {
  failed(state, action, 'isCreating');
  if (action.meta?.arg?.type === ACCOUNT_TYPES.rfb) {
    state.newAccount = {};
  } else {
    state.isInvoiceAccountPending = false;
  }
};

const currentAccountFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  if (!isEqual(state.data.current, payload)) {
    state.data.current = payload;
  }
};

function allAccountsLoading(state) {
  loading(state, 'allIsLoading');
}
function allAccountsFailed(state, action) {
  failed(state, action, 'allIsLoading');
}
const allAccountsFulfilled = (state, { payload }) => {
  fulfilled(state, 'allIsLoading');
  if (!isEqual(state.data.all, payload)) {
    state.data.all = payload;
  }
  const current = payload.find((item) => item?.id === state.data.current?.id);
  if (current && !isEqual(state.data.current, current)) {
    state.data.current = current;
  }
};
function editAccountMemberLoading(state) {
  return loading(state.members);
}
function editAccountMemberFailed(state, action) {
  return failed(state.members, action);
}
const editAccountMemberFulfilled = (state, { payload }) => {
  state.members.isUpdated = true;
  state.members.isLoading = false;
  state.members.error = null;

  if (
    payload?.id &&
    state.members.active.byAccountId[payload.accountId]?.[payload.id]
  ) {
    state.members.active.byAccountId[payload.accountId][payload.id] =
      payload.data;
  }
};
const accountByIdFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  const index = state.data.all.findIndex((item) => item?.id === payload?.id);
  if (index > -1 && !isEqual(state.data.all[index], payload)) {
    state.data.all[index] = payload;
  }
};

const updatePending = (state) => {
  state.isUpdating = true;
};
const updateAccountFulfilled = (state, { payload }) => {
  state.isUpdating = false;
  state.error = null;
  const index = state.data.all.findIndex((item) => item?.id === payload?.id);

  if (index > -1 && !isEqual(state.data.current, payload)) {
    state.data.current = payload;
    state.data.all[index] = payload;
  }
};

const paymentMethodsFulfilled = (state, { payload, meta }) => {
  state.isLoading = false;
  state.error = null;
  const accountId = meta.arg?.account?.id || state.data.current.id;
  if (
    accountId &&
    payload &&
    !isEqual(state.data.paymentMethods[accountId], payload)
  ) {
    state.data.paymentMethods[accountId] = payload;
  }
};

const getActiveMembersLoading = (state, { meta }) => {
  state.members.active.isLoading = true;
  const { accountId } = meta.arg;

  if (!state.members.active.idsByAccountId[accountId]) {
    state.members.active.idsByAccountId[accountId] = [];
  }
  if (!state.members.active.byAccountId[accountId]) {
    state.members.active.byAccountId[accountId] = {};
  }
  if (!state.members.active.metaByAccountId[accountId]) {
    state.members.active.metaByAccountId[accountId] = initialMetaData;
  }
};
const getPendingMembersLoading = (state, { meta }) => {
  state.members.pending.isLoading = true;
  const { accountId } = meta.arg;

  if (!state.members.pending.idsByAccountId[meta.arg?.accountId]) {
    state.members.pending.idsByAccountId[meta.arg?.accountId] = [];
  }
  if (!state.members.pending.byAccountId[meta.arg?.accountId]) {
    state.members.pending.byAccountId[meta.arg?.accountId] = {};
  }
  if (!state.members.pending.metaByAccountId[accountId]) {
    state.members.pending.metaByAccountId[accountId] = initialMetaData;
  }
};
const getActiveMembersFailed = (state) => {
  state.members.active.isLoading = false;
};
const getPendingMembersFailed = (state) => {
  state.members.pending.isLoading = false;
};
const getActiveMembersFulfilled = (state, { payload, meta }) => {
  const { active } = state.members;
  const { total_pages, total, current_page } = payload.meta?.pagination;
  const { accountId } = meta.arg;

  active.isLoading = false;
  const ids = _map(payload.data, 'id');
  const byId = _keyBy(payload.data, 'id');

  if (!isEqual(active.idsByAccountId[accountId], ids)) {
    active.idsByAccountId[accountId] = ids;
  }
  if (!isEqual(active.byAccountId[accountId], byId)) {
    active.byAccountId[accountId] = byId;
  }
  active.metaByAccountId[accountId] = {
    totalPages: total_pages,
    total,
    currentPage: current_page,
  };
};
const getPendingMembersFulfilled = (state, { payload, meta }) => {
  const { pending } = state.members;
  const { total_pages, current_page, total } = payload.meta?.pagination;
  const { accountId } = meta.arg;

  pending.isLoading = false;
  const ids = _map(payload.data, 'id');
  const byId = _keyBy(payload.data, 'id');

  if (!isEqual(pending.idsByAccountId[accountId], ids)) {
    pending.idsByAccountId[accountId] = ids;
  }
  if (!isEqual(pending.byAccountId[accountId], byId)) {
    pending.byAccountId[accountId] = byId;
  }
  pending.metaByAccountId[accountId] = {
    totalPages: total_pages,
    total,
    currentPage: current_page,
  };
};

const csvMemberImportLoading = (state) => {
  state.csvDryRun.isCompleted = false;
  state.csvDryRun.isLoading = true;
};

const csvMemberImportFulfilled = (state, { payload }) => {
  state.csvDryRun.isCompleted = payload.dryRun === 0 ? true : false;
  state.csvDryRun.isLoading = false;
  if (payload.data) {
    state.csvDryRun.result = payload.data;
  }
};

const csvMemberImportFailed = (state) => {
  state.csvDryRun.isCompleted = false;
  state.csvDryRun.isLoading = false;
  state.csvDryRun.result = initialState.csvDryRun;
};

const inviteMemberLoading = (state, { meta }) => {
  const accountId = meta.arg.account?.id || state.data.current?.id;
  loading(state.members);
  if (!accountId) return;

  if (!state.members.pending.idsByAccountId[accountId]) {
    state.members.pending.idsByAccountId[accountId] = [];
  }
  if (!state.members.pending.byAccountId[accountId]) {
    state.members.pending.byAccountId[accountId] = {};
  }
  if (!state.members.pending.metaByAccountId[accountId]) {
    state.members.pending.metaByAccountId[accountId] = initialMetaData;
  }
  if (!state.members.active.idsByAccountId[accountId]) {
    state.members.active.idsByAccountId[accountId] = [];
  }
  if (!state.members.active.byAccountId[accountId]) {
    state.members.active.byAccountId[accountId] = {};
  }
  if (!state.members.active.metaByAccountId[accountId]) {
    state.members.active.metaByAccountId[accountId] = initialMetaData;
  }
};
const inviteMemberFailed = (state, action) => {
  failed(state.members, action);
};
const inviteMemberFulfilled = (state, { payload }) => {
  state.members.isCreated = true;
  state.members.isLoading = false;
  state.members.error = null;

  if (payload.pendingMember?.user) {
    state.pendingMember.first_name = payload.pendingMember.first_name || null;
    state.pendingMember.id = payload.pendingMember.user.id;
  }
  state.members.invited = true;
};

const getCardsFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  if (!isEqual(state.data.cards, payload)) {
    state.data.cards = payload;
  }
};
const deletePaymentMethodLoading = (state) => {
  state.deletePaymentMethod.isLoading = true;
  state.deletePaymentMethod.isSuccess = null;
  state.deletePaymentMethod.error = null;
};
const deletePaymentMethodFulfilled = (state, { payload, meta }) => {
  state.deletePaymentMethod.isLoading = false;
  state.deletePaymentMethod.isSuccess = true;
  state.deletePaymentMethod.error = null;

  const accountId = meta.arg?.account?.id || state.data.current.id;
  if (accountId) {
    const index = state.data.paymentMethods[accountId].findIndex(
      (item) => item.id === payload
    );
    if (index > -1) {
      state.data.paymentMethods[accountId].splice(index, 1);
    }
  }
};
const deletePaymentMethodFailed = (state, action) => {
  state.deletePaymentMethod.isLoading = false;
  state.deletePaymentMethod.isSuccess = false;
  if (action.payload) {
    state.deletePaymentMethod.error = action.payload.message;
  } else {
    state.deletePaymentMethod.error = action.error.message;
  }
};
const deleteMembersLoading = (state) => {
  state.deleteMembers.isLoading = true;
  state.deleteMembers.isSuccess = null;
  state.deleteMembers.error = null;
};
const deleteMembersFulfilled = (state, { payload }) => {
  const { ids, accountId } = payload;
  state.deleteMembers.isLoading = false;
  state.deleteMembers.isSuccess = true;
  state.deleteMembers.error = null;
  const { active, pending } = state.members;

  ids.forEach((id) => {
    if (active.byAccountId[accountId][id])
      delete active.byAccountId[accountId][id];
    if (pending.byAccountId[accountId][id])
      delete pending.byAccountId[accountId][id];

    active.idsByAccountId[accountId] = active.idsByAccountId[accountId].filter(
      (item) => item !== id
    );
    pending.idsByAccountId[accountId] = pending.idsByAccountId[
      accountId
    ].filter((item) => item !== id);
  });
};
const deleteMembersFailed = (state, action) => {
  state.deleteMembers.isLoading = false;
  state.deleteMembers.isSuccess = false;
  if (action.payload) {
    state.deleteMembers.error = action.payload.message;
  } else {
    state.deleteMembers.error = action.error.message;
  }
};

const setDefaultPaymentMethodFulfilled = (state, { payload, meta }) => {
  const accountId = meta.arg?.accountId || state.data.current.id;

  if (accountId) {
    const allIndex = state.data.all.findIndex((item) => item.id === accountId);
    const index = state.data.paymentMethods[accountId].findIndex(
      (item) => item.id === payload
    );
    const nextData = state.data.paymentMethods[accountId].map((item, idx) => ({
      ...item,
      default: index === idx ? true : false,
    }));
    state.data.paymentMethods[accountId] = nextData;
    if (allIndex > -1)
      state.data.all[allIndex].rfbDefaultPayment =
        state.data.paymentMethods[accountId][index];
    state.data.current.rfbDefaultPayment =
      state.data.paymentMethods[accountId][index];
  }
};

const addPaymentMethodFulfilled = (state, { meta, payload }) => {
  const accountId = meta.arg?.accountId || state.data.current.id;

  state.isLoading = false;
  state.error = null;

  if (accountId) {
    state.data.paymentMethods[accountId] = unshiftOrSet(
      state.data.paymentMethods[accountId],
      payload
    );
    if (
      state.redirectTo3DAuth.willContinueAccountCreate &&
      meta.arg.type === PAYMENT_METHODS.card
    ) {
      state.redirectTo3DAuth.cardAuthSuccess = true;
    }
    state.redirectTo3DAuth.isCard = initialRedirectTo3DAuthState.isCard;
    state.redirectTo3DAuth.cardAuthFailed = false;
    state.pendingPaymentMethod = initialState.pendingPaymentMethod;
  }
};
const addPaymentMethodFailed = (state, action) => {
  failed(state, action);
};

const validateAccountLoading = (state) => {
  loading(state);
};
const validateAccountFulfilled = (state, action) => {
  fulfilled(state);
};
const validateAccountFailed = (state, action) => {
  failed(state, action);
};
const searchMembersLoading = (state, { meta }) => {
  state.members.search.isSearching = true;
  state.members.search.query = meta.arg.search;
};
const searchMembersFulfilled = (state, { payload, meta }) => {
  const { total_pages, total, current_page } = payload.meta?.pagination;

  state.members.search.isSearching = false;
  state.members.search.data = payload.data;
  state.members.search.meta.totalPages = total_pages;
  state.members.search.meta.total = total;
  state.members.search.meta.currentPage = current_page;
};

const searchMembersFailed = (state, { meta }) => {
  if (!meta?.aborted) state.members.search.isSearching = false;
};
const favoritePlaceLoading = (state, { meta }) => {
  state.favoritePlaces.isLoading = true;
};
const createNewPlaceFulfilled = (state, { payload }) => {
  state.favoritePlaces.isLoading = false;

  const accountId = state.data.current?.id;
  if (!accountId || !payload) return;

  state.favoritePlaces.dataByAccountId[accountId] = unshiftOrSet(
    state.favoritePlaces.dataByAccountId[accountId],
    payload
  );
};
const getFavoritePlacesFulfilled = (state, { payload }) => {
  state.favoritePlaces.isLoading = false;

  const accountId = state.data.current?.id;
  if (!accountId || !payload) return;

  state.favoritePlaces.dataByAccountId[accountId] = payload;
};
const updateFavoritePlaceFulfilled = (state, { payload, meta }) => {
  state.favoritePlaces.isLoading = false;

  const accountId = state.data.current?.id;
  if (!accountId || !payload.id) return;
  const index = state.favoritePlaces.dataByAccountId[accountId].findIndex(
    (item) => item.id === payload.id
  );
  if (index > -1)
    state.favoritePlaces.dataByAccountId[accountId][index] = payload;
};
const deleteFavoritePlaceFulfilled = (state, { meta }) => {
  state.favoritePlaces.isLoading = false;

  const accountId = state.data.current?.id;
  if (!state.favoritePlaces.dataByAccountId[accountId] || !meta.arg) return;
  const index = state.favoritePlaces.dataByAccountId[accountId].findIndex(
    (item) => item.id === meta.arg
  );
  if (index > -1) {
    state.favoritePlaces.dataByAccountId[accountId].splice(index, 1);
  }
};
const favoritePlaceFailed = (state, action) => {
  state.favoritePlaces.isLoading = false;
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    clearNewAccount: (state) => {
      if (!isEmpty(state.newAccount)) {
        state.newAccount = {};
      }
    },
    clearRedirectTo3DAuth: (state) => {
      state.redirectTo3DAuth = initialRedirectTo3DAuthState;
    },
    setRedirectTo3DAuth: (state, action) => {
      state.redirectTo3DAuth = {
        ...initialRedirectTo3DAuthState,
        ...state.redirectTo3DAuth,
        ...action.payload,
      };
    },
    setPendingPaymentMethod: (state, action) => {
      if (!isEqual(state.pendingPaymentMethod, action.payload)) {
        state.pendingPaymentMethod = action.payload;
      }
    },
    clearPendingPaymentMethod: (state, action) => {
      state.pendingPaymentMethod = initialState.pendingPaymentMethod;
    },
    setCurrentAccount: (state, action) => {
      if (!isEqual(state.data.current, action.payload)) {
        state.data.current = action.payload;
      }
    },
    setRoleFilter: (state, action) => {
      if (!isEqual(state.data.current, action.payload)) {
        state.accountRoleFilter = action.payload;
        state.membersToDelete = initialState.membersToDelete;
        state.deleteMembers = initialState.deleteMembers;
      }
    },
    setMembersToDelete: (state, action) => {
      if (action.payload.isSingle) {
        state.membersToDelete = [action.payload];
      } else {
        addOrRemove(state.membersToDelete, action.payload);
      }
    },
    clearMembersToDelete: (state) => {
      state.membersToDelete = initialState.membersToDelete;
      state.deleteMembers = initialState.deleteMembers;
    },
    clearPendingMember: (state) => {
      state.pendingMember = initialState.pendingMember;
    },
    clearCsvDryRun: (state) => {
      state.csvDryRun = initialState.csvDryRun;
    },
    clearMemberInvited: (state) => {
      state.members.invited = null;
    },
    clearDeletePaymentMethod: (state) => {
      state.deletePaymentMethod = initialState.deletePaymentMethod;
    },
    clearAccountMemberStatus: (state) => {
      state.members.isLoading = null;
      state.members.error = null;
      state.members.isUpdated = null;
      state.members.isCreated = null;
    },
    clearCardStatus: (state) => {
      state.isCardAdded = null;
      state.error = null;
      state.isLoading = null;
    },
    clearInvoiceAccountPending: (state) => {
      state.isInvoiceAccountPending = null;
    },
    clearSearchMembers: (state) => {
      state.members.search = initialState.members.search;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccounts.pending, allAccountsLoading);
    builder.addCase(getCurrentAccount.pending, accountsLoading);
    builder.addCase(getAccountById.pending, accountsLoading);
    builder.addCase(updateAccount.pending, updatePending);
    builder.addCase(getPaymentMethods.pending, accountsLoading);
    builder.addCase(getActiveMembers.pending, getActiveMembersLoading);
    builder.addCase(getPendingMembers.pending, getPendingMembersLoading);
    builder.addCase(deleteMembers.pending, deleteMembersLoading);
    builder.addCase(csvMemberImport.pending, csvMemberImportLoading);
    builder.addCase(createAccount.pending, createAccountLoading);
    builder.addCase(getCards.pending, accountsLoading);
    builder.addCase(inviteMember.pending, inviteMemberLoading);
    builder.addCase(addManager.pending, inviteMemberLoading);
    builder.addCase(deletePaymentMethod.pending, deletePaymentMethodLoading);
    builder.addCase(addCard.pending, addCardLoading);
    builder.addCase(addPaymentMethod.pending, accountsLoading);
    builder.addCase(editAccountMember.pending, editAccountMemberLoading);
    builder.addCase(validateAccount.pending, validateAccountLoading);
    builder.addCase(searchMembers.pending, searchMembersLoading);
    builder.addCase(getFavoritePlaces.pending, favoritePlaceLoading);
    builder.addCase(updateFavoritePlace.pending, favoritePlaceLoading);
    builder.addCase(deleteFavoritePlace.pending, favoritePlaceLoading);
    builder.addCase(createNewPlace.pending, favoritePlaceLoading);
    builder.addCase(editCard.pending, editCardLoading);

    builder.addCase(getCurrentAccount.fulfilled, currentAccountFulfilled);
    builder.addCase(getAccountById.fulfilled, accountByIdFulfilled);
    builder.addCase(getAccounts.fulfilled, allAccountsFulfilled);
    builder.addCase(updateAccount.fulfilled, updateAccountFulfilled);
    builder.addCase(getPaymentMethods.fulfilled, paymentMethodsFulfilled);
    builder.addCase(getActiveMembers.fulfilled, getActiveMembersFulfilled);
    builder.addCase(getPendingMembers.fulfilled, getPendingMembersFulfilled);
    builder.addCase(deleteMembers.fulfilled, deleteMembersFulfilled);
    builder.addCase(csvMemberImport.fulfilled, csvMemberImportFulfilled);
    builder.addCase(createAccount.fulfilled, createAccountFulfilled);
    builder.addCase(getCards.fulfilled, getCardsFulfilled);
    builder.addCase(updateAccountCard.fulfilled, updateAccountCardFulfilled);
    builder.addCase(inviteMember.fulfilled, inviteMemberFulfilled);
    builder.addCase(addManager.fulfilled, inviteMemberFulfilled);
    builder.addCase(
      deletePaymentMethod.fulfilled,
      deletePaymentMethodFulfilled
    );
    builder.addCase(initCard.fulfilled, initCardFulfilled);
    builder.addCase(
      setDefaultPaymentMethod.fulfilled,
      setDefaultPaymentMethodFulfilled
    );
    builder.addCase(addCard.fulfilled, addCardFulfilled);
    builder.addCase(addPaymentMethod.fulfilled, addPaymentMethodFulfilled);
    builder.addCase(editAccountMember.fulfilled, editAccountMemberFulfilled);
    builder.addCase(validateAccount.fulfilled, validateAccountFulfilled);
    builder.addCase(searchMembers.fulfilled, searchMembersFulfilled);
    builder.addCase(getFavoritePlaces.fulfilled, getFavoritePlacesFulfilled);
    builder.addCase(
      updateFavoritePlace.fulfilled,
      updateFavoritePlaceFulfilled
    );
    builder.addCase(
      deleteFavoritePlace.fulfilled,
      deleteFavoritePlaceFulfilled
    );
    builder.addCase(createNewPlace.fulfilled, createNewPlaceFulfilled);
    builder.addCase(editCard.fulfilled, editCardFulfilled);

    builder.addCase(getAccounts.rejected, allAccountsFailed);
    builder.addCase(getCurrentAccount.rejected, accountsFailed);
    builder.addCase(getAccountById.rejected, accountsFailed);
    builder.addCase(updateAccount.rejected, accountsFailed);
    builder.addCase(getPaymentMethods.rejected, accountsFailed);
    builder.addCase(getActiveMembers.rejected, getActiveMembersFailed);
    builder.addCase(getPendingMembers.rejected, getPendingMembersFailed);
    builder.addCase(deleteMembers.rejected, deleteMembersFailed);
    builder.addCase(csvMemberImport.rejected, csvMemberImportFailed);
    builder.addCase(createAccount.rejected, createAccountFailed);
    builder.addCase(getCards.rejected, accountsFailed);
    builder.addCase(inviteMember.rejected, inviteMemberFailed);
    builder.addCase(addManager.rejected, inviteMemberFailed);
    builder.addCase(deletePaymentMethod.rejected, deletePaymentMethodFailed);
    builder.addCase(initCard.rejected, initCardFailed);
    builder.addCase(addCard.rejected, addCardFailed);
    builder.addCase(addPaymentMethod.rejected, addPaymentMethodFailed);
    builder.addCase(editAccountMember.rejected, editAccountMemberFailed);
    builder.addCase(validateAccount.rejected, validateAccountFailed);
    builder.addCase(searchMembers.rejected, searchMembersFailed);
    builder.addCase(getFavoritePlaces.rejected, favoritePlaceFailed);
    builder.addCase(updateFavoritePlace.rejected, favoritePlaceFailed);
    builder.addCase(deleteFavoritePlace.rejected, favoritePlaceFailed);
    builder.addCase(createNewPlace.rejected, favoritePlaceFailed);
    builder.addCase(editCard.rejected, editCardFailed);
  },
});

export const {
  setCurrentAccount,
  setPendingPaymentMethod,
  clearPendingPaymentMethod,
  setRoleFilter,
  setMembersToDelete,
  clearMembersToDelete,
  clearPendingMember,
  clearCsvDryRun,
  clearMemberInvited,
  clearDeletePaymentMethod,
  setRedirectTo3DAuth,
  clearAccountMemberStatus,
  clearCardStatus,
  clearRedirectTo3DAuth,
  clearNewAccount,
  clearInvoiceAccountPending,
  clearSearchMembers,
} = accountsSlice.actions;

export default accountsSlice.reducer;

export const emptyAccountsSelector = (state) => !state.accounts.data.all.length;
export const currentAccountSelector = (state) => state.accounts.data.current;

export const activeMembersDataSelector = (state, accountId) => {
  if (
    state.accounts.members.active.idsByAccountId[accountId] &&
    state.accounts.members.active.byAccountId[accountId]
  ) {
    return _orderBy(
      state.accounts.members.active.idsByAccountId[accountId].map(
        (item) => state.accounts.members.active.byAccountId[accountId][item]
      ),
      'id'
    );
  } else {
    return [];
  }
};
export const activeMembersSelector = (state) => state.accounts.members.active;
export const activeMembersMetaSelector = (state, accountId) =>
  state.accounts.members.active.metaByAccountId[accountId] || initialMetaData;
export const pendingMembersDataSelector = (state, accountId) => {
  if (
    state.accounts.members.pending.idsByAccountId[accountId] &&
    state.accounts.members.pending.byAccountId[accountId]
  ) {
    return _orderBy(
      state.accounts.members.pending.idsByAccountId[accountId].map(
        (item) => state.accounts.members.pending.byAccountId[accountId][item]
      ),
      'id'
    );
  } else {
    return [];
  }
};
export const searchMembersSelector = (state) => state.accounts.members.search;
export const pendingMembersSelector = (state) => state.accounts.members.pending;
export const pendingMembersMetaSelector = (state, accountId) =>
  state.accounts.members.pending.metaByAccountId[accountId] || initialMetaData;
export const roleFilterSelector = (state) => state.accounts.accountRoleFilter;
export const csvDryRunSelector = (state) => state.accounts.csvDryRun;

export const currentPaymentMethodsSelector = (state) =>
  state.accounts.data.paymentMethods[state.accounts.data.current.id];
export const defaultPaymentMethodSelector = (state) =>
  state.accounts.data.paymentMethods[state.accounts.data.current.id]?.find(
    (item) => item.default
  ) || null;
export const newAccountSelector = (state) => state.accounts.newAccount;
export const availablePaymentMethodOptionsSelector = (state, accountId) =>
  checkAvailablePaymentMethodOptions(
    state.accounts.data.paymentMethods[state.accounts.data.current.id]
  );
export const hasCardPaymentMethodAddedSelector = (state) =>
  findCardPaymentMethod(
    state.accounts.data.paymentMethods[state.accounts.data.current.id]
  );
