import styled from 'styled-components';
import Button from 'components/Button';
import * as v from 'utils/variables';

const DEFAULT_SPACING = '2.5em';

export const ModalActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${v.spacingLg} ${DEFAULT_SPACING} ${DEFAULT_SPACING}
    ${DEFAULT_SPACING};
  margin: ${v.spacingLg} -2.5em -2.5em -2.5em;
  background-color: ${v.colorPrimaryLight};
`;

export const PrimaryButtonWrapper = styled.div`
  margin-left: ${v.spacingXs};
  width: 210px;
`;
export const StyledPrimaryButton = styled(Button)`
  padding: ${v.spacingSm} ${v.spacingLg};
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CancelButtonWrapper = styled.div`
  margin-left: 0;
  min-width: 100px;
  max-width: 150px;

  button {
    text-align: left;
    color: ${v.mousegray};
    font-size: ${v.fontSizeSm};
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }
`;
export const StyledCancelButton = styled(Button)`
  justify-content: flex-start;
  align-items: center;
  color: ${v.mousegray};
  font-size: ${v.fontSizeSm};
`;
