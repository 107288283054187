import React, { useState, useCallback, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import _isEqual from 'lodash/isEqual';
import { string, bool, arrayOf, shape, func } from 'prop-types';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import Select from 'components/Select';
import LogoWithText from 'components/LogoWithText';
import SetAccount from 'components/Modals/SetAccount';
import { accountType } from 'constants/propTypes';
import { useOutsideClick } from 'hooks/useOutsideClick';

import styles from './Navbar.module.scss';
import BookingForm from '../Modals/BookingForm/BookingForm';
import { ACCOUNT_ROLE_FRONTDESK } from 'constants/accountUserRoles';
import { logout } from 'store/slices/userSlice';
import { routes } from 'routes/routes';
import {
  setCurrentAccount,
  clearSearchMembers,
  currentAccountSelector,
} from 'store/slices/accountsSlice';
import { StyledButton, StyledAccountWrapper } from './styles';
import { navbarStyles } from 'constants/customSelectStyles';

const Navbar = ({
  accounts,
  isBookingModalOpen,
  handleBookingModalToggle,
  isAccountModalVisible,
  setIsAccountModalVisible,
  hasNoAccount,
  afterNewBusinessAccount,
  cardAuthSuccess,
  cardAuthFailed,
  willContinueAccountCreate,
  appCompanyName,
  isCreatingAccount,
  onClickNewBooking,
  userName,
  headerLogo,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector(currentAccountSelector);
  const [isUserMenuOpened, setUserMenuOpened] = useState(false);
  const handleAvatarClick = useCallback(() => {
    setUserMenuOpened(!isUserMenuOpened);
  }, [isUserMenuOpened]);

  const userMenuReference = useRef(null);
  useOutsideClick(userMenuReference, () => setUserMenuOpened(false));

  const handleLogoutClick = useCallback(async () => {
    await dispatch(logout());

    history.replace(routes.default);
  }, [dispatch, history]);

  const onAccountChange = useCallback(
    (item) => {
      if (item.isCreateNew) {
        setIsAccountModalVisible(true);
      } else {
        const selectedAccount = accounts.find((acc) => acc.id === item.id);
        dispatch(setCurrentAccount(selectedAccount));
        dispatch(clearSearchMembers());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, accounts]
  );

  const isFrontdesk = account?.role === ACCOUNT_ROLE_FRONTDESK;
  const createNewOption = {
    label: (
      <span className={styles.NavbarSelectItem}>Add new business account</span>
    ),
    value: 'create',
    isCreateNew: true,
  };

  const accountOptions = [
    ...accounts.map((_account) => {
      return {
        id: _account?.id,
        name: _account?.name,
        label: _account?.name,
        value: _account?.id,
        rfbDefaultPayment: _account?.rfbDefaultPayment,
        role: _account?.role,
      };
    }),
    ...(!isFrontdesk ? [createNewOption] : []),
  ];

  return (
    <>
      {isAccountModalVisible && (
        <SetAccount
          fromNavbar
          account={account}
          isModalOpen={isAccountModalVisible}
          closeModal={() => setIsAccountModalVisible(false)}
          hasNoAccount={hasNoAccount}
          willContinueAccountCreate={willContinueAccountCreate}
          cardAuthFailed={cardAuthFailed}
          cardAuthSuccess={cardAuthSuccess}
          afterNewBusinessAccount={afterNewBusinessAccount}
          appCompanyName={appCompanyName}
          isCreatingAccount={isCreatingAccount}
        />
      )}
      <div className={styles.NavbarWrapper}>
        <div className={styles.NavbarBrand}>
          <LogoWithText
            logo={headerLogo}
            altText={appCompanyName}
            width="56px"
          />
          <StyledAccountWrapper>
            <Select
              isDisabled={hasNoAccount}
              value={
                account?.id
                  ? {
                      value: account.id,
                      label: account.name,
                    }
                  : null
              }
              onChange={onAccountChange}
              options={accountOptions}
              isSearchable={false}
              blurInputOnSelect
              styles={navbarStyles}
            />
          </StyledAccountWrapper>
        </div>
        <div className={styles.NavbarBrand}>
          <div className={styles.NavbarItem}>
            <div className={styles.NavbarAvatarWrapper} ref={userMenuReference}>
              <button
                className={styles.NavbarAvatar}
                onClick={handleAvatarClick}
              >
                <Avatar name={userName} round="35px" size="30" />
                <span className={styles.NavbarName}>{userName}</span>
              </button>
              <div
                className={cx(styles.NavbarAvatarMenuList, {
                  [styles.NavbarAvatarMenuList__opened]: isUserMenuOpened,
                })}
              >
                <button
                  onClick={handleLogoutClick}
                  className={cx(
                    styles.NavbarAvatar,
                    styles.NavbarAvatarMenuItem
                  )}
                  type="button"
                >
                  Log Out
                </button>
              </div>
            </div>
          </div>
          <StyledButton
            disabled={hasNoAccount}
            onClick={onClickNewBooking}
            type="button"
            primary
            name="Book a taxi"
            isRoundedMedium
          />
          {isBookingModalOpen && (
            <BookingForm
              isModalOpen={isBookingModalOpen}
              handleModalToggle={handleBookingModalToggle}
            />
          )}
        </div>
      </div>
    </>
  );
};

Navbar.propTypes = {
  accounts: arrayOf(shape(accountType)),
  account: shape(accountType),
  isBookingModalOpen: bool,
  isAccountModalVisible: bool,
  afterNewBusinessAccount: bool,
  willContinueAccountCreate: bool,
  isCreatingAccount: bool,
  handleBookingModalToggle: func.isRequired,
  setIsAccountModalVisible: func.isRequired,
  onClickNewBooking: func.isRequired,
  userName: string.isRequired,
  appCompanyName: string.isRequired,
  headerLogo: string.isRequired,
  hasNoAccount: bool.isRequired,
};

Navbar.defaultProps = {
  accounts: [],
  isBookingModalOpen: false,
  isAccountModalVisible: false,
  cardAuthSuccess: false,
  afterNewBusinessAccount: false,
  willContinueAccountCreate: false,
  isCreatingAccount: null,
};

const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.accounts, nextProps.accounts) &&
  prevProps.isBookingModalOpen === nextProps.isBookingModalOpen &&
  prevProps.isAccountModalVisible === nextProps.isAccountModalVisible &&
  prevProps.cardAuthSuccess === nextProps.cardAuthSuccess &&
  prevProps.cardAuthFailed === nextProps.cardAuthFailed &&
  prevProps.afterNewBusinessAccount === nextProps.afterNewBusinessAccount &&
  prevProps.willContinueAccountCreate === nextProps.willContinueAccountCreate &&
  prevProps.isCreatingAccount === nextProps.isCreatingAccount &&
  prevProps.userName === nextProps.userName &&
  prevProps.appCompanyName === nextProps.appCompanyName &&
  prevProps.headerLogo === nextProps.headerLogo &&
  prevProps.hasNoAccount === nextProps.hasNoAccount;

export default memo(Navbar, areEqual);
