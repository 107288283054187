import styled from 'styled-components';
import { dark, lightPrimary } from 'utils/variables';

export const Container = styled.div`
  display: block;
  width: 100%;
  height: 4px;
  background: ${dark};
  position: relative;
`;
export const Bar = styled.div`
  position: absolute;
  width: 0;
  top: 0;
  z-index: 1;
  transition: 0.3s;
  width: ${(props) => (props.$width * 100) / props.$length}%;
  height: 100%;
  background: ${lightPrimary};
`;
