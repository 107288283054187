import * as Yup from 'yup';
import 'utils/yupMethods';

export const FORM_FIELDS_IDS = {
  pickup_at: 'pickup_at',
  pickup_address: 'pickup_address',
  destination_address: 'destination_address',
  destination: 'destination',
  payment_type: 'payment_type',
  type: 'type',
  driver_notes: 'driver_notes',
  account_id: 'account_id',
  pickup_lat: 'pickup_lat',
  pickup_lng: 'pickup_lng',
  destination_lat: 'destination_lat',
  destination_lng: 'destination_lng',
  rfb_payment_method: 'rfb_payment_method',
  date: 'date',
  hour: 'hour',
  cc_receipt: 'cc_receipt',
  vehicle_type: 'vehicle_type',
  phone: 'phone',
  phone_country: 'phone_country',
  phone_number: 'phone_number',
  user_id: 'user_id',
  username: 'username',
  default_phone: 'default_phone',
  flight_number: 'flight_number',
  validation_fields: 'validation_fields',
};

Yup.addMethod(Yup.string, 'isAddressValid', function (message, type) {
  return this.test('isAddressValid', message, function () {
    return !!this.parent[`${type}_lat`] || !!this.parent[`${type}_lng`];
  });
});

export const initialValues = {
  pickup_at: new Date(),
  pickup_address: '',
  destination_address: '',
  payment_type: '',
  type: 'asap',
  driver_notes: '',
  pickup_lat: '',
  pickup_lng: '',
  destination_lat: '',
  destination_lng: '',
  rfb_payment_method: '',
  date: new Date(),
  hour: '',
  cc_receipt: '',
  vehicle_type: 'any',
  phone: '',
  phone_country: '',
  phone_number: '',
  user_id: '',
  username: '',
  default_phone: '',
  flight_number: '',
};

const isEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

export const BookingFormSchema = (isNotRfbAccount) =>
  Yup.object().shape({
    date: Yup.string().required('Pickup date is required'),
    hour: Yup.string(),
    pickup_lat: Yup.string().required('Pickup is required'),
    pickup_lng: Yup.string().required('Pickup is required'),
    destination_lat: Yup.string().required('Destination is required'),
    destination_lng: Yup.string().required('Destination is required'),
    pickup_address: Yup.string()
      .required('Pickup is required')
      .isAddressValid('Pickup is not valid', 'pickup'),
    destination_address: Yup.string()
      .required('Destination is required')
      .isAddressValid('Destination is not valid', 'destination'),
    username: Yup.string()
      .min(3, 'Passenger name is required')
      .required('Passenger name is required'),
    cc_receipt: Yup.string().test(
      'emails',
      'Email is not valid',
      (value) => !value?.length || (value && value.split(',').every(isEmail))
    ),
    phone: Yup.string().required('Phone number is required').isPhoneValid(),
    phone_number: Yup.string().required('Phone number is required'),
    driver_notes: Yup.string().matches(
      /^[a-z0-9\s]+$/i,
      'No special characters allowed'
    ),
    rfb_payment_method: isNotRfbAccount
      ? undefined
      : Yup.number().required('Payment method is required'),
    flight_number: Yup.string(),
  });
