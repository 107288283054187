import React from 'react';
import { oneOfType, number, shape, func, bool, string } from 'prop-types';

import Checkbox from 'components/Checkbox';
import LabelContainer from './LabelContainer';
import InputError from 'components/InputError';
import { StyledCheckboxError } from './styles';

const LabelWithCheckbox = ({
  id,
  name,
  checked,
  onChange,
  label,
  labelSize,
  inputSize,
  error,
  hasError,
  errorCanFit,
  type,
  labelStyle,
  isDisabled,
  testId,
}) => {
  return (
    <>
      <LabelContainer
        testId={testId}
        id={id}
        label={label}
        labelSize={labelSize}
        inputSize={inputSize}
        isReverseRow
        labelStyle={labelStyle}
        hasError={hasError}
      >
        <Checkbox
          type={type}
          id={id}
          name={name}
          onChange={onChange}
          checked={checked}
          isDisabled={isDisabled}
        />
      </LabelContainer>
      <StyledCheckboxError $marginLeft={inputSize}>
        <InputError
          errorCanFit={errorCanFit}
          hasError={hasError}
          error={error}
          width={labelSize}
          absolute
        />
      </StyledCheckboxError>
    </>
  );
};

LabelWithCheckbox.propTypes = {
  checked: bool,
  onChange: func,
  id: string,
  name: string,
  label: oneOfType([string, shape({})]),
  labelSize: number,
  inputSize: number,
  error: string,
  hasError: bool,
  errorCanFit: bool,
  testId: string,
  isDisabled: bool,
  type: string,
  style: shape({}),
  labelStyle: shape({}),
};
LabelWithCheckbox.defaultProps = {
  labelSize: 11.2,
  inputSize: 0.8,
  type: 'checkbox',
  labelStyle: {},
  hasError: false,
  error: null,
  errorCanFit: false,
  testId: 'label-with-checkbox',
  isDisabled: false,
};

export default LabelWithCheckbox;
