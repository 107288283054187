import React from 'react';
import Avatar from 'react-avatar';

const style = (size) => {
  return {
    border: '2px solid white',
    marginLeft: -(size / 2) + 'px',
  };
};

const AvatarStack = ({ usersArray, avatarsToShow, fullName }) => {
  const remaining = usersArray.length - avatarsToShow;
  const avatarsArray = usersArray.slice(0, avatarsToShow);
  return (
    <>
      {' '}
      {avatarsArray.map((item) => {
        return (
          <Avatar
            size={68}
            style={style(50)}
            key={item.id}
            round
            name={fullName(item)}
          />
        );
      })}
      {remaining > 0 && (
        <Avatar
          size={68}
          color="gray"
          style={style(50)}
          round
          value={`+${remaining}`}
        />
      )}
    </>
  );
};

export default AvatarStack;
