import React from 'react';
import { bool, object, func, arrayOf } from 'prop-types';

import { Row } from 'components/Grid';
import { getCSVImportTemplateRequest } from 'api/members';

import styles from './AddUser.module.scss';
import { messages } from './messages';

const {
  manageUsersInBulk: {
    subHeader,
    downloadTemplateButton,
    dropZoneActive,
    dropZoneText,
    dropZoneBrowse,
    fileLoaded,
  },
} = messages;

const AddUserDropZone = ({
  getInputProps,
  getRootProps,
  isDragActive,
  file,
}) => {
  return (
    <div className={styles.AddUserCSVWrapper}>
      <Row>
        <p className={styles.AddUserParagraph}>
          {subHeader},{' '}
          <button
            className={styles.AddUserDownloadTemplateButton}
            onClick={getCSVImportTemplateRequest}
          >
            {downloadTemplateButton}
          </button>
        </p>
      </Row>
      <div className={styles.AddUserCSVDrop} {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>{dropZoneActive}</p>
        ) : (
          <p>
            {file ? (
              <span>{fileLoaded}</span>
            ) : (
              <span>
                {dropZoneText}{' '}
                <span className={styles.AddUserCSVBlue}>{dropZoneBrowse}</span>
              </span>
            )}
          </p>
        )}
      </div>
    </div>
  );
};

AddUserDropZone.propTypes = {
  getInputProps: func.isRequired,
  getRootProps: func.isRequired,
  isDragActive: bool.isRequired,
  file: arrayOf(object),
};

AddUserDropZone.defaultProps = {
  file: null,
};

export default AddUserDropZone;
