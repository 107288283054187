import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  memo,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { shape, number, string, arrayOf, bool } from 'prop-types';
import isEqual from 'lodash/isEqual';

import defaultCarImg from 'assets/booking/riide-car.png';
import { MAP_MARKER_TYPES } from 'constants/map';
import { locationType } from 'constants/propTypes';
import messages from './messages';
import FlyLine from 'components/FlyLine';

import MapMarker from 'components/MapMarker';

import { getMap } from 'store/slices/bookingsSlice';
import { STATE_COMPLETED } from 'constants/bookingStates';
import { DEFAULT_ZOOM, isLatitude, isLongitude } from 'utils/mapUtils';

const BookingMap = ({
  pickupDetails,
  destinationDetails,
  pickupETA,
  driverLocations,
  brandedCarImageUrl,
  companyCoordinates,
  bookingState,
  displayETA,
}) => {
  const dispatch = useDispatch();
  const promiseRef = useRef();
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [currentCoordinates, setCurrentCoordinates] = useState(
    companyCoordinates
  );
  const scale = useMemo(() => Math.pow(2, zoom), [zoom]);

  useEffect(() => {
    if (!map) {
      return;
    }

    // eslint-disable-next-line no-undef
    google.maps.event.addListener(map, 'zoom_changed', function () {
      setZoom(map.getZoom());
    });
  }, [map]);

  useEffect(() => {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {}
      );
      // eslint-disable-next-line no-empty
    } catch {}
  }, []);

  const fitBounds = useCallback(() => {
    let bounds = new maps.LatLngBounds();
    for (let marker of markers) {
      bounds.extend(new maps.LatLng(marker.lat, marker.lng));
    }
    const mapPadding = 100; // Magic number, makes the UFO above markers fit within bounds.
    map.fitBounds(bounds, mapPadding);
    const boundsFromMap = map.getBounds();
    const northBound = boundsFromMap.getNorthEast();
    const southBound = boundsFromMap.getSouthWest();
    if (promiseRef.current) {
      promiseRef.current.abort();
    }
    if (northBound && southBound && isLatitude(markers[0]?.lat)) {
      promiseRef.current = dispatch(
        getMap({
          topLeftLat: northBound.lat(),
          topLeftLng: southBound.lng(),
          bottomRightLat: southBound.lat(),
          bottomRightLng: northBound.lng(),
          markerLat: markers[0].lat,
          markerLng: markers[0].lng,
        })
      );
    }
  }, [dispatch, map, maps, markers]);

  useEffect(() => {
    if (
      maps &&
      map &&
      isLatitude(markers[0]?.lat) &&
      isLatitude(markers[1]?.lat)
    ) {
      const newBounds = new maps.LatLngBounds();
      for (let marker of markers) {
        newBounds.extend(new maps.LatLng(marker.lat, marker.lng));
      }
    }
  }, [map, maps, destinationDetails, pickupDetails, scale, markers]);

  useEffect(() => {
    if (
      !(
        isLatitude(markers[0]?.lat) &&
        isLatitude(markers[1]?.lat) &&
        map &&
        maps
      )
    )
      return;
    fitBounds();
  }, [map, maps, markers, fitBounds]);

  useEffect(() => {
    if (
      !(
        isLatitude(pickupDetails.lat) ||
        isLongitude(pickupDetails.lng) ||
        isLatitude(destinationDetails?.lat) ||
        isLongitude(destinationDetails?.lng)
      )
    )
      return;
    setMarkers([
      { lat: pickupDetails.lat, lng: pickupDetails.lng },
      {
        lat: destinationDetails.lat,
        lng: destinationDetails.lng,
      },
    ]);
  }, [destinationDetails, pickupDetails]);

  const mapValues = useMemo(
    () => ({
      center: {
        lat: isLatitude(pickupDetails?.lat)
          ? pickupDetails.lat
          : currentCoordinates.lat,
        lng: isLongitude(pickupDetails?.lng)
          ? pickupDetails.lng
          : currentCoordinates.lng,
      },
      zoom: DEFAULT_ZOOM,
    }),
    [pickupDetails, currentCoordinates]
  );

  const flyLineProps = useMemo(() => {
    if (!maps || !map || !pickupDetails || !destinationDetails) {
      return;
    }

    function latLngToPixel(latLng) {
      const mapProjection = map.getProjection();
      const point = mapProjection.fromLatLngToPoint(latLng);

      return {
        x: point.x * scale,
        y: point.y * scale,
      };
    }

    return {
      lat: Math.max(pickupDetails.lat, destinationDetails.lat),
      lng: Math.min(pickupDetails.lng, destinationDetails.lng),
      from: latLngToPixel(
        new maps.LatLng(pickupDetails.lat, pickupDetails.lng)
      ),
      to: latLngToPixel(
        new maps.LatLng(destinationDetails.lat, destinationDetails.lng)
      ),
      isAnimating: bookingState !== STATE_COMPLETED,
    };
  }, [maps, map, pickupDetails, destinationDetails, bookingState, scale]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.REACT_APP_MAPS_API_KEY,
      }}
      center={mapValues.center}
      defaultCenter={companyCoordinates}
      defaultZoom={mapValues.zoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        setMaps(maps);
        setMap(map);
      }}
      options={{
        disableDefaultUI: true,
        gestureHandling: 'none',
        zoomControl: false,
        draggableCursor: 'default',
      }}
    >
      {driverLocations?.map(({ id, lat, lng }) => (
        <MapMarker
          key={id}
          type={MAP_MARKER_TYPES.VEHICLE}
          lat={lat}
          lng={lng}
          vehicleImageSrc={brandedCarImageUrl || defaultCarImg}
          vehicleImageAlt={messages.carImgAlt}
        />
      ))}

      {pickupDetails && (
        <MapMarker
          type={MAP_MARKER_TYPES.PICKUP}
          lat={pickupDetails.lat}
          lng={pickupDetails.lng}
          estimatedTime={displayETA && pickupETA.value}
          isLoading={pickupETA.isLoading}
          address={pickupDetails.address}
        />
      )}

      {destinationDetails && (
        <MapMarker
          type={MAP_MARKER_TYPES.DROP_OFF}
          lat={destinationDetails.lat}
          lng={destinationDetails.lng}
          address={destinationDetails.address}
        />
      )}

      {pickupDetails.lat !== null &&
        destinationDetails?.lat !== null &&
        flyLineProps && <FlyLine {...flyLineProps} />}
    </GoogleMapReact>
  );
};

BookingMap.propTypes = {
  pickupDetails: locationType,
  destinationDetails: locationType,
  pickupETA: shape({
    isLoading: bool,
    value: number,
  }),
  driverLocations: arrayOf(locationType),
  brandedCarImageUrl: string,
  companyCoordinates: locationType,
  displayETA: bool.isRequired,
};

BookingMap.defaultProps = {
  pickupDetails: {},
  destinationDetails: {},
  driverLocations: [],
  brandedCarImageUrl: '',
  companyCoordinates: {},
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.pickupDetails, nextProps.pickupDetails) &&
  isEqual(prevProps.destinationDetails, nextProps.destinationDetails) &&
  isEqual(prevProps.pickupETA, nextProps.pickupETA) &&
  isEqual(prevProps.driverLocations, nextProps.driverLocations) &&
  isEqual(prevProps.companyCoordinates, nextProps.companyCoordinates) &&
  prevProps.brandedCarImageUrl === nextProps.brandedCarImageUrl &&
  prevProps.displayETA === nextProps.displayETA;

export default memo(BookingMap, areEqual);
