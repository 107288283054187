import React, { memo, useMemo, useState } from 'react';
import { shape } from 'prop-types';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import _isEqual from 'lodash/isEqual';
import { useDispatch } from 'react-redux';
import { deleteFavoritePlace } from 'store/slices/accountsSlice';

import { EditPlace } from 'components/Place';
import DeleteModal from 'components/Modals/DeleteModal';

import { Column } from 'components/GridNew';

import {
  ActivityItemWrapper,
  StyledDashboardRow,
  EditIcon,
  StyledButtonsWrapper,
} from 'views/Dashboard/styles';

import { FAVORITE_PLACES_COLUMNS } from 'utils/dashboardHeaders';

const FavoritePlacesRow = ({ place, isLoading }) => {
  const dispatch = useDispatch();
  const [isEditPlaceModalVisible, setIsEditPlaceModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const FAVORITE_PLACES_DATA = useMemo(
    () => [
      place.name,
      place.address,
      <StyledButtonsWrapper>
        <EditIcon
          alt="edit"
          icon={faEdit}
          onClick={() => setIsEditPlaceModalVisible(true)}
        />
        <EditIcon
          alt="trash"
          icon={faTrashAlt}
          onClick={() => setIsDeleteModalVisible(true)}
        />
      </StyledButtonsWrapper>,
    ],
    [place]
  );

  const FAVORITE_PLACES_ITEMS = useMemo(
    () =>
      FAVORITE_PLACES_COLUMNS.map((col, idx) => ({
        index: idx + 1,
        col,
        data: FAVORITE_PLACES_DATA[idx],
      })),
    [FAVORITE_PLACES_DATA]
  );

  return (
    <>
      <StyledDashboardRow data-testid="favorite-places-row">
        {FAVORITE_PLACES_ITEMS.map((item) => (
          <Column key={item.index} col={item.col}>
            <ActivityItemWrapper>{item.data}</ActivityItemWrapper>
          </Column>
        ))}
      </StyledDashboardRow>
      {isEditPlaceModalVisible && (
        <EditPlace
          isModalOpen={isEditPlaceModalVisible}
          onClose={() => setIsEditPlaceModalVisible(false)}
          place={place}
          isLoading={isLoading}
        />
      )}
      {isDeleteModalVisible && (
        <DeleteModal
          onDelete={() => dispatch(deleteFavoritePlace(place.id))}
          isModalOpen={isDeleteModalVisible}
          onCancel={() => setIsDeleteModalVisible(false)}
          isLoading={isLoading}
          text="Are you sure you want to delete this place?"
        />
      )}
    </>
  );
};

FavoritePlacesRow.propTypes = {
  place: shape({}),
};

FavoritePlacesRow.defaultProps = {
  place: {
    name: '-',
    address: '-',
  },
};
const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.place, nextProps.place) &&
  prevProps.isLoading === nextProps.isLoading;

export default memo(FavoritePlacesRow, areEqual);
