export const statusDictionary = (status) => {
  const dictionary = {
    requested: 'Booking requested',
    accepted: 'Booking Accepted',
    assigned: 'Assinged to driver',
    'en-route': 'Driver en route',
    arrived: 'Driver arrived',
    'no-show': 'No show',
    'to-destination': 'On board',
    'cancelled-user': 'Cancelled by user',
    'cancelled-dispatch': 'Cancelled by dispatch',
    completed: 'Booking completed',
    scheduled: 'Booking shcedules',
    default: 'Booking requested',
  };
  return Object.prototype.hasOwnProperty.call(dictionary, status)
    ? dictionary[status]
    : dictionary.default;
};
