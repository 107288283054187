import React from 'react';
import { string, bool } from 'prop-types';

import Button from 'components/Button';
import LoaderIcon from 'components/LoaderIcon';
import logo from 'assets/riidelogo.png';
import styles from './UseMobileAppTemplate.module.scss';
import messages from './messages';

const UseMobileAppTemplateContainer = ({ appLink, isLoadingAppLink }) => (
  <div className={styles.UseMobileAppTemplateContainer}>
    <img
      src={logo}
      className={styles.UseMobileAppTemplateLogo}
      alt={messages.imgAlt}
    />
    <h1 className={styles.UseMobileAppTemplateHeader}>{messages.header}</h1>
    <p className={styles.UseMobileAppTemplateText}>{messages.mainText}</p>
    {appLink && (
      <Button
        primary
        renderAs="a"
        linkProperties={{ href: appLink }}
        name={messages.link}
        isRounded
      />
    )}
    {isLoadingAppLink && (
      <div className={styles.UseMobileAppTemplateLoaderWrapper}>
        <LoaderIcon />
      </div>
    )}
  </div>
);

UseMobileAppTemplateContainer.propTypes = {
  appLink: string,
  isLoadingAppLink: bool.isRequired,
};

UseMobileAppTemplateContainer.defaultProps = {
  appLink: '',
};

export default UseMobileAppTemplateContainer;
