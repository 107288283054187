import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as v from 'utils/variables';

export const StyledIcon = styled(FontAwesomeIcon)`
  font-size: ${v.fontSizeMd};
  margin: ${v.spacingMd};
  color: ${v.primary};
`;
export const StyledText = styled.p`
  font-size: ${v.fontSizeSm};
  color: ${v.primary};
`;
export const StyledButton = styled.button`
  cursor: pointer;
  position: absolute;
  padding: ${v.spacingSm};
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  z-index: 99;
  height: 60px;
  background-color: #fafafa;
  border: 0.5px solid ${v.dark};
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  &:hover {
    background: ${v.primary};
    ${StyledText}, ${StyledIcon} {
      color: ${v.white};
    }
  }
`;
