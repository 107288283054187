import * as Yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js/max';

const PHONE_ERROR_MESSAGE = 'Phone number is not valid';

Yup.addMethod(Yup.string, 'isPhoneValid', function () {
  return this.test('is-phone-valid', PHONE_ERROR_MESSAGE, function (value) {
    if (!value) return;
    const phoneNumber =
      value.indexOf('+') > -1
        ? parsePhoneNumber(value)
        : parsePhoneNumber(`+${value}`);
    if (phoneNumber) {
      return phoneNumber.isValid();
    }
  });
});
