import { utcToZonedTime, format, toDate } from 'date-fns-tz';
import { DEFAULT_TIME_ZONE } from 'constants/booking';
import parseISO from 'date-fns/parseISO';

export const FORMAT_WITH_TIME_ZONE = "yyyy-MM-dd'T'HH:mm:ssxx";

export const getFormattedDateWithTimezoneOffset = (dateObject, timeZone) => {
  if (dateObject instanceof Date) {
    const d = toDate(dateObject, "yyyy-MM-dd'T'HH:mm:ss");
    return format(d, FORMAT_WITH_TIME_ZONE, { timeZone: timeZone });
  }
  return null;
};

export const getDateInTimeZoneWithFormat = (
  selectedDate = new Date(),
  timeZone = DEFAULT_TIME_ZONE,
  _format = FORMAT_WITH_TIME_ZONE
) => {
  if (selectedDate instanceof Date) {
    return format(utcToZonedTime(selectedDate, timeZone), _format, {
      timeZone: timeZone,
    });
  } else {
    return format(utcToZonedTime(parseISO(selectedDate), timeZone), _format, {
      timeZone: timeZone,
    });
  }
};

export const getDateInTimeZone = (
  selectedDate = new Date(),
  timeZone = DEFAULT_TIME_ZONE
) => {
  return utcToZonedTime(selectedDate, timeZone);
};

export const getAsapBookingDate = (timeZone = DEFAULT_TIME_ZONE) =>
  getDateInTimeZone(new Date(), timeZone).toISOString();

export function generateOptions({ length, step = 1, type }) {
  const arr = [];
  for (let value = 0; value < length; value += step) {
    const label = value < 10 ? `0${value}` : `${value}`;
    const uniqueValue = `${type}_${value}`;
    arr.push({
      baseValue: value,
      value: uniqueValue,
      label,
      type,
    });
  }
  return arr;
}

export const generateTimeOptions = (minutes = ['00', '30']) => {
  var times = [];
  for (var i = 0; i < 24; i++) {
    for (var j = 0; j < minutes.length; j++) {
      var time = i + ':' + minutes[j];
      if (i < 10) {
        time = '0' + time;
      }
      times.push({ value: time, label: time });
    }
  }
  return times;
};
