import styled from 'styled-components';
import { spacingLg, dimmed } from 'utils/variables';

export const LABEL_STYLE = { fontWeight: 'normal', marginLeft: '2px' };

export const SettingsFormSeparator = styled.hr`
  border: 0;
  height: 1px;
  background-color: ${dimmed};
  margin: ${spacingLg} 0;
  width: 207%;
`;
export const StyledNameWrapper = styled.div`
  position: relative;
`;
