import React, { useState, useEffect, useMemo, memo } from 'react';
import axios from 'axios';
import { components } from 'react-select';
import Select from 'components/Select';
import {
  func,
  arrayOf,
  number,
  instanceOf,
  shape,
  string,
  oneOfType,
  bool,
} from 'prop-types';
import isEqual from 'lodash/isEqual';

import { Col } from 'components/Grid';
import LoaderIcon from 'components/LoaderIcon';
import { getEstimatedFare, cancelEstimateRequest } from 'api/bookings';
import { DEFAULT_TIME_ZONE } from 'constants/booking';

import messages from './messages';
import styles from '../BookingForm.module.scss';
import { bookingFormSelectStyles } from 'constants/customSelectStyles';

const SingleValue = ({ data, ...props }) => (
  <components.SingleValue {...props}>{data.title}</components.SingleValue>
);

function BookingVehicleSelect({
  setFieldValue,
  value,
  vehicleTypes,
  destinationLat,
  destinationLng,
  pickupLat,
  pickupLng,
  pickupDate,
  isDisabled,
  timezone,
}) {
  const [vehiclesWithEstimatedFare, setVehiclesWithEstimatedFare] = useState(
    []
  );
  const [isLoadingVehiclesFare, setIsLoadingVehiclesFare] = useState(false);

  useEffect(() => {
    const hasRequestDetails = !!(
      destinationLat &&
      destinationLng &&
      pickupLat &&
      pickupLng &&
      pickupDate
    );

    if (!hasRequestDetails || !vehicleTypes.length) {
      return;
    }

    if (
      destinationLat &&
      destinationLng &&
      pickupLat &&
      pickupLng &&
      pickupDate
    ) {
      const requests = vehicleTypes.map((vehicle) => {
        setIsLoadingVehiclesFare(true);
        return getEstimatedFare({
          destinationLat,
          destinationLng,
          pickupLat,
          pickupLng,
          pickupDate,
          vehicleKey: vehicle.key,
        }).then(({ data }) => {
          if (data?.data?.price?.label) {
            const estimatedFareLabel = data?.data?.price?.label;
            return {
              id: vehicle.id,
              estimatedFare: estimatedFareLabel,
            };
          }
          return null;
        });
      });
      Promise.all(requests)
        .then((vehicles) => {
          const vehiclesWithFare = vehicles.filter((v) => v !== null);
          setVehiclesWithEstimatedFare(vehiclesWithFare);
          setIsLoadingVehiclesFare(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            setIsLoadingVehiclesFare(false);
          }
        });
    }
  }, [
    destinationLat,
    destinationLng,
    pickupDate,
    pickupLat,
    pickupLng,
    vehicleTypes,
  ]);

  useEffect(() => {
    return () => cancelEstimateRequest();
  }, []);

  const vehicleOptions = useMemo(
    () =>
      vehicleTypes?.length
        ? vehicleTypes.map((item) => {
            const vehicleFareIndex = vehiclesWithEstimatedFare?.findIndex(
              ({ id }) => id === item.id
            );
            const image = item.slider_image_url;
            const title = item.title;
            const priceLabel = isLoadingVehiclesFare ? (
              <span className={styles.LoaderIconWrapper}>
                <LoaderIcon />
              </span>
            ) : (
              vehicleFareIndex > -1 &&
              `${vehiclesWithEstimatedFare[vehicleFareIndex].estimatedFare} est`
            );
            const label = (
              <div className={styles.BookingFormLabelSelect}>
                <div className={styles.BookingFormOptionWrapper}>
                  {image && (
                    <div>
                      <img
                        className={styles.BookingCarSelect}
                        alt={title}
                        src={image}
                      />
                    </div>
                  )}
                  <span className={styles.BookingFormOption}>{title}</span>
                </div>
                <span className={styles.BookingVehiclePriceLabel}>
                  {priceLabel}
                </span>
              </div>
            );

            return {
              title,
              value: item.key,
              key: item.key,
              priceLabel,
              label,
            };
          })
        : [],
    [isLoadingVehiclesFare, vehicleTypes, vehiclesWithEstimatedFare]
  );

  const selectedValue = useMemo(
    () =>
      vehicleOptions?.length && value
        ? vehicleOptions.find((option) => option.value === value) ||
          vehicleOptions[0]
        : null,
    [vehicleOptions, value]
  );

  const selectIsDisabled = useMemo(() => !vehicleTypes.length || isDisabled, [
    isDisabled,
    vehicleTypes,
  ]);

  return (
    <>
      <Col col={5}>
        <label className={styles.BookingFormLabel} htmlFor="vehicle_type">
          {messages.label}
        </label>
      </Col>
      <Col col={7}>
        <div data-testid="booking-form-vehicle-select">
          <Select
            name="vehicle_type"
            value={selectedValue}
            isSearchable={false}
            components={{ SingleValue }}
            onChange={(item) => setFieldValue('vehicle_type', item.value)}
            options={vehicleOptions}
            isDisabled={selectIsDisabled}
            placeholder={messages.placeholder}
            styles={bookingFormSelectStyles(false)}
          />
        </div>
      </Col>
    </>
  );
}

BookingVehicleSelect.propTypes = {
  setFieldValue: func.isRequired,
  value: string,
  vehicleTypes: arrayOf(
    shape({
      key: string.isRequired,
      id: number.isRequired,
      title: string.isRequired,
      slider_image_url: string.isRequired,
    })
  ),
  destinationLat: oneOfType([number, string]),
  destinationLng: oneOfType([number, string]),
  pickupLat: oneOfType([number, string]),
  pickupLng: oneOfType([number, string]),
  pickupDate: oneOfType([instanceOf(Date), string]),
  isDisabled: bool,
  timezone: string,
};

BookingVehicleSelect.defaultProps = {
  value: '',
  vehicleTypes: [],
  destinationLat: null,
  destinationLng: null,
  pickupLat: null,
  pickupLng: null,
  pickupDate: null,
  isDisabled: false,
  timezone: DEFAULT_TIME_ZONE,
};

function areEqual(prevProps, nextProps) {
  return (
    isEqual(prevProps.value, nextProps.value) &&
    isEqual(prevProps.vehicleTypes, nextProps.vehicleTypes) &&
    isEqual(prevProps.destinationLat, nextProps.destinationLat) &&
    isEqual(prevProps.destinationLng, nextProps.destinationLng) &&
    isEqual(prevProps.pickupLat, nextProps.pickupLat) &&
    isEqual(prevProps.pickupLng, nextProps.pickupLng) &&
    isEqual(prevProps.pickupDate, nextProps.pickupDate) &&
    isEqual(prevProps.timezone, nextProps.timezone)
  );
}

export default memo(BookingVehicleSelect, areEqual);
