import React, { memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { shape, func, bool } from 'prop-types';
import { Formik, Form } from 'formik';
import _isEqual from 'lodash/isEqual';

import messages from './messages';
import { commonText } from 'locales/en';

import schema, { FIELDS } from './schema';
import { selectorForOtherAddress } from 'store/slices/bookingsSlice';
import { updateFavoritePlace } from 'store/slices/accountsSlice';
import BasicModal from 'components/Modals/BasicModal';

import {
  LabelWithInput,
  LabelWithTextArea,
  LabelWithAddressSearch,
} from 'components/Label';
import { difference } from 'utils';

const EditPlace = ({ isModalOpen, onClose, place, isLoading }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const { addressResult, serviceStatus } = useSelector(selectorForOtherAddress);

  const onSubmit = (values) => {
    const updatedValues = difference(place, values);
    dispatch(updateFavoritePlace({ id: place.id, ...updatedValues })).then(() =>
      onClose()
    );
  };

  return (
    <BasicModal
      buttonType="submit"
      testId="edit-address-modal"
      isModalOpen={isModalOpen}
      title={messages.editTitle}
      handleModalToggle={onClose}
      primaryTitle={messages.update}
      secondaryTitle={commonText.close}
      onClickSecondary={onClose}
      isPrimaryDisabled={isLoading}
      isPrimaryLoading={isLoading}
      onClickPrimary={() => formRef.current?.submitForm()}
      minHeight="215px"
    >
      <Formik
        validationSchema={schema}
        initialValues={place}
        enableReinitialize
        innerRef={formRef}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          values,
          setFieldValue,
          errors,
          touched,
          setFieldTouched,
          handleBlur,
          dirty,
        }) => {
          return (
            <Form
              data-testid="edit-address-form"
              aria-autocomplete="none"
              autoComplete="off"
            >
              <LabelWithAddressSearch
                label={messages.address}
                id={FIELDS.address}
                name={FIELDS.address}
                hasError={!!(errors.address && touched.address)}
                error={errors.address}
                addressResult={addressResult}
                addressType="other"
                value={values.address}
                serviceStatus={serviceStatus}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                onBlur={handleBlur}
                showSaveAddress={false}
                zIndex={8}
                testId={FIELDS.address}
                fields={{
                  address: FIELDS.address,
                  lat: FIELDS.lat,
                  lng: FIELDS.lng,
                }}
              />
              <LabelWithInput
                testId={FIELDS.name}
                label={messages.name}
                error={errors.name}
                name={FIELDS.name}
                id={FIELDS.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                hasError={!!errors.name && !!touched.name}
              />
              <LabelWithTextArea
                testId={FIELDS.driver_notes}
                label={messages.driver_notes}
                name={FIELDS.driver_notes}
                id={FIELDS.driver_notes}
                value={values.driver_notes}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.driver_notes}
                hasError={!!(errors.driver_notes && touched.driver_notes)}
              />
            </Form>
          );
        }}
      </Formik>
    </BasicModal>
  );
};

EditPlace.propTypes = {
  isModalOpen: bool.isRequired,
  onClose: func.isRequired,
  place: shape({}).isRequired,
  isLoading: bool.isRequired,
};

const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.place, nextProps.place) &&
  prevProps.isLoading === nextProps.isLoading &&
  prevProps.isModalOpen === nextProps.isModalOpen;

export default memo(EditPlace, areEqual);
