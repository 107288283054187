import React, { memo, useMemo, useEffect, useState } from 'react';
import ReactLottie from 'react-lottie';
import { string, bool, shape, arrayOf, number } from 'prop-types';

import { LottieWrapper, Square } from './styles';

const MIN_SIZE = 10;

const Lottie = ({
  options,
  animationData,
  animationStopped,
  preserveAspectRatio,
  frames,
}) => {
  const [isPaused, setPaused] = useState(false);
  const [isStopped, setStopped] = useState(false);
  const [segments, setSegments] = useState();
  const [speed, setSpeed] = useState(1);

  let scaleY = 1;
  let scaleX = 1;
  const { startOnTop, startOnLeft, squareSize, margin } = options;

  const width = useMemo(
    () => (options.width > MIN_SIZE ? options.width : MIN_SIZE),
    [options.width]
  );
  const height = useMemo(
    () => (options.height > MIN_SIZE ? options.height : MIN_SIZE),
    [options.height]
  );

  if (startOnLeft) {
    scaleX = -1;
  }
  if (startOnTop) {
    scaleY = -1;
  }

  useEffect(() => {
    // stop lottie animation to start from beginning and pause when is fully visible
    if (animationStopped && !isPaused) {
      setStopped(true);
      setTimeout(() => {
        setStopped(false);
      }, 100);
      setTimeout(() => {
        setPaused(true);
      }, 700);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationStopped]);

  useEffect(() => {
    if (frames) {
      setTimeout(() => {
        setSegments(frames);
      }, 50);
    }
  }, [frames]);

  useEffect(() => {
    if (options.speed) {
      setTimeout(() => {
        setSpeed(options.speed);
      }, 50);
    }
  }, [options.speed]);

  return (
    <Square
      data-testid="lottie"
      $size={squareSize}
      $margin={margin}
      $width={width}
      $marginFromRight={
        (startOnLeft && startOnTop) || (!startOnLeft && !startOnTop)
      }
    >
      <LottieWrapper
        $scaleY={scaleY}
        $scaleX={scaleX}
        $width={width}
        $height={height}
      >
        <ReactLottie
          speed={speed}
          options={{
            loop: true,
            autoplay: true,
            animationData,
            rendererSettings: {
              preserveAspectRatio,
            },
          }}
          isPaused={isPaused}
          isStopped={isStopped}
          segments={segments}
        />
      </LottieWrapper>
    </Square>
  );
};

Lottie.propTypes = {
  options: shape({}),
  animationData: shape({}).isRequired,
  animationStopped: bool,
  preserveAspectRatio: string,
  frames: arrayOf(number),
};
Lottie.defaultProps = {
  options: {
    loop: true,
  },
  animationStopped: false,
  preserveAspectRatio: 'none',
};

export default memo(Lottie);
