import React from 'react';
import { StyledCheckboxLabel } from './styles';
import {
  TERMS_AND_CONDITIONS_DEFAULT_LINK,
  PRIVACY_POLICY_DEFAULT_LINK,
} from 'constants/appLinks';
import messages from './messages';

const CheckboxLabel = ({ termsUrl, privacyUrl }) => (
  <StyledCheckboxLabel>
    {messages.accept}
    <a
      href={termsUrl || TERMS_AND_CONDITIONS_DEFAULT_LINK}
      target="_blank"
      rel="noopener noreferrer"
    >
      {messages.tcs}
    </a>
    +
    <a
      href={privacyUrl || PRIVACY_POLICY_DEFAULT_LINK}
      target="_blank"
      rel="noopener noreferrer"
    >
      {messages.privacyPolicy}
    </a>
  </StyledCheckboxLabel>
);

export default CheckboxLabel;
