import React, { memo, useState } from 'react';
import { func, bool } from 'prop-types';

import BasicModal from 'components/Modals/BasicModal';
import messages from './messages';
import { Textarea, CharCounter } from './styles';
import { TEXTAREA_LIMIT } from 'constants/booking';
import { commonText } from 'locales/en';

const MessageDriver = ({
  isModalOpen,
  onClose,
  isSendDisabled,
  onClickSend,
}) => {
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const onSend = () => {
    onClickSend && onClickSend(message);
  };

  return (
    <BasicModal
      testId="message-driver-modal"
      isModalOpen={isModalOpen}
      title={messages.messageDriver.title}
      onClickPrimary={onSend}
      onClickSecondary={onClose}
      secondaryTitle={commonText.close}
      primaryTitle={messages.messageDriver.send}
      customFooterComponent={
        <CharCounter>
          {message.length}/{TEXTAREA_LIMIT}
        </CharCounter>
      }
      isPrimaryDisabled={isSendDisabled}
    >
      <Textarea
        placeholder={messages.messageDriver.placeholder}
        data-testid="textArea"
        maxLength={TEXTAREA_LIMIT}
        rows="6"
        value={message}
        onChange={handleChange}
      />
    </BasicModal>
  );
};

MessageDriver.propTypes = {
  isModalOpen: bool,
  isSendDisabled: bool,
  onClose: func.isRequired,
  onClickSend: func.isRequired,
};

MessageDriver.defaultProps = {
  isModalOpen: false,
  isSendDisabled: false,
};

export default memo(MessageDriver);
