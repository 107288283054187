import * as Yup from 'yup';
import { errorMessages } from './messages';

import 'utils/yupMethods';

export const initialValues = {
  name: '',
  working_time: {
    monday: {
      fully_open: true,
      open_hour: '00:00',
      close_hour: '00:00',
    },
    tuesday: {
      fully_open: true,
      open_hour: '00:00',
      close_hour: '00:00',
    },
    wednesday: {
      fully_open: true,
      open_hour: '00:00',
      close_hour: '00:00',
    },
    thursday: {
      fully_open: true,
      open_hour: '00:00',
      close_hour: '00:00',
    },
    friday: {
      fully_open: true,
      open_hour: '00:00',
      close_hour: '00:00',
    },
    saturday: {
      fully_open: true,
      open_hour: '00:00',
      close_hour: '00:00',
    },
    sunday: {
      fully_open: true,
      open_hour: '00:00',
      close_hour: '00:00',
    },
  },
  only_favorite_places: false,
  price_limit: undefined,
  frequency: undefined,
  frequency_interval: 'daily',
};

export const schema = Yup.object().shape({
  name: Yup.string().required(errorMessages.name),
  only_favorite_places: Yup.boolean(),
  price_limit: Yup.number().nullable(),
  frequency: Yup.number().nullable(),
  frequency_interval: Yup.string(),
});
