import React, { useState, useRef, useEffect, memo, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useSelector, useDispatch } from 'react-redux';
import {
  shape,
  arrayOf,
  string,
  bool,
  func,
  number,
  oneOfType,
} from 'prop-types';

import AddressSearchItem from './AddressSearchItem';
import Input from 'components/Input';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { addressSearchPropTypes } from 'constants/propTypes';

import NewAddressButton from 'components/NewAddressButton';
import {
  searchAddress,
  setAddressDetailsByType,
  newBookingFormDataSelector,
  resetAddressLoadingByType,
} from 'store/slices/bookingsSlice';
import { companyDataSelector } from 'store/slices/companySlice';
import { defaultCompanyServiceArea } from 'api/config';
import { StyledWrapper, StyledItem, StyledResults } from './styles';

const AddressSearch = ({
  id,
  name,
  touched,
  errors,
  searchAddressResult,
  addressType,
  setFieldValue,
  value,
  serviceStatus,
  setFieldTouched,
  noErrorMessage,
  onClickNewAddress,
  showSaveAddress,
  zIndex,
  testId,
  isDisabled,
  onBlur,
}) => {
  const promiseRef = useRef();
  const [toggleSearch, setToggleSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const companyData = useSelector(companyDataSelector);
  const newBookingForm = useSelector(newBookingFormDataSelector);
  const { isLoading: isSearching } = newBookingForm[addressType];

  const latitude = companyData.lat ?? defaultCompanyServiceArea.lat;
  const longitude = companyData.lng ?? defaultCompanyServiceArea.lng;

  const searchReference = useRef();
  const listRef = useRef();
  useOutsideClick(searchReference, () => setToggleSearch(false));
  const [searchBoxHeight, setSearchBoxHeight] = useState(0);

  useEffect(
    () => {
      if (!searchValue) return;
      if (searchValue.length >= 3) {
        if (promiseRef.current) {
          promiseRef.current.abort();
        }
        promiseRef.current = dispatch(
          searchAddress({
            address: searchValue,
            type: addressType,
            lat: latitude,
            lng: longitude,
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchValue, addressType, dispatch]
  );
  useEffect(() => {
    setSearchBoxHeight(listRef?.current?.offsetHeight);
  }, [searchAddressResult]);

  const inputProps = useMemo(() => {
    let errorsWithStatus = {};
    if (serviceStatus.isLoading === false && !serviceStatus.hasService) {
      errorsWithStatus = {
        ...errors,
        [name]:
          'There is no taxi service between specified locations, please select a valid route.',
      };
    } else {
      errorsWithStatus = errors;
    }

    const onChange = (event) => {
      setSearchValue(event.target.value);
      setToggleSearch(true);
      setFieldValue(event.target.getAttribute('name'), event.target.value);
      setFieldTouched(`${addressType}_address`, false);
      if (!event.target.value) {
        setFieldValue(`${addressType}_address`, '');
        setFieldValue(`${addressType}_lat`, '');
        setFieldValue(`${addressType}_lng`, '');
      }
    };
    return {
      id,
      name,
      touched,
      errors: errorsWithStatus,
      type: 'text',
      value,
      onChange,
      autoComplete: 'new-password',
      noErrorMessage,
      disabled: isDisabled,
      hasDisabledStyles: isDisabled,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addressType,
    errors,
    id,
    isDisabled,
    name,
    noErrorMessage,
    serviceStatus,
    touched,
    value,
  ]);

  const dispatchSetAddressDetails = ({ addressType, item }) => {
    dispatch(setAddressDetailsByType({ type: addressType, details: item }));
  };

  const handleSelectInput = () => {
    setToggleSearch(false);
    dispatch(resetAddressLoadingByType(addressType));
    setSearchValue('');
  };

  const hasSaveAddressButton = useMemo(
    () => !!searchAddressResult.length && showSaveAddress,
    [searchAddressResult.length, showSaveAddress]
  );
  const handleBlur = (...value) => {
    onBlur(...value);
    setFieldTouched(`${addressType}_address`, true);
  };

  return (
    <StyledWrapper data-testid={testId}>
      <Input
        {...inputProps}
        onBlur={handleBlur}
        textSmall
        bgWhite
        zIndex={zIndex}
      />
      {toggleSearch && searchValue.length >= 3 && (
        <div ref={searchReference}>
          <StyledResults ref={listRef} style={{ zIndex: zIndex - 1 }}>
            {isSearching === false && searchAddressResult.length === 0 ? (
              <StyledItem onClick={() => null} $noResult>
                No results found
              </StyledItem>
            ) : (
              searchAddressResult.map((item, idx) => {
                const key = `${item.name}-${idx}`;
                return (
                  <AddressSearchItem
                    key={key}
                    isFavorite={
                      item.provider === 'favorite' ||
                      item.provider === 'suggested_place'
                    }
                    item={item}
                    addressType={addressType}
                    setSelectedItem={handleSelectInput}
                    setFieldValue={setFieldValue}
                    setAddressDetailsByType={dispatchSetAddressDetails}
                  />
                );
              })
            )}
          </StyledResults>
          {hasSaveAddressButton && (
            <NewAddressButton
              style={{
                top: searchBoxHeight ? `${searchBoxHeight + 34}px` : '214px',
              }}
              onClick={() => {
                setFieldTouched(name, false);
                setToggleSearch(false);
                onClickNewAddress();
              }}
            />
          )}
        </div>
      )}
    </StyledWrapper>
  );
};

AddressSearch.propTypes = {
  name: string.isRequired,
  touched: shape({}),
  errors: shape({}),
  searchAddressResult: arrayOf(shape(addressSearchPropTypes)),
  addressType: string.isRequired,
  setFieldValue: func.isRequired,
  value: string,
  serviceStatus: shape({
    isLoading: bool,
    hasService: bool,
  }).isRequired,
  setFieldTouched: func.isRequired,
  noErrorMessage: bool,
  onClickNewAddress: func,
  showSaveAddress: bool,
  id: string,
  zIndex: oneOfType([number, string]),
  testId: string,
  isDisabled: bool,
};

AddressSearch.defaultProps = {
  touched: {},
  errors: {},
  searchAddressResult: [],
  value: '',
  noErrorMessage: false,
  onClickNewAddress: () => {},
  showSaveAddress: true,
  id: 'address-search',
  zIndex: 'initial',
  testId: 'address-search',
  isDisabled: false,
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.touched, nextProps.touched) &&
  isEqual(prevProps.errors, nextProps.errors) &&
  isEqual(prevProps.searchAddressResult, nextProps.searchAddressResult) &&
  isEqual(prevProps.serviceStatus, nextProps.serviceStatus) &&
  prevProps.name === nextProps.name &&
  prevProps.addressType === nextProps.addressType &&
  prevProps.value === nextProps.value &&
  prevProps.noErrorMessage === nextProps.noErrorMessage &&
  prevProps.showSaveAddress === nextProps.showSaveAddress &&
  prevProps.isDisabled === nextProps.isDisabled;

export default memo(AddressSearch, areEqual);
