import { useLocation, matchPath } from 'react-router-dom';
import { ACTIVITY, TEAM, BILLING, REPORTS, SETTINGS } from 'utils/tabs';
import { routes } from 'routes/routes';

const useGetActivePrimaryTabFromPath = () => {
  const location = useLocation();

  switch (true) {
    case !!matchPath(location.pathname, {
      path: routes.team,
      exact: true,
      strict: true,
    }):
      return TEAM;

    case !!matchPath(location.pathname, {
      path: routes.billing,
      exact: true,
      strict: true,
    }):
      return BILLING;

    case !!matchPath(location.pathname, {
      path: routes.reports,
      exact: true,
      strict: true,
    }):
      return REPORTS;

    case !!matchPath(location.pathname, {
      path: routes.settings,
      exact: true,
      strict: true,
    }):
      return SETTINGS;

    default:
      return ACTIVITY;
  }
};

export default useGetActivePrimaryTabFromPath;
