import React, { memo, useCallback } from 'react';
import { func, bool, string, array, oneOfType, shape } from 'prop-types';
import { routes } from 'routes/routes';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import BasicModal from 'components/Modals/BasicModal';
import { clearAppStatus } from 'store/slices/appStatusSlice';
import {
  resendVerificationCode,
  resetSignUpSteps,
} from 'store/slices/signUpStepsSlice';
import { resetLoginSteps } from 'store/slices/loginStepsSlice';
import messages from './messages';

const ErrorModal = ({
  hasError,
  title,
  subtitle,
  onCancel,
  onClickPrimary,
  primaryTitle,
  secondaryTitle,
  shouldCloseOnEsc,
  meta,
  appLink,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickResend = useCallback(() => {
    dispatch(resendVerificationCode());
  }, [dispatch]);

  const onClickCTA = () => {
    if (onClickPrimary) {
      onClickPrimary();
    } else if (
      primaryTitle.toLowerCase() === messages.createNewAccount ||
      (meta.url === '/users/login/code' && meta.status === 445) ||
      (meta.url === '/users/password/reset' && meta.status === 445) ||
      (meta.url.indexOf('/verifications') > -1 && meta.status === 445) ||
      (meta.url.indexOf('/accounts') > -1 &&
        [445, 442].indexOf(meta.status) > -1)
    ) {
      history.replace(routes.signup);
      dispatch(resetSignUpSteps());
      dispatch(resetLoginSteps());
    } else if (
      primaryTitle.toLowerCase() === messages.login ||
      (meta.url.indexOf('/accounts') > -1 && meta.status === 450)
    ) {
      history.replace(routes.login);
      dispatch(resetSignUpSteps());
      dispatch(resetLoginSteps());
    } else if (
      primaryTitle.toLowerCase() === messages.requestNewCode ||
      (meta.url.indexOf('/verifications') > -1 && meta.status === 454)
    ) {
      onClickResend();
    } else if (
      (primaryTitle.toLowerCase() === messages.appStore ||
        ((meta.url.indexOf('/manager') > -1 ||
          meta.url.indexOf('/invite') > -1) &&
          [454, 451].indexOf(meta.status) > -1)) &&
      appLink
    ) {
      window.open(appLink, '_blank');
    }
    closeModal();
  };

  const onClickCTA2 = () => {
    if (
      secondaryTitle.toLowerCase() === messages.useMobile ||
      (meta.url === '/users/login-manager' &&
        [403, 445].indexOf(meta.status) > -1)
    ) {
      history.replace(routes.login);
      dispatch(resetLoginSteps());
    }
    closeModal();
  };

  const closeModal = () => {
    onCancel && onCancel();
    dispatch(clearAppStatus());
  };

  return (
    <BasicModal
      testId="error-modal"
      isModalOpen={hasError}
      title={title}
      subtitle={subtitle}
      onClose={closeModal}
      onClickPrimary={onClickCTA}
      onClickSecondary={onClickCTA2}
      secondaryTitle={secondaryTitle}
      primaryTitle={primaryTitle}
      type="error"
      shouldCloseOnEsc={shouldCloseOnEsc}
    />
  );
};

ErrorModal.propTypes = {
  hasError: bool,
  onCancel: func,
  onClickPrimary: func,
  primaryTitle: string,
  secondaryTitle: string,
  title: string,
  subtitle: oneOfType([string, array]),
  shouldCloseOnEsc: bool,
  meta: shape({}),
  appLink: string,
};

ErrorModal.defaultProps = {
  hasError: false,
  primaryTitle: '',
  secondaryTitle: '',
  title: '',
  subtitle: '',
  shouldCloseOnEsc: false,
  meta: {
    url: '',
    status: null,
  },
  appLink: null,
};

export default memo(ErrorModal);
