import { axiosGet } from 'api/axiosHelpers';

export const getMapRequest = async (
  topLeftLat,
  topLeftLng,
  bottomRightLat,
  bottomRightLng,
  markerLat,
  markerLng,
  cancelToken
) => {
  return axiosGet({
    url: '/map',
    params: {
      top_left: `${topLeftLat},${topLeftLng}`,
      bottom_right: `${bottomRightLat},${bottomRightLng}`,
      pin: `${markerLat},${markerLng}`,
      all: true,
    },
    cancelToken,
  }).then(({ data, status }) => {
    if (status !== 200) {
      return;
    }
    return data;
  });
};
