import React from 'react';
import { Col } from 'components/Grid';

import styles from './ItemIcon.module.scss';

const Icon = ({ alt, icon, small, withCol = true }) => {
  return withCol ? (
    <Col col={1}>
      <img
        className={small ? styles.ItemIconSmall : styles.ItemIcon}
        alt={alt}
        src={icon}
      />
    </Col>
  ) : (
    <img
      className={small ? styles.ItemIconSmall : styles.ItemIcon}
      alt={alt}
      src={icon}
    />
  );
};

export default Icon;
