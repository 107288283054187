import React, { memo } from 'react';
import { bool, shape } from 'prop-types';
import isEqual from 'lodash/isEqual';

import EditBookingForm from './EditBookingForm';
import ViewBooking from '../ViewBooking';

import 'react-day-picker/lib/style.css';

const EditOrViewBooking = ({ isEdit, editBookingProps, viewBookingProps }) => {
  if (
    (isEdit && !editBookingProps.bookingDetails?.id) ||
    (!isEdit && !viewBookingProps.bookingDetails?.id)
  ) {
    return null;
  }

  return isEdit ? (
    <EditBookingForm {...editBookingProps} />
  ) : (
    <ViewBooking {...viewBookingProps} />
  );
};

EditOrViewBooking.propTypes = {
  isEdit: bool,
  editBookingProps: shape({}),
  viewBookingProps: shape({}),
};

EditOrViewBooking.defaultProps = {
  isEdit: false,
  editBookingProps: {},
  viewBookingProps: {},
};
const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.editBookingProps, nextProps.editBookingProps) &&
  isEqual(prevProps.viewBookingProps, nextProps.viewBookingProps) &&
  prevProps.isEdit === nextProps.isEdit;

export default memo(EditOrViewBooking, areEqual);
