import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import isDate from 'date-fns/isDate';

import { Col } from 'components/Grid';
import { getBookingReport } from 'api/bookings';
import 'scss/overrides/dayPicker.scss';
import 'react-day-picker/lib/style.css';
import messages from './messages';
import { setAppSuccess } from 'store/slices/appStatusSlice';
import { currentAccountSelector } from 'store/slices/accountsSlice';
import { StyledButton, StyledInput, StyledPickerWrapper } from './styles';
import {
  StyledHeader,
  StyledDescription,
  StyledWrapperFlex,
  StyledHeaderWrapper,
  StyledBodyWrapper,
  StyledDashboardHeaderItem,
} from 'views/Dashboard/styles';

const DATE_FORMAT_REQUEST = 'yy-MM-dd';
const DATE_FORMAT_DISPLAYED = 'dd/MM/yyyy';

const Reports = () => {
  const dispatch = useDispatch();
  const account = useSelector(currentAccountSelector);
  const initialState = { from: null, to: null };
  const [state, setState] = useState(initialState);
  const { from, to } = state;
  const modifiers = { start: from, end: to };
  const endDateInputReference = useRef(null);

  const handleDateStartChange = (from) => {
    setState({
      from,
      to,
    });
  };

  const handleDateEndChange = (to) => {
    setState({ to, from });
  };

  const onDownloadReport = () => {
    getBookingReport(
      account.id,
      dateFnsFormat(from, DATE_FORMAT_REQUEST),
      dateFnsFormat(to, DATE_FORMAT_REQUEST)
    ).then(({ data }) =>
      dispatch(
        setAppSuccess({
          title: data?.message,
        })
      )
    );
  };

  const parseDate = (str, format, locale) => {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    if (isDate(parsed)) {
      return parsed;
    }
    return undefined;
  };

  function formatDate(date, format, locale) {
    return dateFnsFormat(date, format, { locale });
  }

  return (
    <>
      <StyledDashboardHeaderItem>Reports</StyledDashboardHeaderItem>
      <StyledWrapperFlex>
        <Col col={11}>
          <StyledHeaderWrapper>
            <StyledHeader>{messages.header}</StyledHeader>
            <StyledDescription>{messages.subtitle}</StyledDescription>
          </StyledHeaderWrapper>
          <StyledBodyWrapper>
            <StyledPickerWrapper>
              <DayPickerInput
                value={from}
                placeholder={messages.startDatePlaceholder}
                onDayChange={handleDateStartChange}
                formatDate={formatDate}
                format={DATE_FORMAT_DISPLAYED}
                parseDate={parseDate}
                inputProps={{ readOnly: true }}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { after: to },
                  modifiers,
                  toMonth: to,
                  className: 'Selectable',
                  onDayClick: () => endDateInputReference?.current?.focus(),
                }}
                component={StyledInput}
              />
              <DayPickerInput
                value={to}
                placeholder={messages.endDatePlaceholder}
                onDayChange={handleDateEndChange}
                formatDate={formatDate}
                format={DATE_FORMAT_DISPLAYED}
                parseDate={parseDate}
                dayPickerProps={{
                  selectedDays: [from, { from, to }],
                  disabledDays: { before: from },
                  modifiers,
                  month: from,
                  fromMonth: from,
                  className: 'Selectable',
                }}
                inputProps={{
                  inputRef: endDateInputReference,
                  readOnly: true,
                }}
                component={StyledInput}
              />
            </StyledPickerWrapper>
            <StyledButton
              disabled={!state.from || !state.to || !account}
              name={messages.downloadReportButton}
              primary
              isRounded
              onClick={onDownloadReport}
            />
          </StyledBodyWrapper>
        </Col>
      </StyledWrapperFlex>
    </>
  );
};

export default Reports;
