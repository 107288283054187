import React, { useState, useEffect, useRef, useMemo, memo } from 'react';
import isEqual from 'lodash/isEqual';
import { useSelector, useDispatch } from 'react-redux';
import { string, shape, func, bool } from 'prop-types';

import UsersSearch from 'components/UsersSearch';
import { FORM_FIELDS_IDS } from '../BookingFormSchema';
import {
  clearUsersResult,
  searchUsers,
  selectPassenger,
  clearPassenger,
} from 'store/slices/bookingsSlice';
const BookingPassengerSearch = ({
  value,
  touched,
  errors,
  handleChange,
  setFieldValue,
  selectedPassenger,
  isGuestUser,
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const promiseRef = useRef();
  const searchUsersResult = useSelector(
    (state) => state.bookings.newBookingForm.data.searchUsersResult
  );
  const [name, setName] = useState('');
  const trimmedName = useMemo(() => name.trim(), [name]);

  useEffect(() => {
    if (trimmedName.length >= 3) {
      if (promiseRef.current) {
        promiseRef.current.abort();
      }
      promiseRef.current = dispatch(searchUsers({ name: trimmedName }));
    } else {
      dispatch(clearUsersResult());
    }
  }, [dispatch, trimmedName]);

  useEffect(() => {
    return () => {
      dispatch(clearPassenger());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedOption = (user) => {
    if (user?.id) {
      dispatch(selectPassenger(user));
      setFieldValue(
        FORM_FIELDS_IDS.username,
        `${user.firstName} ${user.lastName}`
      );
      setFieldValue(FORM_FIELDS_IDS.user_id, user.id);
    }
  };

  const clearSelectedBookingPassenger = () => {
    setFieldValue(FORM_FIELDS_IDS.username, '');
    setFieldValue(FORM_FIELDS_IDS.user_id, '');
    setFieldValue(FORM_FIELDS_IDS.phone, '');
    dispatch(clearPassenger());
  };

  return (
    <UsersSearch
      textSmall
      id={FORM_FIELDS_IDS.username}
      searchResult={searchUsersResult}
      errors={errors}
      touched={touched}
      value={value}
      name={FORM_FIELDS_IDS.username}
      handleChange={(event) => {
        handleChange(event);
        setName(event.target.value);
      }}
      handleSelectedOption={(user) => {
        handleSelectedOption(user);
      }}
      closeDropdownOnSelection
      isClearable={!isDisabled && !!selectedPassenger.id}
      handleClearSelection={clearSelectedBookingPassenger}
      isDisabled={!!selectedPassenger.id || isDisabled}
    />
  );
};

BookingPassengerSearch.propTypes = {
  value: string,
  touched: shape({}),
  errors: shape({}),
  handleChange: func.isRequired,
  setFieldValue: func.isRequired,
  isGuestUser: bool,
  selectedPassenger: shape({}),
  isDisabled: bool,
};

BookingPassengerSearch.defaultProps = {
  value: '',
  touched: {},
  errors: {},
  isGuestUser: false,
  selectedPassenger: {},
  isDisabled: false,
};
const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.touched, nextProps.touched) &&
  isEqual(prevProps.errors, nextProps.errors) &&
  isEqual(prevProps.selectedPassenger, nextProps.selectedPassenger) &&
  prevProps.isGuestUser === nextProps.isGuestUser &&
  prevProps.isDisabled === nextProps.isDisabled &&
  prevProps.value === nextProps.value;

export default memo(BookingPassengerSearch, areEqual);
