import * as Yup from 'yup';

import {
  ACCOUNT_ROLE_FRONTDESK,
  ACCOUNT_ROLE_USER,
} from 'constants/accountUserRoles';
import { validateNotNumber } from 'utils/formValidations';
import { messages } from './messages';

import 'utils/yupMethods';

export const FIRST_NAME_FIELD = 'first_name';
export const LAST_NAME_FIELD = 'last_name';
export const EMAIL_FIELD = 'email';
export const PASSWORD_FIELD = 'password';
export const PHONE_FIELD = 'phone';
export const PHONE_NUMBER_FIELD = 'phone_number';
export const PHONE_COUNTRY_FIELD = 'phone_country';
export const PAYMENT_METHOD_FIELD = 'rfb_payment_method_id';
export const TYPE_FIELD = 'type';
export const GROUP_FIELD = 'group_id';

export const initialValues = {
  [PHONE_FIELD]: '',
  [PHONE_COUNTRY_FIELD]: '',
  [PHONE_NUMBER_FIELD]: '',
  [FIRST_NAME_FIELD]: '',
  [LAST_NAME_FIELD]: '',
  [EMAIL_FIELD]: '',
  [PASSWORD_FIELD]: '',
  [PAYMENT_METHOD_FIELD]: '',
  [TYPE_FIELD]: ACCOUNT_ROLE_USER,
  [GROUP_FIELD]: '',
};

export const addUserSchema = (
  paymentMethodNotRequired = false,
  isEditingUser = false,
  isEditingOwnProfile = false
) =>
  Yup.object().shape({
    ...((!isEditingUser || isEditingOwnProfile) && {
      ...{
        first_name: Yup.string()
          .min(3, 'Please complete your name')
          .matches(/^[a-z\s]+$/i, ({ value }) =>
            validateNotNumber(value, 'Name')
          )
          .required('First name is required'),
        last_name: Yup.string()
          .min(3, 'Please complete your last name')
          .matches(/^[a-z\s]+$/i, ({ value }) =>
            validateNotNumber(value, 'Last name')
          )
          .required('Last name is required'),
      },
    }),
    ...(!isEditingUser && {
      ...{
        [PHONE_FIELD]: Yup.string().when(TYPE_FIELD, (type, schema) =>
          type !== ACCOUNT_ROLE_FRONTDESK
            ? schema.min(3).required('Phone number is required').isPhoneValid()
            : schema
        ),
      },
    }),
    [PHONE_NUMBER_FIELD]: Yup.string().when(TYPE_FIELD, {
      is: (type) => type !== ACCOUNT_ROLE_FRONTDESK,
      then: Yup.string().required('Phone number is required'),
    }),
    [PASSWORD_FIELD]: Yup.string().when(TYPE_FIELD, {
      is: ACCOUNT_ROLE_FRONTDESK,
      then: Yup.string().min(8).required('Password is required'),
    }),
    [EMAIL_FIELD]: Yup.string().when(TYPE_FIELD, {
      is: ACCOUNT_ROLE_FRONTDESK,
      then: Yup.string()
        .email('Email is not valid')
        .required('Email is required'),
    }),
    [PAYMENT_METHOD_FIELD]: Yup.string().when(TYPE_FIELD, (type, schema) => {
      if (paymentMethodNotRequired) return schema;

      return type !== ACCOUNT_ROLE_FRONTDESK
        ? schema.required(messages.choosePaymentMethodErrorMessage)
        : schema;
    }),
  });
