import styled from 'styled-components';
import Button from 'components/Button';
import { spacingSm, spacingLg } from 'utils/variables';

export const StyledButton = styled(Button)`
  padding: ${spacingSm} ${spacingLg};
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
