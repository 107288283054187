import * as Yup from 'yup';
import { validateNotNumber } from 'utils/formValidations';

import 'utils/yupMethods';

const PhoneNumberSchema = Yup.object().shape({
  phone: Yup.string()
    .min(3)
    .required('Phone number is required')
    .isPhoneValid(),
  phone_country: Yup.number(),
  phone_number: Yup.string().required('Phone number is required'),
});
const EmailSchema = Yup.object().shape({
  email: Yup.string().email('Email not valid').required('Email is required'),
});

const NameSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[a-z\s]+$/i, ({ value }) => validateNotNumber(value, 'Name'))
    .min(3, 'Please complete your name')
    .required('Name is required'),
  last_name: Yup.string()
    .matches(/^[a-z\s]+$/i, ({ value }) =>
      validateNotNumber(value, 'Last name')
    )
    .min(3, 'Please complete your last name')
    .required('Last name is required'),
});

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('No password provided.')
    .min(8, 'Password must contain at least 8 characters'),
});

const CodeSchema = Yup.object().shape({
  code_secret_1: Yup.string()
    .matches(/^(\d{1})$/, 'Type one digit')
    .required('Type one digit'),
  code_secret_2: Yup.string()
    .matches(/^(\d{1})$/, 'Type one digit')
    .required('Type one digit'),
  code_secret_3: Yup.string()
    .matches(/^(\d{1})$/, 'Type one digit')
    .required('Type one digit'),
  code_secret_4: Yup.string()
    .matches(/^(\d{1})$/, 'Type one digit')
    .required('Type one digit'),
  code_secret_5: Yup.string()
    .matches(/^(\d{1})$/, 'Type one digit')
    .required('Type one digit'),
  code_secret_6: Yup.string()
    .matches(/^(\d{1})$/, 'Type one digit')
    .required('Type one digit'),
});

const PaymentSchema = Yup.object().shape({
  paymentMethod: Yup.string().min(4).required('Please choose payment method'),
});

const CardSchema = Yup.object().shape({
  cvc: Yup.string()
    .required('Please type your cvc')
    .matches(/^(\d{3})$/, 'Valid cvcnumber should contain 3 digits'),
  card: Yup.string()
    .matches(/^(\d{16})$/, 'Valid card number should contain 16 digits')
    .required('Card number is required'),
  city: Yup.string().min(2).required('City is required'),
  expiry: Yup.string()
    .max(5, 'Not a valid expiration date. Example: MM/YY')
    .matches(/^(\d{2})\/(\d{2})$/, 'Expiration date is not valid')
    .required('Expiration date is required'),
  postcode: Yup.string().min(3).required('Postcode is required'),
  nameOnCard: Yup.string().min(3).required('Name is required'),
});

const BillingSchema = Yup.object().shape({
  accountantName: Yup.string().min(3).required('Name is required'),
  accountantEmail: Yup.string()
    .email('Email not valid')
    .required('Email is required'),
});

export const schemaArray = [
  PhoneNumberSchema,
  CodeSchema,
  EmailSchema,
  PasswordSchema,
  NameSchema,
  PaymentSchema,
  CardSchema,
  BillingSchema,
];

export const initialValues = {
  phone_country: '',
  phone_number: '',
  phone: '',
  iso2: '',
  email: '',
  first_name: '',
  last_name: '',
  code_secret_1: '',
  code_secret_2: '',
  code_secret_3: '',
  code_secret_4: '',
  code_secret_5: '',
  code_secret_6: '',
  paymentMethod: '',
  cvc: '',
  card: '',
  expiry: '',
  city: '',
  postcode: '',
  nameOnCard: '',
  accountantEmail: '',
  accountantName: '',
  password: '',
};
