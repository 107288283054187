/* eslint-disable max-len */
export const messages = {
  companyName: 'Company Name',
  headquarters: 'Headquarters Address',
  city: 'City',
  state: 'State/Region',
  postalCode: 'Postal Code',
  country: 'Country',
  taxId: 'Tax ID',
  spend: 'Expected Monthly Spend',
  header: 'Setup Monthly Invoicing',
  description:
    "Organisations can get many transactions, which can look like fraud to banks. Instead, pay monthly. You'll get a single receipt, and your account won't get interrupted. Complete this application to see if your business is eligible.",
  creditCheck: (companyName = 'Riide') =>
    `In order to set-up monthly billing for your organisation, ${companyName} will be running a credit check on the Company Tax ID provided. You should hear back from us within 5 business days.`,
  disclaimer: (companyName = 'Riide') =>
    `By using monthly billing, ${companyName} will calculate the charge for each transaction and add these to the monthly statement. I agree that ${companyName} may charge the aggregate amount specified in the monthly statement to the Company's card.`,
  agreement: 'I agree to the terms stated above',
  pending: 'PENDING APPROVAL',
  readOnlyHeader: 'Monthly Invoice',
  readOnlyDescription: (companyEmail = 'Riide') =>
    `To make changes to your billing, please contact ${companyEmail}`,
};

export const invoiceErrors = {
  name: 'Company name is a required field',
  country: 'Country is a required field',
  taxId: 'Tax ID is a required field',
  spend: 'Monthly spend must be a number',
  companyAddress: 'Company address is a required field',
  city: 'City is a required field',
  cityFormat: 'City  format is invalid',
  zipcode: 'Postal code is a required field',
  checkbox: 'You must accept the terms and conditions',
};
