import React from 'react';
import { func, string, bool, shape } from 'prop-types';

import BasicModal from 'components/Modals/BasicModal';
import messages from './messages';
import { commonText } from 'locales/en';

const BlockedUser = ({ blockedUser, onClickCreate, onCancel }) => (
  <BasicModal
    testId="blocked-user"
    isModalOpen={blockedUser?.error}
    title={messages.userErrorTitle}
    subtitle={messages.userErrorDescription({
      company: blockedUser?.company,
      userName: blockedUser?.userName,
    })}
    onClickPrimary={onClickCreate}
    onClickSecondary={onCancel}
    handleModalToggle={onCancel}
    secondaryTitle={commonText.close}
    primaryTitle={messages.createNewBooking}
    type="error"
  />
);

BlockedUser.propTypes = {
  blockedUser: shape({
    error: bool,
    company: string,
    userName: string,
  }),
  onCancel: func,
  onClickCreate: func,
};

BlockedUser.defaultProps = {
  blockedUser: {},
  onCancel: () => {},
  onClickCreate: () => {},
};

export default BlockedUser;
