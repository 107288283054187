import * as Yup from 'yup';
import { invoiceErrors } from './messages';
import { ACCOUNT_TYPES } from 'constants/accountTypes';
import { shape, string, bool, oneOf, number } from 'prop-types';

export const initialValues = {
  name: '',
  id: null,
  company_address_region: '',
  company_address_country: '',
  company_address_tax_id: '',
  expected_monthly_spend: null,
  company_name: '',
  company_address_1: '',
  company_address_2: '',
  company_address_city: '',
  company_address_zip_code: '',
  rfbAccountType: ACCOUNT_TYPES.invoice,
  checkbox: false,
};

export const invoiceDataPropShape = shape({
  name: string,
  id: number,
  company_address_region: string,
  company_address_country: string,
  company_address_tax_id: string,
  expected_monthly_spend: number,
  company_name: string,
  company_address_1: string,
  company_address_2: string,
  company_address_city: string,
  company_address_zip_code: string,
  rfbAccountType: oneOf([...Object.values(ACCOUNT_TYPES)]),
  checkbox: bool,
});

export const invoicingSchema = Yup.object().shape({
  name: Yup.string().required(invoiceErrors.name),
  company_address_region: Yup.string(),
  company_address_country: Yup.string().required(invoiceErrors.country),
  company_address_tax_id: Yup.string().required(invoiceErrors.taxId),
  expected_monthly_spend: Yup.number()
    .typeError(invoiceErrors.spend)
    .required(invoiceErrors.spend),
  company_name: Yup.string(),
  company_address_1: Yup.string().required(invoiceErrors.companyAddress),
  company_address_2: Yup.string(),
  company_address_city: Yup.string()
    .matches(/^[a-z\s]+$/i, invoiceErrors.cityFormat)
    .required(invoiceErrors.city),
  company_address_zip_code: Yup.string().required(invoiceErrors.zipcode),
  checkbox: Yup.bool().oneOf([true], invoiceErrors.checkbox).required(),
});
