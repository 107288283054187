import React, { useMemo, useRef, useState, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { number, func, bool, string, shape, array } from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _some from 'lodash/some';

import BookingDayPicker from './BookingDayPicker';
import BookingFormPaymentsSelect from './BookingFormPaymentsSelect';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import { Row, Col } from 'components/Grid';
import BookingMap from './BookingMap';
import CCReceipt from './CCReceipt';
import BookingTimePicker from 'components/Modals/BookingForm/BookingTimePicker';

import FlightDetails from './FlightDetails';
import ValidationFields from './ValidationFields';
import BookingPassengerSearch from './BookingPassengerSearch';
import BookingFormPhone from './BookingFormPhone';
import BookingVehicleSelect from './BookingVehicleSelect';
import {
  BookingFormSchema,
  FORM_FIELDS_IDS,
  initialValues,
} from './BookingFormSchema';
import AddressSearch from 'components/AddressSearch';
import SpacedRow from 'components/Grid/SpacedRow';
import { useOutsideClick } from 'hooks/useOutsideClick';

import styles from './BookingForm.module.scss';
import 'react-day-picker/lib/style.css';

import { getDateInTimeZoneWithFormat } from 'utils/dateTime';
import {
  createBooking,
  createGuestBooking,
  getPreauthId,
  clearCreatedBookingStatus,
  clearPendingBooking,
  clearBookingForm,
  clearCurrentBooking,
} from 'store/slices/bookingsSlice';
import { PICKUP_TYPES, TEXTAREA_LIMIT } from 'constants/booking';
import { ACCOUNT_TYPES } from 'constants/accountTypes';

import { locationType } from 'constants/propTypes';
import { FormCancelButton } from './styles';
import messages from './messages';
import { commonText } from 'locales/en';
import { usePrevious } from 'hooks';

const NewBookingForm = ({
  handleModalToggle,
  companyCoordinates,
  willContinueBooking,
  onSelectNewCardOption,
  companyCountryCode,
  hasPreauth,
  isNotRfbAccount,
  currentAccount,
  serviceStatus,
  onClickAddNewAddress,
  redirectRef,
  formRef,
  clearForm,
  defaultPaymentMethodId,
  paymentMethods,
  isCreating,
  isCreated,
  newBookingFormData,
  newBookingFormValues,
  hasAddCardOption,
  error,
}) => {
  const dispatch = useDispatch();
  const { selectedPassenger, timezone } = newBookingFormData;
  const [isFlightDetailsModalOpen, setIsFlightDetailsModalOpen] = useState(
    false
  );
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);

  const {
    result: destinationAddressResult,
    details: destinationDetails,
  } = newBookingFormData?.destination;
  const {
    result: pickupAddressResult,
    details: pickupDetails,
    eta: pickupETA,
    isInAirport,
  } = newBookingFormData?.pickup;
  const [formValues, setFormValues] = useState(
    !willContinueBooking ? initialValues : {}
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [toggle, setToggle] = useState(false);
  const dayPickerRef = useRef();
  const isInvoiceAccount = useMemo(
    () =>
      currentAccount.type === ACCOUNT_TYPES.invoice ||
      selectedPaymentMethod.type === ACCOUNT_TYPES.invoice,
    [currentAccount.type, selectedPaymentMethod.type]
  );
  useOutsideClick(dayPickerRef, () => setToggle(false));

  const [isASAP, setIsASAP] = useState(formValues.type === PICKUP_TYPES.ASAP);
  const prevIsASAP = usePrevious(isASAP);

  useEffect(() => {
    if (error === 459 && !isFlightDetailsModalOpen) {
      setIsFlightDetailsModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (isCreated) {
      dispatch(clearCreatedBookingStatus());
    }
    if (willContinueBooking) {
      const formDetails = {
        ...newBookingFormValues,
        date: new Date(newBookingFormValues.pickup_at),
        hour: getDateInTimeZoneWithFormat(
          newBookingFormValues.pickup_at,
          timezone,
          'HH:mm'
        ),
        default_phone: newBookingFormValues.phone,
        rfb_payment_method:
          newBookingFormValues.rfb_payment_method || defaultPaymentMethodId,
      };
      setFormValues(formDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [willContinueBooking, isCreated]);

  useEffect(() => {
    if (isInvoiceAccount && !!currentAccount.validation_fields?.length) {
      const formDetails = {
        ...formValues,
        validation_fields: currentAccount.validation_fields.map((item) => ({
          id: item.id,
          type: item.type,
          description: item.description,
          title: item.title,
          values:
            item.type === 'select'
              ? item.values.map((option) => ({
                  ...option,
                  label: option.value,
                }))
              : item.values,
          value: '',
        })),
      };
      setFormValues(formDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount.validation_fields, isInvoiceAccount]);

  const onClickCancel = () => {
    handleModalToggle();
    dispatch(clearPendingBooking());
    dispatch(clearBookingForm());
    dispatch(clearCurrentBooking());
  };

  const onFormSubmissionSuccess = (resetForm) => {
    clearForm(resetForm);
    if (formRef.current?.values.type === PICKUP_TYPES.PRE_BOOKING) {
      handleModalToggle();
    }
  };

  const handleSubmit = async (values) => {
    let { card_id, last4 } = selectedPaymentMethod;
    const isCardAccount = currentAccount.type === ACCOUNT_TYPES.card;
    const _values = Object.assign({}, values);
    delete _values.date;
    if (isCardAccount && currentAccount.rfbDefaultPayment?.card) {
      card_id = currentAccount.rfbDefaultPayment.card.id;
      last4 = currentAccount.rfbDefaultPayment.card.last4;
    }
    if (isInAirport && !values.flight_number) {
      return setIsFlightDetailsModalOpen(true);
    } else if (
      isInvoiceAccount &&
      currentAccount.validation_fields?.length &&
      _some(values.validation_fields, ['value', ''])
    ) {
      return setIsValidationModalOpen(true);
    } else if (hasPreauth && card_id && last4) {
      const { payload } = await dispatch(
        getPreauthId({
          values: _values,
          timezone,
          card_id,
          last4,
          currentAccount,
        })
      );
      if (payload?.preauthId) {
        if (values.user_id) {
          dispatch(
            createBooking({
              values: { ..._values, preauthId: payload.preauthId },
              timezone,
              guestUserId: null,
              referenceElement: redirectRef,
            })
          );
        } else {
          dispatch(
            createGuestBooking({
              values: { ..._values, preauthId: payload.preauthId },
              timezone,
              accountId: currentAccount.id,
              referenceElement: redirectRef,
            })
          );
        }
      }
    } else {
      let bookingValues = _values;
      if (isInvoiceAccount && bookingValues.validation_fields.length > 0) {
        const validationFields = bookingValues.validation_fields.reduce(
          (acc, curr) => (acc = { ...acc, [curr.id]: curr.value }),
          {}
        );
        bookingValues = {
          ..._values,
          validation_fields: validationFields,
        };
      }
      if (_values.user_id) {
        dispatch(
          createBooking({
            values: bookingValues,
            timezone,
            guestUserId: null,
            referenceElement: redirectRef,
          })
        );
      } else {
        dispatch(
          createGuestBooking({
            values: bookingValues,
            timezone,
            accountId: currentAccount.id,
            referenceElement: redirectRef,
          })
        );
      }
    }
    if (isFlightDetailsModalOpen) setIsFlightDetailsModalOpen(false);
    if (isValidationModalOpen) setIsValidationModalOpen(false);
  };

  useEffect(() => {
    if (isCreated) {
      onFormSubmissionSuccess(formRef.current?.resetForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreated]);

  const setBookingTime = (field, value, setFieldValue) => {
    setFieldValue(field, value);

    const type = field === 'type' && value;
    if (type && type !== prevIsASAP) {
      setIsASAP(type === PICKUP_TYPES.ASAP);
    }
  };

  return formValues.type ? (
    <Formik
      validationSchema={BookingFormSchema(isNotRfbAccount)}
      initialValues={
        willContinueBooking
          ? formValues
          : {
              ...formValues,
              rfb_payment_method: defaultPaymentMethodId,
            }
      }
      onSubmit={handleSubmit}
      innerRef={formRef}
      enableReinitialize
    >
      {({
        handleChange,
        values,
        setFieldValue,
        errors,
        touched,
        setFieldError,
        setFieldTouched,
        handleBlur,
      }) => {
        return (
          <Form aria-autocomplete="none" autoComplete="off">
            <Row className={styles.RowWrapper}>
              <Col
                col={6}
                withMarginRight
                className={styles.BookingFormLeftColumn}
              >
                <SpacedRow withMarginLarge>
                  <h2 className={styles.BookingFormHeader}>
                    {messages.newTitle}
                  </h2>
                </SpacedRow>
                <SpacedRow withMarginSmall>
                  <Col col={5}>
                    <label className={styles.BookingFormLabel} htmlFor="pickup">
                      {messages.pickupAddress}
                    </label>
                  </Col>
                  <Col col={7}>
                    <AddressSearch
                      zIndex={9}
                      setFieldValue={setFieldValue}
                      id="pickup"
                      searchAddressResult={pickupAddressResult}
                      addressType="pickup"
                      errors={errors}
                      touched={touched}
                      name="pickup_address"
                      value={values.pickup_address}
                      onBlur={handleBlur}
                      serviceStatus={serviceStatus}
                      setFieldTouched={setFieldTouched}
                      onClickNewAddress={onClickAddNewAddress}
                      showSaveAddress={
                        currentAccount.type === ACCOUNT_TYPES.rfb
                      }
                    />
                  </Col>
                </SpacedRow>
                <SpacedRow withMarginSmall>
                  <Col col={5}>
                    <label
                      className={styles.BookingFormLabel}
                      htmlFor="destination"
                    >
                      {messages.dropOffAddress}
                    </label>
                  </Col>
                  <Col col={7}>
                    <AddressSearch
                      zIndex={7}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      id="destination"
                      searchAddressResult={destinationAddressResult}
                      addressType="destination"
                      errors={errors}
                      touched={touched}
                      name="destination_address"
                      value={values.destination_address}
                      serviceStatus={serviceStatus}
                      setFieldTouched={setFieldTouched}
                      onClickNewAddress={onClickAddNewAddress}
                      showSaveAddress={
                        currentAccount.type === ACCOUNT_TYPES.rfb
                      }
                    />
                  </Col>
                </SpacedRow>
                <SpacedRow withMarginSmall>
                  <Col col={5}>
                    <label className={styles.BookingFormLabel} htmlFor="date">
                      {messages.bookingDateTime}
                    </label>
                  </Col>
                  <Col col={7}>
                    <SpacedRow>
                      <Col col={6}>
                        <BookingDayPicker
                          setToggle={setToggle}
                          toggle={toggle}
                          errors={errors}
                          touched={touched}
                          date={values.date}
                          reference={dayPickerRef}
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                      <Col col={6}>
                        <BookingTimePicker
                          minPrebookTime={newBookingFormData.minPrebookTime}
                          setFieldValue={(field, value) =>
                            setBookingTime(field, value, setFieldValue)
                          }
                          selectedDate={values.date}
                          hasDefaultValue={willContinueBooking}
                          type={values.type}
                          timezone={timezone}
                        />
                      </Col>
                    </SpacedRow>
                  </Col>
                </SpacedRow>
                <hr className={styles.BookingFormSeparator} />
                <SpacedRow withMarginSmall>
                  <Col col={5}>
                    <label
                      className={styles.BookingFormLabel}
                      htmlFor="passenger"
                    >
                      {messages.passengerName}
                    </label>
                  </Col>
                  <Col col={7}>
                    <BookingPassengerSearch
                      isGuestUser={!values.user_id}
                      value={values.username}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      selectedPassenger={selectedPassenger}
                    />
                  </Col>
                </SpacedRow>
                <SpacedRow withMarginSmall>
                  <BookingFormPhone
                    setFieldError={setFieldError}
                    setFieldValue={setFieldValue}
                    userPhone={selectedPassenger?.phone}
                    errors={errors}
                    touched={touched}
                    setFieldTouched={setFieldTouched}
                    defaultCountry={companyCountryCode}
                    defaultValue={values.default_phone}
                  />
                </SpacedRow>
                <CCReceipt
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
                <hr className={styles.BookingFormSeparator} />
                <SpacedRow withMarginMedium>
                  <BookingVehicleSelect
                    setFieldValue={setFieldValue}
                    value={values.vehicle_type}
                    vehicleTypes={newBookingFormData.vehicleTypes}
                    destinationLat={values.destination_lat}
                    destinationLng={values.destination_lng}
                    pickupLat={values.pickup_lat}
                    pickupLng={values.pickup_lng}
                    pickupDate={values.pickup_at}
                    timezone={timezone}
                  />
                </SpacedRow>
                <SpacedRow withMarginLarge alignItemsCenter>
                  <TextArea
                    onChange={handleChange}
                    name="driver_notes"
                    value={values.driver_notes}
                    label={messages.noteForDriver}
                    limit={TEXTAREA_LIMIT}
                    textareaCol={7}
                    errors={errors}
                    touched={touched}
                    onBlur={handleBlur}
                    autoComplete="new-password"
                    withBoldLabel
                  />
                </SpacedRow>
                {paymentMethods && (
                  <SpacedRow withMarginLarge>
                    <BookingFormPaymentsSelect
                      error={errors[FORM_FIELDS_IDS.rfb_payment_method]}
                      hasTouched={touched[FORM_FIELDS_IDS.rfb_payment_method]}
                      isNotRfbAccount={isNotRfbAccount}
                      account={currentAccount}
                      setFieldValue={setFieldValue}
                      selectedValue={values.rfb_payment_method}
                      paymentMethods={paymentMethods}
                      hasAddCardOption={hasAddCardOption}
                      onSelectNewCard={onSelectNewCardOption}
                      onChange={setSelectedPaymentMethod}
                    />
                  </SpacedRow>
                )}
                <input
                  type="hidden"
                  value={currentAccount.id}
                  name="account_id"
                />
                <input type="hidden" value={values.date} name="pickup_at" />
                <hr className={styles.BookingFormSeparator} />
                <SpacedRow around>
                  <FormCancelButton
                    name={commonText.close}
                    onClick={onClickCancel}
                    type="button"
                  />
                  <Button
                    type="submit"
                    primary
                    name={messages.confirm}
                    isRounded
                    disabled={isCreating}
                    isLoading={isCreating}
                    isLarge
                  />
                </SpacedRow>
                {isFlightDetailsModalOpen && (
                  <FlightDetails
                    name="flight_number"
                    value={values.flight_number}
                    handleChange={setFieldValue}
                    isDisabled={!values.flight_number}
                    isLoading={isCreating}
                    onClose={() => setIsFlightDetailsModalOpen(false)}
                  />
                )}
                {isValidationModalOpen && (
                  <ValidationFields
                    name="validation_fields"
                    fields={values.validation_fields}
                    setFieldValue={setFieldValue}
                    onClose={() => setIsValidationModalOpen(false)}
                  />
                )}
              </Col>
              <Col col={6} className={styles.BookingFormMapWrapper}>
                <BookingMap
                  pickupDetails={pickupDetails}
                  destinationDetails={destinationDetails}
                  companyCoordinates={companyCoordinates}
                  pickupETA={pickupETA}
                  driverLocations={newBookingFormData?.driverLocations}
                  brandedCarImageUrl={newBookingFormData?.brandedCarImageUrl}
                  displayETA={isASAP}
                />
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  ) : null;
};

NewBookingForm.propTypes = {
  handleModalToggle: func.isRequired,
  onClickAddNewAddress: func.isRequired,
  onSelectNewCardOption: func.isRequired,
  clearForm: func.isRequired,
  companyCoordinates: locationType.isRequired,
  companyCountryCode: string.isRequired,
  hasPreauth: bool,
  isNotRfbAccount: bool.isRequired,
  isCreated: bool,
  isCreating: bool,
  hasAddCardOption: bool,
  willContinueBooking: bool,
  defaultPaymentMethodId: number,
  currentAccount: shape({}).isRequired,
  serviceStatus: shape({}).isRequired,
  newBookingFormData: shape({}),
  newBookingFormValues: shape({}),
  redirectRef: shape({}).isRequired,
  formRef: shape({}).isRequired,
  paymentMethods: array.isRequired,
};

NewBookingForm.defaultProps = {
  willContinueBooking: null,
  hasPreauth: null,
  isCreated: null,
  isCreating: null,
};

function areEqual(prevProps, nextProps) {
  return (
    _isEqual(prevProps.companyCoordinates, nextProps.companyCoordinates) &&
    prevProps.hasPreauth === nextProps.hasPreauth &&
    prevProps.companyCountryCode === nextProps.companyCountryCode &&
    prevProps.isNotRfbAccount === nextProps.isNotRfbAccount &&
    prevProps.isCreated === nextProps.isCreated &&
    prevProps.isCreating === nextProps.isCreating &&
    prevProps.hasAddCardOption === nextProps.hasAddCardOption &&
    prevProps.willContinueBooking === nextProps.willContinueBooking &&
    prevProps.defaultPaymentMethodId === nextProps.defaultPaymentMethodId &&
    prevProps.error === nextProps.error &&
    _isEqual(prevProps.currentAccount, nextProps.currentAccount) &&
    _isEqual(prevProps.serviceStatus, nextProps.serviceStatus) &&
    _isEqual(prevProps.newBookingFormData, nextProps.newBookingFormData) &&
    _isEqual(prevProps.newBookingFormValues, nextProps.newBookingFormValues) &&
    _isEqual(prevProps.paymentMethods, nextProps.paymentMethods)
  );
}

export default memo(NewBookingForm, areEqual);
