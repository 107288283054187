import {
  ACCOUNT_ROLE_OWNER,
  ACCOUNT_ROLE_ADMIN,
} from 'constants/accountUserRoles';

export const USER_ROLES_DISABLED_FOR_DELETE = [
  ACCOUNT_ROLE_OWNER,
  ACCOUNT_ROLE_ADMIN,
];
export const USER_ROLES_DISABLED_FOR_EDIT_BY_MANAGERS = [
  ACCOUNT_ROLE_OWNER,
  ACCOUNT_ROLE_ADMIN,
];
