import { saveState } from 'utils/localStorage';

const localStorageMiddleware = (store) => (next) => (action) => {
  next(action);
  if (
    !(
      action.type === 'appStatus/setAppError' ||
      action.type.startsWith('signUpSteps') ||
      action.type.endsWith('rejected')
    )
  ) {
    const { accounts, bookings, company, user, groups } = store.getState();
    saveState({
      rfbState: {
        accounts,
        bookings,
        company,
        user,
        groups,
      },
    });
  }
};

export default localStorageMiddleware;
