/* eslint-disable max-len */
const messages = {
  logoText: 'for Business, driven by Riide',
  header: 'Simplify your business travel',
  subheader:
    'Transport teams, impress clients and scale your travel expenses efficiently.',
  buttonSignInTop: 'SIGN IN',
  buttonGetStarted: 'Get Started',
  buttonSignInBottom: 'Sign into My Account',
};

export const getSectionsMessages = (name) => ({
  intro: {
    header: 'Driving your business travel from one place',
    text:
      'Whether you’re making an Airport trip, booking a cab for a client, or a member of your team, simply login and book online. We’ll get you where you need to be!',
  },
  payment: {
    header: 'Pay with the App',
    text: `Invite team members, or clients to your business account via the ${name} app. They can easily book and pay using the account on the ${name} app.`,
  },
  expenses: {
    header: 'Expenses to your inbox',
    text: `We’ll send you email receipts for all Business trips, so your accounts team can stay on top of travel expenses. View historical trips in your ${name} for Business Dashboard.`,
  },
  standard: {
    header: 'Want VIP treatment?',
    text: `Whether you require a chauffeur for an airport, hotel transfer or business meeting or other engagement you can rely on us. We can provide an executive service from a fleet of quality vehicles, charging realistic competitive rates.`,
  },
});

export default messages;
