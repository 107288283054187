import _isEqual from 'lodash/isEqual';
import _reduce from 'lodash/reduce';

const difference = (initialValues = {}, values = {}) =>
  _reduce(
    values,
    function (result, value, key) {
      return _isEqual(value, initialValues[key])
        ? result
        : { ...result, [key]: value };
    },
    {}
  );

export default difference;
