import getMinutes from 'date-fns/getMinutes';
import getHours from 'date-fns/getHours';
import addMinutes from 'date-fns/addMinutes';
import { getDateInTimeZone, generateOptions } from 'utils/dateTime';
import {
  ASAP_TYPE,
  DEFAULT_MINUTE_STEP,
  HOUR_TYPE,
  MINUTE_TYPE,
} from 'constants/booking';

const minutes = generateOptions({
  type: MINUTE_TYPE,
  length: 60,
  step: DEFAULT_MINUTE_STEP,
});
const hours = generateOptions({ type: HOUR_TYPE, length: 24 });

export const ASAP_OPTION = { label: 'ASAP', value: 'asap', type: ASAP_TYPE };
export const groupedOptions = [
  ASAP_OPTION,
  {
    label: 'Hours',
    options: hours,
  },
  {
    label: 'Minutes',
    options: minutes,
  },
];

export const getEnabledMinutes = (minPrebookTime, timezone, selectedHour) => {
  const now = getDateInTimeZone(new Date(), timezone);
  const currentHour = getHours(now);
  const minimumMinute = getMinutes(addMinutes(now, minPrebookTime));

  if (currentHour < selectedHour) {
    return minutes;
  }

  const filteredMinutes = minutes.filter(
    (item) => item.baseValue >= minimumMinute
  );
  return filteredMinutes.length ? filteredMinutes : minutes;
};

export const getEnabledHours = (minPrebookTime, timezone, selectedHour) => {
  const now = getDateInTimeZone(new Date(), timezone);
  const currentHour = getHours(now);

  if (currentHour < selectedHour) {
    return minutes;
  }
  const initialHour =
    60 - getMinutes(now) <= minPrebookTime + DEFAULT_MINUTE_STEP
      ? currentHour + 1
      : currentHour;
  return hours.filter((item) => item.baseValue >= initialHour);
};
