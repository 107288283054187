import { loadState } from 'utils/localStorage';
import { configureStore } from '@reduxjs/toolkit';
import localStorageMiddleware from './middlewares/localStorageMiddleware';
import authMiddleware from './middlewares/authMiddleware';
import rootReducer from 'store/rootReducer';
import { isDevelopment, isTest } from 'utils/getEnvironment';

if (isDevelopment && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

const preloadedState = !isTest ? loadState() : undefined;

const middlewareWithOptions = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        'user/logout/pending',
        'user/logout/fulfilled',
        'bookings/setNewBookingFormValues',
        'accounts/setRedirectTo3DAuth',
      ],
    },
    immutableCheck: false,
  });
export const middleware = (getDefaultMiddleware) =>
  !isTest
    ? middlewareWithOptions(getDefaultMiddleware).concat([
        localStorageMiddleware,
        authMiddleware,
      ])
    : getDefaultMiddleware();

const store = configureStore({
  reducer: rootReducer,
  devTools: isDevelopment,
  preloadedState,
  middleware,
});

export default store;
