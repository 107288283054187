import styled, { css } from 'styled-components';
import * as v from 'utils/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/Button';
import { Link } from 'react-router-dom';

// start action buttons on dashboard row (edit/delete...)
export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  min-width: 60px;
`;
export const EditIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-right: 0.5em;
  color: ${v.mousegray};
  transition: color 0.2s;
  display: none;

  &:hover {
    color: ${v.primary};
  }
`;
// end action buttons on dashboard row (edit/delete...)

export const marginForOverflow = { margin: '0 .5em' };

export const StyledDashboardItemWrapper = styled.div`
  overflow: hidden;
  position: relative;
  margin-left: 0;
  padding: ${v.spacingLg};
  background: ${v.white};
  border-radius: ${v.borderRadiusLarge};
  box-shadow: 0 2px 15px 0 ${v.dimmed};
  min-height: ${(props) => (props.$minHeight ? props.$minHeight : '640px')};
  ${(props) =>
    props.$hasStaticHeight &&
    css`
      height: 77vh;
    `}
  ${(props) =>
    props.$isActivity &&
    css`
      height: 72vh;
    `}
`;
export const DashboardHeaderItem = styled.span`
  color: ${v.charCoal};
  opacity: 0.2;
  font-size: ${v.fontSizeSm};
  display: block;
  font-weight: ${v.fontWeightBold};
  ${marginForOverflow};
  ${(props) =>
    props.$alignRight &&
    css`
      text-align: right;
      flex-grow: 1;
    `}
`;

const paddingForScrollBar = css`
  padding-left: ${v.spacingMd};
  padding-right: ${v.spacingMd};
`;
export const dashboardItemCommon = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
`;
export const DashboardHeaderWrapper = styled.div`
  display: flex;
  ${paddingForScrollBar};
  ${(props) =>
    !props.$noBottomPadding &&
    css`
      padding-bottom: ${v.spacingMd};
    `};

  ${(props) =>
    props.$borderBottom &&
    css`
      border-bottom: 1px solid ${v.dimmed};
      height: 80px;
      align-items: center;
    `}
  ${(props) =>
    props.$isBilling &&
    css`
      justify-content: space-between;
      padding-bottom: ${v.spacingLg};
      align-items: center;
      border-bottom: 1px solid ${v.dimmed};
    `}
`;

export const DashboardScrollView = styled.div`
  height: ${(props) => props.$height || '90%'};
  overflow-y: auto;
  overflow-x: hidden;
  ${(props) =>
    props.$isActivity &&
    css`
      margin-left: 0;
      margin-right: 0;
    `};
  ${(props) =>
    props.$isTeam &&
    css`
      height: 72%;
    `}
  ${(props) =>
    props.$disableScroll &&
    css`
      overflow: hidden;
      position: relative;
    `}
`;
export const ActivityItemWrapper = styled.div`
  color: ${(props) => props.$color || v.gray};
  font-size: ${v.fontSizeSm};
  ${dashboardItemCommon};
  ${marginForOverflow};

  ${(props) =>
    props.$hasTextAlignRight &&
    css`
      padding-right: 0;
      text-align: right;
      flex-grow: 1;
    `}
`;
export const StyledDashboardRow = styled.div`
  background: transparent;
  border: none;
  width: 100%;
  overflow: visible;
  border-bottom: 1px solid ${v.dimmed};
  position: relative;
  height: 90px;
  align-items: center;
  ${paddingForScrollBar};
  color: ${v.charCoal};
  font-size: ${v.fontSizeSm};
  display: flex;
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      &:hover {
        transition: 0.15s;
        background: ${v.shadedgray};
      }
    `};
  &:hover {
    transition: 0.15s;
    ${EditIcon} {
      display: block;
    }
  }
`;
export const StyledDashboardList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: ${v.spacingXl};
`;
export const StyledDashboardWrapper = styled.div`
  display: flex;
  background: ${v.colorLightGreyBlue};
  min-height: 94vh;
  width: 100%;
  padding: ${v.spacingXl} ${v.spacingXl} ${v.spacingMd} ${v.spacingMd};
  @media screen and (min-width: ${v.mobile}) and (max-width: ${v.tablet}) {
    padding: ${v.spacingLg};
  }
`;
export const StyledDashboardHeaderItemSmall = styled(Link)`
  padding-right: ${v.spacingMd};
  padding-left: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: ${v.charCoal};
  opacity: 0.2;
  font-size: ${v.fontSizeLg};
  font-weight: ${v.fontWeightBold};
  text-decoration: none;

  &:focus {
    outline: none;
  }
  ${(props) =>
    props.$active &&
    css`
      opacity: 1;
      color: ${v.lightblack};
      outline: none;
    `}
`;
export const StyledDashboardHeaderItem = styled.span`
  padding: 0 ${v.spacingXs} ${v.spacingSm} 0;
  color: ${v.lightblack};
  font-size: 32px;
  font-weight: ${v.fontWeightBold};
`;
export const StyledDashboardHeader = styled.div`
  display: flex;
  padding-bottom: ${v.spacingMd};
`;
export const StyledDashboardCopyrightsWrapper = styled.div`
  margin-top: auto;
  padding-bottom: ${v.spacingMd};
`;
export const StyledActionText = styled.button`
  color: ${(props) => props.$color || v.primary};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  border: none;
  font-weight: ${(props) => (props.$bold ? '600' : '400')};
  font-size: ${(props) => props.$size || v.fontSizeSm};
  background: transparent;
  ${(props) =>
    props.$paddingRight &&
    css`
      padding-right: ${props.$paddingRight};
    `}

  &:focus {
    outline: none;
  }
`;

export const StyledWrapperFlex = styled.div`
  padding: ${v.spacingLg};
  background: ${v.white};
  border-radius: ${v.borderRadiusLarge};
  box-shadow: 0 2px 15px 0 ${v.dimmed};
  display: flex;
  flex-direction: ${(props) => (props.$column ? 'column' : 'row')};
`;
export const StyledHeader = styled.h1`
  font-size: ${v.fontSizeLg};
  color: ${v.lightblack};
  margin-bottom: ${v.spacingSm};
  font-weight: ${v.fontWeightBold};
`;
export const StyledDescription = styled.h2`
  font-size: ${v.fontSizeSm};
  color: ${v.mousegray};
  margin-bottom: ${v.spacingLg};
`;
export const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledBodyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${(props) => (props?.$column ? 'column' : 'row')};
`;
export const StyledInputWrapper = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props?.$alignStart ? 'flex-start' : 'space-between'};
  flex-direction: ${(props) => (props?.$row ? 'row' : 'column')};
  margin-bottom: ${(props) => (props?.$marginSm ? v.spacingSm : v.spacingMd)};
  ${(props) =>
    props.$noMargin &&
    css`
      margin: 0;
    `};
`;
export const StyledCancelButton = styled(Button)`
  text-align: left;
  width: 150px;
  font-size: ${v.fontSizeSm};
  color: ${v.mousegray};
  background-color: transparent;
  &:disabled {
    cursor: not-allowed;
  }
`;
