import styled, { css } from 'styled-components';
import { darkgray } from 'utils/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { dashboardItemCommon, marginForOverflow } from 'views/Dashboard/styles';

export const StyledCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
export const StyledLoadingIcon = styled(FontAwesomeIcon)`
  color: ${darkgray};
  font-size: 2.5em;
`;
export const StyledBlurryText = styled.div`
  color: transparent;
  text-shadow: 0 0 6px ${darkgray};
  ${dashboardItemCommon};
  ${marginForOverflow};

  ${(props) =>
    props.$alignRight &&
    css`
      padding-right: 0;
      text-align: right;
      flex-grow: 1;
    `}
`;
