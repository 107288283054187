import styled from 'styled-components';
import Button from 'components/Button';
import { spacingXl } from 'utils/variables';

export const StyledButton = styled(Button)`
  min-width: 170px;
  min-height: 49px;
`;
export const StyledAccountWrapper = styled.div`
  width: 11em;
  margin-left: ${spacingXl};
  align-self: center;
`;
