import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';
import { LOCALSTORAGE_KEY } from 'utils/localStorage';

import { fetchMe, updateNameRequest, logoutRequest } from 'api/users';

export const initialState = {
  error: null,
  isLoading: false,
  data: {
    token: null,
  },
};

export const getMe = createAsyncThunk(
  'user/me',
  async (_, { getState, rejectWithValue, extra }) => {
    try {
      const { data } = await fetchMe();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateName = createAsyncThunk(
  'user/update',
  async ({ first_name, last_name }, { getState, rejectWithValue, extra }) => {
    try {
      const { data } = await updateNameRequest(first_name, last_name);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  'user/logout',
  async (_, { getState }) => {
    const token = getState().user.data.token;
    localStorage.removeItem(LOCALSTORAGE_KEY);
    return { token };
  }
);

function userLoading(state) {
  state.isLoading = true;
  state.error = null;
}
function userFailed(state, action) {
  state.isLoading = false;
  if (action.payload) {
    state.error = action.payload.message;
  } else {
    state.error = action.error.message;
  }
}

const getMeFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  const nextData = {
    ...payload,
    name: `${payload.first_name} ${payload.last_name}`,
  };
  if (!isEqual(state.data, nextData)) {
    state.data = nextData;
  }
};

const updateNameFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  const nextData = {
    ...payload,
    name: `${payload.first_name} ${payload.last_name}`,
  };
  if (!isEqual(state.data, nextData)) {
    state.data = nextData;
  }
};

const logoutFulfilled = (state, { payload }) => {
  if (payload.token) logoutRequest(payload.token);
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setMe: getMeFulfilled,
  },
  extraReducers: (builder) => {
    builder.addCase(getMe.pending, userLoading);
    builder.addCase(updateName.pending, userLoading);
    builder.addCase(logout.pending, userLoading);

    builder.addCase(getMe.fulfilled, getMeFulfilled);
    builder.addCase(updateName.fulfilled, updateNameFulfilled);
    builder.addCase(logout.fulfilled, logoutFulfilled);

    builder.addCase(getMe.rejected, userFailed);
    builder.addCase(updateName.rejected, userFailed);
    builder.addCase(logout.rejected, userFailed);
  },
});

export const { setMe } = userSlice.actions;
export default userSlice.reducer;

export const tokenSelector = (state) => state.user.data?.token;
