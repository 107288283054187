export const errorMessages = {
  city: 'City format is invalid',
  name: 'Name is a required field',
};

export default {
  name: 'Group name',
  restrictions: 'Set restrictions for this group',
  time: 'Time',
  timeRestrictionsInfo:
    // eslint-disable-next-line max-len
    "Restrict members of this Group from creating bookings on<br />account between the times selected. Choosing 00:00 - 00:00<br />on a day will block the user from booking all day, or you can<br /> select 'No restrictions'",
  timeSubtitle: 'Limit when users can book trips',
  noRestrictions: 'No restrictions',
  locations: 'Locations',
  onlySaveAddresses: 'Only allow Saved Addresses for Pickup and Dropoff',
  value: 'Value',
  valueSubtitle: 'Set a maximum trip value allowable',
  frequency: 'Frequency',
  create: 'Create Group',
  update: 'Save Changes',
  back: 'Back',
};
