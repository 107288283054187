import {
  FRONDESK_ROLE_OPTIONS,
  MANAGER_AND_USER_ROLE_OPTIONS,
  OWNER_AND_ADMIN_ROLE_OPTIONS,
  MANAGER_ROLE_OPTIONS,
  NEW_USER_ROLE_OPTIONS,
} from 'constants/roleOptions';

import {
  ACCOUNT_ROLE_MANAGER,
  ACCOUNT_ROLE_USER,
  ACCOUNT_ROLE_FRONTDESK,
  ACCOUNT_ROLE_OWNER,
  ACCOUNT_ROLE_ADMIN,
} from 'constants/accountUserRoles';
import {
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  PHONE_FIELD,
  PHONE_COUNTRY_FIELD,
  PHONE_NUMBER_FIELD,
} from './addUserSchema';

export const getEditRoleOptions = (userRole, isManagerEditHimself = false) => {
  let editRoleOptions;
  switch (userRole) {
    case ACCOUNT_ROLE_USER:
    case ACCOUNT_ROLE_MANAGER: {
      if (isManagerEditHimself) {
        editRoleOptions = MANAGER_ROLE_OPTIONS;
      } else {
        editRoleOptions = MANAGER_AND_USER_ROLE_OPTIONS;
      }

      break;
    }
    case ACCOUNT_ROLE_FRONTDESK: {
      editRoleOptions = FRONDESK_ROLE_OPTIONS;
      break;
    }
    case ACCOUNT_ROLE_ADMIN:
    case ACCOUNT_ROLE_OWNER: {
      editRoleOptions = OWNER_AND_ADMIN_ROLE_OPTIONS;
      break;
    }
    default:
      editRoleOptions = NEW_USER_ROLE_OPTIONS;
  }

  return editRoleOptions;
};

export const resetUserFormNameFields = (setFieldValue, setFieldTouched) => {
  setFieldValue(FIRST_NAME_FIELD, '');
  setFieldValue(LAST_NAME_FIELD, '');
  setFieldTouched(FIRST_NAME_FIELD, false);
  setFieldTouched(LAST_NAME_FIELD, false);
};

export const resetUserFormPhoneFields = (setFieldValue, setFieldTouched) => {
  setFieldValue(PHONE_FIELD, '');
  setFieldValue(PHONE_COUNTRY_FIELD, '');
  setFieldValue(PHONE_NUMBER_FIELD, '');
  setFieldTouched(PHONE_FIELD, false);
  setFieldTouched(PHONE_COUNTRY_FIELD, false);
  setFieldTouched(PHONE_NUMBER_FIELD, false);
};
