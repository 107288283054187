import React from 'react';
import { string } from 'prop-types';
import {
  TERMS_AND_CONDITIONS_DEFAULT_LINK,
  PRIVACY_POLICY_DEFAULT_LINK,
} from 'constants/appLinks';
import styles from './SignUpSteps.module.scss';
import {
  StyledHeaderWrapper,
  StyledHeader,
  StyledRow,
} from 'views/Login/styles';
import { lastStep as messages } from './messages';

const LastStep = ({ appCompanyName, privacyUrl, termsUrl }) => (
  <>
    <StyledHeaderWrapper>
      <StyledHeader>{messages.header}</StyledHeader>
    </StyledHeaderWrapper>
    <StyledRow>
      <p className={styles.SignUpDisclaimer}>
        {messages.byClicking(appCompanyName)}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={styles.SignUpDisclaimerLink}
          href={termsUrl}
        >
          {messages.termsOfUse}
        </a>
        {messages.acknowledge}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={styles.SignUpDisclaimerLink}
          href={privacyUrl}
        >
          {messages.privacyPolicy}
        </a>
        .
      </p>
    </StyledRow>
  </>
);

LastStep.propTypes = {
  appCompanyName: string,
  privacyUrl: string,
  termsUrl: string,
};
LastStep.defaultProps = {
  appCompanyName: 'Riide',
  privacyUrl: PRIVACY_POLICY_DEFAULT_LINK,
  termsUrl: TERMS_AND_CONDITIONS_DEFAULT_LINK,
};

export default LastStep;
