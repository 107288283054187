import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faCheck,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import { green, primary, brownish } from 'utils/variables';
import { modalStatusType } from 'constants/propTypes';

const Status = ({ type }) => {
  let icon;
  let color;
  if (type === 'success') {
    icon = faCheck;
    color = green;
  } else if (type === 'info') {
    icon = faExclamationTriangle;
    color = primary;
  } else if (type === 'error') {
    icon = faTimes;
    color = brownish;
  }

  return <FontAwesomeIcon size="lg" icon={icon} color={color} />;
};

Status.propTypes = {
  type: modalStatusType.isRequired,
};

export default memo(Status);
