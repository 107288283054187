import { createGlobalStyle } from 'styled-components';
import {
  colorLightGreyBlue,
  fontFamilyPrimary,
  fontSizeMd,
} from 'utils/variables';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    background-color: ${(props) =>
      props.$isDashboard ? colorLightGreyBlue : 'white'};
    font-size: ${fontSizeMd};
    font-family: ${fontFamilyPrimary};
    box-sizing: border-box;
    input, button, textarea {
      font-family: ${fontFamilyPrimary};
    }
    input[type='text'] {
      text-transform: capitalize !important;
    }
  }
`;
export default GlobalStyle;
