import React, { useState } from 'react';
import { Row, Col } from 'components/Grid';
import Input from 'components/Input';

import styles from './BookingForm.module.scss';
import messages from './messages';

function CCReceipt({ values, handleChange, errors, touched }) {
  const [showField, setShowField] = useState(false);
  return (
    <>
      <Row
        center
        withMarginMedium
        className={styles.BookingFormEmailCCButtonWrapper}
      >
        <button
          type="button"
          onClick={() => setShowField(!showField)}
          className={styles.BookingFormButton}
        >
          {messages.ccEmailTitle}
        </button>
      </Row>
      {showField && (
        <Row withMarginSmall withSpaceBetween alignItemsCenter>
          <Col col={5}>
            <label className={styles.BookingFormLabel} htmlFor="mobile">
              {messages.ccEmail}
            </label>
          </Col>
          <Col col={7}>
            <Input
              onChange={handleChange}
              name="cc_receipt"
              id="cc_receipt"
              bgWhite
              errors={errors}
              touched={touched}
              values={values.cc_receipt}
              type="email"
            />
          </Col>
        </Row>
      )}
    </>
  );
}

export default CCReceipt;
