import { axiosGet, axiosDelete, axiosPatch, axiosPost } from './axiosHelpers';

export const getGroupsRequest = (id) =>
  axiosGet({
    url: `/accounts/${id}/groups`,
  }).then(({ data }) => data);

export const getGroupMembersRequest = (accountId, id) =>
  axiosGet({
    url: `/accounts/${accountId}/members?group_id=${id}`,
  }).then(({ data }) => data);

export const createGroupRequest = (accountId, payload) =>
  axiosPost({
    url: `/accounts/${accountId}/groups`,
    data: {
      ...payload,
      frequency: payload.frequency ?? 0,
      price_limit: payload.price_limit ?? 0,
      only_favorite_places: payload.only_favorite_places ? 1 : 0,
    },
  }).then(({ data }) => data);

export const updateGroupRequest = (id, payload) =>
  axiosPatch({
    url: `/accounts/groups/${id}`,
    data: payload,
  }).then(({ data }) => data);

export const deleteGroupRequest = (id) =>
  axiosDelete({
    url: `/accounts/groups/${id}`,
  }).then(({ data }) => data);
