import React, { useMemo } from 'react';
import { func, number, object, string, bool } from 'prop-types';

import TextAreaCounter from './TextAreaCounter';
import InputError from 'components/InputError';
import { Col } from 'components/Grid';

import { StyledTextArea, StyledLabel, StyledCol } from './styles';

const TextArea = ({
  value,
  name,
  label,
  limit,
  onChange,
  errors,
  touched,
  textareaCol,
  withBoldLabel,
  isDisabled,
  ...textAreaProps
}) => {
  const hasError = useMemo(() => !!(touched?.[name] && errors?.[name]), [
    errors,
    name,
    touched,
  ]);

  return (
    <>
      <Col col={5}>
        <StyledLabel
          data-testid="label"
          htmlFor="textarea"
          $isBold={withBoldLabel}
        >
          {label}
        </StyledLabel>
      </Col>
      <StyledCol col={textareaCol}>
        <StyledTextArea
          name={name}
          placeholder="Type a message"
          data-testid="textArea"
          maxLength={limit}
          value={value}
          onChange={onChange}
          disabled={isDisabled}
          $hasError={hasError}
          {...textAreaProps}
        />
        <InputError hasError={hasError} error={errors[name]} errorCanFit />
        <TextAreaCounter limit={limit} textArea={value} />
      </StyledCol>
    </>
  );
};

TextArea.defaultProps = {
  touched: {},
  errors: {},
  value: '',
  textareaCol: 6,
  withBoldLabel: false,
};

TextArea.propTypes = {
  value: string,
  name: string.isRequired,
  label: string.isRequired,
  limit: number.isRequired,
  onChange: func.isRequired,
  errors: object,
  touched: object,
  textareaCol: number,
  withBoldLabel: bool,
};

export default TextArea;
