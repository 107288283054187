import React, { memo } from 'react';
import { func, number, object, string, bool } from 'prop-types';

import TextAreaCounter from 'components/TextArea/TextAreaCounter';
import { StyledTextArea } from 'components/TextArea/styles';
import InputError from 'components/InputError';

import LabelContainer from './LabelContainer';
import { TEXTAREA_LIMIT } from 'constants/booking';

const WithTextArea = ({
  name,
  id = name,
  value,
  label,
  limit,
  onChange,
  error,
  hasError,
  isDisabled,
  inputSize,
  labelSize,
  placeholder,
  testId,
  errorCanFit,
  ...textAreaProps
}) => {
  return (
    <LabelContainer
      testId={testId}
      labelSize={labelSize}
      inputSize={inputSize}
      label={label}
      id={id}
      name={name}
      hasError={hasError}
    >
      <StyledTextArea
        id={id}
        name={name}
        placeholder={placeholder}
        maxLength={limit}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
        $hasError={hasError}
        {...textAreaProps}
      />
      <TextAreaCounter limit={limit} textArea={value} />
      <InputError
        errorCanFit={errorCanFit}
        hasError={hasError}
        error={error}
        absolute
      />
    </LabelContainer>
  );
};

WithTextArea.propTypes = {
  value: string,
  name: string.isRequired,
  label: string,
  limit: number,
  onChange: func.isRequired,
  errors: object,
  touched: object,
  labelSize: number,
  inputSize: number,
  placeholder: string,
  isDisabled: bool,
  errorCanFit: bool,
  hasError: bool,
};

WithTextArea.defaultProps = {
  touched: {},
  errors: {},
  value: '',
  labelSize: 4,
  inputSize: 8,
  placeholder: 'Type a message',
  limit: TEXTAREA_LIMIT,
  errorCanFit: false,
  hasError: false,
};

export default memo(WithTextArea);
