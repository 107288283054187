import React, { memo, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { func, shape } from 'prop-types';

import BasicModal from 'components/Modals/BasicModal';
import { LabelWithInput } from 'components/Label';

import { editCard } from 'store/slices/accountsSlice';
import t from 'locales/en';
import messages from './messages';
import { LABEL_STYLE, INPUT_STYLE } from './styles';

const EditCard = ({ handleModalClose, card }) => {
  const isUpdating = useSelector((state) => state.accounts.isUpdating);
  const dispatch = useDispatch();
  const exp_month =
    card?.exp_month >= 2
      ? `${card?.exp_month}`?.padStart(2, '0')
      : card?.exp_month;

  const [values, setValues] = useReducer(
    (prevState, nextState) => ({
      ...prevState,
      ...nextState,
    }),
    {
      exp_date: `${exp_month}/${`${card?.exp_year}`.substring(2)}` || '',
      exp_month,
      exp_year: card?.exp_year,
      address_zip: card?.address_zip,
    }
  );
  const handleSubmit = async () => {
    const _values = { ...values };
    delete _values.exp_date;
    if (_values.address_zip === card.address_zip) delete _values.address_zip;

    const action = await dispatch(
      editCard({
        id: card.id,
        values: _values,
      })
    );
    if (action.type.endsWith('fulfilled')) {
      handleModalClose();
    }
  };

  const handleChangeExpiry = (e) => {
    e.target.value = e.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        '0$1/' // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        '$1/' // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        '0$1/$2' // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        '$1/$2' // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        '0' // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d/]|^[/]*$/g,
        '' // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        '/' // Prevent entering more than 1 `/`
      );

    setValues({
      exp_date: e.target.value,
      exp_month: e.target.value.substring(0, 2),
      exp_year: `20${e.target.value.substring(3, 5)}`,
    });
  };

  const onBackspaceExpiry = (e) => {
    if (e.key === 'Backspace' && values.exp_date.substring(2) === '/') {
      setValues({
        exp_date: `${values.exp_date.substring(0, 2)}`,
      });
    }
  };

  const onChangeZipCode = (e) => {
    setValues({
      address_zip: e.target.value,
    });
  };

  return (
    <BasicModal
      isModalOpen
      title={messages.header}
      onClickPrimary={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
      onClickSecondary={handleModalClose}
      secondaryTitle={t.common.close}
      primaryTitle={messages.update}
      buttonType="submit"
      renderAsForm
      isPrimaryLoading={isUpdating}
      isPrimaryDisabled={values.exp_date.length < 5}
    >
      <LabelWithInput
        labelStyle={LABEL_STYLE}
        testId="card_number"
        label={messages.cardNumber}
        name="card_number"
        value={card.last4}
        isColumn
        labelSize={12}
        inputStyle={INPUT_STYLE}
        isDisabled
      />
      <LabelWithInput
        labelStyle={LABEL_STYLE}
        testId="expiryDate"
        label={messages.expiryDate}
        name="expiryDate"
        onChange={handleChangeExpiry}
        onKeyDown={onBackspaceExpiry}
        value={values.exp_date}
        labelSize={12}
        isColumn
        inputStyle={INPUT_STYLE}
        maxLength={5}
      />
      <LabelWithInput
        labelStyle={LABEL_STYLE}
        testId="cardHolder"
        label={messages.cardholder}
        name="cardHolder"
        value={card.card_holder_name}
        isColumn
        labelSize={12}
        inputStyle={INPUT_STYLE}
        isDisabled
      />
      <LabelWithInput
        labelStyle={LABEL_STYLE}
        testId="address_zip"
        label={messages.postalCode}
        name="address_zip"
        onChange={onChangeZipCode}
        isColumn
        labelSize={12}
        inputStyle={INPUT_STYLE}
        value={values.address_zip}
      />
    </BasicModal>
  );
};

EditCard.propTypes = {
  handleModalClose: func,
  card: shape({}),
};

EditCard.defaultProps = {
  handleModalClose: () => null,
  card: {},
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.card, nextProps.card);

export default memo(EditCard, areEqual);
