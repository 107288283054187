import styled from 'styled-components';
import Button from 'components/Button';
import { mousegray, spacingXmd } from 'utils/variables';

export const StyledCancelButton = styled(Button)`
  text-align: left;
  color: ${mousegray};
  padding: 10px 0;
  margin-right: ${spacingXmd};
  min-width: 100px;
`;
