import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import { Route } from 'react-router-dom';
import LabeledLandingPage from 'views/LabeledLandingPage';
import Dashboard from 'views/Dashboard';
import { DEFAULT_COMPANY } from 'constants/companies';

const PrivateRoute = ({
  token,
  dashboardProps,
  landingPageProps,
  subdomain,
  appCompanyCode = DEFAULT_COMPANY,
  ...rest
}) => {
  if (!subdomain && appCompanyCode) {
    const { protocol, host } = window.location;
    window.location = `${protocol}//${appCompanyCode}.${host}`;
  }

  if (token) {
    return (
      <Route
        {...rest}
        render={(routeProps) => (
          <Dashboard {...routeProps} {...dashboardProps} />
        )}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <LabeledLandingPage {...routeProps} {...landingPageProps} />
      )}
    />
  );
};
const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.dashboardProps, nextProps.dashboardProps) &&
  isEqual(prevProps.landingPageProps, nextProps.landingPageProps) &&
  isEqual(prevProps.location, nextProps.location) &&
  prevProps.token === nextProps.token &&
  prevProps.subdomain === nextProps.subdomain &&
  prevProps.appCompanyCode === nextProps.appCompanyCode;

export default memo(PrivateRoute, areEqual);
