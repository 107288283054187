import React, { useCallback, useState, memo } from 'react';
import { shape, func, string, bool } from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setAppSuccess, setAppError } from 'store/slices/appStatusSlice';
import Input from 'components/Input';
import { passwordStep as messages } from './messages';
import { resetPasswordRequest } from 'api/users';
import {
  StyledHeaderWrapper,
  StyledHeader,
  StyledSubtitle,
  StyledCol,
  ForgotPassword,
  Spinner,
} from 'views/Login/styles';

const PasswordStep = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  header,
  email,
  isLogin,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPasswordClick = useCallback(() => {
    setIsLoading(true);

    resetPasswordRequest(email)
      .then(() => {
        setIsLoading(false);
        dispatch(
          setAppSuccess({
            title: 'We sent the instructions to your email.',
          })
        );
      })
      .catch((error) => {
        setIsLoading(false);
        dispatch(
          setAppError({
            title: error.message,
          })
        );
      });
  }, [dispatch, email]);

  return (
    <>
      <StyledHeaderWrapper>
        <StyledHeader>{header}</StyledHeader>
        {email && <StyledSubtitle>{email}</StyledSubtitle>}
      </StyledHeaderWrapper>
      <StyledCol>
        <Input
          bgWhite
          isLarge
          errorBgPrimary
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          name="password"
          errors={errors}
          touched={touched}
          placeholder="Password"
          autoFocus
        />
        {isLogin && (
          <ForgotPassword
            onClick={handleForgotPasswordClick}
            $isLoading={isLoading}
          >
            Forgot password?
            {isLoading && <Spinner icon={faSpinner} className="fa-spin" />}
          </ForgotPassword>
        )}
      </StyledCol>
    </>
  );
};

PasswordStep.propTypes = {
  values: shape({}),
  handleChange: func,
  handleBlur: func,
  errors: shape({}),
  touched: shape({}),
  header: string,
  email: string,
  isLogin: bool,
};
PasswordStep.defaultProps = {
  header: messages.createHeader,
  isLogin: false,
};

export default memo(PasswordStep);
