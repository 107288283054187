import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import { shape, string, bool, func } from 'prop-types';
import { faStar, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import { addressSearchPropTypes } from 'constants/propTypes';
import { StyledIcon, StyledItem, StyledText } from './styles';

const AddressSearchItem = ({
  addressType,
  item,
  fields,
  setSelectedItem,
  isFavorite,
  setFieldValue,
  setAddressDetailsByType,
}) => {
  const [street, ...rest] = item.address.split(',');
  const customAddressName = isFavorite && item.name;
  return (
    <StyledItem
      onClick={() => {
        setFieldValue(fields.address, item.address);
        setFieldValue(fields.lat, item.lat);
        setFieldValue(fields.lng, item.lng);
        setAddressDetailsByType({ addressType, item });
        setSelectedItem();
      }}
    >
      <StyledIcon
        data-testid="marker"
        $isFavorite={isFavorite}
        icon={isFavorite ? faStar : faMapMarkerAlt}
      />
      <span>
        <p>{customAddressName || street}</p>
        <StyledText>
          {rest.length === 0 ? street : rest.map((item) => item)}
        </StyledText>
      </span>
    </StyledItem>
  );
};

AddressSearchItem.propTypes = {
  addressType: string.isRequired,
  item: shape(addressSearchPropTypes).isRequired,
  setSelectedItem: func,
  isFavorite: bool,
  setFieldValue: func.isRequired,
  setAddressDetailsByType: func,
  fields: shape({}),
};

AddressSearchItem.defaultProps = {
  isFavorite: false,
  setSelectedItem: () => {},
  setAddressDetailsByType: () => {},
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.item, nextProps.item) &&
  isEqual(prevProps.fields, nextProps.fields) &&
  prevProps.isFavorite === nextProps.isFavorite &&
  prevProps.addressType === nextProps.addressType;

export default memo(AddressSearchItem, areEqual);
