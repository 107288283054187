import React, { memo } from 'react';
import ReactSelect from 'react-select';
import {
  object,
  string,
  arrayOf,
  func,
  shape,
  bool,
  oneOfType,
  number,
  array,
} from 'prop-types';

import { customSelectStyles } from 'constants/customSelectStyles';

const Select = ({
  styles,
  placeholder,
  options,
  onChange,
  value,
  isSearchable,
  isDisabled,
  components,
  selectRef,
  ...otherProps
}) => (
  <ReactSelect
    ref={selectRef}
    styles={{ ...customSelectStyles, ...styles }}
    placeholder={placeholder}
    options={options}
    onChange={onChange}
    value={value}
    isSearchable={isSearchable}
    isDisabled={isDisabled}
    components={{ IndicatorSeparator: () => null, ...components }}
    openMenuOnClick={!isDisabled}
    {...otherProps}
  />
);

Select.propTypes = {
  styles: shape({}),
  placeholder: string,
  options: arrayOf(
    shape({
      value: oneOfType([string, number, array, object]),
      label: oneOfType([string, shape({})]).isRequired,
    })
  ),
  onChange: func.isRequired,
  value: oneOfType([arrayOf(shape({})), shape({}), string]),
  isSearchable: bool,
  isDisabled: bool,
  components: shape({}),
  selectRef: object,
};

Select.defaultProps = {
  styles: {},
  placeholder: 'Select...',
  options: [],
  isSearchable: false,
  value: undefined,
  isDisabled: false,
  components: {},
  selectRef: null,
};

export default memo(Select);
