import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { bool, string, func, shape } from 'prop-types';
import {
  StyledButton,
  StyledLink,
  StyledIcon,
  StyledText,
  StyledSpinner,
} from './styles';

const Button = ({
  hidden,
  primary,
  secondary,
  withBlueBorder,
  pink,
  withPinkBorder,
  light,
  alert,
  isRounded,
  type,
  disabled,
  onClick,
  withArrow,
  name,
  isFullWidth,
  small,
  renderAs,
  linkProperties,
  wide,
  isRoundedMedium,
  noPaddingLeft,
  noPaddingLeftRight,
  isLarge,
  flex,
  flexHalf,
  className,
  dataTestId,
  isLoading,
}) => {
  const Component = renderAs === 'a' ? StyledLink : StyledButton;

  return (
    <Component
      data-testid={dataTestId}
      type={type}
      disabled={disabled || isLoading}
      onClick={disabled || isLoading ? () => null : onClick}
      className={className}
      $hidden={hidden}
      $isLoading={isLoading}
      $secondary={secondary}
      $primary={primary}
      $withBlueBorder={withBlueBorder}
      $withPinkBorder={withPinkBorder}
      $pink={pink}
      $small={small}
      $light={light}
      $alert={alert}
      $isRounded={isRounded}
      $isRoundedMedium={isRoundedMedium}
      $wide={wide}
      $isFullWidth={isFullWidth}
      $noPaddingLeft={noPaddingLeft}
      $noPaddingLeftRight={noPaddingLeftRight}
      $isLarge={isLarge}
      $flex={flex}
      $flexHalf={flexHalf}
      {...linkProperties}
    >
      {isLoading && <StyledSpinner icon={faSpinner} className="fa-spin" />}
      <StyledText $isLoading={isLoading}>
        {name}
        {withArrow && (
          <StyledIcon>
            <FontAwesomeIcon icon={faArrowRight} />
          </StyledIcon>
        )}
      </StyledText>
    </Component>
  );
};

Button.propTypes = {
  hidden: bool,
  primary: bool,
  secondary: bool,
  light: bool,
  alert: bool,
  isRounded: bool,
  type: string,
  disabled: bool,
  onClick: func,
  withArrow: bool,
  name: string,
  isFullWidth: bool,
  small: bool,
  withBlueBorder: bool,
  pink: bool,
  withPinkBorder: bool,
  wide: bool,
  isRoundedMedium: bool,
  noPaddingLeft: bool,
  linkProperties: shape({
    href: string,
  }),
  noPaddingLeftRight: bool,
  renderAs: string,
  className: string,
  dataTestId: string,
};

Button.defaultProps = {
  renderAs: 'button',
  onClick: () => null,
  withBlueBorder: false,
  pink: false,
  withPinkBorder: false,
  linkProperties: null,
  wide: false,
  isRoundedMedium: false,
  noPaddingLeft: false,
  noPaddingLeftRight: false,
  className: '',
  dataTestId: 'button',
  hidden: false,
  primary: false,
  secondary: false,
  light: false,
  alert: false,
  isRounded: false,
  disabled: false,
  withArrow: false,
  isFullWidth: false,
  small: false,
  name: '',
  type: null,
};

export default Button;
