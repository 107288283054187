import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { SVG } from '@svgdotjs/svg.js';
import isEqual from 'lodash/isEqual';

const FlyLine = (props) => {
  const svgRef = useRef(null);
  const animationRef = useRef(null);
  const resizeFlyLineAreaBy = 8;
  const {
    from,
    to,
    strokeWidth,
    strokeColor,
    overlayStrokeColor,
    maxQuadraticOffset,
    isAnimating,
  } = props;

  //region Resize FlyLine
  // Tune the positions before calculating width and height values to align with markers correctly
  if (from.x > to.x) {
    from.x += resizeFlyLineAreaBy;
  } else {
    to.x += resizeFlyLineAreaBy;
  }

  if (from.y > to.y) {
    from.y += resizeFlyLineAreaBy;
  } else {
    to.y += resizeFlyLineAreaBy;
  }
  //endregion

  const width = Math.max(strokeWidth + 2, Math.abs(from.x - to.x));
  const height = Math.max(strokeWidth + 2, Math.abs(from.y - to.y));

  const tuneLocationPoints = useCallback(() => {
    // Add or subtract this value from the position values according to the cases below to
    // prevent line paths overflowing from the bounding view
    const pointOffset = strokeWidth / 2 + 1;

    // Subtract these offsets from each position to make positions 0 based.
    const xOffset = Math.min(from.x, to.x);
    const yOffset = Math.min(from.y, to.y);

    from.x -= xOffset;
    from.y -= yOffset;
    to.x -= xOffset;
    to.y -= yOffset;

    if (from.x < to.x) {
      from.x += pointOffset;
      to.x -= pointOffset;
    }

    if (from.x > to.x) {
      from.x -= pointOffset;
      to.x += pointOffset;
    }

    if (from.y < to.y) {
      from.y += pointOffset;
      to.y -= pointOffset;
    }

    if (from.y > to.y) {
      from.y -= pointOffset;
      to.y += pointOffset;
    }

    from.x = Math.max(pointOffset, from.x);
    to.x = Math.max(pointOffset, to.x);
    from.y = Math.max(pointOffset, from.y);
    to.y = Math.max(pointOffset, to.y);

    return { from, to };
  }, [from, to, strokeWidth]);

  // This value is used to make the line curved.
  const calculateQuadraticOffsetPoint = useCallback(
    (from, to) => {
      // center of two points
      const centerPoint = {
        x: (from.x + to.x) / 2,
        y: (from.y + to.y) / 2,
      };

      // Dimensions of the right triangle
      const rightTriangle = {
        xCorner: centerPoint.x - from.x,
        yCorner: centerPoint.y - from.y,
      };

      const hypotenuse = Math.sqrt(
        Math.pow(rightTriangle.xCorner, 2) + Math.pow(rightTriangle.yCorner, 2)
      );

      let quadraticOffset = Math.min(
        maxQuadraticOffset,
        (width < height ? width : height) / 2
      );
      quadraticOffset = quadraticOffset < strokeWidth ? 0 : quadraticOffset;

      const offsetPointScale = quadraticOffset / hypotenuse;

      return {
        x: centerPoint.x - rightTriangle.yCorner * offsetPointScale,
        y: centerPoint.y + rightTriangle.xCorner * offsetPointScale,
      };
    },
    [width, height, strokeWidth, maxQuadraticOffset]
  );

  // Draw SVG
  useLayoutEffect(() => {
    const svg = SVG();
    const draw = svg.addTo(svgRef.current).size(width, height);
    const { from, to } = tuneLocationPoints();
    const offsetPoint = calculateQuadraticOffsetPoint(from, to);

    const basePath = draw
      .path(
        `M${from.x} ${from.y} Q${offsetPoint.x} ${offsetPoint.y} ${to.x} ${to.y}`
      )
      .attr({
        fill: 'none',
        stroke: strokeColor,
        'stroke-width': strokeWidth,
        'stroke-linecap': 'round',
      });

    const overlayPath = draw
      .path(
        `M${from.x} ${from.y} Q${offsetPoint.x} ${offsetPoint.y} ${to.x} ${to.y}`
      )
      .attr({
        fill: 'none',
        stroke: overlayStrokeColor,
        'stroke-width': strokeWidth - 2,
        'stroke-linecap': 'round',
        'stroke-dashoffset': 0,
        'stroke-dasharray': 0,
      });

    const length = basePath.length();

    overlayPath.attr('stroke-dasharray', length);
    overlayPath.attr('stroke-dashoffset', 3 * length);

    animationRef.current = overlayPath.animate({
      duration: 2000,
      delay: 0,
      times: Infinity,
      wait: 200,
    });

    animationRef.current.attr({
      'stroke-dashoffset': length,
    });

    return () => {
      svg.remove();
    };
  }, [
    width,
    height,
    strokeWidth,
    strokeColor,
    overlayStrokeColor,
    tuneLocationPoints,
    calculateQuadraticOffsetPoint,
  ]);

  useEffect(() => {
    if (!animationRef.current) {
      return;
    }

    if (!isAnimating) {
      animationRef.current.reset();
    }

    animationRef.current.active(isAnimating);
  }, [isAnimating, animationRef]);

  return <div ref={svgRef} />;
};

FlyLine.propTypes = {
  from: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number })
    .isRequired,
  to: PropTypes.shape({ x: PropTypes.number, y: PropTypes.number }).isRequired,
  strokeWidth: PropTypes.number,
  strokeColor: PropTypes.string,
  maxQuadraticOffset: PropTypes.number, // This value is used to define how much can line be curved.
  isAnimating: PropTypes.bool,
};

FlyLine.defaultProps = {
  strokeWidth: 6,
  strokeColor: '#646464',
  overlayStrokeColor: '#010005',
  maxQuadraticOffset: 50,
  isAnimating: true,
};

export default React.memo(
  FlyLine,
  (prevProps, nextProps) =>
    isEqual(prevProps.from, nextProps.from) &&
    isEqual(prevProps.to, nextProps.to) &&
    prevProps.strokeWidth === nextProps.strokeWidth &&
    prevProps.strokeColor === nextProps.strokeColor &&
    prevProps.overlayStrokeColor === nextProps.overlayStrokeColor &&
    prevProps.maxQuadraticOffset === nextProps.maxQuadraticOffset
);
