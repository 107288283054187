import React, { useEffect, useState, memo, useRef } from 'react';
import { func, string, number, oneOfType } from 'prop-types';

import { StyledText } from './styles';
import messages from './messages';
import { usePrevious } from 'hooks';

const TIME = 119;
const initialMinutes = `0${Math.floor(TIME / 60)}`;
const initialSeconds = TIME - initialMinutes * 60;

const ResendCode = ({ onClickResend, userId }) => {
  const promiseRef = useRef();
  const prevUserId = usePrevious(userId);
  const [counter, setCounter] = useState({
    initial: TIME,
    minutes: initialMinutes,
    seconds: initialSeconds,
  });

  useEffect(() => {
    var myInterval = setInterval(function () {
      var minutes = Math.floor(counter.initial / 60);
      var seconds = counter.initial - minutes * 60;
      setCounter({
        initial: counter.initial - 1,
        minutes: `0${minutes}`,
        seconds: seconds > 10 ? seconds : `0${seconds}`,
      });
    }, 1000);

    if (counter.initial < 0) {
      clearInterval(myInterval);
    }
    return () => {
      clearInterval(myInterval);
    };
  }, [counter.initial]);

  useEffect(() => {
    if (userId && prevUserId !== userId) {
      setCounter({
        initial: TIME,
        minutes: initialMinutes,
        seconds: initialSeconds,
      });
    }
  }, [userId, prevUserId]);

  const handleClickResend = () => {
    if (!promiseRef.current) {
      promiseRef.current = onClickResend();
    }
  };

  if (counter.initial >= 0) {
    return (
      <StyledText>
        {messages.resendIn} {counter.minutes}:{counter.seconds}
      </StyledText>
    );
  }
  return (
    <StyledText $clickable onClick={handleClickResend}>
      {messages.resend}
    </StyledText>
  );
};

ResendCode.propTypes = {
  onClickResend: func.isRequired,
  userId: oneOfType([string, number]),
};
ResendCode.defaultProps = {
  onClickResend: func,
  userId: '',
};

export default memo(ResendCode);
