import React from 'react';
import { useDispatch } from 'react-redux';
import { func, string } from 'prop-types';

import { clearPendingMember } from 'store/slices/accountsSlice';
import { BasicModalContent } from 'components/Modals/BasicModal';
import { messages } from './messages';

const PendingMemberNotice = ({ firstName, onClickAddMember, onCancel }) => {
  const dispatch = useDispatch();

  const onClickPrimary = () => {
    dispatch(clearPendingMember());
    onClickAddMember();
  };

  const onClickSecondary = () => {
    dispatch(clearPendingMember());
    onCancel();
  };

  return (
    <BasicModalContent
      title={messages.header(firstName)}
      onClickPrimary={onClickPrimary}
      onClickSecondary={onClickSecondary}
      secondaryTitle={messages.done}
      primaryTitle={messages.addNew}
      type="success"
    />
  );
};

PendingMemberNotice.propTypes = {
  firstName: string,
  onCancel: func,
  onClickAddMember: func,
};

PendingMemberNotice.defaultProps = {
  firstName: '',
  onCancel: () => {},
  onClickAddMember: () => {},
};

export default PendingMemberNotice;
