import styled from 'styled-components';
import * as v from 'utils/variables';
import { basicModalStyles } from 'constants/modalStyles';

export const modalStyle = {
  ...basicModalStyles,
  content: {
    ...basicModalStyles.content,
    maxWidth: '645px',
    boxSizing: 'border-box',
  },
};
export const PinWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;
export const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  label {
    color: ${v.mousegray};
    font-size: 12px;
    font-weight: bold;
    flex: 0 1 49%;
  }
`;

export const Code = styled.input`
  width: 56px;
  height: 62px;
  border-radius: 3px;
  border: 3px solid transparent;
  background-color: ${v.shadedgray};
  caret-color: transparent;
  color: ${v.charCoal};
  font-size: 2em;
  outline: none;
  text-align: center;
  box-sizing: border-box;

  &:focus,
  &:active {
    border-bottom: 3px solid ${v.primary};
  }
`;
export const Input = styled.input`
  height: ${v.DEFAULT_INPUT_HEIGHT};
  flex: 0 0 50%;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid ${v.dark};
  background-color: white;
  color: ${v.charCoal};
  font-size: 12px;
  outline: none;
  box-sizing: border-box;
`;
export const SelectWrapper = styled.div`
  flex: 0 0 50%;
`;
