import React from 'react';
import Select from 'components/Select';

const SelectField = ({ options, field, form, ...props }) => (
  <Select
    options={options}
    name={field.name}
    value={
      options ? options.find((option) => option.value === field.value) : ''
    }
    onChange={(option) => form.setFieldValue(field.name, option.value)}
    onBlur={field.onBlur}
    {...props}
  />
);

export default SelectField;
