import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import { shape, string } from 'prop-types';
import parsePhoneNumber from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { Switch, Route } from 'react-router-dom';
import { routes } from 'routes/routes';

import Navbar from 'components/Navbar';
import DashboardSidebar from 'components/DashboardSidebar';
import { Row, Column } from 'components/GridNew';
import Copyrights from 'components/Copyrights';
import Confirmation from 'components/Confirmation';

import Activity from '../Activity';
import Team from '../Team';
import Billing from '../Billing';
import Reports from '../Reports';
import Settings from '../Settings';
import AddEditGroup from '../AddEditGroup';

import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';

import { ACCOUNT_TYPES } from 'constants/accountTypes';
import getParameterByName from 'utils/getParameterByName';
import { ACTIVITY, TEAM, BILLING, REPORTS, SETTINGS } from 'utils/tabs';
import t from 'locales/en';
import fallbackHeaderLogo from 'assets/riidelogo.png';

import {
  clearCreatedPrebookingStatus,
  clearUpdatedBookingStatus,
  createBooking,
  updateBooking,
  createGuestBooking,
  createGuestAndUpdateBooking,
  clearPendingBooking,
  clearBookingForm,
  clearCurrentBooking,
} from 'store/slices/bookingsSlice';
import {
  getAccounts,
  getSelectedAccountDetails,
  addPaymentMethod,
  clearPendingPaymentMethod,
  clearRedirectTo3DAuth,
  setRedirectTo3DAuth,
  initCard,
  getFavoritePlaces,
  clearNewAccount,
  currentPaymentMethodsSelector,
  currentAccountSelector,
} from 'store/slices/accountsSlice';
import {
  usePrevious,
  useGetDashboardItems,
  useGetActivePrimaryTabFromPath,
  useGetActiveSecondaryTabFromPath,
} from 'hooks';

import {
  setActivePrimaryTab,
  setActiveSecondaryTab,
} from 'store/slices/tabsSlice';
import {
  StyledDashboardWrapper,
  StyledDashboardList,
  StyledDashboardCopyrightsWrapper,
} from './styles';

const Dashboard = ({
  history,
  appCompanyName,
  companyCountryCode,
  userName,
  headerLogo,
}) => {
  const dispatch = useDispatch();
  const pendingBooking = useSelector((state) => state.bookings.pendingBooking);
  const accounts = useSelector((state) => state.accounts);
  const paymentMethods = useSelector(currentPaymentMethodsSelector);
  const {
    isCreatedPrebooking,
    isUpdated: isBookingUpdated,
    isCreating: isCreatingBooking,
  } = useSelector((state) => state.bookings.newBookingForm);
  const dashboardItems = useGetDashboardItems();
  const { isAddGroupView } = useSelector((state) => state.groups);

  const {
    pendingPaymentMethod,
    redirectTo3DAuth,
    allIsLoading,
    data: accountsData,
    favoritePlaces: _favoritePlaces,
    isCreating: isCreatingAccount,
    isUpdating: isUpdatingAccount,
  } = accounts;
  const { all: allAccounts, cards } = accountsData;
  const currentAccount = useSelector(currentAccountSelector);
  const favoritePlaces = {
    data: _favoritePlaces.dataByAccountId?.[currentAccount?.id] || [],
    isLoading: _favoritePlaces.isLoading,
  };
  const {
    willContinueBooking,
    cardAuthSuccess,
    cardAuthFailed,
    willContinueAccountCreate,
  } = redirectTo3DAuth;
  const hasNoAccount = !allAccounts?.length;
  const afterNewBusinessAccount = allAccounts?.length > 1;
  const previousAccount = usePrevious(currentAccount);

  const [initialCountryCode, setInitialCountryCode] = useState(
    companyCountryCode
  );
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [isAccountModalVisible, setIsAccountModalVisible] = useState(false);
  const [successModal, setSuccessModal] = useState({
    visible: false,
    title: '',
  });
  const [errorModal, setErrorModal] = useState({
    visible: false,
    title: '',
    subtitle: '',
  });
  const redirectReference = useRef();
  const promiseRef = useRef();

  const setAdminPhoneCountry = useCallback(() => {
    if (currentAccount.admin_phone) {
      const parsed =
        currentAccount.admin_phone?.indexOf('+') > -1
          ? parsePhoneNumber(currentAccount.admin_phone)
          : parsePhoneNumber(`+${currentAccount.admin_phone}`);
      setInitialCountryCode(parsed?.country || companyCountryCode);
    }
  }, [currentAccount.admin_phone, companyCountryCode]);
  const activePrimaryTab = useGetActivePrimaryTabFromPath();
  const activeSecondaryTab = useGetActiveSecondaryTabFromPath();

  useEffect(() => {
    dispatch(setActivePrimaryTab(activePrimaryTab));
  }, [dispatch, activePrimaryTab]);

  useEffect(() => {
    dispatch(setActiveSecondaryTab(activeSecondaryTab));
  }, [dispatch, activeSecondaryTab]);

  useEffect(() => {
    if (
      previousAccount &&
      !isEqual(previousAccount.admin_phone, currentAccount.admin_phone)
    ) {
      setAdminPhoneCountry();
    }
  }, [currentAccount.admin_phone, previousAccount, setAdminPhoneCountry]);

  useEffect(() => {
    if (
      !currentAccount.admin_phone &&
      initialCountryCode !== companyCountryCode
    ) {
      setInitialCountryCode(companyCountryCode);
    }
  }, [initialCountryCode, companyCountryCode, currentAccount.admin_phone]);

  useEffect(() => {
    if (allIsLoading === false && hasNoAccount) {
      setIsAccountModalVisible(true);
    }
  }, [hasNoAccount, allIsLoading]);

  const onClickNewBooking = () => {
    dispatch(clearBookingForm());
    dispatch(clearRedirectTo3DAuth());
    dispatch(clearCurrentBooking());
    handleBookingModalToggle();
  };

  const handleBookingModalToggle = () => {
    if (!isBookingModalOpen) {
      if (pendingPaymentMethod?.data?.id) {
        dispatch(clearPendingPaymentMethod());
      }
    }
    setIsBookingModalOpen((previousState) => !previousState);
  };

  const handleAccountModalToggle = (value = null) => {
    if (!isAccountModalVisible) {
      if (pendingPaymentMethod?.data?.id) {
        dispatch(clearPendingPaymentMethod());
      }
    }
    if (value) {
      setIsAccountModalVisible(value);
    } else {
      setIsAccountModalVisible(!isAccountModalVisible);
    }
  };
  const fetchActiveAccount = useCallback(() => {
    if (
      !!previousAccount?.id &&
      !!currentAccount?.id &&
      previousAccount.id !== currentAccount.id
    ) {
      if (promiseRef.current) {
        promiseRef.current.abort();
      }
      promiseRef.current = dispatch(
        getSelectedAccountDetails({
          account: currentAccount,
          accountChanged: true,
        })
      );
    }
  }, [currentAccount, previousAccount, dispatch]);

  const fetchInitialState = useCallback(() => {
    dispatch(getAccounts());
    dispatch(initCard());
    if (currentAccount.type === ACCOUNT_TYPES.rfb) {
      dispatch(getFavoritePlaces());
    }
  }, [dispatch, currentAccount.type]);

  useEffect(() => {
    fetchActiveAccount();
  }, [fetchActiveAccount]);

  useEffect(() => {
    fetchInitialState();
    setAdminPhoneCountry();
    const reason = getParameterByName('reason');
    const success = getParameterByName('success');

    if (success) {
      if (pendingBooking?.preauthId || pendingPaymentMethod?.data?.id) {
        setSuccessModal({
          visible: true,
          title: t.threeDAuth.success,
        });
      } else {
        history.replace(routes.default);
      }
      if (pendingPaymentMethod?.data?.id) {
        dispatch(
          addPaymentMethod({
            paymentId: pendingPaymentMethod?.data?.id,
            accountId: pendingPaymentMethod?.accountId,
            // eslint-disable-next-line max-len
            name: `${pendingPaymentMethod?.data?.brand} ending ${pendingPaymentMethod?.data?.last4}`,
            type: 'card',
          })
        );
      } else if (pendingBooking?.preauthId) {
        if (pendingBooking.values?.user_id) {
          // for current members
          if (pendingBooking.isEdit) {
            dispatch(
              updateBooking({
                id: pendingBooking.values.id,
                values: {
                  ...pendingBooking.updatedFields,
                  preauthId: pendingBooking.preauthId,
                },
                guestUserId: null,
                referenceElement: redirectReference,
              })
            );
          } else {
            dispatch(
              createBooking({
                values: {
                  ...pendingBooking.values,
                  preauthId: pendingBooking.preauthId,
                },
                timezone: pendingBooking.timezone,
                guestUserId: null,
                referenceElement: redirectReference,
              })
            );
          }
        } else {
          // for new members/not invited yet
          if (pendingBooking.isEdit) {
            dispatch(
              createGuestAndUpdateBooking({
                values: pendingBooking.values,
                booking: {
                  ...pendingBooking.updatedFields,
                  preauthId: pendingBooking.preauthId,
                },
                accountId: currentAccount.id,
                referenceElement: redirectReference,
              })
            );
          } else {
            dispatch(
              createGuestBooking({
                values: {
                  ...pendingBooking.values,
                  preauthId: pendingBooking.preauthId,
                },
                timezone: pendingBooking.timezone,
                accountId: currentAccount?.id,
                referenceElement: redirectReference,
              })
            );
          }
        }
      }
    } else if (reason) {
      if (pendingBooking?.preauthId || pendingPaymentMethod?.data?.id) {
        const { error } = pendingBooking?.preauthId
          ? t.threeDAuth.createBooking
          : t.threeDAuth.addNewCard;
        let { title } = error.default;
        const last4 = getParameterByName('last4');
        title =
          reason === 'duplicated'
            ? error[reason](last4)?.title
            : error[reason]?.title;

        setErrorModal({
          visible: true,
          title,
          subtitle: error.subtitle,
        });
        dispatch(
          setRedirectTo3DAuth({
            cardAuthFailed: true,
            isCard: { data: null, accountId: null },
            willContinueBooking: pendingBooking?.preauthId,
          })
        );
      }
    } else {
      // clear redirect state on did mount if not coming from 3d authentication
      dispatch(clearRedirectTo3DAuth());
      dispatch(clearNewAccount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCreatedPrebooking || isBookingUpdated) {
      let creationTimeout = setTimeout(() => {
        dispatch(clearCreatedPrebookingStatus());
        dispatch(clearUpdatedBookingStatus());
      }, 5000);
      return () => {
        clearTimeout(creationTimeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatedPrebooking, isBookingUpdated]);

  const closeModals = () => {
    history.push(routes.default);
    if (willContinueBooking) {
      setIsBookingModalOpen(true);
    }
    if (pendingPaymentMethod?.data?.id) {
      dispatch(clearPendingPaymentMethod());
      if (activePrimaryTab !== BILLING) {
        dispatch(setActivePrimaryTab(BILLING));
      }
    }
    if (pendingBooking?.preauthId) {
      if (activePrimaryTab !== ACTIVITY) {
        dispatch(setActivePrimaryTab(ACTIVITY));
      }
      dispatch(clearPendingBooking());
    }

    if (willContinueAccountCreate) {
      setIsAccountModalVisible(true);
    }

    setSuccessModal({ visible: false, title: '' });
    setErrorModal({ visible: false, title: '', subtitle: '' });
  };

  return (
    <>
      <div ref={redirectReference} />
      <Navbar
        isAccountModalVisible={isAccountModalVisible}
        setIsAccountModalVisible={handleAccountModalToggle}
        isBookingModalOpen={isBookingModalOpen}
        handleBookingModalToggle={handleBookingModalToggle}
        history={history}
        accounts={allAccounts}
        hasNoAccount={hasNoAccount}
        account={currentAccount}
        afterNewBusinessAccount={afterNewBusinessAccount}
        cardAuthSuccess={cardAuthSuccess}
        cardAuthFailed={cardAuthFailed}
        willContinueAccountCreate={willContinueAccountCreate}
        appCompanyName={appCompanyName}
        isCreatingAccount={isCreatingAccount}
        onClickNewBooking={onClickNewBooking}
        userName={userName}
        headerLogo={headerLogo}
      />
      <StyledDashboardWrapper>
        <Row>
          <Column col={1.5}>
            <DashboardSidebar />
          </Column>
          <Column spaceBetween col={10.5}>
            <StyledDashboardList>
              {(isBookingUpdated || isCreatedPrebooking) && (
                <Confirmation
                  isBookingUpdated={isBookingUpdated}
                  handleModalToggle={onClickNewBooking}
                />
              )}

              <Switch>
                {dashboardItems.map((item) => (
                  <Route key={item} path={routes[item.toLowerCase()]} exact>
                    {item === ACTIVITY && <Activity />}

                    {item === TEAM &&
                      (isAddGroupView ? <AddEditGroup /> : <Team />)}

                    {item === BILLING && (
                      <Billing
                        appCompanyName={appCompanyName}
                        cards={cards}
                        paymentMethods={paymentMethods}
                        isCreatingAccount={isCreatingAccount}
                      />
                    )}

                    {item === REPORTS && <Reports />}

                    {item === SETTINGS && (
                      <Settings
                        isUpdating={isUpdatingAccount}
                        defaultCountryCode={initialCountryCode}
                        favoritePlaces={favoritePlaces}
                      />
                    )}
                  </Route>
                ))}
              </Switch>
              <StyledDashboardCopyrightsWrapper>
                <Copyrights />
              </StyledDashboardCopyrightsWrapper>
            </StyledDashboardList>
          </Column>
        </Row>
      </StyledDashboardWrapper>
      {successModal.visible && (
        <SuccessModal
          isVisible
          title={successModal.title}
          onClickPrimary={closeModals}
          onCancel={closeModals}
          secondaryTitle={t.common.close}
          primaryTitle={t.common.ok}
          isPrimaryDisabled={isCreatingBooking}
          isPrimaryLoading={isCreatingBooking}
        />
      )}
      {errorModal.visible && (
        <ErrorModal
          hasError
          title={errorModal.title}
          subtitle={errorModal.subtitle}
          onClickPrimary={closeModals}
          onCancel={closeModals}
          secondaryTitle={t.common.close}
          primaryTitle={t.common.ok}
        />
      )}
    </>
  );
};

Dashboard.propTypes = {
  history: shape({}).isRequired,
  appCompanyName: string.isRequired,
  companyCountryCode: string.isRequired,
  userName: string.isRequired,
  headerLogo: string,
};
Dashboard.defaultProps = {
  headerLogo: fallbackHeaderLogo,
};

const areEqual = (prevProps, nextProps) =>
  prevProps.appCompanyName === nextProps.appCompanyName &&
  prevProps.companyCountryCode === nextProps.companyCountryCode &&
  prevProps.headerLogo === nextProps.headerLogo &&
  prevProps.userName === nextProps.userName;

export default memo(Dashboard, areEqual);
