import addMinutes from 'date-fns/addMinutes';
import getMinutes from 'date-fns/getMinutes';
import getHours from 'date-fns/getHours';
import isSameDay from 'date-fns/isSameDay';
import isToday from 'date-fns/isToday';
import set from 'date-fns/set';

import { useMemo, useReducer, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import {
  groupedOptions,
  getEnabledHours,
  getEnabledMinutes,
  ASAP_OPTION,
} from 'components/Modals/BookingForm/BookingTimePicker/helpers';
import {
  PICKUP_TYPES,
  HOUR_TYPE,
  MINUTE_TYPE,
  DEFAULT_TIME_ZONE,
} from 'constants/booking';
import {
  getDateInTimeZone,
  getFormattedDateWithTimezoneOffset,
} from 'utils/dateTime';
import { usePrevious } from './index';

export function useTimePickerInput({
  minPrebookTime = 0,
  isEditBooking,
  hasDefaultValue,
  selectedDate,
  bookingType,
  timezone = DEFAULT_TIME_ZONE,
  setFieldValue = () => {},
  isDisabled = false,
}) {
  const dateInTimeZone = useMemo(
    () => getDateInTimeZone(selectedDate, timezone),
    [selectedDate, timezone]
  );
  const initialState = {
    hour: hasDefaultValue || isEditBooking ? getHours(dateInTimeZone) : 0,
    hourOption:
      hasDefaultValue || isEditBooking
        ? groupedOptions[1].options.find(
            (item) => item.baseValue === getHours(dateInTimeZone)
          )
        : null,
    minute: hasDefaultValue || isEditBooking ? getMinutes(dateInTimeZone) : 0,
    minuteOption:
      hasDefaultValue || isEditBooking
        ? groupedOptions[2].options.find(
            (item) => item.baseValue === getMinutes(dateInTimeZone)
          )
        : null,
    type: hasDefaultValue || isEditBooking ? bookingType : PICKUP_TYPES.ASAP,
    isAsapDisabled: false,
    validMinuteOptions: [],
  };
  const [state, setState] = useReducer(
    (prevState, nextState) => ({
      ...prevState,
      ...nextState,
    }),
    initialState
  );

  const prevSelectedDate = usePrevious(selectedDate);
  const prevState = usePrevious(state);

  const nowWithMinPrebookTime = useMemo(
    () => addMinutes(getDateInTimeZone(new Date(), timezone), minPrebookTime),
    [minPrebookTime, timezone]
  );

  const validMinuteOptions = useMemo(() => {
    if (isDisabled || !isSameDay(selectedDate, nowWithMinPrebookTime)) {
      return groupedOptions[2].options;
    }

    return getEnabledMinutes(minPrebookTime, timezone, state.hour);
  }, [
    minPrebookTime,
    nowWithMinPrebookTime,
    selectedDate,
    state.hour,
    timezone,
    isDisabled,
  ]);

  const validMinutes = validMinuteOptions.map((item) => item.baseValue);
  const previousValidMinutes = usePrevious(validMinutes);

  const setPickupAt = (date = dateInTimeZone, _hours = 0, _minutes = 0) => {
    const pickupAt = set(date, { hours: _hours, minutes: _minutes });
    setFieldValue(
      'pickup_at',
      getFormattedDateWithTimezoneOffset(pickupAt, timezone)
    );
  };

  const validHourOptions = useMemo(() => {
    if (isDisabled || !isSameDay(dateInTimeZone, nowWithMinPrebookTime)) {
      return groupedOptions[1].options;
    }
    const enabledHours = getEnabledHours(minPrebookTime, timezone);

    return enabledHours;
  }, [
    isDisabled,
    minPrebookTime,
    dateInTimeZone,
    nowWithMinPrebookTime,
    timezone,
  ]);

  const validHours = useMemo(() => {
    return validHourOptions.map((item) => item.baseValue);
  }, [validHourOptions]);

  const setPrebooking = ({ hours = 0, minutes = 0 }) => {
    setFieldValue('type', PICKUP_TYPES.PRE_BOOKING);
    setFieldValue('hour', `${hours}:${minutes}`);
    setPickupAt(dateInTimeZone, hours, minutes);
  };

  const setAsapBooking = () => {
    const now = dateInTimeZone;
    setFieldValue('type', PICKUP_TYPES.ASAP);
    setPickupAt(now, getHours(now), getMinutes(now));
    setState({
      ...initialState,
      type: PICKUP_TYPES.ASAP,
      isAsapDisabled: false,
    });
  };

  // didMount
  useEffect(() => {
    if (isDisabled) {
      if (bookingType === PICKUP_TYPES.PRE_BOOKING) {
        setState({
          hour: getHours(dateInTimeZone),
          minute: getMinutes(dateInTimeZone),
        });
        return;
      }
    } else {
      if (
        (isEditBooking && bookingType === PICKUP_TYPES.PRE_BOOKING) ||
        hasDefaultValue
      ) {
        const initialDate = getDateInTimeZone(selectedDate, timezone);
        setForEditing(initialDate);
      } else {
        setAsapBooking(selectedDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // selects new date
  useEffect(() => {
    if (!!prevSelectedDate && !isEqual(prevSelectedDate, selectedDate)) {
      if (isToday(selectedDate)) {
        setAsapBooking();
      } else {
        setFutureDateDefaultBooking();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevSelectedDate, selectedDate]);

  // select hour/minute when another one is missing
  useEffect(() => {
    if (prevState?.hour !== state.hour && !!state.hour && !state.minute) {
      setState({
        minute: validMinutes[0],
        minuteOption: validMinuteOptions[0],
      });
      setPrebooking({ hours: state.hour, minutes: validMinutes[0] });
    } else if (
      prevState?.minute !== state.minute &&
      !!state.minute &&
      !state.hour
    ) {
      setState({
        hour: validHours[0],
        hourOption: validHourOptions[0],
      });
      setPrebooking({ hours: validHours[0], minutes: state.minute });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevState, state, validHourOptions, validHours, validMinutes]);

  const handleHourChange = () => (option) => {
    if (option.type === PICKUP_TYPES.ASAP) {
      setAsapBooking();
    } else {
      if (option.type === HOUR_TYPE) {
        setState({
          type: PICKUP_TYPES.PRE_BOOKING,
          hour: option.baseValue,
          hourOption: option,
        });
        // if has minute, set prebooking via useEffect below
      } else if (option.type === MINUTE_TYPE) {
        setState({
          type: PICKUP_TYPES.PRE_BOOKING,
          minute: option.baseValue,
          minuteOption: option,
        });
        if (state.hour) {
          setPrebooking({
            hours: state.hour,
            minutes: option.baseValue,
          });
        }
      }
    }
  };

  /* eslint-disable max-len */
  /*
    update state.minute/minuteOption when hour changes and if state.minute is not one of validMinutes options
    set prebooking if state.minute set
  */
  /* eslint-enable */
  useEffect(() => {
    if (
      !!prevState?.hour &&
      !!state.hour &&
      state.hour !== prevState.hour &&
      !!state.minute
    ) {
      if (validMinutes?.length < previousValidMinutes?.length) {
        if (validMinutes.indexOf(state.minute) === -1) {
          setState({
            minute: validMinutes[0],
            minuteOption: validMinuteOptions[0],
          });
          setPrebooking({
            hours: state.hour,
            minutes: validMinutes[0],
          });
        }
      } else {
        setPrebooking({ hours: state.hour, minutes: state.minute });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousValidMinutes, validMinutes, state.hour]);

  const setFutureDateDefaultBooking = () => {
    setState({ isAsapDisabled: true });
    setFieldValue('type', PICKUP_TYPES.PRE_BOOKING);

    if (state.type === PICKUP_TYPES.ASAP) {
      setFieldValue('hour', '00:00');
      setPickupAt(dateInTimeZone, '00', '00');
      setState({
        minuteOption: validMinuteOptions[0],
        hourOption: validHourOptions[0],
        minute: 0,
        hour: 0,
        type: PICKUP_TYPES.PRE_BOOKING,
      });
    } else {
      setPickupAt(dateInTimeZone, state.hour, state.minute);
    }
  };

  const setForEditing = (initialDate) => {
    let hours = getHours(initialDate);
    let minutes = getMinutes(initialDate);

    const hourOption = validHourOptions.find(
      (item) => item.baseValue === hours
    );
    const minuteOption = validMinuteOptions.find(
      (item) => item.baseValue === minutes
    );

    setState({
      hour: hours,
      hourOption,
      minuteOption,
      minute: minutes,
      type: PICKUP_TYPES.PRE_BOOKING,
      isAsapDisabled: !isToday(initialDate),
    });
  };

  const TIME_OPTIONS = [
    {
      ...ASAP_OPTION,
      isDisabled: isEditBooking
        ? bookingType === PICKUP_TYPES.PRE_BOOKING
        : state.isAsapDisabled,
    },
    {
      label: 'Hours',
      options: validHourOptions,
    },
    {
      label: 'Minutes',
      options: validMinuteOptions,
    },
  ];
  const value = useMemo(() => {
    if (state.type === PICKUP_TYPES.ASAP) {
      return [ASAP_OPTION];
    } else {
      if (!!state.hourOption && !!state.minuteOption) {
        return [state.hourOption, state.minuteOption];
      }
    }
  }, [state.hourOption, state.minuteOption, state.type]);

  return {
    onChange: handleHourChange(dateInTimeZone),
    options: TIME_OPTIONS,
    value,
  };
}
