import React from 'react';
import { Column } from 'components/GridNew';
import Input from 'components/Input';
import {
  StyledHeaderWrapper,
  StyledHeader,
  StyledRow,
} from 'views/Login/styles';
import { nameStep as messages } from './messages';

const NameStep = ({ values, handleChange, handleBlur, errors, touched }) => (
  <>
    <StyledHeaderWrapper>
      <StyledHeader>{messages.header}</StyledHeader>
    </StyledHeaderWrapper>
    <StyledRow $spaceBetween>
      <Column col={5.8}>
        <Input
          bgWhite
          isLarge
          errorBgPrimary
          value={values.first_name}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          name="first_name"
          errors={errors}
          touched={touched}
          placeholder={'First name'}
          autoComplete="new-password" // workaround for chrome to not autofill
        />
      </Column>
      <Column col={5.8}>
        <Input
          bgWhite
          isLarge
          errorBgPrimary
          placeholder={'Last name'}
          type="text"
          name="last_name"
          value={values.last_name}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors}
          touched={touched}
          autoComplete="new-password" // workaround for chrome to not autofill
        />
      </Column>
    </StyledRow>
  </>
);

export default NameStep;
