import { initialState as initialAccountsState } from 'store/slices/accountsSlice';
import { initialState as initialBookingsState } from 'store/slices/bookingsSlice';
import { initialState as initialCompanyState } from 'store/slices/companySlice';
import { initialState as initialUserState } from 'store/slices/userSlice';
import { initialState as initialAppStatus } from 'store/slices/appStatusSlice';
import { initialState as initialSignUpSteps } from 'store/slices/signUpStepsSlice';
import { initialState as initialLoginSteps } from 'store/slices/loginStepsSlice';
import { initialState as initialGroups } from 'store/slices/groupsSlice';

export const LOCALSTORAGE_KEY = 'rfbState';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(LOCALSTORAGE_KEY);
    if (serializedState === null) {
      return undefined;
    }
    const { accounts, bookings, company, user, groups } = JSON.parse(
      serializedState
    );

    const parsedState = {
      accounts: {
        ...initialAccountsState,
        data: {
          all: accounts.data.all || initialAccountsState.data.all,
          current: accounts.data.current || initialAccountsState.data.current,
          paymentMethods: Array.isArray(accounts.data.paymentMethods)
            ? initialAccountsState.data.paymentMethods
            : accounts.data.paymentMethods ||
              initialAccountsState.data.paymentMethods,
          cards: accounts.data.cards || initialAccountsState.data.cards,
        },
        members: {
          ...initialAccountsState.members,
          pending: {
            ...initialAccountsState.members.pending,
            idsByAccountId:
              accounts.members.pending.idsByAccountId ||
              initialAccountsState.members.pending.idsByAccountId,
            byAccountId:
              accounts.members.pending.byAccountId ||
              initialAccountsState.members.pending.byAccountId,
            metaByAccountId:
              accounts.members.pending.metaByAccountId ||
              initialAccountsState.members.pending.metaByAccountId,
          },
          active: {
            ...initialAccountsState.members.active,
            idsByAccountId:
              accounts.members.active.idsByAccountId ||
              initialAccountsState.members.active.idsByAccountId,
            byAccountId:
              accounts.members.active.byAccountId ||
              initialAccountsState.members.active.byAccountId,
            metaByAccountId:
              accounts.members.active.metaByAccountId ||
              initialAccountsState.members.active.metaByAccountId,
          },
        },
        favoritePlaces:
          accounts.favoritePlaces || initialAccountsState.favoritePlaces,
        pendingPaymentMethod:
          accounts.pendingPaymentMethod ||
          initialAccountsState.pendingPaymentMethod,
        redirectTo3DAuth:
          accounts.redirectTo3DAuth || initialAccountsState.redirectTo3DAuth,
        newAccount: accounts.newAccount || initialAccountsState.newAccount,
      },
      bookings: {
        ...initialBookingsState,
        currentBooking:
          bookings.currentBooking || initialBookingsState.currentBooking,
        data: {
          ...(bookings.data || initialBookingsState.data),
          byAccountId:
            bookings.data.byAccountId || initialBookingsState.data.byAccountId,
          all: {
            ...initialBookingsState.data.all,
            idsByAccountIdAndPage:
              bookings.data.all.idsByAccountIdAndPage ||
              initialBookingsState.data.all.idsByAccountIdAndPage,
            metaByAccountId:
              bookings.data.all.metaByAccountId ||
              initialBookingsState.data.all.metaByAccountId,
          },
          upcoming: {
            ...initialBookingsState.data.upcoming,
            idsByAccountIdAndPage:
              bookings.data.upcoming.idsByAccountIdAndPage ||
              initialBookingsState.data.upcoming.idsByAccountIdAndPage,
            metaByAccountId:
              bookings.data.upcoming.metaByAccountId ||
              initialBookingsState.data.upcoming.metaByAccountId,
          },
          active: {
            ...initialBookingsState.data.active,
            idsByAccountIdAndPage:
              bookings.data.active.idsByAccountIdAndPage ||
              initialBookingsState.data.active.idsByAccountIdAndPage,
            metaByAccountId:
              bookings.data.active.metaByAccountId ||
              initialBookingsState.data.active.metaByAccountId,
          },
          historical: {
            ...initialBookingsState.data.historical,
            idsByAccountIdAndPage:
              bookings.data.historical.idsByAccountIdAndPage ||
              initialBookingsState.data.historical.idsByAccountIdAndPage,
            metaByAccountId:
              bookings.data.historical.metaByAccountId ||
              initialBookingsState.data.historical.metaByAccountId,
          },
        },
        driverMessages: {
          isLoading: null,
          ...(bookings.driverMessages || initialBookingsState.driverMessages),
        },
        bookingCompany:
          bookings.bookingCompany || initialBookingsState.bookingCompany,
        pendingBooking:
          bookings.pendingBooking || initialBookingsState.pendingBooking,
        newBookingForm: {
          ...initialBookingsState.newBookingForm,
          data:
            bookings.newBookingForm?.data ||
            initialBookingsState.newBookingForm.data,
          values:
            bookings.newBookingForm?.values ||
            initialBookingsState.newBookingForm.values,
        },
      },
      company: {
        isLoading: initialCompanyState.isLoading,
        error: initialCompanyState.error,
        ...company,
      },
      user: {
        isLoading: initialUserState.isLoading,
        error: initialUserState.error,
        data: user.data || initialUserState.data,
      },
      appStatus: initialAppStatus,
      signUpSteps: initialSignUpSteps,
      loginSteps: initialLoginSteps,
      groups: {
        ...initialGroups,
        idsByAccountId: groups.idsByAccountId || initialGroups.idsByAccountId,
        byAccountId: groups.byAccountId || initialGroups.byAccountId,
      },
    };
    return parsedState;
  } catch (err) {
    return {
      accounts: initialAccountsState,
      bookings: initialBookingsState,
      company: initialCompanyState,
      user: initialUserState,
      appStatus: initialAppStatus,
      signUpSteps: initialSignUpSteps,
      loginSteps: initialLoginSteps,
      groups: initialGroups,
    };
  }
};

export const saveState = ({ rfbState }) => {
  try {
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(rfbState));
  } catch {
    return;
  }
};
