import React, { memo } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Container } from 'components/Grid';

import { getSidebarItemsByRole } from './utils';
import { useGetActivePrimaryTabFromPath } from 'hooks';
import { currentAccountSelector } from 'store/slices/accountsSlice';
import styles from './DashboardSidebar.module.scss';

const DashboardSidebar = () => {
  const account = useSelector(currentAccountSelector);
  const currentUserRole = account?.role;
  const activePrimaryTab = useGetActivePrimaryTabFromPath();
  const className = (item) =>
    cx(styles.DashboardSidebarItem, {
      [styles.isActive]: activePrimaryTab === item,
    });

  const accountDashboardItems = React.useMemo(
    () => getSidebarItemsByRole(currentUserRole) || [],
    [currentUserRole]
  );

  return (
    <Container>
      <div className={styles.DashboardSidebarItems}>
        {accountDashboardItems.map((item) => {
          return (
            <Link
              to={`/${item.toLowerCase()}`}
              className={className(item)}
              key={item}
              disabled={!account.id}
            >
              {item}
            </Link>
          );
        })}
      </div>
    </Container>
  );
};

export default memo(DashboardSidebar);
