import { axiosGet } from 'api/axiosHelpers';
import { headers } from 'api/config';

const createHeadersWithCompanyCode = (companyCode) => {
  return {
    ...headers,
    'App-Company-Code': companyCode,
    'Company-Code': companyCode,
  };
};
export const fetchDefaultCompany = async (companyCode) => {
  const { data } = await axiosGet({
    url: '/companies/default',
    headers: createHeadersWithCompanyCode(companyCode),
  });
  return data;
};

export const fetchCompanyByCode = async (companyCode) => {
  const { data } = await axiosGet({
    url: `/companies/view/${companyCode}`,
    headers: createHeadersWithCompanyCode(companyCode),
  });
  return data;
};
