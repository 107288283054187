/**
 * State requested, which means it's newly created
 */
export const STATE_REQUESTED = 'requested';
/**
 * State accepted, which means dispatch has confirmed it, will be skipped in asap bookings
 */
export const STATE_ACCEPTED = 'accepted';
/**
 * State assigned, which means a driver has been assigned
 */
export const STATE_ASSIGNED = 'assigned';
/**
 * State en route, which means driver is on the way to pickup
 */
export const STATE_EN_ROUTE = 'en-route';
/**
 * State arrived, driver has arrived at pick up
 */
export const STATE_ARRIVED = 'arrived';
/**
 * State no show, driver has waited x min and passenger did not show
 */
export const STATE_NO_SHOW = 'no-show';
/**
 * State to destination, driver with user is on the way to destination
 */
export const STATE_TO_DESTINATION = 'to-destination';
/**
 * State driver completed, trip is over and driver has typed in the cost
 */
export const STATE_COMPLETED = 'completed';
/**
 * State cancelled user, user has cancelled
 */
export const STATE_CANCELLED_USER = 'cancelled-user';
/**
 * State cancelled driver, driver has canceled, but booking will go back to requested soon
 */
export const STATE_CANCELLED_DRIVER = 'cancelled-driver';
/**
 * State cancelled dispatch, dispatch has cancelled the booking, it a completed state
 */
export const STATE_CANCELLED_DISPATCH = 'cancelled-dispatch';

/**
 * @type { boolean }
 * only returns if type=pre-booking
 * @if false Before booking is live in dispatch
 * @if true Booking in dispatch;
 */
export const IS_PREBOOKING_ACTIVE = 'is_prebooking_active';

export const ASAP_IN_DISPATCH = STATE_REQUESTED;
export const EDITABLE_BOOKING_STATES = [
  STATE_REQUESTED,
  STATE_ACCEPTED,
  STATE_ASSIGNED,
  STATE_EN_ROUTE,
  STATE_ARRIVED,
  STATE_TO_DESTINATION,
];
export const VIEWABLE_BOOKING_STATES = [
  ...EDITABLE_BOOKING_STATES,
  STATE_COMPLETED,
];
export const CANCELLED_BOOKING_STATES = [
  STATE_CANCELLED_DISPATCH,
  STATE_CANCELLED_USER,
  STATE_CANCELLED_DRIVER,
];
