import React, { memo } from 'react';
import { func, bool, string } from 'prop-types';

import { useDispatch } from 'react-redux';
import { clearAppStatus } from 'store/slices/appStatusSlice';

import BasicModal from 'components/Modals/BasicModal';
import t from 'locales/en';

const SuccessModal = ({
  isVisible,
  title,
  subtitle,
  onCancel,
  onClickPrimary,
  primaryTitle,
  secondaryTitle,
  isPrimaryDisabled,
  isPrimaryLoading,
  shouldCloseOnEsc,
}) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    onCancel && onCancel();
    dispatch(clearAppStatus());
  };

  return (
    <BasicModal
      testId="success-modal"
      isModalOpen={isVisible}
      title={title}
      subtitle={subtitle}
      onClickPrimary={onClickPrimary}
      onClickSecondary={closeModal}
      secondaryTitle={secondaryTitle}
      primaryTitle={primaryTitle}
      isPrimaryDisabled={isPrimaryDisabled}
      isPrimaryLoading={isPrimaryLoading}
      type="success"
      shouldCloseOnEsc={shouldCloseOnEsc}
    />
  );
};

SuccessModal.propTypes = {
  isVisible: bool,
  onCancel: func,
  onClickPrimary: func,
  primaryTitle: string,
  secondaryTitle: string,
  title: string,
  subtitle: string,
  isPrimaryDisabled: bool,
  isPrimaryLoading: bool,
  shouldCloseOnEsc: bool,
};

SuccessModal.defaultProps = {
  isVisible: false,
  onCancel: () => {},
  onClickPrimary: () => {},
  primaryTitle: '',
  secondaryTitle: t.common.close,
  title: '',
  subtitle: '',
  isPrimaryDisabled: false,
  isPrimaryLoading: false,
  shouldCloseOnEsc: false,
};

export default memo(SuccessModal);
