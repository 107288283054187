import React, { memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import _isEqual from 'lodash/isEqual';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import Input from 'components/InputNew';
import Button from 'components/Button';
import { LabelWithInput } from 'components/Label';
import Checkbox from 'components/Checkbox';
import SelectField from 'components/SelectField';
import TooltipWithIcon from 'components/TooltipWithIcon';
import { difference } from 'utils';

import {
  createGroup,
  updateGroup,
  setGroupState,
  currentGroupSelector,
} from 'store/slices/groupsSlice';
import { currencySelector } from 'store/slices/companySlice';

import { schema, initialValues } from './schema';
import messages from './messages';
import {
  LABEL_STYLE,
  TIME_STYLE,
  FREQUENCY_STYLE,
  SecondaryHeader,
  StyledField,
  StyledFieldRight,
  StyledTimeRow,
  StyledTimeContainer,
  StyledValueContainer,
  StyledFrequencyContainer,
  StyledLabelContainer,
  StyledSeparator,
  StyledCancelButton,
} from './styles';
import { StyledLabel } from 'components/Label/styles';
import {
  StyledDashboardItemWrapper,
  StyledInputWrapper,
} from 'views/Dashboard/styles';
import { TIME_OPTIONS, DAYS, FREQUENCY_OPTIONS } from './selectOptions';

const AddEditGroup = () => {
  const currentGroup = useSelector(currentGroupSelector);
  const isEditModal = useMemo(() => !!currentGroup, [currentGroup]);

  const dispatch = useDispatch();
  const { isSaving } = useSelector((state) => state.groups);
  const currency = useSelector(currencySelector);

  const initialData = useMemo(() => {
    if (isEditModal) {
      return {
        name: currentGroup.name,
        working_time: currentGroup.working_time,
        only_favorite_places: currentGroup.only_favorite_places,
        price_limit: currentGroup.price_limit,
        frequency: currentGroup.frequency,
        frequency_interval: currentGroup.frequency_interval,
      };
    }
    return initialValues;
  }, [isEditModal, currentGroup]);

  const onSubmit = (values) => {
    if (isEditModal) {
      const updatedValues = difference(initialData, values);
      dispatch(updateGroup({ id: currentGroup.id, payload: updatedValues }));
    } else {
      dispatch(createGroup(values));
    }
  };

  return (
    <div data-testid="add-group">
      <Formik
        enableReinitialize
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialData}
      >
        {({
          values,
          handleChange,
          errors,
          touched,
          setFieldValue,
          isValid,
          dirty,
        }) => {
          const notChanged = isEditModal && _isEqual(initialData, values);
          return (
            <Form noValidate>
              <StyledDashboardItemWrapper $minHeight="100%">
                <LabelWithInput
                  hasOffset={false}
                  labelSize={3}
                  inputSize={4}
                  label={messages.name}
                  labelStyle={LABEL_STYLE}
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  error={errors.name}
                  hasError={!!(touched.name && errors.name)}
                />
              </StyledDashboardItemWrapper>
              <br />
              <SecondaryHeader>{messages.restrictions}</SecondaryHeader>
              <StyledDashboardItemWrapper
                $minHeight="700px"
                style={{ overflow: 'initial' }}
              >
                <StyledField $alignStart>
                  <StyledLabelContainer>
                    <StyledLabel $labelStyle={{ ...LABEL_STYLE }}>
                      {messages.time}{' '}
                      <TooltipWithIcon
                        id={'time-restrictions-tooltip'}
                        inline
                        icon={faInfoCircle}
                        message={messages.timeRestrictionsInfo}
                      />
                    </StyledLabel>
                    <StyledLabel
                      $labelStyle={{
                        fontSize: '14px',
                        marginTop: '0.5em',
                        fontWeight: 400,
                      }}
                    >
                      {messages.timeSubtitle}
                    </StyledLabel>
                  </StyledLabelContainer>
                  <StyledTimeContainer>
                    {DAYS.map((day) => (
                      <StyledTimeRow key={day.value}>
                        <StyledLabel
                          $labelStyle={{
                            fontWeight: 400,
                            fontSize: '14px',
                            marginRight: '3em',
                            color: '#000',
                            opacity: 0.6,
                          }}
                        >
                          {day.label}
                        </StyledLabel>
                        <div style={{ display: 'flex' }}>
                          <Checkbox
                            id={`working_time.${day.value}.fully_open`}
                            name={`working_time.${day.value}.fully_open`}
                            onChange={setFieldValue}
                            checked={values.working_time[day.value].fully_open}
                            value={true}
                            type="radio"
                          />
                          <StyledLabel
                            htmlFor={`working_time.${day.value}.fully_open`}
                            $labelStyle={{
                              fontSize: '14px',
                              marginLeft: '0.5em',
                              fontWeight: 400,
                            }}
                          >
                            {messages.noRestrictions}
                          </StyledLabel>
                        </div>
                        <Checkbox
                          name={`working_time.${day.value}.fully_open`}
                          onChange={setFieldValue}
                          checked={!values.working_time[day.value].fully_open}
                          value={false}
                          type="radio"
                        />
                        <Field
                          component={SelectField}
                          name={`working_time.${day.value}.open_hour`}
                          options={TIME_OPTIONS}
                          styles={TIME_STYLE}
                          isDisabled={values.working_time[day.value].fully_open}
                        />
                        <Field
                          component={SelectField}
                          name={`working_time.${day.value}.close_hour`}
                          options={TIME_OPTIONS}
                          styles={TIME_STYLE}
                          isDisabled={values.working_time[day.value].fully_open}
                        />
                      </StyledTimeRow>
                    ))}
                  </StyledTimeContainer>
                </StyledField>
                <StyledSeparator />
                <StyledField>
                  <StyledLabel $labelStyle={{ width: '25%', ...LABEL_STYLE }}>
                    {messages.locations}
                  </StyledLabel>
                  <StyledFieldRight>
                    <Checkbox
                      id="only_favorite_places"
                      name="only_favorite_places"
                      onChange={setFieldValue}
                      checked={values.only_favorite_places}
                    />
                    <StyledLabel
                      htmlFor="only_favorite_places"
                      $labelStyle={{
                        fontSize: '14px',
                        marginLeft: '0.5em',
                        fontWeight: 400,
                      }}
                    >
                      {messages.onlySaveAddresses}
                    </StyledLabel>
                  </StyledFieldRight>
                </StyledField>
                <StyledSeparator />
                <StyledField>
                  <StyledLabelContainer>
                    <StyledLabel $labelStyle={{ ...LABEL_STYLE }}>
                      {messages.value}
                    </StyledLabel>
                    <StyledLabel
                      $labelStyle={{
                        fontSize: '14px',
                        marginTop: '0.5em',
                        fontWeight: 400,
                      }}
                    >
                      {messages.valueSubtitle}
                    </StyledLabel>
                  </StyledLabelContainer>
                  <StyledValueContainer>
                    <Input
                      name="price_limit"
                      onChange={handleChange}
                      value={values.price_limit}
                      type="number"
                      placeholder={`${currency} 0`}
                    />
                  </StyledValueContainer>
                </StyledField>
                <StyledSeparator />
                <StyledField>
                  <StyledLabel $labelStyle={{ width: '25%', ...LABEL_STYLE }}>
                    {messages.frequency}
                  </StyledLabel>
                  <StyledFrequencyContainer>
                    <Input
                      name="frequency"
                      onChange={handleChange}
                      value={values.frequency}
                      type="number"
                      placeholder="0"
                    />
                    <Field
                      component={SelectField}
                      name="frequency_interval"
                      options={FREQUENCY_OPTIONS}
                      styles={FREQUENCY_STYLE}
                    />
                  </StyledFrequencyContainer>
                </StyledField>
              </StyledDashboardItemWrapper>
              <br />
              <StyledInputWrapper $row>
                <StyledCancelButton
                  onClick={() =>
                    dispatch(
                      setGroupState({
                        isAddGroupView: false,
                        current: null,
                      })
                    )
                  }
                  name={messages.back}
                />
                <Button
                  isRoundedMedium
                  type="submit"
                  primary
                  name={currentGroup ? messages.update : messages.create}
                  disabled={
                    isEditModal
                      ? notChanged || isSaving
                      : !dirty || !isValid || isSaving
                  }
                />
              </StyledInputWrapper>
            </Form>
          );
        }}
      </Formik>
      <br />
    </div>
  );
};

export default memo(AddEditGroup);
