import styled from 'styled-components';
import { primary, palegray, white } from 'utils/variables';

const SIZE = '18px';

export const CheckboxContainer = styled.div`
  height: ${SIZE} !important;
  width: ${SIZE} !important;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => (props.$checked ? 'transparent' : palegray)};
  border-radius: ${(props) => (props.$type === 'checkbox' ? '4px' : '50%')};
  background-color: ${(props) => (props.$checked ? primary : white)};
  cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
  padding: ${(props) => (props.$type === 'checkbox' ? '0' : '4px')};
`;
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  outline: none;
`;
