import { useSelector } from 'react-redux';

const useGetCurrentAccount = () => {
  const { data: { current = null } = {} } = useSelector(
    (state) => state.accounts
  );

  return current;
};

export default useGetCurrentAccount;
