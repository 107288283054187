/* eslint-disable max-len */
export const messages = {
  header: 'Payment added successfully!',
  header2: 'Now invite your team!',
  subtitle:
    "Add your teammates, so that they can start booking taxis on your company account. We'll send a push notification, or sms to add them to your team.",
  approvalHeader: 'Your request to pay monthly has been submitted!',
  approvalHeader2: (companyName = 'Riide') =>
    `${companyName} will be in touch to approve your application.`,
  approvalSubtitle:
    "Invite your teammates below! We'll send a push notification, or sms to add them to your team. Until your monthly invoice is approved, you can add a business card, or pay in-car.",
  phone: 'Phone',
  first_name: 'First name',
  last_name: 'Last name',
  email: 'Email',
  password: 'Password',
  role: 'Role',
  skip: 'Skip',
  submit: 'Send Invite',
  bulkUpload: 'Bulk Upload Team',
};

export const errors = {
  firstName: 'First name is required',
  lastName: 'Last name is required',
  phoneRequired: 'Phone number is required',
  phoneInvalid: 'Phone is not valid',
  password: 'Password is required',
  emailRequired: 'Email is required',
  emailInvalid: 'Email is not valid',
};
