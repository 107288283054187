import CodeStep from './CodeStep';
import EmailStep from './EmailStep';
import LastStep from './LastStep';
import NameStep from './NameStep';
import PasswordStep from './PasswordStep';
import PhoneNumberStep from './PhoneNumberStep';
export * from './SignupSchemas';

export const STEPS = [
  {
    component: PhoneNumberStep,
    fields: ['phone_country', 'phone_number', 'phone'],
  },
  {
    component: CodeStep,
    fields: [
      'code_secret_1',
      'code_secret_2',
      'code_secret_3',
      'code_secret_4',
      'code_secret_5',
      'code_secret_6',
    ],
  },
  { component: EmailStep, fields: ['email'] },
  { component: PasswordStep, fields: ['password'] },
  { component: NameStep, fields: ['first_name', 'last_name'] },
  { component: LastStep, fields: [] },
];
