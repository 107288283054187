import styled from 'styled-components';
import { Col } from 'components/Grid';
import * as v from 'utils/variables';
import Button from 'components/Button';

export const Label = styled.label`
  display: block;
  font-size: ${v.fontSizeXs};
  color: ${v.mousegray};
  font-weight: ${v.fontWeightBold};
`;

export const BookingPhoneContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex: 1;
  justify-content: space-between;
`;
export const LabelCol = styled(Col)`
  flex: 1 1 auto;
`;
export const FormCancelButton = styled(Button)`
  color: ${v.mousegray};
  text-align: left;
  padding: ${v.spacingMd} 0;
  min-width: 170px;
  font-size: ${v.fontSizeSm};
`;
