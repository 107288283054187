import React, { memo } from 'react';
import { oneOfType, func, string, bool, node, array } from 'prop-types';
import Button from 'components/Button';
import PrimaryButton from 'components/PrimaryButton';
import Status from './Status';
import { modalStatusType } from 'constants/propTypes';

import * as S from './styles';

const Content = ({
  title,
  subtitle,
  onClickPrimary,
  onClickSecondary,
  secondaryTitle,
  primaryTitle,
  text,
  testId,
  type,
  children,
  customFooterComponent,
  isSecondaryDisabled,
  isPrimaryDisabled,
  isPrimaryLoading,
  centerTitle,
  buttonType,
  minHeight,
  renderAsForm,
}) => {
  const Wrapper = renderAsForm ? S.Form : S.Container;
  return (
    <Wrapper
      data-testid={testId}
      onSubmit={renderAsForm ? onClickPrimary : undefined}
    >
      {!!title && (
        <S.Head $centerTitle={centerTitle}>
          {!!type && <Status type={type} />}
          <S.Header>{title}</S.Header>
        </S.Head>
      )}
      <S.Body $minHeight={minHeight}>
        {!!subtitle &&
          (Array.isArray(subtitle) ? (
            <S.List>
              {subtitle.map((text, index) => (
                <li key={index}>
                  <S.Subtitle
                    data-testid="subtitle"
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                </li>
              ))}
            </S.List>
          ) : (
            <S.Subtitle
              data-testid="subtitle"
              dangerouslySetInnerHTML={{
                __html: subtitle,
              }}
            />
          ))}
        {text && <S.Text>{text}</S.Text>}
        {children && children}
      </S.Body>
      {!!(secondaryTitle || primaryTitle) && (
        <S.Footer data-testid="content-footer">
          {!!secondaryTitle && (
            <S.SecondaryButton>
              <Button
                dataTestId="secondary"
                type="button"
                secondary
                name={secondaryTitle}
                onClick={onClickSecondary}
                disabled={isSecondaryDisabled || isPrimaryLoading}
                isFullWidth
                noPaddingLeftRight
              />
            </S.SecondaryButton>
          )}
          {!!primaryTitle && (
            <S.PrimaryWrapper>
              {customFooterComponent && customFooterComponent}
              <PrimaryButton
                onClick={!renderAsForm ? onClickPrimary : undefined}
                title={primaryTitle}
                isDisabled={isPrimaryDisabled}
                isLoading={isPrimaryLoading}
                type={buttonType}
              />
            </S.PrimaryWrapper>
          )}
        </S.Footer>
      )}
    </Wrapper>
  );
};

Content.propTypes = {
  onClickSecondary: func,
  onClickPrimary: func,
  secondaryTitle: string,
  primaryTitle: string,
  title: oneOfType([string, node]),
  subtitle: oneOfType([string, array]),
  text: string,
  testId: string,
  type: modalStatusType,
  buttonType: string,
  children: node,
  isSecondaryDisabled: bool,
  isPrimaryDisabled: bool,
  isPrimaryLoading: bool,
  centerTitle: bool,
  renderAsForm: bool,
};

Content.defaultProps = {
  onClickSecondary: () => {},
  onClickPrimary: () => {},
  secondaryTitle: '',
  primaryTitle: '',
  title: '',
  subtitle: '',
  text: '',
  testId: 'modal-content',
  type: null,
  buttonType: 'button',
  isSecondaryDisabled: false,
  isPrimaryDisabled: false,
  isPrimaryLoading: false,
  centerTitle: false,
  renderAsForm: false,
};

export default memo(Content);
