import React from 'react';
import { shape, number, array, oneOfType } from 'prop-types';

import { Row, Col } from 'components/Grid';
import { objectIterator } from 'utils/objectIterator';
import user from 'assets/icons/user.png';

import styles from './AddUser.module.scss';
import { messages } from './messages';

const {
  manageUsersInBulk: {
    usersFound,
    usersToAdd,
    usersWithErrorFound,
    usersWithError,
  },
} = messages;

const AddUserDryRun = ({ csvDryRunResult }) => {
  return (
    <>
      {' '}
      <Row withMarginMedium>
        <Col col={12}>
          <div className={styles.AddUserCSVDropSmall} data-testid="total">
            <span className={styles.AddUserCSVBlue}>
              <img className={styles.AddUserIcon} src={user} alt="user" />{' '}
              {csvDryRunResult?.total} {usersFound}
            </span>
          </div>
        </Col>
      </Row>
      <Row withMarginLarge>
        <Col col={12}>
          {csvDryRunResult?.success > 0 && (
            <p className={styles.AddUserParagraphMedium}>
              {csvDryRunResult?.success} {usersToAdd}
            </p>
          )}
          {csvDryRunResult?.failed > 0 && (
            <>
              <p className={styles.AddUserParagraphMedium}>
                {usersWithErrorFound} {csvDryRunResult?.failed} {usersWithError}{' '}
                :
              </p>
              <div className={styles.AddUserResult}>
                {csvDryRunResult?.errors &&
                  objectIterator(csvDryRunResult?.errors).map((item) => {
                    return (
                      <p key={item} className={styles.AddUserParagraphSmall}>
                        {item}
                      </p>
                    );
                  })}
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

AddUserDryRun.propTypes = {
  csvDryRunResult: shape({
    total: number,
    success: number,
    failed: number,
    errors: oneOfType([array, shape({})]),
  }),
};

AddUserDryRun.defaultProps = {
  csvDryRunResult: null,
};

export default AddUserDryRun;
