export default {
  noAccountHeader:
    "You don't appear to have a business account.\n Please apply below",
  header: 'Add a new business account',
  accept: 'Accept',
  privacyPolicy: ' Privacy Policy',
  tcs: ' T&Cs ',
  logout: 'Log Out ',
  or: 'or',
  continueApp: ' Continue to App',
  primaryTitle: 'Create Account',
  companyName: 'Company Name',
  companyEmail: 'Company Email',
};
