import styled from 'styled-components';
import * as v from 'utils/variables';

export const StyledDiv = styled.div`
  padding: ${v.spacingMd} ${v.spacingLg};
  background: ${v.white};
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  border-radius: 0 0 10px 10px;
`;
