import React from 'react';
import { func, bool, string, oneOfType } from 'prop-types';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { white } from 'utils/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HiddenCheckbox, CheckboxContainer } from './styles';

const Checkbox = ({
  onChange,
  checked,
  id,
  name,
  type,
  testId = `${type}-input`,
  isDisabled,
  value,
}) => (
  <CheckboxContainer
    $type={type}
    $isDisabled={isDisabled}
    data-testid={testId}
    $checked={checked}
    onClick={(e) => {
      if (!isDisabled && onChange) {
        e.stopPropagation();
        if (value === undefined) {
          onChange(name, !checked);
        } else {
          onChange(name, value);
        }
      }
    }}
  >
    <HiddenCheckbox id={id} name={name} checked={checked} readOnly />
    {checked && (
      <FontAwesomeIcon
        data-testid="faCheck"
        size="xs"
        color={white}
        icon={faCheck}
      />
    )}
  </CheckboxContainer>
);

Checkbox.propTypes = {
  onChange: func.isRequired,
  checked: bool,
  id: string,
  name: string,
  testId: string,
  type: string,
  isDisabled: bool,
  value: oneOfType([bool, string]),
};

Checkbox.defaultProps = {
  checked: false,
  type: 'checkbox',
  isDisabled: false,
  value: undefined,
};

export default Checkbox;
