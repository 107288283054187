import React, { useEffect, useState, useMemo, useRef, memo } from 'react';
import { shape } from 'prop-types';
import _isEqual from 'lodash/isEqual';
import { useSelector, useDispatch } from 'react-redux';

import { Column } from 'components/GridNew';
import Pagination from 'components/Pagination';
import scrollTop from 'utils/scrollTop';
import { accountType } from 'constants/propTypes';
import UsersList from '../UsersList';
import {
  DashboardHeaderWrapper,
  DashboardHeaderItem,
  DashboardScrollView,
} from 'views/Dashboard/styles';
import { TEAM_HEADERS } from 'utils/dashboardHeaders';
import UserFilters from '../UserFilters';
import {
  activeMembersSelector,
  activeMembersMetaSelector,
  activeMembersDataSelector,
  searchMembersSelector,
  getActiveMembers,
  searchMembers,
  roleFilterSelector,
  clearSearchMembers,
} from 'store/slices/accountsSlice';

const ActiveUsers = ({ account }) => {
  const promiseRef = useRef();
  const dispatch = useDispatch();
  const [isRoleDisabled, setIsRoleDisabled] = useState(false);
  const _searchMembers = useSelector(searchMembersSelector);

  const {
    data: searchMembersData,
    isSearching: isSearchingMembers,
    meta: searchMembersMeta,
    query: searchQuery,
  } = _searchMembers;
  const { isLoading } = useSelector(activeMembersSelector);
  const members = useSelector((state) =>
    activeMembersDataSelector(state, account.id)
  );
  const { currentPage, totalPages } = useSelector((state) =>
    activeMembersMetaSelector(state, account.id)
  );
  const accountRoleFilter = useSelector(roleFilterSelector);

  const activeUsersProps = useMemo(() => {
    if (searchQuery) {
      return {
        members: searchMembersData,
        isLoading: isSearchingMembers,
        totalPages: searchMembersMeta.totalPages,
        currentPage: searchMembersMeta.currentPage,
        getActiveMembers: ({ page }) =>
          dispatch(searchMembers({ search: searchQuery, page })),
      };
    }
    return {
      members,
      isLoading,
      totalPages,
      currentPage,
      getActiveMembers: ({ accountId, page }) =>
        dispatch(getActiveMembers({ accountId, page })),
    };
  }, [
    currentPage,
    dispatch,
    isLoading,
    isSearchingMembers,
    members,
    searchMembersData,
    searchMembersMeta.currentPage,
    searchMembersMeta.totalPages,
    searchQuery,
    totalPages,
  ]);

  useEffect(() => {
    if (!searchQuery) {
      activeUsersProps.getActiveMembers({ accountId: account.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const members = activeUsersProps.members;
    if (!isSearchingMembers) {
      if (!members.length) {
        setIsRoleDisabled(true);
      } else {
        setIsRoleDisabled(false);
      }
    }
  }, [activeUsersProps.members, isSearchingMembers]);

  const handlePageChange = (data) => {
    activeUsersProps.getActiveMembers({
      accountId: account.id,
      page: data.selected + 1,
    });
    scrollTop();
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value?.trim();
    if (searchValue.length >= 3) {
      if (promiseRef.current) {
        promiseRef.current.abort();
      }
      promiseRef.current = dispatch(searchMembers({ search: searchValue }));
    } else {
      dispatch(clearSearchMembers());
    }
  };

  return (
    <>
      <DashboardHeaderWrapper data-testid="active-users-list" $noBottomPadding>
        <UserFilters
          accountRoleFilter={accountRoleFilter}
          isRoleDisabled={isRoleDisabled}
          account={account}
          searchQuery={searchQuery}
          onSearch={handleSearch}
        />
      </DashboardHeaderWrapper>
      <DashboardHeaderWrapper $noBottomPadding $borderBottom>
        {TEAM_HEADERS.map((header) => (
          <Column key={header.index} col={header.col}>
            <DashboardHeaderItem>{header.data}</DashboardHeaderItem>
          </Column>
        ))}
      </DashboardHeaderWrapper>
      <DashboardScrollView $isTeam $disableScroll={activeUsersProps.isLoading}>
        <UsersList
          account={account}
          usersList={activeUsersProps.members}
          accountRoleFilter={accountRoleFilter}
          isLoading={activeUsersProps.isLoading}
        />
      </DashboardScrollView>
      {activeUsersProps.currentPage && (
        <Pagination
          pageCount={activeUsersProps.totalPages || 1}
          onPageChange={handlePageChange}
          currentPage={activeUsersProps.currentPage}
        />
      )}
    </>
  );
};

ActiveUsers.propTypes = {
  account: shape(accountType).isRequired,
};

const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.account, nextProps.account);
export default memo(ActiveUsers, areEqual);
