import React, { memo, useEffect, useCallback, useMemo } from 'react';
import { bool, shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import isEqual from 'lodash/isEqual';

import Input from 'components/InputNew';
import Button from 'components/Button';
import { Row, Column } from 'components/GridNew';
import CountrySelect from 'components/CountrySelect';
import {
  LabelWithInput,
  LabelContainer,
  LabelWithPhoneNumber,
} from 'components/Label';

import { ACCOUNT_TYPES } from 'constants/accountTypes';
import {
  currentAccountSelector,
  getCurrentAccount,
  updateAccount,
} from 'store/slices/accountsSlice';
import {
  StyledHeaderWrapper,
  StyledBodyWrapper,
  StyledHeader,
  StyledDescription,
  StyledInputWrapper,
  StyledDashboardItemWrapper,
  StyledCancelButton,
  StyledDashboardHeaderItem,
} from 'views/Dashboard/styles';
import FavoritePlaces from './FavoritePlaces';
import {
  SettingsFormSeparator,
  StyledNameWrapper,
  LABEL_STYLE,
} from './styles';
import { settingsSchema, initialValues } from './settingsSchema';
import messages from './messages';
import { difference } from 'utils';
import { COLUMN_INPUT_HEIGHT } from 'utils/variables';

const Settings = ({ isUpdating, defaultCountryCode, favoritePlaces }) => {
  const dispatch = useDispatch();
  const account = useSelector(currentAccountSelector);
  const initialData = useMemo(() => {
    const {
      name,
      company_name,
      company_address_1,
      company_address_2,
      company_address_city,
      company_address_zip_code,
      company_address_country,
      company_address_region,
      admin_email,
      admin_phone,
      admin_phone_number,
      admin_phone_country,
      company_address_tax_id,
      id,
    } = account;
    return {
      id,
      name: name || initialValues.name,
      company_name: company_name || initialValues.company_name,
      company_address_1: company_address_1 || initialValues.company_address_1,
      company_address_2: company_address_2 || initialValues.company_address_2,
      company_address_city:
        company_address_city || initialValues.company_address_city,
      company_address_zip_code:
        company_address_zip_code || initialValues.company_address_zip_code,
      company_address_country:
        company_address_country || initialValues.company_address_country,
      company_address_region:
        company_address_region || initialValues.company_address_region,
      admin_email: admin_email || initialValues.admin_email,
      admin_phone: admin_phone || initialValues.admin_phone,
      admin_phone_number:
        admin_phone_number || initialValues.admin_phone_number,
      admin_phone_country:
        admin_phone_country || initialValues.admin_phone_country,
      company_address_tax_id:
        company_address_tax_id || initialValues.company_address_tax_id,
    };
  }, [account]);

  const getAccount = useCallback(() => {
    if (!account) {
      dispatch(getCurrentAccount());
    }
  }, [dispatch, account]);

  useEffect(() => {
    getAccount();
  }, [getAccount]);

  const handleCancelClick = (resetForm) => {
    resetForm({ values: initialData });
  };

  const onSubmit = (values) => {
    const updatedValues = difference(initialData, values);
    // send only admin_phone value if phone changed
    if (updatedValues.admin_phone_number)
      delete updatedValues.admin_phone_number;
    if (updatedValues.admin_phone_country)
      delete updatedValues.admin_phone_country;

    dispatch(updateAccount(updatedValues));
  };

  return (
    <>
      <StyledDashboardHeaderItem>Settings</StyledDashboardHeaderItem>
      <StyledDashboardItemWrapper data-testid="settings-dashboard-1">
        <StyledHeaderWrapper>
          <StyledHeader>{messages.header}</StyledHeader>
          <StyledDescription>{messages.subtitle}</StyledDescription>
        </StyledHeaderWrapper>
        <Formik
          enableReinitialize
          validationSchema={settingsSchema}
          onSubmit={onSubmit}
          initialValues={initialData}
        >
          {({
            values,
            handleChange,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            resetForm,
          }) => {
            const notChanged = isEqual(initialData, values);
            return (
              <Form noValidate>
                <Column col={5.8}>
                  <StyledNameWrapper>
                    <Input
                      hasError={!!(touched.name && errors.name)}
                      error={errors.name}
                      height={COLUMN_INPUT_HEIGHT}
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      errorPositionAbsolute
                    />
                  </StyledNameWrapper>
                  <SettingsFormSeparator />
                  <StyledBodyWrapper $column>
                    <StyledHeaderWrapper>
                      <StyledHeader>{messages.info}</StyledHeader>
                      <StyledDescription>
                        {messages.infoDisclaimer}
                      </StyledDescription>
                    </StyledHeaderWrapper>
                    <LabelWithInput
                      isColumn
                      labelSize={12}
                      labelStyle={LABEL_STYLE}
                      label={messages.name}
                      value={values.company_name}
                      name="company_name"
                      onChange={handleChange}
                      error={errors.company_name}
                      hasError={!!(touched.company_name && errors.company_name)}
                    />
                    <br />
                    <LabelWithInput
                      isColumn
                      labelSize={12}
                      labelStyle={LABEL_STYLE}
                      label={messages.address}
                      value={values.company_address_1}
                      name="company_address_1"
                      onChange={handleChange}
                      error={errors.company_address_1}
                      hasError={
                        !!(
                          touched.company_address_1 && errors.company_address_1
                        )
                      }
                    />
                    <LabelWithInput
                      isColumn
                      labelSize={12}
                      labelStyle={LABEL_STYLE}
                      value={values.company_address_2}
                      name="company_address_2"
                      onChange={handleChange}
                      error={errors.company_address_2}
                      hasError={
                        !!(
                          touched.company_address_2 && errors.company_address_2
                        )
                      }
                    />
                    <br />
                    <Row spaceBetween>
                      <LabelWithInput
                        isColumn
                        labelSize={5.8}
                        labelStyle={LABEL_STYLE}
                        label={messages.city}
                        value={values.company_address_city}
                        name="company_address_city"
                        onChange={handleChange}
                        error={errors.company_address_city}
                        hasError={
                          !!(
                            touched.company_address_city &&
                            errors.company_address_city
                          )
                        }
                      />
                      <LabelWithInput
                        isColumn
                        labelSize={5.8}
                        labelStyle={LABEL_STYLE}
                        label={messages.state}
                        value={values.company_address_region}
                        name="company_address_region"
                        onChange={handleChange}
                        error={errors.company_address_region}
                        hasError={
                          !!(
                            touched.company_address_region &&
                            errors.company_address_region
                          )
                        }
                      />
                    </Row>
                    <br />
                    <Row spaceBetween>
                      <LabelWithInput
                        isColumn
                        labelSize={5.8}
                        labelStyle={LABEL_STYLE}
                        label={messages.postalCode}
                        value={values.company_address_zip_code}
                        name="company_address_zip_code"
                        onChange={handleChange}
                      />
                      <LabelContainer
                        isColumn
                        labelSize={5.8}
                        labelStyle={LABEL_STYLE}
                        label={messages.country}
                        id="country"
                        error={errors.company_address_country}
                        hasError={
                          !!(
                            touched.company_address_country &&
                            errors.company_address_country
                          )
                        }
                      >
                        <CountrySelect
                          hasError={
                            touched['company_address_country'] &&
                            errors['company_address_country']
                          }
                          error={errors['company_address_country']}
                          onChange={(option) =>
                            setFieldValue(
                              'company_address_country',
                              option.value
                            )
                          }
                          value={values.company_address_country}
                          height={COLUMN_INPUT_HEIGHT}
                        />
                      </LabelContainer>
                    </Row>
                    <br />
                    <LabelWithInput
                      isColumn
                      labelSize={12}
                      label={messages.taxId}
                      labelStyle={LABEL_STYLE}
                      value={values.company_address_tax_id}
                      name="company_address_tax_id"
                      onChange={handleChange}
                      error={errors.company_address_tax_id}
                      hasError={
                        !!(
                          touched.company_address_tax_id &&
                          errors.company_address_tax_id
                        )
                      }
                    />
                    <br />
                    <Row spaceBetween>
                      <LabelWithPhoneNumber
                        isColumn
                        labelSize={5.8}
                        labelStyle={LABEL_STYLE}
                        label={messages.phone}
                        error={errors.admin_phone}
                        hasError={!!(touched.admin_phone && errors.admin_phone)}
                        id="admin_phone"
                        phoneProps={{
                          setFieldValue,
                          setFieldTouched,
                          placeholder: '',
                          defaultValue: values.admin_phone_number,
                          defaultCountry: defaultCountryCode,
                          fieldNames: {
                            phone: 'admin_phone',
                            phoneNumber: 'admin_phone_number',
                            phoneCountry: 'admin_phone_country',
                          },
                        }}
                      />
                      <LabelWithInput
                        isColumn
                        type="email"
                        labelSize={5.8}
                        labelStyle={LABEL_STYLE}
                        label={messages.email}
                        value={values.admin_email}
                        name="admin_email"
                        onChange={handleChange}
                        error={errors.admin_email}
                        hasError={!!(touched.admin_email && errors.admin_email)}
                      />
                    </Row>
                    <SettingsFormSeparator />
                    <StyledInputWrapper $row $alignStart>
                      <Button
                        isRoundedMedium
                        type="submit"
                        primary
                        name={messages.save}
                        disabled={isUpdating || notChanged}
                        isLoading={isUpdating}
                      />
                      <StyledCancelButton
                        type="button"
                        name={messages.cancel}
                        disabled={isUpdating || notChanged}
                        onClick={() => handleCancelClick(resetForm)}
                      />
                    </StyledInputWrapper>
                  </StyledBodyWrapper>
                </Column>
              </Form>
            );
          }}
        </Formik>
      </StyledDashboardItemWrapper>
      <br />

      {account.type === ACCOUNT_TYPES.rfb && (
        <FavoritePlaces
          data={favoritePlaces.data}
          isLoading={favoritePlaces.isLoading}
        />
      )}
    </>
  );
};

Settings.propTypes = {
  favoritePlaces: shape({}),
  defaultCountryCode: string.isRequired,
  isUpdating: bool,
};

Settings.defaultProps = {
  isUpdating: false,
  favoritePlaces: [],
};
const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.favoritePlaces, nextProps.favoritePlaces) &&
  prevProps.defaultCountryCode === nextProps.defaultCountryCode &&
  prevProps.isUpdating === nextProps.isUpdating;

export default memo(Settings, areEqual);
