import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { string, func, bool } from 'prop-types';
import { StyledInput, StyledWrapper } from './styles';

const SearchInput = ({
  placeholder,
  onSearch,
  onBlur,
  isDisabled,
  testId,
  minWidth,
  ...props
}) => (
  <StyledWrapper $minWidth={minWidth}>
    <StyledInput
      data-testid={testId}
      placeholder={placeholder}
      onChange={onSearch}
      onBlur={onBlur}
      disabled={isDisabled}
      {...props}
    />
    <FontAwesomeIcon icon={faSearch} />
  </StyledWrapper>
);

SearchInput.propTypes = {
  placeholder: string,
  onBlur: func,
  onSearch: func.isRequired,
  isDisabled: bool,
  testId: string,
  minWidth: string,
};

SearchInput.defaultProps = {
  placeholder: 'Search',
  isDisabled: false,
  testId: 'search-input',
};

export default SearchInput;
