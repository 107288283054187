import _uniq from 'lodash/uniq';

export function failed(state, action, isLoading) {
  if (isLoading === undefined) {
    state.isLoading = false;
  } else {
    state[isLoading] = false;
  }
  if (action.payload) {
    state.error = action.payload.message;
  } else {
    state.error = action.error.message;
  }
}
export const loading = (state, isLoading) => {
  if (isLoading === undefined) {
    state.isLoading = true;
  } else {
    state[isLoading] = true;
  }
  state.error = null;
};
export function fulfilled(state, isLoading) {
  if (isLoading === undefined) {
    state.isLoading = false;
  } else {
    state[isLoading] = false;
  }
  state.error = null;
}

export function addOrRemove(array, value) {
  const index = array.findIndex((item) => item.id === value.id);
  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
}
export function unshiftOrSet(array, payload) {
  return Array.isArray(array) ? _uniq([payload, ...array]) : [payload];
}
