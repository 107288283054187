import React from 'react';
import cn from 'classnames';
import { node, string } from 'prop-types';

import style from './Grid.module.scss';

const Container = ({ children, className }) => {
  return <div className={cn(style.container, className)}>{children}</div>;
};

Container.propTypes = {
  children: node,
  className: string,
};

Container.defaultProps = {
  children: null,
  className: '',
};

export default Container;
