import React, { memo } from 'react';
import { shape, string } from 'prop-types';
import isEqual from 'lodash/isEqual';

import Button from 'components/Button';
import BoxWithTextAndImage from 'components/BoxWithTextAndImage';
import { routes } from 'routes/routes';
import LogoWithText from 'components/LogoWithText';
import messages from './messages';
import { getLandingPageSection } from './const';
import * as S from './styles';
import fallbackHeaderLogo from 'assets/riidelogo.png';
import fallbackFooterLogo from 'assets/riidelogowhite.png';

const {
  header,
  subheader,
  buttonGetStarted,
  buttonSignInTop,
  buttonSignInBottom,
} = messages;

const LabeledLandingPage = ({
  history,
  companyName,
  headerLogo,
  footerLogo,
}) => (
  <>
    <S.LandingPageWrapper>
      <S.TopPanelWrapper>
        <LogoWithText
          testId="label-landing-page-logo"
          logo={headerLogo}
          altText={companyName}
        />
        <div>
          <S.ButtonsWrapper>
            <S.ButtonWrapper $medium $isUppercase>
              <Button
                onClick={() => history.push(routes.signup)}
                secondary
                withBlueBorder
                isFullWidth
                small
                hasUppercaseText
                name={buttonGetStarted}
              />
            </S.ButtonWrapper>
            <S.ButtonWrapper $isUppercase>
              <Button
                onClick={() => history.push(routes.login)}
                primary
                withBlueBorder
                isFullWidth
                small
                name={buttonSignInTop}
              />
            </S.ButtonWrapper>
          </S.ButtonsWrapper>
        </div>
      </S.TopPanelWrapper>
    </S.LandingPageWrapper>
    <main>
      <S.SectionWrapper>
        <S.ContentWrapper $top>
          <S.ContentHeader>{header}</S.ContentHeader>
          <S.ContentSubheader>{subheader}</S.ContentSubheader>
          <S.ButtonsWrapper>
            <S.ButtonWrapper $medium>
              <Button
                onClick={() => history.push(routes.signup)}
                pink
                withPinkBorder
                isFullWidth
                small
                name={buttonGetStarted}
              />
            </S.ButtonWrapper>
            <S.ButtonWrapper $big>
              <Button
                onClick={() => history.push(routes.login)}
                withPinkBorder
                isFullWidth
                small
                name={buttonSignInBottom}
              />
            </S.ButtonWrapper>
          </S.ButtonsWrapper>
        </S.ContentWrapper>
      </S.SectionWrapper>
      <section>
        {getLandingPageSection(companyName).map(
          ({ header, text, textOrder, imgSource, imgOrientation }) => (
            <S.SectionBoxWrapper key={header}>
              <S.ContentWrapper>
                <BoxWithTextAndImage
                  key={header}
                  header={header}
                  text={text}
                  textOrder={textOrder}
                  imgSource={imgSource}
                  imgOrientation={imgOrientation}
                />
              </S.ContentWrapper>
            </S.SectionBoxWrapper>
          )
        )}
      </section>
    </main>
    <S.LandingPageFooter>
      <S.LandingPageFooterWrapper>
        <S.Logo src={footerLogo} alt={companyName} title={companyName} />
        <S.Copyrights>&copy; {companyName} 2020</S.Copyrights>
      </S.LandingPageFooterWrapper>
    </S.LandingPageFooter>
  </>
);
LabeledLandingPage.propTypes = {
  history: shape({}).isRequired,
  companyName: string,
  headerLogo: string,
  footerLogo: string,
};
LabeledLandingPage.defaultProps = {
  companyName: '',
  headerLogo: fallbackHeaderLogo,
  footerLogo: fallbackFooterLogo,
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.history, nextProps.history) &&
  prevProps.companyName === nextProps.companyName &&
  prevProps.headerLogo === nextProps.headerLogo &&
  prevProps.footerLogo === nextProps.footerLogo;

export default memo(LabeledLandingPage, areEqual);
