import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import UseMobileAppTemplateContainer from './UseMobileAppTemplateContainer';
import { generalCompanyConfig } from 'store/slices/companySlice';

const UseMobileAppTemplate = ({ subdomain }) => {
  const [appLink, setAppLink] = useState('');
  const company = useSelector((state) => state.company);
  const config = useSelector(generalCompanyConfig);

  useEffect(() => {
    if (config?.onelink_url) {
      setAppLink(config.onelink_url);
    }
  }, [config]);

  return (
    <UseMobileAppTemplateContainer
      appLink={appLink}
      isLoadingAppLink={company.isLoading && !appLink}
    />
  );
};

UseMobileAppTemplate.propTypes = {
  subdomain: string,
};

UseMobileAppTemplate.defaultProps = {
  subdomain: '',
};

export default UseMobileAppTemplate;
