import styled from 'styled-components';

import { primary, fontSizeSm, mousegray, spacingLg } from 'utils/variables';

export const StyledCheckboxLabel = styled.p`
  font-size: ${fontSizeSm};
  a {
    color: ${primary};
    text-decoration: none;
  }
`;
export const StyledLink = styled.span`
  border: none;
  outline: none;
  color: ${(props) => props.$color || primary};
  padding-left: 0;
  cursor: pointer;
`;
export const StyledBottom = styled.p`
  color: ${mousegray};
  font-size: ${fontSizeSm};
  margin-top: ${spacingLg};
  display: block;
  a {
    color: ${primary};
    text-decoration: none;
  }
`;
