import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { string, func } from 'prop-types';

import { Row, Col } from 'components/Grid';
import { BasicModalContent } from 'components/Modals/BasicModal';

import AddUserDryRun from './AddUserDryRun';
import AddUserDropZone from './AddUserDropZone';
import styles from './AddUser.module.scss';
import { messages } from './messages';

import {
  csvMemberImport,
  csvDryRunSelector,
  clearCsvDryRun,
} from 'store/slices/accountsSlice';
import { commonText } from 'locales/en';

const AddUserCSV = ({ toggleCSV, handleModalToggle, title, subtitle }) => {
  const dispatch = useDispatch();
  const { isLoading, isCompleted, result: csvDryRunResult } = useSelector(
    csvDryRunSelector
  );
  const { total, failed } = csvDryRunResult;
  const [file, uploadFile] = useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    uploadFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  useEffect(() => {
    return () => {
      dispatch(clearCsvDryRun());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAddingDisabled = !file || (total !== null && total === failed);

  if (isCompleted) {
    return (
      <BasicModalContent
        subtitle={messages.manageUsersInBulk.usersInvitedSubHeader}
        text={messages.manageUsersInBulk.usersInvitedText}
        primaryTitle={messages.manageUsersInBulk.usersInvitedButton}
        onClickPrimary={handleModalToggle}
      />
    );
  }

  return (
    <BasicModalContent
      title={title || messages.manageUsersInBulk.header}
      subtitle={subtitle}
      centerTitle
      isPrimaryDisabled={isAddingDisabled || isLoading}
      isPrimaryLoading={isLoading}
      primaryTitle={
        total
          ? messages.manageUsersInBulk.finishBtn
          : messages.manageUsersInBulk.continueBtn
      }
      onClickPrimary={() => {
        dispatch(
          csvMemberImport({
            accountId: null,
            file,
            dryRun: !isCompleted && total ? 0 : 1,
          })
        );
      }}
      onClickSecondary={toggleCSV}
      secondaryTitle={commonText.close}
    >
      <Row withMarginLarge>
        <Col col={12} className={styles.AddUserDropZoneWrapper}>
          {!isCompleted && total === null && (
            <AddUserDropZone
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              isDragActive={isDragActive}
              file={file}
            />
          )}
          {!isCompleted && total !== null && (
            <AddUserDryRun csvDryRunResult={csvDryRunResult} />
          )}
        </Col>
      </Row>
    </BasicModalContent>
  );
};

AddUserCSV.propTypes = {
  toggleCSV: func.isRequired,
  handleModalToggle: func.isRequired,
  title: string,
  subtitle: string,
};
AddUserCSV.defaultProps = {
  title: null,
  subtitle: null,
};
export default AddUserCSV;
