import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { func, bool, string, shape } from 'prop-types';
import cn from 'classnames';

import CountrySelect from 'components/CountrySelect';
import Input from 'components/Input';
import ModalDefaultActionButtons from 'components/ModalDefaultActionButtons';
import {
  initialValues,
  invoiceDataPropShape,
  invoicingSchema,
} from './invoicingSchema';
import { Row, Col } from 'components/Grid';
import { messages } from './messages';
import { ACCOUNT_TYPES } from 'constants/accountTypes';
import { createAccount } from 'store/slices/accountsSlice';
import { usePrevious } from 'hooks';
import { LabelWithCheckbox } from 'components/Label';
import { fontSizeSm } from 'utils/variables';
import styles from './Invoicing.module.scss';

const Invoicing = ({
  readOnly,
  invoiceData,
  handleModalClose,
  onSuccess,
  companyEmail,
  companyName,
  isPending,
  account,
  isCreatingAccount,
}) => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.accounts.error);
  const prevIsCreatingAccount = usePrevious(isCreatingAccount);

  useEffect(() => {
    if (prevIsCreatingAccount && isCreatingAccount === false && !error) {
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevIsCreatingAccount, isCreatingAccount, error]);

  return (
    <div>
      <Formik
        onSubmit={(values) =>
          dispatch(
            createAccount({
              rfbAccountId: account?.id,
              values,
              type: ACCOUNT_TYPES.invoice,
            })
          )
        }
        initialValues={invoiceData}
        validationSchema={invoicingSchema}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          errors,
          touched,
          handleBlur,
          dirty,
        }) => {
          return (
            <Form noValidate>
              <Row>
                <div className={styles.InvoicingContainer}>
                  <p className={styles.InvoicingHeader}>
                    {readOnly ? messages.readOnlyHeader : messages.header}
                  </p>
                  <p
                    className={cn(styles.InvoicingDescription, {
                      [styles.hasBorderBottom]: true,
                    })}
                  >
                    {readOnly
                      ? messages.readOnlyDescription(companyEmail)
                      : messages.description}
                  </p>
                  {readOnly && isPending && (
                    <p className={styles.PendingApprovalMessage}>
                      {messages.pending}
                    </p>
                  )}
                  <Row withMarginMedium>
                    <div className={styles.InvoicingInputWrapper}>
                      <label
                        className={styles.InvoicingLabel}
                        htmlFor="companyName"
                      >
                        {messages.companyName}
                      </label>
                      <Input
                        value={values.name}
                        name="name"
                        id="companyName"
                        bgWhite
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        disabled={readOnly}
                        hasDisabledStyles
                      />
                    </div>
                  </Row>
                  <Row>
                    <label
                      className={styles.InvoicingLabel}
                      htmlFor="companyAddress"
                    >
                      {messages.headquarters}
                    </label>
                  </Row>
                  <Row withMarginSmall>
                    <div className={styles.InvoicingInputWrapper}>
                      <Input
                        value={values.company_address_1}
                        id="companyAddress"
                        bgWhite
                        name="company_address_1"
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        disabled={readOnly}
                        hasDisabledStyles
                      />
                    </div>
                  </Row>
                  <Row withMarginMedium>
                    <div className={styles.InvoicingInputWrapper}>
                      <Input
                        name="company_address_2"
                        value={values.company_address_2}
                        id="company_address2"
                        bgWhite
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        disabled={readOnly}
                        hasDisabledStyles
                      />
                    </div>
                  </Row>
                  <Row withMarginMedium>
                    <Col withMarginRight col={6}>
                      <label htmlFor="city" className={styles.InvoicingLabel}>
                        {messages.city}
                      </label>
                      <Input
                        value={values.company_address_city}
                        name="company_address_city"
                        id="city"
                        bgWhite
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        disabled={readOnly}
                        hasDisabledStyles
                      />
                    </Col>
                    <Col col={6}>
                      <label htmlFor="state" className={styles.InvoicingLabel}>
                        {messages.state}
                      </label>
                      <Input
                        name="company_address_region"
                        value={values.company_address_region}
                        id="state"
                        bgWhite
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        disabled={readOnly}
                        hasDisabledStyles
                      />
                    </Col>
                  </Row>
                  <Row withMarginMedium>
                    <Col withMarginRight col={6}>
                      <label
                        className={styles.InvoicingLabel}
                        htmlFor="postalCode"
                      >
                        {messages.postalCode}
                      </label>
                      <Input
                        value={values.company_address_zip_code}
                        name="company_address_zip_code"
                        id="postalCode"
                        bgWhite
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        disabled={readOnly}
                        hasDisabledStyles
                      />
                    </Col>
                    <Col col={6}>
                      <label
                        className={styles.InvoicingLabel}
                        htmlFor="country"
                      >
                        {messages.country}
                      </label>
                      <CountrySelect
                        hasError={
                          !!(
                            touched['company_address_country'] &&
                            errors['company_address_country']
                          )
                        }
                        error={errors['company_address_country']}
                        onChange={(option) =>
                          setFieldValue('company_address_country', option.value)
                        }
                        isDisabled={readOnly}
                        value={values.company_address_country}
                      />
                    </Col>
                  </Row>
                  <Row withMarginMedium>
                    <div className={styles.InvoicingInputWrapper}>
                      <label htmlFor="taxId" className={styles.InvoicingLabel}>
                        {messages.taxId}
                      </label>
                      <Input
                        name="company_address_tax_id"
                        value={values.company_address_tax_id}
                        id="taxId"
                        bgWhite
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        disabled={readOnly}
                        hasDisabledStyles
                      />
                    </div>
                  </Row>
                  <Row withMarginMedium>
                    <div className={styles.InvoicingInputWrapper}>
                      <label htmlFor="spend" className={styles.InvoicingLabel}>
                        {messages.spend}
                      </label>
                      <Input
                        type="number"
                        name="expected_monthly_spend"
                        value={values.expected_monthly_spend}
                        id="spend"
                        bgWhite
                        onChange={handleChange}
                        errors={errors}
                        touched={touched}
                        disabled={readOnly}
                        hasDisabledStyles
                        min={1}
                      />
                    </div>
                  </Row>
                  {!readOnly && (
                    <>
                      <p className={styles.InvoicingDescription}>
                        {messages.creditCheck(companyName)}
                      </p>
                      <p className={styles.InvoicingDescription}>
                        {messages.disclaimer(companyName)}
                      </p>
                      <LabelWithCheckbox
                        labelStyle={{
                          fontWeight: 'normal',
                          fontSize: fontSizeSm,
                        }}
                        label={messages.agreement}
                        id="checkbox"
                        name="checkbox"
                        onChange={setFieldValue}
                        checked={values.checkbox}
                        hasError={!!(touched.checkbox && errors.checkbox)}
                        error={errors.checkbox}
                      />
                      <ModalDefaultActionButtons
                        onCancelButtonClick={handleModalClose}
                        submitButtonText="Save Changes"
                        isSubmitButtonDisabled={!dirty || isCreatingAccount}
                        isSubmitButtonLoading={isCreatingAccount}
                      />
                    </>
                  )}
                </div>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

Invoicing.propTypes = {
  readOnly: bool,
  handleModalClose: func,
  onSuccess: func,
  invoiceData: invoiceDataPropShape,
  companyEmail: string,
  isPending: bool,
  isCreatingAccount: bool,
  account: shape({}),
};

Invoicing.defaultProps = {
  readOnly: false,
  handleModalClose: () => {},
  onSuccess: () => {},
  invoiceData: initialValues,
  companyEmail: '',
  isPending: false,
  isCreatingAccount: null,
  account: {},
};

export default memo(Invoicing);
