import React, { useState } from 'react';
import { string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import * as S from './styles';

const ProfileImage = ({ src }) => {
  const [error, setError] = useState(false);
  const onError = () => {
    if (!error) {
      setError(true);
    }
  };

  return src && !error ? (
    <S.Image onError={onError} src={src} />
  ) : (
    <S.Border>
      <FontAwesomeIcon
        className="driver"
        size="3x"
        color="white"
        icon={faUser}
      />
    </S.Border>
  );
};
ProfileImage.propTypes = {
  src: string,
};
export default ProfileImage;
