import React, { memo } from 'react';
import { generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ActiveUsers from './ActiveUsers';
import PendingUsers from './PendingUsers';
import Groups from './Groups';

import { TEAMMATES, PENDING_INVITES, GROUPS, TEAM_TABS } from 'utils/tabs';
import { routes } from 'routes/routes';
import { useGetActiveSecondaryTabFromPath } from 'hooks';
import { currentAccountSelector } from 'store/slices/accountsSlice';
import {
  StyledDashboardItemWrapper,
  StyledDashboardHeader,
  StyledHeader,
  StyledDashboardHeaderItemSmall,
} from 'views/Dashboard/styles';

const Team = () => {
  const account = useSelector(currentAccountSelector);
  const activeTab = useGetActiveSecondaryTabFromPath();
  const isTeammatesTab = activeTab === TEAMMATES;
  const isGroupsTab = activeTab === GROUPS;
  const isPendingInvitesTab = activeTab === PENDING_INVITES;
  const { isAddGroupView, current: currentGroup } = useSelector(
    (state) => state.groups
  );

  return (
    <>
      <StyledDashboardHeader>
        {isAddGroupView ? (
          <StyledHeader>
            {currentGroup ? 'Edit Group' : 'Add Group'}
          </StyledHeader>
        ) : (
          <>
            {TEAM_TABS.map((item) => {
              const isActive =
                item === activeTab || (!activeTab && item === TEAMMATES);

              return (
                <StyledDashboardHeaderItemSmall
                  key={item}
                  to={generatePath(routes.team, {
                    tab: item.replace(/\s+/g, '-').toLowerCase(),
                  })}
                  $active={isActive}
                >
                  {item}
                </StyledDashboardHeaderItemSmall>
              );
            })}
          </>
        )}
      </StyledDashboardHeader>
      <StyledDashboardItemWrapper $hasStaticHeight data-testid="user-dashboard">
        {isTeammatesTab && <ActiveUsers account={account} />}
        {isPendingInvitesTab && <PendingUsers account={account} />}
        {isGroupsTab && <Groups />}
      </StyledDashboardItemWrapper>
    </>
  );
};

export default memo(Team);
