const payInCar = 'pay-in-car';

export const PAYMENT_METHODS = {
  cash: 'cash',
  card: 'card',
  account: 'account',
  invoice: 'invoice',
  [payInCar]: payInCar,
  default: 'default',
};

const isPaymentMethod = (paymentMethodType) => (
  rfbPaymentMethodId,
  availablePaymentMethods
) => {
  const paymentMethod = availablePaymentMethods.find(
    (method) => method.type === paymentMethodType
  );

  return rfbPaymentMethodId === paymentMethod?.id;
};

export const checkMethod = {
  isCash: isPaymentMethod(PAYMENT_METHODS.cash),
  isCard: isPaymentMethod(PAYMENT_METHODS.card),
  isAccount: isPaymentMethod(PAYMENT_METHODS.account),
  isInvoice: isPaymentMethod(PAYMENT_METHODS.invoice),
  isPayInCar: isPaymentMethod(PAYMENT_METHODS[payInCar]),
};

export const ADD_CARD_OPTION_VALUE = 'add_card';
export const ADD_CARD_OPTION_LABEL = 'Add new payment method';
