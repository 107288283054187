import styled from 'styled-components';
import { StyledActionText } from 'views/Dashboard/styles';

export const StyledBody = styled.div`
  min-height: 280px;
`;
export const StyledBulkButton = styled(StyledActionText)`
  display: block;
  margin-left: auto;
  margin-right: 0;
`;
export const LABEL_STYLE = { fontWeight: 'normal' };
