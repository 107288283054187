import { initialState as bookings } from 'store/slices/bookingsSlice';
import { initialState as accounts } from 'store/slices/accountsSlice';
import { initialState as user } from 'store/slices/userSlice';
import { initialState as company } from 'store/slices/companySlice';
import { initialState as signUpSteps } from 'store/slices/signUpStepsSlice';
import { initialState as appStatus } from 'store/slices/appStatusSlice';
import { initialState as tabs } from 'store/slices/tabsSlice';

export default {
  bookings,
  accounts,
  user,
  company,
  signUpSteps,
  appStatus,
  tabs,
};
