import styled, { css } from 'styled-components';

export const Square = styled.div`
  position: relative;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  ${(props) =>
    props.$marginFromRight &&
    css`
      margin-right: -${(props) => props.$width}px;
    `};
  ${(props) =>
    props.$marginFromRight === false &&
    css`
      margin-left: -${(props) => props.$width}px;
    `};

  ${(props) =>
    props.$margin &&
    css`
      margin-top: -${props.$margin}px;
      margin-left: -${(props) => props.$margin}px;
    `};
`;
export const LottieWrapper = styled.div`
  position: absolute;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  transform: ${(props) =>
    `scale(${props.$scaleX}, ${props.$scaleY}) !important`};
  z-index: -1;
  div {
    overflow: visible !important;
  }
`;
