import React from 'react';
import styles from './Grid.module.scss';
import classNames from 'classnames/bind';

let cx = classNames.bind(styles);

const Col = ({
  col,
  className,
  children,
  withMarginRight,
  withMarginForInput,
}) => {
  let composedClassNames = cx(
    {
      [`col-${col}`]: col,
      [styles.withMarginRight]: withMarginRight,
      [styles.withMarginForInput]: withMarginForInput,
    },
    className
  );
  return <div className={composedClassNames}>{children}</div>;
};

export default Col;
