import React from 'react';
import { oneOfType, shape, string } from 'prop-types';

import messages from 'views/LabeledLandingPage/messages';
import { LogoWrapper, Logo, LogoText } from 'views/LabeledLandingPage/styles';
import { routes } from 'routes/routes';

const LogoWithText = ({ testId, logo, altText, text, to, height, width }) => (
  <LogoWrapper to={to}>
    <Logo
      data-testid={testId}
      src={logo}
      alt={altText}
      title={altText}
      $width={width}
      $height={height}
    />
    <LogoText>{text}</LogoText>
  </LogoWrapper>
);

LogoWithText.propTypes = {
  testId: string,
  logo: oneOfType([shape({}), string]).isRequired,
  altText: string,
  text: string,
  to: string,
  height: string,
  width: string,
};
LogoWithText.defaultProps = {
  testId: 'logo-with-text',
  altText: '',
  text: messages.logoText,
  to: routes.default,
  height: null,
  width: null,
};
export default LogoWithText;
