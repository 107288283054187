import React from 'react';
import cx from 'classnames';
import { string, number, oneOf } from 'prop-types';

import styles from './BoxWithTextAndImage.module.scss';

const IMG_ORIENTATION_HORIZONTAL = 'horizontal';
const IMG_ORIENTATION_VERTICAL = 'vertical';

const BoxWithTextAndImage = ({
  header,
  textOrder,
  text,
  imgOrientation,
  imgSource,
}) => (
  <div
    data-testid="box-with-text-and-image"
    key={header}
    className={styles.Box}
  >
    <div
      className={cx(styles.BoxTextWrapper, {
        [styles.isSecond]: textOrder === 2,
      })}
    >
      <p
        data-testid="box-with-text-and-image-header"
        className={styles.BoxHeader}
      >
        {header}
      </p>
      <p data-testid="box-with-text-and-image-text" className={styles.BoxText}>
        {text}
      </p>
    </div>
    <div
      className={cx(styles.BoxImageWrapper, {
        [styles.isSecond]: textOrder !== 2,
      })}
    >
      <img
        data-testid="box-with-text-and-image-img"
        className={cx(styles.BoxImg, {
          [styles.horizontal]: imgOrientation === IMG_ORIENTATION_HORIZONTAL,
          [styles.vertical]: imgOrientation === IMG_ORIENTATION_VERTICAL,
        })}
        src={imgSource}
        alt={header}
      />
    </div>
  </div>
);

BoxWithTextAndImage.propTypes = {
  header: string,
  textOrder: number,
  text: string,
  imgOrientation: oneOf([IMG_ORIENTATION_HORIZONTAL, IMG_ORIENTATION_VERTICAL]),
  imgSource: string,
};

BoxWithTextAndImage.defaultProps = {
  header: '',
  textOrder: 1,
  text: '',
  imgOrientation: IMG_ORIENTATION_HORIZONTAL,
  imgSource: '',
};

export default BoxWithTextAndImage;
