import React, { memo, useMemo, useState } from 'react';
import { bool, array } from 'prop-types';
import isEqual from 'lodash/isEqual';

import { Column } from 'components/GridNew';
import { NewPlace } from 'components/Place';
import DashboardEmptyStateTemplate from 'components/DashboardEmptyStateTemplate';
import noUsersImg from 'assets/dashboard/no-users.png';

import {
  StyledHeaderWrapper,
  StyledHeader,
  StyledDescription,
  StyledActionText,
  StyledDashboardItemWrapper,
  DashboardHeaderWrapper,
  DashboardHeaderItem,
  DashboardScrollView,
} from 'views/Dashboard/styles';
import ListLoader from 'components/ListLoader';
import FavoritePlacesRow from './FavoritePlacesRow';

import {
  FAVORITE_PLACES_HEADERS,
  FAVORITE_PLACES_COLUMNS,
} from 'utils/dashboardHeaders';

import messages from './messages';

const FavoritePlaces = ({ data, isLoading }) => {
  const [isNewPlaceModalVisible, setIsNewPlaceModalVisible] = useState(false);

  const renderConditionalView = useMemo(() => {
    if (!data.length && !isLoading) {
      return (
        <DashboardEmptyStateTemplate
          imgSource={noUsersImg}
          imgAlt={messages.savedPlaces.emptyStateHeader}
          header={messages.savedPlaces.emptyStateHeader}
          text={messages.savedPlaces.emptyStateText}
        >
          <StyledActionText onClick={() => setIsNewPlaceModalVisible(true)}>
            {messages.savedPlaces.saveNew}
          </StyledActionText>
        </DashboardEmptyStateTemplate>
      );
    } else if (data.length) {
      return data.map((place) => (
        <FavoritePlacesRow key={place.id} place={place} isLoading={isLoading} />
      ));
    } else {
      return (
        <ListLoader
          length={8}
          cols={FAVORITE_PLACES_COLUMNS}
          data={['Office', 'Information desk, terminal 1', '']}
        />
      );
    }
  }, [data, isLoading]);

  return (
    <>
      <StyledHeaderWrapper>
        <StyledHeader>{messages.savedPlaces.header}</StyledHeader>
        <StyledDescription>
          {messages.savedPlaces.subtitle}
          <StyledActionText onClick={() => setIsNewPlaceModalVisible(true)}>
            {messages.savedPlaces.saveNew}
          </StyledActionText>
        </StyledDescription>
      </StyledHeaderWrapper>
      <StyledDashboardItemWrapper $hasStaticHeight data-testid="saved-places">
        <DashboardHeaderWrapper $noBottomPadding $borderBottom>
          {FAVORITE_PLACES_HEADERS.map((header) => (
            <Column key={header.index} col={header.col}>
              <DashboardHeaderItem>{header.data}</DashboardHeaderItem>
            </Column>
          ))}
        </DashboardHeaderWrapper>
        <DashboardScrollView $disableScroll={isLoading}>
          {renderConditionalView}
        </DashboardScrollView>
      </StyledDashboardItemWrapper>

      {isNewPlaceModalVisible && (
        <NewPlace
          isModalOpen={isNewPlaceModalVisible}
          onClose={() => setIsNewPlaceModalVisible(false)}
        />
      )}
    </>
  );
};

FavoritePlaces.propTypes = {
  data: array.isRequired,
  isLoading: bool,
};

FavoritePlaces.defaultProps = {
  data: [],
  isLoading: null,
};
const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.data, nextProps.data) &&
  prevProps.isLoading === nextProps.isLoading;

export default memo(FavoritePlaces, areEqual);
