import React, { memo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func, bool } from 'prop-types';
import { Formik, Form } from 'formik';

import messages from './messages';
import { commonText } from 'locales/en';

import schema, { initialValues, FIELDS } from './schema';
import { selectorForOtherAddress } from 'store/slices/bookingsSlice';
import { createNewPlace } from 'store/slices/accountsSlice';
import BasicModal from 'components/Modals/BasicModal';

import {
  LabelWithInput,
  LabelWithTextArea,
  LabelWithAddressSearch,
} from 'components/Label';

const NewPlace = ({ isModalOpen, onClose }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const { isRequesting, addressResult, serviceStatus } = useSelector(
    selectorForOtherAddress
  );

  const onSubmit = ({ name, driver_notes, lat, lng, address }) => {
    dispatch(
      createNewPlace({
        address,
        name,
        driver_notes,
        lat,
        lng,
      })
    ).then(() => onClose());
  };

  return (
    <BasicModal
      buttonType="submit"
      testId="new-address-modal"
      isModalOpen={isModalOpen}
      title={messages.newTitle}
      handleModalToggle={onClose}
      primaryTitle={messages.save}
      secondaryTitle={commonText.close}
      onClickSecondary={onClose}
      isPrimaryDisabled={formRef.current?.dirty === false || isRequesting}
      isPrimaryLoading={isRequesting}
      onClickPrimary={() => formRef.current?.submitForm()}
      minHeight="215px"
    >
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        enableReinitialize
        innerRef={formRef}
        onSubmit={onSubmit}
      >
        {({
          handleChange,
          values,
          setFieldValue,
          errors,
          touched,
          setFieldTouched,
          handleBlur,
          dirty,
        }) => {
          return (
            <Form
              data-testid="new-address-form"
              aria-autocomplete="none"
              autoComplete="off"
            >
              <LabelWithAddressSearch
                label={messages.address}
                id={FIELDS.address}
                name={FIELDS.address}
                hasError={!!(errors.address && touched.address)}
                error={errors.address}
                addressResult={addressResult}
                addressType="other"
                value={values.address}
                serviceStatus={serviceStatus}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                onBlur={handleBlur}
                showSaveAddress={false}
                zIndex={8}
                testId={FIELDS.address}
                fields={{
                  address: FIELDS.address,
                  lat: FIELDS.lat,
                  lng: FIELDS.lng,
                }}
              />
              <LabelWithInput
                testId={FIELDS.name}
                label={messages.name}
                error={errors.name}
                name={FIELDS.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                hasError={!!errors.name && !!touched.name}
              />
              <LabelWithTextArea
                testId={FIELDS.driver_notes}
                label={messages.driver_notes}
                name={FIELDS.driver_notes}
                value={values.driver_notes}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.driver_notes}
                hasError={!!(errors.driver_notes && touched.driver_notes)}
              />
            </Form>
          );
        }}
      </Formik>
    </BasicModal>
  );
};

NewPlace.propTypes = {
  isModalOpen: bool.isRequired,
  onClose: func.isRequired,
};

const areEqual = (prevProps, nextProps) =>
  prevProps.isModalOpen === nextProps.isModalOpen;

export default memo(NewPlace, areEqual);
