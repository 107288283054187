import React, { memo, useMemo } from 'react';
import { shape, string } from 'prop-types';

import Promo from './Promo';
import PaymentName from './PaymentName';
import { Column, Row, BoldText, Text, HorizontalLine, Label } from './styles';
import messages from './messages';

const JourneySummary = ({ summary, paymentMethod, currency }) => {
  const reward = useMemo(() => summary?.reward, [summary]);
  const defaultPrice = useMemo(() => `${currency}0.00`, [currency]);

  if (!summary || !paymentMethod || !currency) return;

  return (
    <Column data-testid="journeySummary">
      <HorizontalLine $smallMargin />
      <Row>
        <Label $largeText>{messages.journeySummary.fare}</Label>
        <Text>{summary.fare_price || defaultPrice}</Text>
      </Row>
      <Row>
        <Label $largeText>{messages.journeySummary.tip}</Label>
        <Text>{summary.tip || defaultPrice}</Text>
      </Row>
      {reward && (
        <Row data-testid="reward">
          <Label $largeText>
            <Promo text={reward.name} />
          </Label>
          <Text $green>
            -{reward.value_type === 'amount' ? currency : '%'}
            {reward.value?.toFixed(2) || defaultPrice}
          </Text>
        </Row>
      )}
      <HorizontalLine $smallMargin />
      <Row>
        <Label $flex $largeText>
          <PaymentName paymentMethod={paymentMethod} />
        </Label>
        <BoldText>{summary.sub_total_price || defaultPrice}</BoldText>
      </Row>
    </Column>
  );
};

JourneySummary.propTypes = {
  summary: shape({}).isRequired,
  paymentMethod: shape({}).isRequired,
  currency: string.isRequired,
};
export default memo(JourneySummary);
