import React from 'react';
import { node, bool, string } from 'prop-types';

import { StyledRow } from './styles';

const Row = ({
  testId,
  children,
  spaceBetween,
  withMarginLarge,
  withMarginSmall,
  padding,
  margin,
  alignItemsCenter,
}) => (
  <StyledRow
    data-testid={testId}
    $spaceBetween={spaceBetween}
    $withMarginLarge={withMarginLarge}
    $withMarginSmall={withMarginSmall}
    $padding={padding}
    $margin={margin}
    $alignItemsCenter={alignItemsCenter}
  >
    {children}
  </StyledRow>
);

Row.propTypes = {
  testId: string,
  children: node,
  spaceBetween: bool,
  withMarginLarge: bool,
  withMarginSmall: bool,
  padding: string,
  margin: string,
};
Row.defaultProps = {
  testId: 'row',
  children: null,
  spaceBetween: false,
  withMarginLarge: false,
  withMarginSmall: false,
  padding: null,
  margin: null,
};
export default Row;
