import * as Yup from 'yup';

export const initialValues = {
  admin_email: '',
  name: '',
  checkbox: false,
};

export const schema = Yup.object().shape({
  admin_email: Yup.string()
    .email('Email not valid')
    .required('Email is required'),
  name: Yup.string()
    .min(3, 'Company name must be at least 3 characters')
    .required('Company name is required'),
  checkbox: Yup.bool()
    .oneOf([true], 'You must accept the terms and conditions')
    .required(),
});
