import React, { useMemo, useState, useRef, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { array, func, bool, string } from 'prop-types';
import _some from 'lodash/some';
import _isEmpty from 'lodash/isEmpty';

import { validateAccount } from 'store/slices/accountsSlice';
import BasicModal from 'components/Modals/BasicModal';
import Select from 'components/Select';
import { usePrevious } from 'hooks';

import messages from './messages';
import {
  modalStyle,
  PinWrapper,
  Code,
  QuestionWrapper,
  SelectWrapper,
  Input,
} from './styles';

const TEXT = 'text';
const PIN = 'pin';

const ValidationFields = ({
  isModalOpen,
  name,
  onClose,
  setFieldValue,
  fields,
}) => {
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector((state) => state.accounts);
  const prevIsLoading = usePrevious(isLoading);
  const [selectedValue, setSelectedValue] = useState(null);
  const [code, setCode] = useReducer(
    (prevState, nextState) => ({
      ...prevState,
      ...nextState,
    }),
    { code1: '', code2: '', code3: '', code4: '' }
  );
  const code1 = useRef(null);
  const code2 = useRef(null);
  const code3 = useRef(null);
  const code4 = useRef(null);
  const pinValue = useMemo(
    () => `${code.code1}${code.code2}${code.code3}${code.code4}`,
    [code.code1, code.code2, code.code3, code.code4]
  );

  const codeRefArray = [code1, code2, code3, code4];

  const [pinVerified, setPinVerified] = useState(false);
  const hasPinField = fields.findIndex((item) => item.type === PIN) > -1;
  const isPinView = useMemo(() => hasPinField && !pinVerified, [
    hasPinField,
    pinVerified,
  ]);
  const isDisabled = isPinView
    ? _some(code, _isEmpty)
    : _some(fields, ['value', '']);

  const questions = hasPinField
    ? fields.filter((item) => item.type !== PIN)
    : fields;

  const onClickPrimary = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isPinView && pinValue.length) {
      const pinIndex = fields.findIndex((item) => item.type === PIN);
      const newValue = [...fields];
      newValue[pinIndex] = { ...fields[pinIndex], value: pinValue };
      setFieldValue(name, newValue);
      questions.length ? setPinVerified(true) : handleClose();
    }
    if (!isPinView) {
      const requestBody = fields.reduce(
        (acc, curr) => (acc = { ...acc, [curr.id]: curr.value }),
        {}
      );
      dispatch(validateAccount({ fields: requestBody }));
    }
  };

  useEffect(() => {
    if (prevIsLoading && isLoading === false && !error) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevIsLoading, isLoading, error]);

  const onKeyPress = (e, codeIndex) => {
    let key = Number(e.key);
    if (e.key === 'Backspace') {
      setCode({
        [`code${codeIndex + 1}`]: '',
      });
      codeIndex > 0 && codeRefArray[codeIndex - 1].current.focus();
    } else if (Number.isNaN(key) || e.key === null || e.key === ' ') {
      return;
    } else {
      setCode({
        [`code${codeIndex + 1}`]: e.key,
      });
      codeIndex < 3 && codeRefArray[codeIndex + 1].current.focus();
    }
  };

  const onChange = (e, field) => {
    const index = fields.findIndex((item) => item.id === field.id);
    const newValue = [...fields];
    if (index > -1) {
      newValue[index] = { ...fields[index], value: e.target.value };
      setFieldValue(name, newValue);
    } else {
      setFieldValue(name, [...fields, { id: field.id, value: e.target.value }]);
    }
  };

  const onBlur = (e, field) => {
    const index = fields.findIndex((item) => item.id === field.id);
    const newValue = [...fields];
    if (index > -1) {
      newValue[index] = { ...fields[index], value: field.value.trim() };
      setFieldValue(name, newValue);
    } else {
      setFieldValue(name, [
        ...fields,
        { id: field.id, value: field.value.trim() },
      ]);
    }
  };

  const onSelect = (option, field) => {
    const index = fields.findIndex((item) => item.id === field.id);
    const newValue = [...fields];
    if (index > -1) {
      newValue[index] = { ...fields[index], value: option.key };
      setFieldValue(name, newValue);
      setSelectedValue(option);
    }
  };

  const handleClose = () => {
    if (error) {
      setFieldValue(
        name,
        fields.map((item) => ({
          ...item,
          value: '',
        }))
      );
    }
    onClose();
  };

  const handleGoBack = () => setPinVerified(false);

  return (
    <BasicModal
      testId="validation-fields-modal"
      isModalOpen={isModalOpen}
      title={isPinView ? messages.code : messages.questions}
      onClickPrimary={onClickPrimary}
      primaryTitle={messages.continue}
      secondaryTitle={pinVerified ? messages.back : undefined}
      isPrimaryDisabled={isDisabled}
      isPrimaryLoading={isPinView ? false : isLoading}
      onClickSecondary={pinVerified ? handleGoBack : () => null}
      centerTitle
      style={isPinView ? undefined : modalStyle}
      shouldCloseOnEsc
      onClose={pinVerified ? handleGoBack : handleClose}
      buttonType="submit"
      renderAsForm
    >
      {isPinView ? (
        <PinWrapper>
          {codeRefArray.map((itemRef, idx) => {
            return (
              <Code
                key={idx}
                maxLength={1}
                autoFocus={idx === 0}
                ref={itemRef}
                onChange={() => null}
                onKeyUp={(e) => onKeyPress(e, idx)}
                type="text"
                value={code[`code${idx + 1}`]}
              />
            );
          })}
        </PinWrapper>
      ) : (
        questions.map((field, idx) => (
          <QuestionWrapper key={idx}>
            <label htmlFor={field.id}>{field.description}</label>
            {field.type === TEXT ? (
              <Input
                id={field.id}
                onChange={(e) => onChange(e, field)}
                type="text"
                onBlur={(e) => onBlur(e, field)}
                value={field.value}
                autoComplete="new-password"
              />
            ) : (
              <SelectWrapper>
                <Select
                  options={field.values}
                  onChange={(option) => onSelect(option, field)}
                  placeholder={field.title}
                  value={selectedValue}
                />
              </SelectWrapper>
            )}
          </QuestionWrapper>
        ))
      )}
    </BasicModal>
  );
};

ValidationFields.propTypes = {
  isModalOpen: bool,
  name: string,
  onClose: func.isRequired,
  setFieldValue: func.isRequired,
  fields: array,
};

ValidationFields.defaultProps = {
  isModalOpen: true,
  fields: [],
};

export default ValidationFields;
