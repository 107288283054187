export const ACCOUNT_ROLE_OWNER = 'owner';
export const ACCOUNT_ROLE_ADMIN = 'admin';
export const ACCOUNT_ROLE_FRONTDESK = 'frontdesk login';
export const ACCOUNT_ROLE_USER = 'user';
export const ACCOUNT_ROLE_MANAGER = 'manager';

export const ACCOUNT_USER_ROLES = [
  ACCOUNT_ROLE_OWNER,
  ACCOUNT_ROLE_ADMIN,
  ACCOUNT_ROLE_FRONTDESK,
  ACCOUNT_ROLE_USER,
  ACCOUNT_ROLE_MANAGER,
];

export const ROLE_OPTIONS = [
  {
    value: ACCOUNT_USER_ROLES,
    label: 'All',
  },
  { value: [ACCOUNT_ROLE_OWNER], label: 'Owner' },
  { value: [ACCOUNT_ROLE_MANAGER], label: 'Managers' },
  { value: [ACCOUNT_ROLE_USER], label: 'Users' },
  { value: [ACCOUNT_ROLE_FRONTDESK], label: 'Frontdesk Login' },
];
// Role type determines what kind of manager the user is
// web = frontdesk login
// app = regular manager
export const ROLE_TYPE_WEB = 'web';
export const ROLE_TYPE_APP = 'app';

export const resolveRoleByType = (role, roleType) => {
  if (role === ACCOUNT_ROLE_MANAGER) {
    return roleType === ROLE_TYPE_WEB
      ? ACCOUNT_ROLE_FRONTDESK
      : ACCOUNT_ROLE_MANAGER;
  }

  return role;
};
