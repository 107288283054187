import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import Copyrights from 'components/Copyrights';
import logo from 'assets/riidelogo.png';
import { routes } from 'routes/routes';

import { getMessages } from './messages';
import styles from './CookiePolicy.module.scss';

const messages = getMessages('Riide for Business');

const CookiePolicy = () => (
  <div className={styles.CookiePolicyContainer}>
    <div className={styles.CookiePolicyLogoWrapper}>
      <Link to={routes.default}>
        <img
          src={logo}
          className={styles.CookiePolicyLogo}
          alt={messages.logoAlt}
        />
      </Link>
    </div>
    <div className={styles.CookiePolicyWrapper}>
      <div className={styles.CookiePolicyContent}>
        <h1 className={styles.CookiePolicyHeader}>{messages.header}</h1>
        <h2 className={styles.CookiePolicySubheader}>{messages.subHeader}</h2>
        <p className={styles.CookiePolicyText}>{messages.par01}</p>
        <p className={styles.CookiePolicyText}>{messages.par02}</p>
        <p className={styles.CookiePolicyText}>{messages.par03}</p>
        <p className={styles.CookiePolicyText}>{messages.par04}</p>
        <p className={cn(styles.CookiePolicyText, styles.CookiePolicyBoldText)}>
          {messages.listHeader}
        </p>
        <ul className={styles.CookiePolicyList}>
          <li>
            <span className={styles.CookiePolicyListHeader}>
              1.1.{messages.listEl01.header}
            </span>
            <span className={styles.CookiePolicyListText}>
              {messages.listEl01.content}
            </span>
          </li>
          <li>
            <span className={styles.CookiePolicyListHeader}>
              1.2.{messages.listEl02.header}
            </span>
            <span className={styles.CookiePolicyListText}>
              {messages.listEl02.content}
            </span>
          </li>
          <li>
            <span className={styles.CookiePolicyListHeader}>
              1.3.{messages.listEl03.header}
            </span>
            <span className={styles.CookiePolicyListText}>
              {messages.listEl03.content}
            </span>
          </li>
        </ul>
        <p className={styles.CookiePolicyText}>{messages.par05}</p>
      </div>
      <div className={styles.CookiePolicyCopyrightsWrapper}>
        <Copyrights />
      </div>
    </div>
  </div>
);

export default CookiePolicy;
