import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func, number, object, string, oneOfType } from 'prop-types';

import { Row } from 'components/Grid';
import {
  deletePaymentMethod,
  clearDeletePaymentMethod,
} from 'store/slices/accountsSlice';
import ModalWarningActionButtons from 'components/ModalWarningActionButtons';

import styles from './DeletePaymentMethod.module.scss';
import messages from './messages';

const DeletePaymentMethod = ({ account, handleModalToggle, paymentId }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => state.accounts.deletePaymentMethod
  );
  const handleDeleteMethodClick = useCallback(() => {
    dispatch(deletePaymentMethod({ account, paymentId })).then(() => {
      dispatch(clearDeletePaymentMethod());
      handleModalToggle();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, dispatch, paymentId]);

  return (
    <div className={styles.wrapper}>
      <Row center withMarginMedium>
        <p className={styles.DeletePaymentMethodHeader}>{messages.header}</p>
      </Row>
      <ModalWarningActionButtons
        onCancelButtonClick={handleModalToggle}
        onSubmitButtonClick={handleDeleteMethodClick}
        submitButtonText={messages.delete}
        isSubmitButtonDisabled={isLoading}
        isSubmitButtonLoading={isLoading}
      />
    </div>
  );
};

DeletePaymentMethod.propTypes = {
  handleModalToggle: func.isRequired,
  paymentId: oneOfType([string, number]),
  account: object,
};

export default DeletePaymentMethod;
