import React from 'react';
import { func, string, bool } from 'prop-types';

import {
  StyledWrapper,
  StyledPrimaryButton,
  StyledCancelButton,
} from './styles';
import { commonText } from 'locales/en';

const ModalWarningActionButtons = ({
  onCancelButtonClick,
  onSubmitButtonClick,
  cancelButtonText,
  submitButtonText,
  isSubmitButtonDisabled,
  isSubmitButtonLoading,
  isCancelButtonDisabled,
}) => {
  return (
    <StyledWrapper>
      <StyledCancelButton
        type="button"
        name={cancelButtonText}
        onClick={onCancelButtonClick}
        isRounded
        light
        disabled={isCancelButtonDisabled}
      />
      <StyledPrimaryButton
        type="button"
        onClick={onSubmitButtonClick}
        name={submitButtonText}
        isRounded
        alert
        disabled={isSubmitButtonDisabled}
        isLoading={isSubmitButtonLoading}
      />
    </StyledWrapper>
  );
};

ModalWarningActionButtons.propTypes = {
  onCancelButtonClick: func.isRequired,
  onSubmitButtonClick: func.isRequired,
  cancelButtonText: string,
  submitButtonText: string.isRequired,
  isSubmitButtonDisabled: bool,
  isCancelButtonDisabled: bool,
  isSubmitButtonLoading: bool,
};
ModalWarningActionButtons.defaultProps = {
  cancelButtonText: commonText.close,
  submitButtonText: commonText.ok,
};

export default ModalWarningActionButtons;
