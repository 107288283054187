import styled from 'styled-components';
import * as v from 'utils/variables';
import { StyledActionText } from 'views/Dashboard/styles';

export const StyledCSVButton = styled(StyledActionText)`
  position: absolute;
  top: 3em;
  right: ${v.modalActionButtonsSpacing};
`;
export const StyledInfoText = styled.p`
  font-size: ${v.fontSizeSm};
  color: ${v.mousegray};
`;
