export const codeStep = {
  header: 'Enter the 6-digit code sent to your mobile',
  verificationRequired: 'Verification code is required',
};
export const phoneNumberStep = {
  header: 'Sign in, or create a new account',
  subtitle:
    'You will be sent a 6-digit code to your mobile number to confirm your identity',
};
export const passwordStep = {
  createHeader: 'Create your password',
  enterHeader: 'Enter your password',
};
export const emailStep = {
  header: "What's Your Email",
};
export const nameStep = {
  header: "What's Your Name",
};
export const lastStep = {
  header: 'Nearly there',
  byClicking: (companyName) =>
    `By clicking Finish below, you agree with ${companyName || 'Riide'} `,
  termsOfUse: 'Terms of Use ',
  acknowledge: 'and acknowledge that you have read the ',
  privacyPolicy: 'Privacy Policy',
};
