import styled from 'styled-components';
import * as v from 'utils/variables';

export const StyledText = styled.p`
  font-size: ${v.fontSizeMd};
  color: ${v.gray};
  cursor: ${(props) => (props.$clickable ? 'pointer' : 'initial')};
  text-decoration: ${(props) => (props.$clickable ? 'underline' : 'none')};
  margin: ${v.spacingSm} 0;
  width: fit-content;
`;
