import styled, { css } from 'styled-components';
import * as v from 'utils/variables';

const DISABLED_OPACITY = 0.7;
const disabled = css`
  opacity: ${DISABLED_OPACITY};
  cursor: not-allowed;
`;
const primary = css`
  background-color: ${v.primary};
  color: ${v.white};
  border: 1px solid ${v.primary};
  border-radius: ${v.borderRadiusButton};
  text-align: center;
  &:not(:disabled):hover {
    background-color: ${v.primary};
    transition: ${v.transitionTimeBasic};
  }
  &:disabled {
    ${disabled};
  }
`;
const commonStyles = css`
  height: 50px;
  width: 200px;
  font-size: ${v.fontSizeMd};
  transition: ${v.transitionTimeBasic};
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  &:focus {
    outline: none;
  }
  svg {
    margin-right: 10px;
  }
  ${primary};
`;

export const StyledButton = styled.button`
  ${commonStyles};
  ${(props) => props.isLoading && disabled};
`;
