import styled, { css } from 'styled-components';
import * as v from 'utils/variables';

import {
  StyledColumn as _StyledColumn,
  StyledRow as _StyledRow,
} from 'components/GridNew/styles';

export const StyledContainer = styled(_StyledRow)`
  margin: ${(props) => (props.$hasError ? '0 0 1.5em 0' : '0 0 .5em 0')};
  height: auto;
  ${(props) =>
    props.$marginTop &&
    css`
      margin-top: ${props.$marginTop};
    `}
  ${(props) =>
    props.$isReverseRow &&
    css`
      flex-direction: row-reverse;
      margin-top: 1em;
      margin-bottom: 0;
    `}
    ${(props) =>
    props.$style &&
    css`
      ${props.$style};
    `}
`;
export const StyledColumn = styled(_StyledColumn)`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.$label ? 'center' : 'flex-start')};
  position: relative;
  ${(props) =>
    props.$offset &&
    css`
      margin-left: ${(props.$offset / 12) * 100}%;
    `};
  .input-tel-tel {
    height: ${v.DEFAULT_INPUT_HEIGHT};
  }
`;
export const StyledColumnWrapper = styled(_StyledColumn)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
`;
export const StyledChildrenWrapper = styled(_StyledColumn)`
  position: relative;
  .input-tel-tel {
    height: ${v.COLUMN_INPUT_HEIGHT};
  }
`;
export const StyledLabel = styled.label`
  font-size: ${v.fontSizeXs};
  color: ${v.mousegray};
  font-weight: bold;
  ${(props) =>
    props.$isColumn &&
    css`
      margin: 0.5em 0;
    `}
  a {
    color: ${v.primary};
    text-decoration: none;
  }
  ${(props) => props.$labelStyle};
`;
export const StyledCheckboxError = styled.div`
  position: relative;
  margin-left: ${(props) => ((props.$marginLeft || 0.8) / 12) * 100}%;
`;
