export const reactSelectTheme = (error) => (theme) => {
  const errorStyling = error
    ? {
        primary: 'red',
        neutral10: 'red',
        neutral30: 'red',
        neutral20: 'red',
        neutral60: 'red',
      }
    : {};

  return {
    ...theme,
    colors: {
      ...theme.colors,
      ...errorStyling,
    },
  };
};
