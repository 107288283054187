/* eslint-disable max-len */
export const errorMessages = {
  city: 'City format is invalid',
  name: 'Name is a required field',
};

export default {
  header: 'Profile Display Name',
  subtitle: 'This will show up as the name of the profile in the Riide app',
  info: 'Company Information',
  infoDisclaimer:
    'This information is required for monthly billing and invoices',
  name: 'Company name',
  address: 'Address',
  country: 'Country',
  city: 'City',
  state: 'State/Region',
  postalCode: 'Postal Code',
  taxId: 'Tax ID',
  phone: 'Phone',
  email: 'Email',
  cancel: 'Cancel',
  save: 'Save Changes',

  savedPlaces: {
    header: 'Saved Places',
    subtitle:
      'Add saved places to help you book faster to places where you frequently travel.',
    saveNew: 'Save new address',
    emptyStateHeader: 'No saved places yet',
    emptyStateText:
      'Save your most frequently used addresses to help you book your trips faster. Start by adding your work address.',
  },
};
