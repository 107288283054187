import React, { useState, useMemo } from 'react';
import { bool, func, object, string, number, oneOfType } from 'prop-types';

import Modal from 'components/Modal';
import DeletePaymentMethod from 'components/Modals/DeletePaymentMethod';
import Invoicing from 'components/Modals/Invoicing/Invoicing';
import { Column } from 'components/GridNew';
import { PAYMENT_METHODS } from 'constants/paymentMethods';
import { getPaymentMethodStatus, getInvoiceData, isPending } from './utils';
import Checkbox from 'components/Checkbox';
import {
  ActivityItemWrapper,
  StyledDashboardRow,
  EditIcon,
  StyledButtonsWrapper,
} from 'views/Dashboard/styles';

import { BILLING_DASHBOARD_COLUMNS } from 'utils/dashboardHeaders';
import cashIcon from 'assets/icons/cash.png';
import calendarAlt from 'assets/icons/calendar.png';
import Icon from 'components/ItemIcon';
import { charCoal } from 'utils/variables';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const BillingRow = ({
  item,
  setPaymentId,
  paymentId,
  account,
  companyEmail,
  companyName,
  isCreatingAccount,
}) => {
  const isDefaultMethod = item.default;
  const isInvoiceAccount =
    item.type === PAYMENT_METHODS.invoice && item.isNotRfb;
  const isInvoicePayment = item.type === PAYMENT_METHODS.account;
  const isInvoice = isInvoiceAccount || isInvoicePayment;

  const isCard = item.type === PAYMENT_METHODS.card;
  const isInvoiceAccepted =
    isInvoiceAccount ||
    (isInvoicePayment && item[PAYMENT_METHODS.account].is_accepted);
  const [isInvoicingOpen, setIsInvoicingOpen] = useState(false);
  const [isDeleteModalOpen, toggleDeleteModal] = useState(false);

  const handleDeleteModalToggle = (event) => {
    event && event.stopPropagation();
    setPaymentId(null);
    toggleDeleteModal(!isDeleteModalOpen);
  };

  const closeInvoicingModal = () => {
    setIsInvoicingOpen(false);
  };
  const openInvoicingModal = () => {
    setIsInvoicingOpen(true);
  };

  const invoiceData = getInvoiceData(
    isInvoiceAccount,
    isInvoicePayment,
    account,
    item
  );

  const paymentMethodStatus = getPaymentMethodStatus(
    isDefaultMethod,
    isInvoice,
    isInvoiceAccepted
  );

  const BILLING_DASHBOARD_DATA = useMemo(() => {
    let icon = cashIcon;
    if (isCard) {
      icon = item.card?.icon;
    } else if (isInvoice) {
      icon = calendarAlt;
    }
    let name = item.name;
    if (isCard) {
      name = item.card?.last4;
    }
    return [
      !isDefaultMethod && !item.isNotRfb ? (
        <Checkbox
          onChange={() => setPaymentId(item.id)}
          checked={item.id === paymentId}
        />
      ) : null,
      <Icon small={isInvoice} withCol={false} icon={icon} alt="billing_icon" />,
      <ActivityItemWrapper $color={charCoal}>{name}</ActivityItemWrapper>,
      isCard ? (
        <ActivityItemWrapper $color={charCoal}>
          {item.card?.exp_month}/{item.card?.exp_year}
        </ActivityItemWrapper>
      ) : null,
      isCard ? (
        <ActivityItemWrapper $color={charCoal}>
          {item.card?.card_holder_name}
        </ActivityItemWrapper>
      ) : null,
      <ActivityItemWrapper $color={charCoal}>
        {paymentMethodStatus}
      </ActivityItemWrapper>,
      (isInvoice || isCard) && !item.isNotRfb && (
        <StyledButtonsWrapper>
          <EditIcon
            alt="edit"
            icon={faTrashAlt}
            onClick={handleDeleteModalToggle}
          />
        </StyledButtonsWrapper>
      ),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCard,
    isDefaultMethod,
    isInvoice,
    item,
    paymentId,
    paymentMethodStatus,
  ]);

  const BILLING_DASHBOARD_ITEMS = useMemo(
    () =>
      BILLING_DASHBOARD_COLUMNS.map((col, idx) => ({
        index: idx + 1,
        col,
        data: BILLING_DASHBOARD_DATA[idx],
      })),
    [BILLING_DASHBOARD_DATA]
  );

  return (
    <>
      <StyledDashboardRow
        data-testid="billing-row"
        onClick={isInvoice ? openInvoicingModal : undefined}
      >
        {BILLING_DASHBOARD_ITEMS.map((item) => (
          <Column
            style={item.index === 2 ? { textAlign: 'center' } : {}}
            key={item.index}
            col={item.col}
          >
            {item.data}
          </Column>
        ))}
      </StyledDashboardRow>
      {isDeleteModalOpen && (
        <Modal
          isModalOpen={isDeleteModalOpen}
          handleModalToggle={handleDeleteModalToggle}
          modalStyle="warningModal"
        >
          <DeletePaymentMethod
            handleModalToggle={handleDeleteModalToggle}
            paymentId={item.id}
            account={account}
          />
        </Modal>
      )}
      {isInvoicingOpen && (
        <Modal
          isModalOpen={isInvoicingOpen}
          handleModalToggle={closeInvoicingModal}
          modalStyle="isAccount"
          shouldCloseOnOverlayClick
        >
          <Invoicing
            onSuccess={closeInvoicingModal}
            readOnly={isInvoice}
            invoiceData={invoiceData}
            companyEmail={companyEmail}
            companyName={companyName}
            isPending={isPending(paymentMethodStatus)}
            handleModalClose={closeInvoicingModal}
            isCreatingAccount={isCreatingAccount}
          />
        </Modal>
      )}
    </>
  );
};

BillingRow.propTypes = {
  item: object,
  paymentId: oneOfType([string, number]),
  setPaymentId: func,
  companyEmail: string,
  companyName: string,
  isCreatingAccount: bool,
};

BillingRow.defaultProps = {
  item: {},
  paymentId: '',
  setPaymentId: () => {},
  companyEmail: '',
  companyName: '',
  isCreatingAccount: null,
};

export default BillingRow;
