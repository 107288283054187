import { customSelectStyles } from 'constants/customSelectStyles';

const selectStyles = {
  container: (styles) => ({
    ...styles,
    pointerEvents: undefined,
  }),
  control: (styles, { isDisabled }) => {
    return {
      ...styles,
      ...customSelectStyles.control(styles, { isDisabled }),
      minHeight: '50px',
      boxShadow: '0 1px 4px 1px rgba(0, 0, 0, 0.1)',
    };
  },
};

export default selectStyles;
