import React from 'react';
import { node, bool, func, string } from 'prop-types';
import cn from 'classnames';

import styles from './UnstyledButton.module.scss';

const UnstyledButton = ({ children, isDisabled, onClick, className }) => (
  <button
    data-testid="unstyled-button"
    className={cn(styles.UnstyledButton, className)}
    type="button"
    disabled={isDisabled}
    onClick={onClick}
  >
    {children}
  </button>
);

UnstyledButton.propTypes = {
  children: node,
  isDisabled: bool,
  onClick: func,
  className: string,
};

UnstyledButton.defaultProps = {
  children: null,
  isDisabled: false,
  onClick: () => null,
  className: '',
};

export default UnstyledButton;
