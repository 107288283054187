import styled, { css } from 'styled-components';

const Root = styled.div`
  display: inline-block;
  height: 50%;
  margin-top: auto;
  margin-bottom: auto;

  ${({ $inline }) =>
    !$inline &&
    css`
      display: block;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
    `}

  svg {
    opacity: 0.2;
  }
`;

export default Root;
