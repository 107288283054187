import {
  string,
  number,
  shape,
  bool,
  oneOfType,
  array,
  object,
  arrayOf,
  oneOf,
} from 'prop-types';

export const metaType = {
  pagination: shape({
    count: number,
    current_page: number,
    links: object,
    per_page: number,
    total: number,
    total_pages: number,
  }),
};

export const accountType = {
  id: number,
  name: string,
};

const accountUserPropTypes = {
  id: number.isRequired,
  last_name: string.isRequired,
  first_name: string.isRequired,
  phone_country: string,
  phone_number: string,
};

export const accountMembersPropTypes = {
  state: string.isRequired,
  id: number.isRequired,
  invitedAt: string,
  type: string.isRequired,
  user: shape(accountUserPropTypes).isRequired,
};

export const cardPropTypes = {
  card_holder_name: string,
  exp_year: number.isRequired,
  last4: string.isRequired,
  icon: string.isRequired,
};

export const billingCashPropTypes = {
  name: string.isRequired,
};

export const billingCardPropTypes = {
  card: shape(cardPropTypes).isRequired,
};

export const availablePaymentMethodsPropTypes = shape({
  card: bool,
  account: bool,
  'pay-in-car': bool,
  isEmpty: bool,
});

export const paymentMethodsMetaPropTypes = {
  hasFetchedData: bool.isRequired,
};

export const accountCardsMetaPropTypes = {
  hasFetchedData: bool.isRequired,
};

export const selectedPassengerForBookingPropTypes = {
  firstName: string,
  lastName: string,
  id: number,
  type: string,
  phone: string.isRequired,
};

export const addressSearchPropTypes = {
  id: oneOfType([string, number]),
  address: string,
  lat: number,
  lng: number,
  name: string,
  provider: string,
};
export const addressFieldsType = shape({
  address: string,
  lat: oneOfType([number, string]),
  lng: oneOfType([number, string]),
});
export const addressFieldsDefaultType = {
  address: '',
  lat: 0,
  lng: 0,
};
export const apiResponsePropTypes = {
  data: array,
  meta: shape(metaType),
};

export const accountRoleFilterType = shape({
  value: arrayOf(string),
  label: string,
});
export const locationType = shape({
  lat: number,
  lng: number,
});
export const userPaymentMethodType = shape({
  type: string.isRequired,
  id: number.isRequired,
  item: shape({}),
  default: bool.isRequired,
});
export const modalStatusType = oneOf(['success', 'error', 'info']);
