import React from 'react';
import cx from 'classnames';
import { oneOf } from 'prop-types';

import styles from './LoaderIcon.module.scss';

const SPINNER_ICON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

const LoaderIcon = ({ variant }) => {
  const loaderIconClassNames = cx(styles.LoaderIcon, {
    [styles.secondary]: variant === SPINNER_ICON_VARIANTS.SECONDARY,
  });
  return (
    <span
      data-testid="loader-icon-component"
      className={styles.LoaderIconWrapper}
    >
      <span className={loaderIconClassNames} />
    </span>
  );
};

LoaderIcon.propTypes = {
  variant: oneOf([
    SPINNER_ICON_VARIANTS.PRIMARY,
    SPINNER_ICON_VARIANTS.SECONDARY,
  ]),
};

LoaderIcon.defaultProps = {
  variant: SPINNER_ICON_VARIANTS.PRIMARY,
};

export default LoaderIcon;
