import styled from 'styled-components';
import Button from 'components/Button';
import {
  spacingXmd,
  spacingMd,
  mousegray,
  spacingLg,
  fontWeightBold,
} from 'utils/variables';

export const StyledWrapper = styled.div`
  padding-top: ${spacingLg};
  display: flex;
  justify-content: center;
`;
export const StyledCancelButton = styled(Button)`
  min-width: 150px;
  padding-left: ${spacingXmd};
  padding-right: ${spacingXmd};
  margin-right: calc(${spacingMd} * 1.25);
  font-weight: ${fontWeightBold};
  color: ${mousegray};
`;

export const StyledPrimaryButton = styled(Button)`
  min-width: 150px;
  padding-left: ${spacingXmd};
  padding-right: ${spacingXmd};
`;
