import React, { useCallback } from 'react';
import Modal from 'components/Modal';
import { func, bool, string } from 'prop-types';

import { Row } from 'components/Grid';
import ModalWarningActionButtons from 'components/ModalWarningActionButtons';

import styles from './DeletePaymentMethod/DeletePaymentMethod.module.scss';
import messages from './DeletePaymentMethod/messages';

const DeleteModal = ({ isModalOpen, onDelete, onCancel, isLoading, text }) => {
  const handleDeleteMethodClick = useCallback(() => {
    onDelete().then(() => {
      onCancel();
    });
  }, [onCancel, onDelete]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleModalToggle={onCancel}
      modalStyle="warningModal"
    >
      <div className={styles.wrapper}>
        <Row center withMarginMedium>
          <p className={styles.DeletePaymentMethodHeader}>{text}</p>
        </Row>
        <ModalWarningActionButtons
          onCancelButtonClick={onCancel}
          onSubmitButtonClick={handleDeleteMethodClick}
          submitButtonText={messages.delete}
          isSubmitButtonDisabled={isLoading}
          isSubmitButtonLoading={isLoading}
        />
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  onCancel: func.isRequired,
  onDelete: func.isRequired,
  isModalOpen: bool,
  isLoading: bool,
  text: string.isRequired,
};

export default DeleteModal;
