import styled, { css } from 'styled-components';
import {
  red,
  spacingXLg,
  spacingXs,
  fontSizeXs,
  slidedown,
  spacingSm,
} from 'utils/variables';

export const StyledError = styled.p`
  color: ${red};
  ${(props) =>
    props.$absolute
      ? css`
          position: absolute;
          bottom: -${spacingXLg};
        `
      : css`
          margin-top: ${(props) => props.$marginTop ?? spacingSm};
          margin-left: ${spacingXs};
        `}
  font-size: ${fontSizeXs};

  ${(props) =>
    props.$errorCanFit &&
    css`
      position: absolute;
      bottom: -20px;
    `}

  ${(props) =>
    props.$width &&
    css`
      width: ${(props) => (props.$width / 12) * 100}%;
      float: right;
      margin-top: -5px;
      margin-bottom: 5px;
    `}

    ${(props) =>
    props.$style &&
    css`
      ${props.$style}
    `};
  ${slidedown};
`;
