import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  generatePath,
} from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import SignUp from 'views/SignUp/SignUp';
import Login from 'views/Login';
import CookiePolicy from 'views/CookiePolicy';

import PrivateRoute from 'components/PrivateRoute';
import Loader from 'components/Loader';
import NotFoundPage from 'components/NotFoundPage';
import ErrorModal from 'components/Modals/ErrorModal';
import SuccessModal from 'components/Modals/SuccessModal';
import InfoModal from 'components/Modals/InfoModal';
import UseMobileAppTemplate from 'components/UseMobileAppTemplate';
import CookieConsent from 'components/CookieConsent';

import { routes } from 'routes/routes';
import { getSubdomain } from 'utils/subdomain';
import { getMe } from 'store/slices/userSlice';
import { getDefaultCompany } from 'store/slices/companySlice';
import GlobalStyle from './globalStyles';
import { useGetDashboardItems } from './hooks';

const App = () => {
  const subdomain = getSubdomain();
  const { isLoading: userIsLoading, data: userData } = useSelector(
    (state) => state.user
  );
  const { token, name: userName } = userData;
  const appStatus = useSelector((state) => state.appStatus);

  const {
    appCompanyCode,
    data: companyData,
    error: companyError,
  } = useSelector((state) => state.company);
  const {
    name: appCompanyName,
    country_code: companyCountryCode,
    configs = {},
  } = companyData || {};
  const appLink = useMemo(() => configs.general?.onelink_url, [configs]);
  const termsUrl = useMemo(() => configs.general?.terms_url, [configs]);
  const privacyUrl = useMemo(() => configs.general?.privacy_url, [configs]);

  const { logo_url: headerLogo, logo_footer_url: footerLogo } =
    useMemo(() => configs.rfb, [configs]) || {};
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDefaultCompany());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initMe = useCallback(() => {
    if (token && appCompanyCode) {
      dispatch(getMe());
    }
  }, [appCompanyCode, dispatch, token]);

  useEffect(() => {
    initMe();
  }, [initMe]);

  const homePaths = useMemo(
    () => [
      routes.default,
      routes.activity,
      routes.team,
      routes.billing,
      routes.reports,
      routes.settings,
    ],
    []
  );

  const dashboardItems = useGetDashboardItems();
  const redirectPath = useMemo(() => {
    const dashboardItem = dashboardItems && dashboardItems[0];
    const routeName = dashboardItem && dashboardItem.toLowerCase();

    return routeName && routes[routeName]
      ? generatePath(routes[routeName])
      : null;
  }, [dashboardItems]);

  if (isMobile) {
    return <UseMobileAppTemplate subdomain={subdomain} />;
  }
  if (companyError) {
    return <NotFoundPage hasCompanyError={!!companyError} />;
  }
  if (!appCompanyCode) {
    return <div />;
  }

  return (
    <>
      <GlobalStyle $isDashboard={!!token} />
      {userIsLoading && <Loader />}
      <Router>
        {window.location.pathname === '/' && redirectPath && (
          <Redirect to={generatePath(redirectPath)} />
        )}
        <Switch>
          <PrivateRoute
            token={token}
            dashboardProps={{
              appCompanyName,
              companyCountryCode,
              userName,
              headerLogo,
            }}
            landingPageProps={{
              companyName: appCompanyName,
              headerLogo,
              footerLogo,
            }}
            exact
            path={homePaths}
            subdomain={subdomain}
            appCompanyCode={appCompanyCode}
          />
          <Route
            exact
            path={routes.signup}
            render={(routeProps) => (
              <SignUp
                {...routeProps}
                companyCountryCode={companyCountryCode}
                hasToken={!!token}
                subdomain={subdomain}
                appCompanyCode={appCompanyCode}
                appCompanyName={appCompanyName}
                termsUrl={termsUrl}
                privacyUrl={privacyUrl}
              />
            )}
          />
          <Route
            exact
            path={routes.login}
            render={(routeProps) => (
              <Login
                {...routeProps}
                hasToken={!!token}
                subdomain={subdomain}
                companyCountryCode={companyCountryCode}
                appCompanyCode={appCompanyCode}
              />
            )}
          />
          <Route exact path={routes.cookiePolicy} component={CookiePolicy} />
          <Route
            path="*"
            render={(routeProps) => {
              return <NotFoundPage {...routeProps} />;
            }}
          />
        </Switch>
        <CookieConsent />
        {appStatus.type === 'error' && (
          <ErrorModal
            hasError
            title={appStatus.title}
            subtitle={appStatus.subtitle}
            primaryTitle={appStatus.cta}
            secondaryTitle={appStatus.cta2}
            meta={appStatus.meta}
            appLink={appLink}
          />
        )}
      </Router>
      {appStatus.type === 'success' && (
        <SuccessModal
          isVisible
          title={appStatus.title}
          subtitle={appStatus.subtitle}
        />
      )}
      {appStatus.type === 'info' && (
        <InfoModal
          isVisible
          title={appStatus.title}
          subtitle={appStatus.subtitle}
        />
      )}
    </>
  );
};

export default App;
