import React from 'react';
import { func, string } from 'prop-types';

import { BasicModalContent } from 'components/Modals/BasicModal';
import { messages } from './messages';

const AccountReady = ({
  newAccountName,
  currentAccountName,
  onClickTake,
  onClickStay,
}) => (
  <BasicModalContent
    title={messages.title}
    subtitle={messages.subtitle(newAccountName)}
    onClickPrimary={onClickTake}
    onClickSecondary={onClickStay}
    primaryTitle={messages.take(newAccountName)}
    secondaryTitle={messages.stay(currentAccountName)}
    type="success"
  />
);

AccountReady.propTypes = {
  newAccountName: string,
  currentAccountName: string,
  onClickTake: func,
  onClickStay: func,
};

AccountReady.defaultProps = {
  newAccountName: '',
  currentAccountName: '',
  onClickTake: () => {},
  onClickStay: () => {},
};

export default AccountReady;
