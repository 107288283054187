import styled, { css } from 'styled-components';
import { fontWeightBold, darkgray, palegray } from 'utils/variables';
import { marginForOverflow } from 'views/Dashboard/styles';

export const StyledItemName = styled.p`
  color: ${(props) => (props.$isPale ? palegray : darkgray)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${marginForOverflow};

  ${(props) =>
    props.$hasBoldText &&
    css`
      font-weight: ${fontWeightBold};
    `};
`;
export const StyledItemGroup = styled(StyledItemName)`
  display: flex;
`;
