import React from 'react';
import ReactPaginate from 'react-paginate';
import { string, number, func } from 'prop-types';

import 'scss/overrides/paginate.scss';

import { DEFAULT_PAGE_RANGE_DISPLAYED } from './const';
import { StyledDiv } from './styles';

const Pagination = ({
  activeClassName,
  previousLabel,
  nextLabel,
  pageCount,
  containerClassName,
  onPageChange,
  pageRangeDisplayed,
  currentPage,
}) => {
  return (
    <StyledDiv>
      <ReactPaginate
        data-testid="list-pagination"
        activeClassName={activeClassName}
        previousLabel={previousLabel}
        nextLabel={nextLabel}
        pageCount={pageCount}
        containerClassName={containerClassName}
        onPageChange={onPageChange}
        forcePage={currentPage - 1}
        pageRangeDisplayed={pageRangeDisplayed}
      />
    </StyledDiv>
  );
};

Pagination.propTypes = {
  activeClassName: string,
  previousLabel: string,
  nextLabel: string,
  pageCount: number,
  containerClassName: string,
  onPageChange: func.isRequired,
  pageRangeDisplayed: number,
  currentPage: number,
};

Pagination.defaultProps = {
  activeClassName: 'active',
  containerClassName: 'container',
  previousLabel: '<',
  nextLabel: '>',
  pageRangeDisplayed: DEFAULT_PAGE_RANGE_DISPLAYED,
  currentPage: 1,
  pageCount: 1,
};

export default Pagination;
