import { PAYMENT_METHODS } from 'constants/paymentMethods';

export const paymentMethodStatuses = {
  default: 'Default',
  defaultPending: 'Default (pending)',
  pending: 'Pending',
};

export const isPending = (status) =>
  status === paymentMethodStatuses.defaultPending ||
  status === paymentMethodStatuses.pending;

export const getPaymentMethodStatus = (
  isDefault,
  isInvoice,
  isInvoiceAccepted
) => {
  if (isDefault && isInvoice && !isInvoiceAccepted) {
    return paymentMethodStatuses.defaultPending;
  } else if (
    (isDefault && isInvoice && isInvoiceAccepted) ||
    (isDefault && !isInvoice)
  ) {
    return paymentMethodStatuses.default;
  } else if (!isDefault && isInvoice && !isInvoiceAccepted) {
    return paymentMethodStatuses.pending;
  }

  return '';
};

export const getInvoiceData = (
  isInvoiceAccount,
  isInvoicePayment,
  currentAccount,
  item
) => {
  if (isInvoiceAccount) {
    return currentAccount;
  } else if (isInvoicePayment) {
    return item[PAYMENT_METHODS.account];
  }
};
