import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { fontSizeSm, charCoal, spacingSm, mousegray } from 'utils/variables';

export const Wrapper = styled.p`
  font-size: ${fontSizeSm};
  color: ${charCoal};
`;
export const Text = styled.span`
  margin-right: ${spacingSm};
  color: ${mousegray};
`;
export const Link = styled(RouterLink)`
  color: ${charCoal};
  text-decoration: none;
`;
