import React, { useState, useEffect } from 'react';

import PhoneNumberInput from 'components/PhoneNumberInput';
import Input from 'components/Input';
import { Row } from 'components/Grid';
import { usePrevious } from 'hooks';
import { preferredCountries } from './const';

import {
  StyledHeaderWrapper,
  StyledHeader,
  StyledSubtitle,
  StyledCol,
} from 'views/Login/styles';
import { firstStep as messages } from './messages';

const EMAIL = 'email';
const PHONE = 'phone';

const EmailOrPhoneStep = ({
  values,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  handleBlur,
  errors,
  touched,
  companyCountryCode,
  isDisabled,
}) => {
  const [value, setValue] = useState('');
  const previousValue = usePrevious(value);
  const [type, setType] = useState(EMAIL);

  useEffect(() => {
    if (value.length >= 2 && value !== previousValue) {
      if (isNaN(value)) {
        setType(EMAIL);
        setFieldValue('is_email', true);
        setFieldValue('email', value);
      } else {
        setType(PHONE);
        setFieldValue('is_email', false);
        setFieldValue('phone_number', value);
      }
    } else if (value < 2) {
      if (type === PHONE) {
        setType(EMAIL);
        setFieldValue('is_email', true);
        setFieldValue('email', value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousValue, value, type]);

  const onInputChange = (e) => {
    setValue(e.target.value);
    setFieldValue('email', e.target.value);
  };

  const onPhoneChange = (number) => {
    setValue(number);
  };

  const onEmailBlur = (e) => {
    if (value) {
      setValue(value.replace(/\s/g, ''));
      setFieldValue('email', values.email.replace(/\s/g, ''));
      if (value.length > 2) {
        handleBlur(e);
      }
    }
  };

  return (
    <>
      <StyledHeaderWrapper>
        <StyledHeader>{messages.header}</StyledHeader>
        <StyledSubtitle>{messages.subtitle}</StyledSubtitle>
      </StyledHeaderWrapper>
      <Row>
        <StyledCol col={12}>
          {type === EMAIL ? (
            <Input
              bgWhite
              isLarge
              errorBgPrimary
              value={values.email}
              onChange={onInputChange}
              onBlur={onEmailBlur}
              placeholder={messages.placeholder}
              type="email"
              name="email"
              errors={errors}
              touched={touched}
              autoFocus
            />
          ) : (
            <PhoneNumberInput
              zIndex={10}
              autoFocus
              containerClass="flex"
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              error={errors.phone}
              hasError={!!(touched.phone && errors.phone)}
              defaultCountry={values.iso2 || companyCountryCode}
              preferredCountries={preferredCountries}
              placeholder="Phone number"
              isLarge
              isDisabled={isDisabled}
              onChange={onPhoneChange}
              defaultValue={value}
            />
          )}
        </StyledCol>
      </Row>
    </>
  );
};

export default EmailOrPhoneStep;
