import React, { useMemo, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { shape } from 'prop-types';
import { Column } from 'components/GridNew';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import _isEqual from 'lodash/isEqual';

import DeleteModal from 'components/Modals/DeleteModal';

import { GROUP_COLUMNS } from 'utils/dashboardHeaders';
import {
  StyledDashboardRow,
  EditIcon,
  StyledButtonsWrapper,
} from 'views/Dashboard/styles';
import { StyledItemName, StyledItemGroup } from 'views/Team/styles';
import { setGroupState, deleteGroup } from 'store/slices/groupsSlice';

const GroupRow = ({ group, canDelete, isDefault }) => {
  const dispatch = useDispatch();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { isLoading } = useSelector((state) => state.groups);

  const GROUP_DATA = useMemo(() => {
    return [
      <>
        <StyledItemGroup $hasBoldText>
          {group.name}
          {isDefault ? (
            <StyledItemName $isPale>(Default)</StyledItemName>
          ) : null}
        </StyledItemGroup>
      </>,
      <StyledItemName>{group.users_count} Users</StyledItemName>,
      <StyledButtonsWrapper>
        <EditIcon
          alt="edit"
          icon={faEdit}
          onClick={() =>
            dispatch(setGroupState({ isAddGroupView: true, current: group }))
          }
        />
        {group.name !== 'General' && (
          <EditIcon
            alt="trash"
            icon={faTrashAlt}
            onClick={() => setIsDeleteModalVisible(true)}
          />
        )}
      </StyledButtonsWrapper>,
    ];
  }, [group, isDefault, dispatch]);

  const TEAM_ITEMS = useMemo(
    () =>
      GROUP_COLUMNS.map((col, idx) => ({
        index: idx + 1,
        col,
        data: GROUP_DATA[idx],
      })),
    [GROUP_DATA]
  );

  return (
    <>
      <StyledDashboardRow data-testid="group-row">
        {TEAM_ITEMS.map((item) => (
          <Column key={item.index} col={item.col}>
            {item.data}
          </Column>
        ))}
      </StyledDashboardRow>
      {isDeleteModalVisible && (
        <DeleteModal
          onDelete={() => dispatch(deleteGroup(group.id))}
          isModalOpen={isDeleteModalVisible}
          onCancel={() => setIsDeleteModalVisible(false)}
          isLoading={isLoading}
          text="Are you sure you want to delete this group?"
        />
      )}
    </>
  );
};

GroupRow.propTypes = {
  group: shape({}).isRequired,
};

const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.group, nextProps.group);

export default memo(GroupRow, areEqual);
