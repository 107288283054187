import styled from 'styled-components';
import * as v from 'utils/variables';
import { StyledInput as _StyledInput } from 'components/InputNew/styles';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: ${(props) => props.$minWidth || '155px'};
  height: 38px;
  margin-right: ${v.spacingLg};
  position: relative;
  border: 1px solid ${v.dark};
  border-radius: ${v.borderRadiusBasic};
  .fa-search {
    color: ${v.mousegray};
    position: absolute;
    right: 10px;
  }
`;
export const StyledInput = styled(_StyledInput)`
  width: 80%;
  border: 0;
  text-overflow: ellipsis;
`;
