export const routes = {
  default: '/',
  signup: '/signup',
  login: '/login',
  cookiePolicy: '/cookie-policy/',
  activity: '/activity/:tab?',
  team: '/team/:tab?',
  billing: '/billing/:tab?',
  reports: '/reports',
  settings: '/settings',
};
