import React, { memo } from 'react';
import { func, bool, string } from 'prop-types';

import Button from 'components/Button';
import SetAccountItem from './SetAccountItem';
import { Row } from 'components/Grid';
import { messages } from './messages';
import { availablePaymentMethodsPropTypes } from 'constants/propTypes';
import { PAYMENT_METHODS } from 'constants/paymentMethods';

import styles from './SetAccount.module.scss';
import { StyledCancelButton } from './styles';
import { commonText } from 'locales/en';

const SetAccountPayment = ({
  clearPaymentType,
  onClickNext,
  handleModalToggle,
  fromNavbar,
  setPaymentType,
  paymentType,
  availablePaymentMethods,
}) => {
  return (
    <div className={styles.SetAccountSpacer}>
      <div>
        <p className={styles.SetAccountPayments}>{messages.header}</p>
        {(fromNavbar || !availablePaymentMethods.card) && (
          <SetAccountItem
            paymentType={paymentType}
            paymentMethod={PAYMENT_METHODS.card}
            setPaymentType={setPaymentType}
            label="addCard"
            message={messages.addCard}
          />
        )}
        {(fromNavbar || !availablePaymentMethods.account) && (
          <SetAccountItem
            paymentType={paymentType}
            paymentMethod={PAYMENT_METHODS.invoice}
            setPaymentType={setPaymentType}
            label="invoice"
            message={messages.addInvoice}
          />
        )}

        {fromNavbar && (
          <SetAccountItem
            paymentType={paymentType}
            paymentMethod={PAYMENT_METHODS['pay-in-car']}
            setPaymentType={setPaymentType}
            label="payInCar"
            message={messages.payInCar}
          />
        )}
      </div>
      <div className={styles.SetAccountPaymentButtonsWrapper}>
        <Row between>
          {!fromNavbar && (
            <StyledCancelButton
              onClick={fromNavbar ? clearPaymentType : handleModalToggle}
              name={commonText.close}
              noPaddingLeft
            />
          )}
          <Button
            isRoundedMedium
            wide
            onClick={onClickNext}
            name={messages.next}
            primary
            flexHalf
            disabled={!paymentType}
          />
        </Row>
      </div>
    </div>
  );
};

SetAccountPayment.propTypes = {
  clearPaymentType: func.isRequired,
  handleModalToggle: func.isRequired,
  onClickNext: func.isRequired,
  fromNavbar: bool.isRequired,
  setPaymentType: func.isRequired,
  paymentType: string,
  availablePaymentMethods: availablePaymentMethodsPropTypes,
};
SetAccountPayment.defaultProps = {
  paymentType: '',
  availablePaymentMethods: {},
};

export default memo(SetAccountPayment);
