import { fontFamilyPrimary } from 'utils/variables';

export const overlayStyles = {
  display: 'flex',
  zIndex: 1,
};
const sharedModalStyles = {
  position: 'relative',
  top: undefined,
  right: undefined,
  bottom: undefined,
  left: undefined,
  maxHeight: '95vh',
  margin: 'auto',
  boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.1)',
  fontFamily: fontFamilyPrimary,
  border: 'none',
  borderRadius: '10px',
  padding: '2.5em',
};

export const customModalStyles = {
  overlay: overlayStyles,
  content: {
    ...sharedModalStyles,
    width: '35%',
    padding: '2em 2em 3em 2em',
  },
};

export const setAccountModalStyles = {
  overlay: overlayStyles,
  content: {
    ...sharedModalStyles,
    width: '525px',
    padding: '2.5em',
    boxSizing: 'border-box',
  },
};

export const warningModalStyles = {
  overlay: overlayStyles,
  content: {
    ...sharedModalStyles,
    width: '75%',
    maxWidth: '525px',
    boxSizing: 'border-box',
  },
};

export const addOrEditUserModalStyles = {
  overlay: overlayStyles,
  content: {
    ...sharedModalStyles,
    width: '75%',
    maxWidth: '525px',
    boxSizing: 'border-box',
  },
};

export const customBigModalStyles = {
  overlay: overlayStyles,
  content: {
    ...sharedModalStyles,
    width: '75%',
    padding: '0 0 3em 2em',
  },
};

export const bookingFormModalStyles = {
  overlay: overlayStyles,
  content: {
    ...sharedModalStyles,
    width: '90%',
    maxWidth: '1200px',
    height: '745px',
    maxHeight: '90%',
    padding: '0 0 0 2.5em',
  },
};

export const enableCookiesModalStyles = {
  overlay: overlayStyles,
  content: {
    ...sharedModalStyles,
    width: '75%',
    maxWidth: '525px',
    zIndex: 2,
  },
};
export const basicModalStyles = {
  overlay: {
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: 9999,
  },
  content: {
    ...sharedModalStyles,
    width: '75%',
    maxWidth: '575px',
    boxSizing: 'border-box',
  },
};
