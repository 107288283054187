import { combineReducers } from '@reduxjs/toolkit';

import initialState from 'store/initialState';
import bookingsReducer from 'store/slices/bookingsSlice';
import accountsReducer from 'store/slices/accountsSlice';
import userReducer from 'store/slices/userSlice';
import companyReducer from 'store/slices/companySlice';
import signUpStepsReducer from 'store/slices/signUpStepsSlice';
import loginStepsReducer from 'store/slices/loginStepsSlice';
import appStatusReducer from 'store/slices/appStatusSlice';
import tabsReducer from 'store/slices/tabsSlice';
import groupsReducer from 'store/slices/groupsSlice';

const combinedReducer = combineReducers({
  bookings: bookingsReducer,
  accounts: accountsReducer,
  user: userReducer,
  company: companyReducer,
  signUpSteps: signUpStepsReducer,
  loginSteps: loginStepsReducer,
  appStatus: appStatusReducer,
  tabs: tabsReducer,
  groups: groupsReducer,
});

const rootReducer = (state, action) => {
  if (
    action.type === 'user/logout/fulfilled' ||
    (action.type.endsWith('rejected') && action.payload?.code === 442)
  ) {
    state = { ...initialState, company: state.company };
  }
  return combinedReducer(state, action);
};
export default rootReducer;
