import React, { useEffect, useState, memo } from 'react';
import { shape } from 'prop-types';
import _isEqual from 'lodash/isEqual';
import { useSelector, useDispatch } from 'react-redux';

import { Column } from 'components/GridNew';
import Pagination from 'components/Pagination';
import {
  DashboardHeaderWrapper,
  DashboardHeaderItem,
  DashboardScrollView,
} from 'views/Dashboard/styles';
import { TEAM_HEADERS } from 'utils/dashboardHeaders';
import {
  pendingMembersSelector,
  pendingMembersMetaSelector,
  pendingMembersDataSelector,
  getPendingMembers,
  roleFilterSelector,
} from 'store/slices/accountsSlice';
import scrollTop from 'utils/scrollTop';
import { accountType } from 'constants/propTypes';

import UserFilters from '../UserFilters';
import UsersList from '../UsersList';

import messages from './messages';

const PendingUsers = ({ account }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(pendingMembersSelector);
  const members = useSelector((state) =>
    pendingMembersDataSelector(state, account.id)
  );
  const { totalPages, currentPage } = useSelector((state) =>
    pendingMembersMetaSelector(state, account.id)
  );
  const accountRoleFilter = useSelector(roleFilterSelector);

  const [isRoleDisabled, setIsRoleDisabled] = useState(false);

  useEffect(() => {
    dispatch(getPendingMembers({ accountId: account.id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!members.length) {
      setIsRoleDisabled(true);
    } else {
      setIsRoleDisabled(false);
    }
  }, [members]);

  const handlePageChange = (data) => {
    dispatch(
      getPendingMembers({ accountId: account.id, page: data.selected + 1 })
    );
    scrollTop();
  };

  return (
    <>
      <DashboardHeaderWrapper data-testid="pending-users-list" $noBottomPadding>
        <UserFilters
          accountRoleFilter={accountRoleFilter}
          isRoleDisabled={isRoleDisabled}
          account={account}
          displaySearchInput={false}
        />
      </DashboardHeaderWrapper>
      <DashboardHeaderWrapper $noBottomPadding $borderBottom>
        {TEAM_HEADERS.map((header) => (
          <Column key={header.index} col={header.col}>
            <DashboardHeaderItem>{header.data}</DashboardHeaderItem>
          </Column>
        ))}
      </DashboardHeaderWrapper>
      <DashboardScrollView $isTeam $disableScroll={isLoading}>
        <UsersList
          usersList={members}
          emptyStateHeader={messages.emptyStateHeader}
          emptyStateText=" "
          accountRoleFilter={accountRoleFilter}
          account={account}
          isLoading={isLoading}
        />
      </DashboardScrollView>
      {currentPage && (
        <Pagination
          pageCount={totalPages || 1}
          onPageChange={handlePageChange}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

PendingUsers.propTypes = {
  account: shape(accountType).isRequired,
};

const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.account, nextProps.account);

export default memo(PendingUsers, areEqual);
