import React, { memo } from 'react';
import { bool } from 'prop-types';

import Button from 'components/Button';
import notFoundPageImg from 'assets/not-found-page.png';

import { APP_BASE_URL } from 'utils/subdomain';
import { routes } from 'routes/routes';
import styles from './NotFoundPage.module.scss';
import messages from './messages';

const NotFoundPage = ({ hasCompanyError }) => (
  <div
    data-testid="not-found-page-component"
    className={styles.NotFoundPageContainer}
  >
    <div className={styles.NotFoundPageContentWrapper}>
      <div className={styles.NotFoundPageColumnText}>
        <p className={styles.NotFoundPageHeader}>{messages.header}</p>
        <p className={styles.NotFoundPageMainText}>{messages.mainText}</p>
        <Button
          name={messages.button}
          renderAs="a"
          linkProperties={{
            href: hasCompanyError
              ? `${APP_BASE_URL}${routes.signup}`
              : window.location.origin,
          }}
          isRoundedMedium
          primary
        />
      </div>
      <div className={styles.NotFoundPageImgWrapper}>
        <img
          src={notFoundPageImg}
          alt={messages.imgAlt}
          className={styles.NotFoundPageImg}
        />
      </div>
    </div>
  </div>
);

NotFoundPage.propTypes = {
  hasCompanyError: bool,
};
NotFoundPage.defaultProps = {
  hasCompanyError: false,
};

const areEqual = (prevProps, nextProps) =>
  prevProps.hasCompanyError === nextProps.hasCompanyError;

export default memo(NotFoundPage, areEqual);
