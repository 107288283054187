import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { number, bool, func, string } from 'prop-types';

import Modal from 'components/Modal';
import ModalWarningActionButtons from 'components/ModalWarningActionButtons';
import { Row, Col } from 'components/Grid';
import { cancelBooking } from 'store/slices/bookingsSlice';
import exclamationTriangle from 'assets/icons/exclamation-triangle.png';
import taxiIcon from 'assets/icons/taxi.png';
import styles from './CancelBooking.module.scss';

const CancelBooking = ({
  id,
  closeModal,
  onSuccess,
  isModalOpen,
  dataTestId,
}) => {
  const dispatch = useDispatch();
  const handleCancelClick = useCallback(
    // close modal whether it's success/error
    () =>
      dispatch(cancelBooking(id)).then(() => {
        onSuccess();
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, id]
  );
  const { isCancelling } = useSelector((state) => state.bookings);

  return (
    <Modal
      isModalOpen={isModalOpen}
      handleModalToggle={closeModal}
      modalStyle="warningModal"
      testId={dataTestId}
    >
      <Row center withMarginMedium>
        <img
          className={styles.CancelBookingTaxi}
          alt="taxi-icon"
          src={taxiIcon}
        />
      </Row>
      <Row center>
        <p className={styles.CancelBookingHeader}>
          Are you sure you want to cancel this trip?
        </p>
      </Row>
      <Row center withMarginLarge>
        <Col col={1}>
          <img
            className={styles.CancelBookingInfo}
            src={exclamationTriangle}
            alt="exclamation-triangle"
          />
        </Col>
        <Col col={7} className={styles.CancelWarningColumn}>
          <span className={styles.SubtitleSpan}>
            This action cannot be undone
          </span>
        </Col>
      </Row>
      <ModalWarningActionButtons
        onCancelButtonClick={closeModal}
        onSubmitButtonClick={handleCancelClick}
        cancelButtonText={"Don't Cancel"}
        submitButtonText="Cancel Trip"
        isCancelButtonDisabled={isCancelling}
        isSubmitButtonDisabled={isCancelling}
        isSubmitButtonLoading={isCancelling}
      />
    </Modal>
  );
};

CancelBooking.propTypes = {
  id: number.isRequired,
  closeModal: func.isRequired,
  isModalOpen: bool,
  dataTestId: string,
  onSuccess: func.isRequired,
};
CancelBooking.defaultProps = {
  isModalOpen: true,
  dataTestId: 'cancelBookingModal',
};
export default CancelBooking;
