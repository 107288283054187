import React from 'react';

import { StyledCounter } from './styles';

const TextAreaCounter = ({ limit, textArea }) => {
  const textAreaLength = textArea && textArea.length > 0 ? textArea.length : 0;
  const isTextRed = limit <= textAreaLength;

  return (
    <StyledCounter $red={isTextRed}>
      {textAreaLength}/{limit}
    </StyledCounter>
  );
};

export default TextAreaCounter;
