import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as v from 'utils/variables';

export const StyledWrapper = styled.div`
  position: relative;
`;
export const StyledIcon = styled(FontAwesomeIcon)`
  font-size: ${(props) => (props.$isFavorite ? v.fontSizeSm : v.fontSizeMd)};
  margin: ${v.spacingMd};
`;
export const StyledText = styled.p`
  font-size: ${v.fontSizeSm};
  color: ${v.gray};
  height: auto;
  max-height: 44px;
  overflow: hidden;
`;
export const StyledItem = styled.button`
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  font-size: ${v.fontSizeSm};
  text-align: left;
  height: 60px;
  background-color: #fafafa;
  border-bottom: 0.5px solid ${v.dark};
  &:hover {
    background: ${v.primary};
    color: ${v.white};
    ${StyledText} {
      color: ${v.white};
    }
  }
  &:focus {
    opacity: 0.8;
  }
  ${(props) =>
    props.$noResult &&
    css`
      padding: 0 ${v.spacingMd};
      &:hover {
        background-color: #fafafa;
        cursor: default;
      }
    `}
`;
export const StyledResults = styled.div`
  border: 0.5px solid ${v.dark};
  border-top: none;
  padding: 0;
  color: ${v.charCoal};
  font-size: ${v.fontSizeSm};
  z-index: 2;
  position: absolute;
  width: 100%;
  background: ${v.white};
  top: ${(props) => props.$top || '2.7em'};
  left: 0;
  max-height: 180px;
  overflow-y: scroll;
`;
