import React from 'react';
import { func, object, string } from 'prop-types';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import messages from './messages';
import { StyledText, StyledButton, StyledIcon } from './styles';

const NewAddressButton = ({ onClick, style, type }) => (
  <StyledButton
    type={type}
    style={style}
    onClick={onClick}
    data-testid="new-address-button"
  >
    <StyledIcon icon={faPlus} />
    <StyledText>{messages.save}</StyledText>
  </StyledButton>
);

NewAddressButton.propTypes = {
  onClick: func.isRequired,
  style: object,
  type: string,
};

NewAddressButton.defaultProps = {
  style: {},
  type: 'button',
};
export default NewAddressButton;
