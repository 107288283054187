import styled from 'styled-components';
import * as v from 'utils/variables';

export const StyledDiv = styled.div`
  display: flex;
  margin-top: ${(props) => (props.$hasTopMargin ? v.spacingLg : 0)};
`;

export const StyledItem = styled.span`
  text-align: left;
  display: flex;
  align-items: center;
  flex: 1;
  box-sizing: border-box;
  margin-top: ${v.spacingSm};
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${(props) => (props.$isLabel ? v.mousegray : v.charCoal)};
`;
