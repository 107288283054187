import React, { useEffect, useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';

import ListLoader from 'components/ListLoader';
import DashboardEmptyStateTemplate from 'components/DashboardEmptyStateTemplate';
import noUsersImg from 'assets/dashboard/no-users.png';
import filterMembers from 'utils/filterMembers';
import { ROLE_OPTIONS } from 'constants/accountUserRoles';
import {
  accountMembersPropTypes,
  accountRoleFilterType,
} from 'constants/propTypes';
import messages from './messages';
import UserRow from '../UserRow';
import { TEAM_COLUMNS } from 'utils/dashboardHeaders';

const UsersList = ({
  usersList,
  accountRoleFilter,
  emptyStateHeader,
  emptyStateText,
  account,
  isLoading,
}) => {
  const [filteredData, setFilteredData] = useState(usersList);
  useEffect(() => {
    const formattedAccountMembersData = filterMembers(usersList, {
      role: accountRoleFilter,
    });
    setFilteredData(formattedAccountMembersData);
  }, [accountRoleFilter, usersList]);

  if (!filteredData.length && isLoading) {
    return (
      <ListLoader
        length={8}
        cols={TEAM_COLUMNS}
        data={[
          '',
          'Jane Doe',
          'jane@gmail.com',
          '+44 12345 6789',
          'User',
          'General',
          '',
          '',
        ]}
      />
    );
  } else if (!filteredData.length && !isLoading) {
    return (
      <DashboardEmptyStateTemplate
        imgSource={noUsersImg}
        imgAlt={emptyStateHeader}
        header={emptyStateHeader}
        text={emptyStateText}
      />
    );
  }

  return (
    <>
      {filteredData?.map(
        ({
          id,
          state,
          userId,
          firstName,
          lastName,
          phoneCountry,
          phoneNumber,
          email,
          role,
          group,
          invitedAt,
          userRfbPaymentMethodId,
        }) => (
          <UserRow
            key={id}
            id={id}
            isPending={state === 'invited'}
            userId={userId}
            group={group}
            firstName={firstName}
            lastName={lastName}
            phoneCountry={phoneCountry}
            phoneNumber={phoneNumber}
            email={email}
            role={role}
            invitedAt={invitedAt}
            userRfbPaymentMethodId={userRfbPaymentMethodId}
            account={account}
          />
        )
      )}
    </>
  );
};

UsersList.propTypes = {
  usersList: arrayOf(shape(accountMembersPropTypes)),
  accountRoleFilter: accountRoleFilterType,
  emptyStateHeader: string,
  emptyStateText: string,
};

UsersList.defaultProps = {
  usersList: [],
  accountRoleFilter: ROLE_OPTIONS[0],
  emptyStateHeader: messages.emptyStateHeader,
  emptyStateText: messages.emptyStateText,
};

export default UsersList;
