import React, { memo, useMemo, useEffect } from 'react';
import {
  string,
  arrayOf,
  number,
  bool,
  shape,
  func,
  oneOfType,
} from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Select from 'components/Select';
import { CARD_OPTION, getPaymentMethodOptions } from 'utils/payment';
import cashIcon from 'assets/icons/cash.png';
import styles from './PaymentMethodsSelect.module.scss';

const PaymentMethodsSelect = ({
  name,
  options,
  placeholder,
  onChange,
  selectStyles,
  error,
  hasTouched,
  hasAddCardOption,
  selectedValue,
  ...otherProps
}) => {
  const paymentMethodOptions = useMemo(
    () => [
      ...getPaymentMethodOptions(options),
      ...(hasAddCardOption ? [CARD_OPTION] : []),
    ],
    [hasAddCardOption, options]
  );

  const formattedSelectOptions = useMemo(
    () =>
      paymentMethodOptions
        ? paymentMethodOptions.map((item) => ({
            card_id: item.card?.id,
            last4: item.card?.last4,
            id: item.id,
            value: item.value,
            label: (
              <div
                data-testid="payment-methods-options"
                className={cx(styles.PaymentMethodsSelectLabel, {
                  [styles.hasSmallText]: true,
                })}
              >
                <div className={styles.PaymentMethodsSelectOptionWrapper}>
                  {item.hasImage && (
                    <img
                      className={styles.PaymentMethodsSelectImg}
                      alt={item.name}
                      src={item.card?.icon || cashIcon}
                    />
                  )}
                  <span
                    className={cx(styles.PaymentMethodsSelectLabelText, {
                      [styles.hasSmallText]: true,
                    })}
                  >
                    {item.card ? item.card.last4 : item.name}
                  </span>
                </div>
              </div>
            ),
          }))
        : [],
    [paymentMethodOptions]
  );

  useEffect(() => {
    if (onChange) {
      const selectedIndex = formattedSelectOptions.findIndex(
        (item) => item.id === selectedValue
      );
      if (selectedIndex === -1) {
        onChange(formattedSelectOptions[0]);
      } else {
        onChange(formattedSelectOptions[selectedIndex]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => formattedSelectOptions.find((option) => option.id === selectedValue),
    [formattedSelectOptions, selectedValue]
  );

  return (
    <>
      <Select
        {...otherProps}
        name={name}
        value={value}
        isSearchable={false}
        styles={selectStyles}
        placeholder={placeholder}
        onChange={onChange}
        options={formattedSelectOptions}
      />
      {isEmpty(value) && error && hasTouched && (
        <span className={styles.PaymentMethodsSelectError}>{error}</span>
      )}
    </>
  );
};

PaymentMethodsSelect.propTypes = {
  name: string.isRequired,
  options: arrayOf(
    shape({
      id: number,
      value: oneOfType([string, number]),
      name: string,
      card: shape({
        icon: string,
      }),
      hasImage: bool,
    })
  ),
  placeholder: string,
  onChange: func.isRequired,
  selectStyles: shape({}),
  error: string,
  hasTouched: bool,
  hasAddCardOption: bool,
  selectedValue: oneOfType([string, number]),
};

PaymentMethodsSelect.defaultProps = {
  options: [],
  placeholder: 'Choose payment method',
  selectStyles: {},
  error: '',
  hasTouched: false,
  hasAddCardOption: false,
  selectedValue: null,
};

export default memo(PaymentMethodsSelect);
