import React from 'react';
import { string, node, number, bool, oneOfType, shape } from 'prop-types';

import {
  StyledColumnWrapper,
  StyledContainer,
  StyledColumn,
  StyledLabel,
  StyledChildrenWrapper,
} from './styles';

const LabelContainer = ({
  id,
  label,
  labelSize,
  inputSize,
  isReverseRow,
  hasOffset,
  inputOffset = 12 - (inputSize + labelSize),
  children,
  testId,
  labelStyle,
  isColumn,
  style,
  hasError,
}) => {
  return isColumn ? (
    <StyledColumnWrapper data-testid={testId} $col={labelSize}>
      <StyledLabel $isColumn $labelStyle={labelStyle} htmlFor={id}>
        {label}
      </StyledLabel>
      <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
    </StyledColumnWrapper>
  ) : (
    <>
      <StyledContainer
        $hasError={hasError}
        $style={style}
        data-testid={testId}
        $isReverseRow={isReverseRow}
      >
        <StyledColumn $label $col={labelSize}>
          <StyledLabel $labelStyle={labelStyle} htmlFor={id}>
            {label}
          </StyledLabel>
        </StyledColumn>
        <StyledColumn $offset={!!hasOffset && inputOffset} $col={inputSize}>
          {children}
        </StyledColumn>
      </StyledContainer>
    </>
  );
};

LabelContainer.propTypes = {
  id: string,
  label: oneOfType([string, node]),
  labelSize: number,
  inputSize: number,
  isReverseRow: bool,
  children: node,
  inputOffset: number,
  testId: string,
  labelStyle: shape({}),
  isColumn: bool,
  hasError: bool,
  style: shape({}),
  hasOffset: bool,
};
LabelContainer.defaultProps = {
  label: null,
  labelSize: 4,
  inputSize: 8,
  isReverseRow: false,
  children: null,
  testId: 'label-container',
  isColumn: false,
  hasError: false,
  style: {},
  labelStyle: {},
  hasOffset: true,
};

export default LabelContainer;
