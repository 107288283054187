import React, { useEffect, useState, useMemo, memo } from 'react';
import { useDispatch } from 'react-redux';
import { bool, func, array, shape, string } from 'prop-types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash/isEqual';

import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

import { PICKUP_TYPES } from 'constants/booking';
import { mousegray } from 'utils/variables';
import CancelBooking from 'components/Modals/CancelBooking';
import BookingMap from 'components/Modals/BookingForm/BookingMap';
import MapWithPubNub from 'components/Modals/BookingForm/BookingMap/MapWithPubNub';

import InfoModal from 'components/Modals/InfoModal';

import {
  getBookingState,
  updateBooking,
  getPreauthId,
  sendMessageToDriver,
  clearCreatedBookingStatus,
} from 'store/slices/bookingsSlice';
import {
  STATE_REQUESTED,
  STATE_EN_ROUTE,
  STATE_ARRIVED,
  STATE_TO_DESTINATION,
  STATE_COMPLETED,
  VIEWABLE_BOOKING_STATES,
} from 'constants/bookingStates';
import { locationType } from 'constants/propTypes';
import { usePrevious } from 'hooks';

import DriverInfoWithPayment from './DriverInfoWithPayment';
import JourneySummary from './JourneySummary';
import MessageDriver from './MessageDriver';
import ProgressBar from './ProgressBar';

import * as S from './styles';
import messages from './messages';

const ViewBooking = ({
  isCreated,
  paymentMethods,
  hasStateFulfilled,
  onClose,
  bookingDetails,
  companyCoordinates,
  companyName,
  companyPhoneNumber,
  hasPreauth,
  redirectRef,
  driverMessages,
  hasAddCardOption,
  onSelectNewCardOption,
  isNotRfbAccount,
  defaultPaymentMethod,
}) => {
  const {
    id: bookingId,
    external_id: iCabbiID,
    driver = {},
    state,
    summary,
    payment = {},
    pickup = {},
    destination = {},
    pubnub_channel,
    pubnub_subscribe_key,
    pubnub_token,
    eta,
  } = bookingDetails;
  const pubnubClient = new PubNub({
    authKey: pubnub_token,
    subscribeKey: pubnub_subscribe_key,
  });
  const dispatch = useDispatch();
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [isMessageDriverOpen, setIsMessageDriverOpen] = useState(false);
  const [isCallModalOpen, setIsCallModalOpen] = useState(false);

  const selectedPaymentMethod = payment?.rfb_payment_method;
  const previousState = usePrevious(state);
  const isEnRouteOrArrived =
    [STATE_ARRIVED, STATE_EN_ROUTE].indexOf(state) > -1;
  const vehicleInfo = useMemo(
    () =>
      driver?.vehicle
        ? // eslint-disable-next-line max-len
          `${driver.vehicle.color} ${driver.vehicle.brand} ${driver.vehicle.model} ${driver.vehicle.plate}`
        : '',
    [driver]
  );
  const [view, setView] = useState({
    isAssigning: state === STATE_REQUESTED,
    isDriverInfo:
      [STATE_EN_ROUTE, STATE_ARRIVED, STATE_TO_DESTINATION].indexOf(state) >
        -1 && driver.id,
    isCompleted: state === STATE_COMPLETED && summary,
  });

  const driverMessage = useMemo(
    () => driverMessages.messagesByBookingId?.[bookingId] || '',
    [bookingId, driverMessages.messagesByBookingId]
  );
  const isSendingMessageToDriver = useMemo(
    () => driverMessages.isLoading || false,
    [driverMessages.isLoading]
  );

  useEffect(() => {
    dispatch(
      getBookingState({
        id: bookingId,
        currentState: state,
      })
    );
    return () => {
      if (isCreated) {
        dispatch(clearCreatedBookingStatus());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasStateFulfilled && state !== STATE_COMPLETED) {
      const timer = setTimeout(() => {
        dispatch(
          getBookingState({
            id: bookingId,
            currentState: state,
          })
        );
      }, 10000);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasStateFulfilled, bookingId, state]);

  useEffect(() => {
    if (VIEWABLE_BOOKING_STATES.indexOf(state) === -1) {
      onClose();
      return;
    }
    if (state !== previousState) {
      if (state === STATE_REQUESTED) {
        setView({
          isAssigning: true,
        });
      } else if (
        [STATE_EN_ROUTE, STATE_ARRIVED, STATE_TO_DESTINATION].indexOf(state) >
          -1 &&
        driver.id
      ) {
        setView({
          isDriverInfo: true,
        });
      } else if (state === STATE_COMPLETED && summary) {
        setView({
          isCompleted: true,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driver, previousState, state, bookingDetails]);

  const title = useMemo(() => {
    if (view.isAssigning) {
      return 'We’re assigning your driver.';
    } else if (view.isDriverInfo && state === STATE_EN_ROUTE && driver.name) {
      return `${driver.name} is on the way!`;
    } else if (
      view.isDriverInfo &&
      state === STATE_ARRIVED &&
      driver.name &&
      vehicleInfo
    ) {
      return `${driver.name} has arrived. Look out for ${vehicleInfo}`;
    } else if (view.isDriverInfo && state === STATE_TO_DESTINATION) {
      return 'Journey in progress';
    } else if (view.isCompleted) {
      return 'You’ve arrived!';
    }
  }, [view, driver.name, state, vehicleInfo]);

  if (!bookingId) {
    return null;
  }

  const onCancelBooking = () => {
    setIsCancelModalVisible(true);
  };

  const discardCancelBookingModal = () => {
    setIsCancelModalVisible(false);
  };

  const updatePaymentMethod = async (paymentMethod) => {
    const updatedFields = { rfb_payment_method: paymentMethod.id };

    if (hasPreauth && paymentMethod?.card_id) {
      const { card_id, last4 } = paymentMethod;
      const { payload } = await dispatch(
        getPreauthId({
          updatedFields,
          values: {
            ...bookingDetails,
            ...updatedFields,
            user_id: bookingDetails.user.id,
            pickup_address: pickup.address,
            pickup_lat: pickup.lat,
            pickup_lng: pickup.lng,
            destination_address: destination?.address || '',
            destination_lat: destination?.lat || '',
            destination_lng: destination?.lng || '',
            card_id,
            last4,
          },
        })
      );
      if (payload?.preauthId) {
        dispatch(
          updateBooking({
            id: bookingId,
            values: {
              ...updatedFields,
              preauthId: payload.preauthId,
            },
            guestUserId: null,
            referenceElement: redirectRef,
          })
        );
      }
    } else {
      dispatch(
        updateBooking({
          id: bookingId,
          values: updatedFields,
          guestUserId: null,
          referenceElement: redirectRef,
        })
      );
    }
  };

  const openMessageDriverModal = () => setIsMessageDriverOpen(true);
  const closeMessageDriverModal = () => setIsMessageDriverOpen(false);

  const openCallModal = () => setIsCallModalOpen(true);
  const closeCallModal = () => setIsCallModalOpen(false);

  const handleSendMessage = async (message) => {
    const { payload } = await dispatch(
      sendMessageToDriver({ id: bookingId, message })
    );
    if (payload.data === true) {
      setIsMessageDriverOpen(false);
    }
  };

  return (
    <>
      <S.Icon data-testid="closeModal" onClick={onClose}>
        <FontAwesomeIcon color={mousegray} icon={faTimes} />
      </S.Icon>
      <S.Flex $spaceBetween data-testid="container">
        <S.Column>
          <S.Content>
            <S.Text $smallText>Trip {iCabbiID}</S.Text>
            <S.Heading data-testid="heading">{title}</S.Heading>
            {view.isAssigning && <ProgressBar />}
            {view.isDriverInfo && (
              <DriverInfoWithPayment
                bookingState={state}
                paymentMethod={selectedPaymentMethod}
                paymentMethods={paymentMethods}
                companyName={companyName}
                driver={driver}
                updatePaymentMethod={updatePaymentMethod}
                onClickCall={openCallModal}
                onClickMessage={openMessageDriverModal}
                driverMessage={driverMessage}
                hasAddCardOption={hasAddCardOption}
                onSelectNewCardOption={onSelectNewCardOption}
                isNotRfbAccount={isNotRfbAccount}
                defaultPaymentMethod={defaultPaymentMethod}
              />
            )}
            {view.isCompleted && (
              <JourneySummary
                summary={summary}
                paymentMethod={selectedPaymentMethod}
                currency={payment?.currency?.label}
              />
            )}
          </S.Content>
          {view.isAssigning && (
            <S.Footer data-testid="footer">
              <S.Text
                data-testid="cancelBooking"
                onClick={onCancelBooking}
                $clickable
                $smallText
              >
                {messages.cancelBooking}
              </S.Text>
            </S.Footer>
          )}
        </S.Column>
        <S.ColumnMapWrapper>
          {isEnRouteOrArrived ? (
            <PubNubProvider client={pubnubClient}>
              <MapWithPubNub
                pubnubChannels={[pubnub_channel]}
                pickupDetails={pickup}
                initialDriverPosition={{
                  lat: driver?.lat,
                  lng: driver?.lng,
                }}
                bookingState={state}
              />
            </PubNubProvider>
          ) : (
            <BookingMap
              pickupDetails={pickup}
              destinationDetails={destination}
              companyCoordinates={companyCoordinates}
              pickupETA={{ value: eta }}
              bookingState={state}
              displayETA={
                bookingDetails.type === PICKUP_TYPES.ASAP && !view.isCompleted
              }
            />
          )}
        </S.ColumnMapWrapper>
      </S.Flex>
      {view.isAssigning && isCancelModalVisible && (
        <CancelBooking
          dataTestId="cancelBookingModal"
          id={bookingId}
          closeModal={discardCancelBookingModal}
          onSuccess={onClose}
        />
      )}
      {view.isDriverInfo && isMessageDriverOpen && (
        <MessageDriver
          isModalOpen
          onClose={closeMessageDriverModal}
          bookingId={bookingId}
          isSendDisabled={isSendingMessageToDriver}
          onClickSend={handleSendMessage}
        />
      )}
      {view.isDriverInfo && isCallModalOpen && (
        <InfoModal
          isVisible
          title={messages.callModal.title(companyName)}
          subtitle={messages.callModal.subtitle(companyPhoneNumber, bookingId)}
          onCancel={closeCallModal}
        />
      )}
    </>
  );
};

ViewBooking.propTypes = {
  onSelectNewCardOption: func,
  onClose: func.isRequired,
  paymentMethods: array.isRequired,
  bookingDetails: shape({}).isRequired,
  companyCoordinates: locationType.isRequired,
  redirectRef: shape({}).isRequired,
  driverMessages: shape({}).isRequired,
  companyName: string.isRequired,
  hasPreauth: bool,
  hasStateFulfilled: bool,
  hasAddCardOption: bool,
  companyPhoneNumber: string.isRequired,
  isCreated: bool,
  isNotRfbAccount: bool,
  defaultPaymentMethod: shape({}),
};
ViewBooking.defaultProps = {
  onSelectNewCardOption: () => {},
  hasAddCardOption: false,
  hasStateFulfilled: false,
  hasPreauth: null,
  isNotRfbAccount: false,
  defaultPaymentMethod: {},
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.paymentMethods, nextProps.paymentMethods) &&
  isEqual(prevProps.bookingDetails, nextProps.bookingDetails) &&
  isEqual(prevProps.companyCoordinates, nextProps.companyCoordinates) &&
  isEqual(prevProps.driverMessages, nextProps.driverMessages) &&
  isEqual(prevProps.defaultPaymentMethod, nextProps.defaultPaymentMethod) &&
  prevProps.companyName === nextProps.companyName &&
  prevProps.hasPreauth === nextProps.hasPreauth &&
  prevProps.hasStateFulfilled === nextProps.hasStateFulfilled &&
  prevProps.hasAddCardOption === nextProps.hasAddCardOption &&
  prevProps.companyPhoneNumber === nextProps.companyPhoneNumber &&
  prevProps.isCreated === nextProps.isCreated;

export default memo(ViewBooking, areEqual);
