import React, { useEffect, useState, useMemo, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  DashboardHeaderWrapper,
  DashboardScrollView,
} from 'views/Dashboard/styles';
import UserFilters from '../UserFilters';
import { getGroups, groupsDataSelector } from 'store/slices/groupsSlice';
import GroupList from './GroupList';

const Groups = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState(null);
  const { isLoading } = useSelector((state) => state.groups);
  const groups = useSelector(groupsDataSelector);

  const displayedGroups = useMemo(() => {
    if (searchQuery) {
      return groups.filter(
        (group) =>
          group.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
      );
    }
    return groups;
  }, [groups, searchQuery]);

  useEffect(() => {
    dispatch(getGroups());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <DashboardHeaderWrapper $noBottomPadding data-testid="groups">
        <UserFilters
          searchQuery={searchQuery}
          onSearch={handleSearch}
          displayAddGroupButton
          displayAddUserButton={false}
          displayBulkActions={false}
          displayRoleSelect={false}
          minSearchWidth="200px"
        />
      </DashboardHeaderWrapper>
      <DashboardScrollView $disableScroll={isLoading}>
        <GroupList data={displayedGroups} />
      </DashboardScrollView>
    </>
  );
};

export default memo(Groups);
