import axios from 'axios';
import { headers, apiUrl, defaultCompanyServiceArea } from 'api/config';
import { setAppError } from 'store/slices/appStatusSlice';
import { DEFAULT_COMPANY } from 'constants/companies';
import { commonText, customErrors } from 'locales/en';
import { LOCALSTORAGE_KEY } from 'utils/localStorage';

const CancelToken = axios.CancelToken;
let source = CancelToken.source();

export const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers,
});

export const cancelSource = (message = 'Request cancelled manually') => {
  source.cancel(message);

  source = CancelToken.source();
};

const handleError = (error, store) => {
  if (axios.isCancel(error) || !error.response) {
    return;
  }
  const { status } = error?.response || {};
  const { url, method } = error.response?.config || {};
  if (status === 442) {
    localStorage.removeItem(LOCALSTORAGE_KEY);
    return;
  }

  let title = commonText.errorTitle;
  let body = '';

  // validation errors need to be handled on FE
  if (status === 412) {
    title = commonText.errorTitle;
    body = Object.values(error.response.data.errors).map((item) => item[0]);
  } else if (
    ((url.indexOf('validate') > -1 || url.indexOf('bookings') > -1) &&
      status === 489) ||
    (url === '/users/create_verified' && [450, 451].indexOf(status) > -1) ||
    (['get', 'patch', 'post'].indexOf(method) > -1 &&
      url.indexOf('accounts') > -1 &&
      status === 450)
  ) {
    const urlKey = url.split('/')[1];
    let methodKey = method;
    if (method === 'patch') {
      methodKey = urlKey === 'accounts' ? 'get' : 'post';
    }
    const key = `${methodKey}_${urlKey}_${status}`;

    title = customErrors[key].title;
    if (urlKey === 'accounts' && status === 450) {
      const companyEmail = store.getState().company.data.configs?.general
        ?.email;
      body = customErrors[key].body(companyEmail);
    } else {
      body = customErrors[key].body;
    }
  } else if (error.response?.data) {
    if (error.response.data.debug) delete error.response.data.debug;
    store.dispatch(
      setAppError({
        ...error.response.data,
        meta: { status, url, method },
      })
    );
    return;
  }
  store.dispatch(
    setAppError({
      title,
      body,
      meta: { status, url, method },
    })
  );
};

axiosInstance.interceptors.request.use((config) => {
  const store = require('../store').default;

  const token = store.getState().user.data?.token;
  const company = store.getState().company;
  const currentAccount = store.getState().accounts.data.current;
  config.headers['Lng'] = company?.data?.lng ?? defaultCompanyServiceArea.lng;
  config.headers['Lat'] = company?.data?.lat ?? defaultCompanyServiceArea.lat;

  config.headers['App-Company-Code'] =
    config.headers['App-Company-Code'] ||
    company?.appCompanyCode ||
    DEFAULT_COMPANY;
  config.headers['Company-Code'] =
    config.headers['Company-Code'] || company?.companyCode || DEFAULT_COMPANY;

  config.headers['rfb-account-id'] = currentAccount?.id || 1;
  config.headers['Authorization'] = config.headers.Authorization || token;

  config.cancelToken =
    config.url.indexOf('map') > -1 ||
    config.url.indexOf('search') > -1 ||
    config.url.indexOf('bookings') > -1 ||
    config.url.indexOf('account') > -1 ||
    config.url.indexOf('cards') > -1 ||
    config.url.indexOf('quotes/estimate') > -1
      ? config.cancelToken
      : source.token;

  return config;
});
axiosInstance.interceptors.response.use(
  function (response) {
    if (response.config.url === '/users/logout' && response.status === 200) {
      cancelSource();
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const axiosGet = (options) => {
  const store = require('../store').default;
  return axiosInstance({
    method: 'GET',
    ...options,
  })
    .then((res) => Promise.resolve(res))
    .catch((error) => {
      if (
        (options.url === '/companies/default' &&
          error?.response.status === 445) ||
        options.url === '/accounts/current' ||
        (options.url === '/quotes/estimate' &&
          [499, 451].indexOf(error?.response?.status) > -1)
      )
        return;
      handleError(error, store);
      return Promise.reject(error);
    });
};

export const axiosPost = (options) => {
  const store = require('../store').default;

  return axiosInstance({
    method: 'POST',
    ...options,
  })
    .then((res) => Promise.resolve(res))
    .catch((error) => {
      if (
        !(
          options.url === '/bookings' &&
          [447, 459].indexOf(error?.response?.status) > -1
        )
      ) {
        handleError(error, store);
      }
      return Promise.reject(error);
    });
};

export const axiosPostFile = (options) => {
  const store = require('../store').default;

  return axiosInstance({
    method: 'POST',
    ...options,
  })
    .then((res) => Promise.resolve(res))
    .catch((error) => {
      handleError(error, store);
      return Promise.reject(error);
    });
};

export const axiosPatch = (options) => {
  const store = require('../store').default;

  return axiosInstance({
    method: 'PATCH',
    ...options,
  })
    .then((res) => Promise.resolve(res))
    .catch((error) => {
      if (
        !(
          options.url.indexOf('/bookings') > -1 &&
          [447, 459].indexOf(error?.response?.status) > -1
        )
      ) {
        handleError(error, store);
      }
      return Promise.reject(error);
    });
};

export const axiosDelete = (options) => {
  const store = require('../store').default;

  return axiosInstance({
    method: 'DELETE',
    ...options,
  })
    .then((res) => Promise.resolve(res))
    .catch((error) => {
      handleError(error, store);
      return Promise.reject(error);
    });
};
