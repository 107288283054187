import React, { memo } from 'react';
import ReactModal from 'react-modal';
import { node, func, string, shape, array, oneOfType, bool } from 'prop-types';

import Content from './Content';
import { basicModalStyles } from 'constants/modalStyles';
import { modalStatusType } from 'constants/propTypes';

const BasicModal = ({
  title,
  subtitle,
  onClickPrimary,
  onClickSecondary,
  onClose = onClickSecondary,
  secondaryTitle,
  primaryTitle,
  testId,
  type,
  style,
  children,
  customFooterComponent,
  isSecondaryDisabled,
  isPrimaryDisabled,
  isPrimaryLoading,
  centerTitle,
  buttonType,
  shouldCloseOnOverlayClick,
  minHeight,
  renderAsForm,
  ...modalProps
}) => {
  return (
    <ReactModal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={modalProps.isModalOpen}
      onRequestClose={onClose}
      style={style}
      testId={testId}
      appElement={document.body}
      onAfterOpen={() => {
        document.body.style.overflow = 'hidden';
      }}
      onAfterClose={() => {
        document.body.removeAttribute('style');
      }}
      {...modalProps}
    >
      <Content
        renderAsForm={renderAsForm}
        minHeight={minHeight}
        centerTitle={centerTitle}
        buttonType={buttonType}
        type={type}
        title={title}
        subtitle={subtitle}
        onClickPrimary={onClickPrimary}
        onClickSecondary={onClickSecondary}
        secondaryTitle={secondaryTitle}
        primaryTitle={primaryTitle}
        isSecondaryDisabled={isSecondaryDisabled}
        isPrimaryDisabled={isPrimaryDisabled}
        isPrimaryLoading={isPrimaryLoading}
        children={children}
        customFooterComponent={customFooterComponent}
      />
    </ReactModal>
  );
};

BasicModal.propTypes = {
  onClickSecondary: func,
  onClickPrimary: func,
  onClose: func,
  secondaryTitle: string,
  primaryTitle: string,
  title: oneOfType([string, node]),
  subtitle: oneOfType([string, array]),
  testId: string,
  type: modalStatusType,
  style: shape({}),
  buttonType: string,
  shouldCloseOnOverlayClick: bool,
  isSecondaryDisabled: bool,
  isPrimaryDisabled: bool,
  isPrimaryLoading: bool,
  centerTitle: bool,
  renderAsForm: bool,
};

BasicModal.defaultProps = {
  onClickSecondary: () => {},
  onClickPrimary: () => {},
  onClose: () => {},
  secondaryTitle: '',
  primaryTitle: '',
  title: '',
  subtitle: '',
  testId: 'basic-modal',
  type: null,
  style: basicModalStyles,
  buttonType: undefined,
  shouldCloseOnOverlayClick: false,
  isSecondaryDisabled: false,
  isPrimaryDisabled: false,
  isPrimaryLoading: false,
  centerTitle: false,
  renderAsForm: false,
};

export default memo(BasicModal);
