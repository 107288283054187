import appImg from 'assets/landingPage/app@3x.png';
import desktopAppImg from 'assets/landingPage/desktop-b@3x.png';
import vehicleImg from 'assets/landingPage/vehicle-options@3x.png';

import { getSectionsMessages } from './messages';

export const getLandingPageSection = (name) => [
  {
    header: getSectionsMessages(name).intro.header,
    text: getSectionsMessages(name).intro.text,
    textOrder: 1,
    imgSource: desktopAppImg,
    imgOrientation: 'horizontal',
  },
  {
    header: getSectionsMessages(name).payment.header,
    text: getSectionsMessages(name).payment.text,
    textOrder: 2,
    imgSource: appImg,
    imgOrientation: 'vertical',
  },
  {
    header: getSectionsMessages(name).expenses.header,
    text: getSectionsMessages(name).expenses.text,
    textOrder: 1,
    imgSource: desktopAppImg,
    imgOrientation: 'horizontal',
  },
  {
    header: getSectionsMessages(name).standard.header,
    text: getSectionsMessages(name).standard.text,
    textOrder: 2,
    imgSource: vehicleImg,
    imgOrientation: 'vertical',
  },
];
