import React from 'react';
import { string, node } from 'prop-types';

import styles from './DashboardEmptyStateTemplate.module.scss';

const DashboardEmptyStateTemplate = ({
  imgSource,
  imgAlt,
  header,
  text,
  children,
}) => {
  return (
    <div className={styles.Container}>
      <div className={styles.ContentWrapper}>
        <img className={styles.ImageWrapper} src={imgSource} alt={imgAlt} />
        <p className={styles.Header}>{header}</p>
        <p className={styles.MainText}>{text}</p>
        {children}
      </div>
    </div>
  );
};

DashboardEmptyStateTemplate.propTypes = {
  imgSource: string.isRequired,
  imgAlt: string.isRequired,
  header: string,
  text: string,
  children: node,
};

DashboardEmptyStateTemplate.defaultProps = {
  header: '',
  text: '',
  children: null,
};

export default DashboardEmptyStateTemplate;
