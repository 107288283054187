/* eslint-disable max-len */
export const messages = {
  roleDescription:
    'Frontdesk login allows your team to use the booking site at reception areas without needing a 2-factor phone number to sign in.',
  emailDescription:
    'This email address should not be associated with a phone number, or the 2-factor authentication with phone number sign in will be required.',
  infoDescription: ' will receive an invite to',
  account: 'account',
  choosePaymentMethodErrorMessage: 'Default payment method is required',
  infoIconAlt: 'info',
  discardChangesBtn: 'Discard Changes',
  saveChangesBtn: 'Save Changes',
  addUserBtn: 'Add Teammate',
  labelFirstName: 'First Name',
  labelLastName: 'Last Name',
  labelRole: 'Role',
  labelGroup: 'Group',
  labelPaymentMethod: 'Default payment method',
  labelPhoneNumber: 'Phone number',
  labelEmail: 'Email',
  labelPassword: 'Password',
  importCSVButton: 'Import CSV',
  addTeammate: 'Add new teammate',
  manageUsersInBulk: {
    header: 'Manage teammates in bulk',
    subHeader: ' Bulk manage teammates by CSV. To get started',
    downloadTemplateButton: 'download the template.',
    dropZoneActive: 'Drop the files here ...',
    fileLoaded: 'File loaded, hit continue to proceed',
    dropZoneText: 'Drag and drop some .csv file here, or',
    dropZoneBrowse: 'click to browse your computer',
    usersFound: 'teammate(s) found',
    usersToAdd: 'teammate(s) will be added',
    usersWithErrorFound: 'Found',
    usersWithError: 'teammate(s) with errors',
    continueBtn: 'Continue',
    finishBtn: 'Finish',
    usersInvitedSubHeader: 'We are sending your invites',
    usersInvitedText:
      'Your full team should be visible in your Teammates list shortly',
    usersInvitedButton: 'OK',
  },
  userSearchInfo:
    'If this personal user is not your teammate, please check you have the right number.',
  editTeammate: 'Edit teammate',
  editFrontdesk: 'Edit Frontdesk User',
  createFrontdesk: 'Create Frontdesk User',
  createFrontdeskSubtitle:
    'This login is for use in reception areas to access the site via email and password only.  The email address entered below should not be associated with a mobile number. If a phone number is added, 2-factor sign-in via the phone number will be required for security.',
};
