import styled, { css } from 'styled-components';
import * as v from 'utils/variables';

export const StyledInput = styled.input`
  height: ${(props) => props.$height || v.DEFAULT_INPUT_HEIGHT};
  width: 100%;
  margin: auto 0;
  border: 1px solid ${v.dark};
  ${(props) =>
    props.$hasError &&
    css`
      border: 1px solid ${v.red};
    `}
  border-radius: ${v.borderRadiusBasic};
  color: ${v.lightblack};
  font-size: ${v.fontSizeSm};
  outline: none;
  padding: 10px 0 10px 10px;
  box-sizing: border-box;
  z-index: ${(props) => props.$zIndex || 'initial'};

  ${(props) =>
    props.$style &&
    css`
      ${props.$style}
    `};

  &::placeholder {
    color: ${v.palegray};
  }
  &:disabled {
    cursor: not-allowed;
    color: ${v.mousegray};
    background-color: ${v.dimmed};
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
