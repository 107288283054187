import { Form } from 'formik';
import styled from 'styled-components';

import Button from 'components/Button';
import * as v from 'utils/variables';
import {
  StyledColumn,
  StyledRow as _StyledRow,
} from 'components/GridNew/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledButton = styled(Button)`
  padding: ${v.spacingMd} ${v.spacingLg};
`;
export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) =>
    props.$isLogin ? 'space-around' : 'space-around'};
`;
export const StyledContainer = styled.div`
  display: flex;
  height: ${(props) => (props.$isLogin ? '235px' : '175px')};
  flex-direction: column;
  justify-content: space-evenly;
`;
export const StyledSubtitle = styled.h2`
  font-size: ${v.fontSizeMd};
  color: ${v.gray};
  cursor: ${(props) => (props.$clickable ? 'pointer' : 'initial')};
  text-decoration: ${(props) => (props.$clickable ? 'underline' : 'none')};
  margin: ${v.spacingMd} 0;
`;
export const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100px;
  flex: 0 0 50%;
`;
export const StyledHeader = styled.h1`
  font-size: ${v.fontSizeXl};
  color: ${v.charCoal};
`;
export const StyledCol = styled(StyledColumn)`
  height: 80px;
`;
export const StyledRow = styled(_StyledRow)`
  height: 80px;
`;
export const ForgotPassword = styled.span`
  font-size: ${v.fontSizeSm};
  color: ${({ $isLoading }) => ($isLoading ? v.gray : v.primary)};
  display: flex;
  margin: 0.5rem 0 0 3px;
  cursor: pointer;
  position: relative;
`;

export const Spinner = styled(FontAwesomeIcon)`
  margin: 0.125rem 0 0 0.5rem;
  color: ${v.primary};
`;
