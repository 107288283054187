import styled, { css } from 'styled-components';
import * as v from 'utils/variables';
import ButtonWithIconComponent from 'components/ButtonWithIcon';
import PaymentMethodsSelectComponent from 'components/PaymentMethodsSelect';

export const Flex = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  ${(props) =>
    props.$spaceBetween &&
    css`
      justify-content: space-between;
    `};
`;

export const Column = styled.div`
  flex: 0 1 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ColumnMapWrapper = styled.div`
  height: 100%;
  flex: 0 1 48%;
  margin: 0;
  padding: 0;
`;
export const Heading = styled.h1`
  color: ${v.lightblack};
  font-size: 2em;
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const Content = styled.div`
  margin-top: 65px;
`;
export const Footer = styled.div`
  position: absolute;
  bottom: 50px;
  left: 0px;
  button {
    color: ${v.mousegray};
    padding: 0;
    margin: 0;
  }
`;
export const Icon = styled.span`
  position: absolute;
  top: 20px;
  right: 1.25rem;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 99;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
`;
export const Row = styled.div`
  display: flex;
  padding: 5px 0;
  flex-direction: row;
  align-items: center;
  ${(props) =>
    props.$buttonWrapper &&
    css`
      flex-wrap: wrap;
      margin: 16px 6px 6px;
    `};
`;
export const Text = styled.p`
  color: ${(props) => (props.$green ? v.green : v.mousegray)};
  font-size: ${(props) => (props.$smallText ? v.fontSizeSm : v.fontSizeMd)};
  ${(props) =>
    props.$clickable &&
    css`
      cursor: pointer;
      padding: 10px 0;
    `};
`;
export const Label = styled.span`
  color: ${v.mousegray};
  font-size: ${(props) => (props.$largeText ? v.fontSizeMd : v.fontSizeXs)};
  font-weight: ${(props) => (props.$largeText ? 'normal' : 'bold')};
  flex: 1;
  ${(props) =>
    props.$flex &&
    css`
      display: flex;
      align-items: center;
    `};
`;
export const BoldText = styled(Text)`
  color: ${v.lightblack};
  font-weight: bold;
`;
export const SmallText = styled.p`
  font-size: ${(props) => (props.$isXSmall ? v.fontSizeXs : v.fontSizeSm)};
  color: ${v.lightblack};
  ${(props) =>
    props.$flex &&
    css`
      flex: 1;
    `};
`;
export const Image = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: solid 3.6px #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  margin-right: 20px;
`;
export const Info = styled.div``;
export const ButtonWithIcon = styled(ButtonWithIconComponent)`
  margin: 4px;
`;
export const HorizontalLine = styled.hr`
  border: 0;
  height: 1px;
  background-color: ${v.dimmed};
  margin: ${(props) => (props.$smallMargin ? v.spacingMd : v.spacingLg)} 0;
`;

export const PaymentMethodsSelect = styled(PaymentMethodsSelectComponent)`
  flex: 1;
  img {
    width: 33px;
  }
`;
export const CardIcon = styled.img`
  height: ${(props) => (props.$isXSmall ? 10 : 15)}px;
  margin-right: 10px;
`;
export const PaymentView = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;
export const Border = styled.span`
  display: flex;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: solid 3.6px #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  margin-right: 20px;
  background-color: ${v.mousegray};
  svg.driver {
    margin: auto;
  }
`;
export const Textarea = styled.textarea`
  font-size: ${v.fontSizeSm};
  color: ${v.gray};
  border: none;
  resize: none;
  width: 100%;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: ${v.fontSizeSm};
    color: ${v.mousegray};
  }
`;
export const CharCounter = styled.span`
  margin-right: 30px;
  color: ${v.mousegray};
  font-size: ${v.fontSizeSm};
`;
