import EmailOrPhoneStep from './EmailOrPhoneStep';
import PasswordOrCodeStep from './PasswordOrCodeStep';
export * from './LoginSchemas';

export const STEPS = [
  {
    component: EmailOrPhoneStep,
    fields: ['email', 'phone_country', 'phone_number', 'phone'],
  },
  {
    component: PasswordOrCodeStep,
    fields: [
      'password',
      'code_secret_1',
      'code_secret_2',
      'code_secret_3',
      'code_secret_4',
      'code_secret_5',
      'code_secret_6',
    ],
  },
];
