import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isIPhone13, isAndroid } from 'react-device-detect';
import { Formik, Form } from 'formik';
import { bool, func } from 'prop-types';

import { schema, initialValues } from './schema';
import { appLinks } from 'constants/appLinks';
import { commonText } from 'locales/en';

import { ACCOUNT_TYPES } from 'constants/accountTypes';
import { createAccount, clearNewAccount } from 'store/slices/accountsSlice';
import { logout } from 'store/slices/userSlice';
import { generalCompanyConfig } from 'store/slices/companySlice';
import { usePrevious } from 'hooks';
import BasicModalContent from 'components/Modals/BasicModal/Content';
import { LabelWithInput, LabelWithCheckbox } from 'components/Label';
import CheckboxLabel from './CheckboxLabel';
import messages from './messages';
import { StyledBottom, StyledLink } from './styles';

const detectedDevice = () => {
  if (isIPhone13) {
    return appLinks.ios;
  } else if (isAndroid) {
    return appLinks.android;
  } else {
    return appLinks.web;
  }
};

const NewAccountForm = ({
  isCreatingAccount,
  onCancel,
  onCreateAccount,
  hasNoAccount,
}) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const companyConfig = useSelector(generalCompanyConfig);
  const { error } = useSelector((state) => state.accounts);
  const prevIsCreating = usePrevious(isCreatingAccount);

  useEffect(() => {
    if (prevIsCreating && isCreatingAccount === false && !error) {
      onCreateAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevIsCreating, isCreatingAccount, error]);

  const onSubmit = useCallback(
    (values) => {
      dispatch(createAccount({ values, type: ACCOUNT_TYPES.rfb }));
    },
    [dispatch]
  );

  const handleCancel = () => {
    dispatch(clearNewAccount());
    onCancel();
  };

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={schema}
        innerRef={formRef}
      >
        {({
          handleChange,
          setFieldValue,
          handleBlur,
          values,
          errors,
          touched,
          dirty,
        }) => {
          return (
            <Form noValidate>
              <BasicModalContent
                minHeight="170px"
                title={
                  hasNoAccount ? messages.noAccountHeader : messages.header
                }
                onClickPrimary={() => formRef.current?.submitForm()}
                onClickSecondary={handleCancel}
                secondaryTitle={commonText.close}
                primaryTitle={messages.primaryTitle}
                isPrimaryDisabled={isCreatingAccount || !dirty}
                buttonType="submit"
              >
                <LabelWithInput
                  label={messages.companyName}
                  error={errors.name}
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  hasError={!!errors.name && !!touched.name}
                />
                <LabelWithInput
                  label={messages.companyEmail}
                  error={errors.admin_email}
                  name="admin_email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.admin_email}
                  hasError={errors.admin_email && touched.admin_email}
                  type="email"
                />
                <LabelWithCheckbox
                  label={
                    <CheckboxLabel
                      termsUrl={companyConfig.terms_url}
                      privacyUrl={companyConfig.privacy_url}
                    />
                  }
                  error={errors.checkbox}
                  name="checkbox"
                  onChange={setFieldValue}
                  onBlur={handleBlur}
                  checked={values.checkbox}
                  hasError={!!(errors.checkbox && touched.checkbox)}
                />
                {hasNoAccount && (
                  <StyledBottom>
                    <StyledLink onClick={() => dispatch(logout())}>
                      {messages.logout}
                    </StyledLink>
                    {messages.or}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={companyConfig?.onelink_url || detectedDevice()}
                    >
                      {messages.continueApp}
                    </a>
                  </StyledBottom>
                )}
              </BasicModalContent>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

NewAccountForm.propTypes = {
  hasNoAccount: bool,
  onCancel: func,
  onCreateAccount: func,
  isCreatingAccount: bool,
};

NewAccountForm.defaultProps = {
  hasNoAccount: false,
  onCancel: () => {},
  onCreateAccount: () => {},
  isCreatingAccount: null,
};

export default NewAccountForm;
