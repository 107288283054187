import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';

import { Column } from 'components/GridNew';
import Pagination from 'components/Pagination';
import { handleGetBookings } from 'store/slices/bookingsSlice';
import DashboardEmptyStateTemplate from 'components/DashboardEmptyStateTemplate';
import BookingForm from 'components/Modals/BookingForm/BookingForm';
import noBookingsImg from 'assets/dashboard/no-activity.png';
import { ACTIVITY_DASHBOARD_HEADERS } from 'utils/dashboardHeaders';
import messages from './messages';

import ActivityRow from './ActivityRow';
import ListLoader from 'components/ListLoader';
import { ACTIVITY_TABS, ALL, ACTIVE, UPCOMING, HISTORICAL } from 'utils/tabs';
import { useGetActiveSecondaryTabFromPath, usePrevious } from 'hooks';
import { routes } from 'routes/routes';
import { currentAccountSelector } from 'store/slices/accountsSlice';
import {
  setCurrentBookingId,
  bookingsDataSelector,
  bookingsMetaSelector,
  bookingsSelector,
} from 'store/slices/bookingsSlice';
import {
  DashboardHeaderWrapper,
  DashboardHeaderItem,
  DashboardScrollView,
  StyledDashboardItemWrapper,
  StyledActionText,
  StyledDashboardHeaderItem,
  StyledDashboardHeader,
  StyledDashboardHeaderItemSmall,
} from 'views/Dashboard/styles';

const Activity = () => {
  const dispatch = useDispatch();
  const account = useSelector(currentAccountSelector);
  const activeTab = useGetActiveSecondaryTabFromPath();
  const previousTab = usePrevious(activeTab);
  const previousAccount = usePrevious(account);
  const [isBookingFormOpen, setIsBookingFormOpen] = useState(false);
  const bookingsByTab = useSelector(bookingsSelector);
  const currentAccount = useSelector(currentAccountSelector);
  const bookingsMeta = useSelector((state) =>
    bookingsMetaSelector(state, account.id)
  );
  const bookingsData = useSelector((state) =>
    bookingsDataSelector(state, account.id)
  );

  const promiseRef = useRef({});

  const closeBookingModal = () => setIsBookingFormOpen(false);
  const openBookingModal = () => setIsBookingFormOpen(true);

  useEffect(() => {
    if (
      !!previousAccount?.id &&
      !!account?.id &&
      previousAccount.id !== account.id
    ) {
      if (promiseRef.current) {
        [ALL, ACTIVE, UPCOMING, HISTORICAL].forEach((tab) => {
          promiseRef.current[tab] && promiseRef.current[tab].abort();
        });
      }
    }
  }, [previousAccount, account]);

  useEffect(() => {
    if (!!previousTab && !!activeTab && previousTab !== activeTab) {
      promiseRef.current[activeTab] && promiseRef.current[activeTab].abort();
      promiseRef.current[activeTab] = dispatch(
        handleGetBookings[activeTab]({ accountId: account.id })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, previousTab]);

  const renderConditionalView = useMemo(() => {
    if (!bookingsData?.length && !bookingsByTab?.isLoading) {
      return (
        <DashboardEmptyStateTemplate
          imgSource={noBookingsImg}
          imgAlt={messages.emptyStateHeader}
          header={messages.emptyStateHeader}
          text={messages.emptyStateText}
        >
          <StyledActionText onClick={openBookingModal}>
            {messages.emptyStateButton}
          </StyledActionText>
          {isBookingFormOpen && (
            <BookingForm
              isModalOpen={isBookingFormOpen}
              handleModalToggle={closeBookingModal}
            />
          )}
        </DashboardEmptyStateTemplate>
      );
    } else if (bookingsData?.length) {
      return bookingsData.map((item) => (
        <ActivityRow
          key={item.id}
          id={item.id}
          bookingDetails={item}
          onSetCurrentBooking={() => dispatch(setCurrentBookingId(item.id))}
        />
      ));
    } else {
      return <ListLoader />;
    }
  }, [bookingsByTab, bookingsData, dispatch, isBookingFormOpen]);

  useEffect(() => {
    if (bookingsByTab?.isLoading === false) {
      const timer = setTimeout(() => {
        [ALL, ACTIVE, UPCOMING].forEach((tab) => {
          promiseRef.current[tab] = dispatch(
            handleGetBookings[tab]({
              isAuto: true,
              accountId: account.id,
            })
          );
        });
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [activeTab, bookingsByTab, dispatch, account.id]);

  if (ACTIVITY_TABS.indexOf(activeTab) === -1 || !account.id) {
    return null;
  }

  const onPageChange = (data) => {
    if (promiseRef.current?.[activeTab]) {
      promiseRef.current[activeTab].abort();
    }
    promiseRef.current[activeTab] = dispatch(
      handleGetBookings[activeTab]({
        page: data.selected + 1,
        accountId: account.id,
      })
    );
  };

  return (
    <>
      <StyledDashboardHeaderItem>Your trips</StyledDashboardHeaderItem>
      <StyledDashboardHeader>
        {ACTIVITY_TABS.map((item) => {
          const isActive = item === activeTab || (!activeTab && item === ALL);

          return (
            <StyledDashboardHeaderItemSmall
              key={item}
              to={generatePath(routes.activity, {
                tab: item.replace(/\s+/g, '-').toLowerCase(),
              })}
              $active={isActive}
              disabled={!currentAccount.id}
            >
              {item}
            </StyledDashboardHeaderItemSmall>
          );
        })}
      </StyledDashboardHeader>
      <StyledDashboardItemWrapper data-testid="activity-dashboard" $isActivity>
        <DashboardHeaderWrapper>
          {ACTIVITY_DASHBOARD_HEADERS.map((header) => (
            <Column key={header.data} col={header.col}>
              <DashboardHeaderItem
                $alignRight={header.data === messages.amount}
              >
                {header.data}
              </DashboardHeaderItem>
            </Column>
          ))}
        </DashboardHeaderWrapper>
        <DashboardScrollView
          $isActivity
          $disableScroll={!bookingsData.length && bookingsByTab?.isLoading}
        >
          {renderConditionalView}
        </DashboardScrollView>
        {bookingsMeta?.currentPage && (
          <Pagination
            currentPage={bookingsMeta.currentPage}
            pageCount={bookingsMeta.totalPages}
            onPageChange={onPageChange}
          />
        )}
      </StyledDashboardItemWrapper>
    </>
  );
};

export default memo(Activity);
