import React, { useEffect, useState, useMemo, memo } from 'react';
import isEqual from 'lodash/isEqual';
import GoogleMapReact from 'google-map-react';
import { string, arrayOf } from 'prop-types';
import { usePubNub } from 'pubnub-react';

import curve from 'assets/lotties/curve.json';
import pinShake from 'assets/lotties/pin-shake.json';
import carRipple from 'assets/lotties/car-ripple.json';
import pinHop from 'assets/lotties/pin-hop.json';

import defaultCarImg from 'assets/booking/riide-car.png';
import { locationType } from 'constants/propTypes';
import { StyledCar } from './styles';

import Lottie from 'components/Lottie';
import { STATE_EN_ROUTE } from 'constants/bookingStates';
import {
  degreeToRadian,
  DEFAULT_ZOOM,
  isLatitude,
  isLongitude,
} from 'utils/mapUtils';

const MapWithPubNub = ({
  pickupDetails,
  initialDriverPosition,
  brandedCarImageUrl,
  bookingState,
  pubnubChannels,
}) => {
  const pubnub = usePubNub();
  const [map, setMap] = useState(null);
  const [maps, setMaps] = useState(null);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [degree, setDegree] = useState(0);
  const [lottieOptions, setLottieOptions] = useState({});
  const zoom = map?.getZoom() || DEFAULT_ZOOM;
  const scale = useMemo(() => Math.pow(2, zoom), [zoom]);

  const [driverPosition, setDriverPosition] = useState({
    lat: isLatitude(initialDriverPosition.lat)
      ? initialDriverPosition.lat
      : pickupDetails.lat,
    lng: isLongitude(initialDriverPosition.lng)
      ? initialDriverPosition.lng
      : pickupDetails.lng,
  });

  const markers = useMemo(
    () => [
      {
        lat: driverPosition.lat,
        lng: driverPosition.lng,
      },
      { lat: pickupDetails.lat, lng: pickupDetails.lng },
    ],
    [driverPosition, pickupDetails]
  );

  const handleMessage = (m) => {
    const event = m.message.event;
    const eventData = m.message.payload;
    const bounds = new maps.LatLngBounds();

    if (event === 'gps') {
      const position = {
        lat: eventData.lat,
        lng: eventData.lng,
      };
      if (!isEqual(position, driverPosition)) {
        setDriverPosition(position);
        bounds.extend(new maps.LatLng(position.lat, position.lng));
        bounds.extend(new maps.LatLng(pickupDetails.lat, pickupDetails.lng));
        map.fitBounds(bounds);
      }
    } else if (event === 'booking') {
      if (
        eventData.status === 'PAYMENT' ||
        eventData.status !== 'madecontact'
      ) {
        pubnub.unsubscribe({ channels: pubnubChannels });
      }
    }
  };

  const handleStatus = (statusEvent) => {
    if (statusEvent.category === 'PNConnectedCategory') {
      if (initialDriverPosition) {
        setDriverPosition(initialDriverPosition);
      } else {
        setDriverPosition({
          lat: pickupDetails.lat,
          lng: pickupDetails.lng,
        });
      }
    }
  };

  useEffect(() => {
    if (
      !maps ||
      !map ||
      !pubnubChannels.length ||
      bookingState !== STATE_EN_ROUTE
    )
      return;
    pubnub.addListener({
      message: handleMessage,
      status: handleStatus,
    });
    pubnub.subscribe({ channels: pubnubChannels });
    return () => {
      pubnub.unsubscribe({ channels: pubnubChannels });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingState, map, maps, pubnubChannels]);

  useEffect(() => {
    if (
      maps &&
      map &&
      isLatitude(markers[0]?.lat) &&
      isLatitude(markers[1]?.lat)
    ) {
      const bounds = new maps.LatLngBounds();
      for (let marker of markers) {
        bounds.extend(new maps.LatLng(marker.lat, marker.lng));
      }
      const areaSW = bounds.getSouthWest();
      const areaNE = bounds.getNorthEast();

      const mapProjection = map.getProjection();
      const bottomLeft = mapProjection.fromLatLngToPoint(areaSW);
      const topRight = mapProjection.fromLatLngToPoint(areaNE);

      const deg45ToRad = degreeToRadian(45);
      const finishLongitude = degreeToRadian(markers[1].lng);
      const finishLatitude = degreeToRadian(markers[1].lat);
      const startLongitude = degreeToRadian(markers[0].lng);
      const startLatitude = degreeToRadian(markers[0].lat);

      let angle = -Math.atan2(
        Math.sin(startLongitude - finishLongitude) * Math.cos(finishLatitude),
        Math.cos(startLatitude) * Math.sin(finishLatitude) -
          Math.sin(startLatitude) *
            Math.cos(finishLatitude) *
            Math.cos(startLongitude - finishLongitude)
      );

      if (angle < 0.0) angle += Math.PI * 2.0;
      if (angle === 0) {
        angle = 1.5;
      }
      setDegree(angle - deg45ToRad);

      const startOnTop = markers[0]?.lat > markers[1]?.lat;
      const startOnLeft = markers[0]?.lng < markers[1]?.lng;
      const areaWidth = Math.abs(
        Math.round((bottomLeft.x - topRight.x) * scale)
      );
      const areaHeight = Math.abs(
        Math.round((bottomLeft.y - topRight.y) * scale)
      );

      map.fitBounds(bounds);
      setLottieOptions({
        height: areaHeight,
        width: areaWidth,
        startOnTop,
        startOnLeft,
        squareSize: Math.max(areaWidth, areaHeight),
      });

      if (!animationStarted) {
        setTimeout(() => {
          setAnimationStarted(true);
        }, 1000);
      }
    }
  }, [animationStarted, map, maps, markers, scale]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.REACT_APP_MAPS_API_KEY,
      }}
      center={{ lat: pickupDetails.lat, lng: pickupDetails.lng }}
      defaultCenter={{ lat: pickupDetails.lat, lng: pickupDetails.lng }}
      defaultZoom={DEFAULT_ZOOM}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        setMaps(maps);
        setMap(map);
      }}
      options={{
        disableDefaultUI: true,
        gestureHandling: 'none',
        zoomControl: false,
        draggableCursor: 'default',
      }}
    >
      {pickupDetails && animationStarted && (
        <Lottie
          lat={pickupDetails.lat}
          lng={pickupDetails.lng}
          animationData={bookingState === STATE_EN_ROUTE ? pinShake : pinHop}
          preserveAspectRatio="xMidYMid meet"
          options={{
            squareSize: 100,
            width: 100,
            height: 100,
            margin: 50,
          }}
        />
      )}
      {bookingState === STATE_EN_ROUTE &&
        isLatitude(driverPosition.lat) &&
        isLatitude(pickupDetails.lat) &&
        animationStarted && (
          <Lottie
            lat={
              driverPosition.lat > pickupDetails.lat
                ? driverPosition.lat
                : pickupDetails.lat
            }
            lng={
              driverPosition.lat > pickupDetails.lat
                ? driverPosition.lng
                : pickupDetails.lng
            }
            animationData={curve}
            options={lottieOptions}
          />
        )}
      {isLatitude(driverPosition.lat) && animationStarted && (
        <Lottie
          lat={driverPosition.lat}
          lng={driverPosition.lng}
          animationData={carRipple}
          preserveAspectRatio="xMidYMid meet"
          options={{
            squareSize: 100,
            width: 100,
            height: 100,
            margin: 50,
          }}
        />
      )}
      {isLatitude(driverPosition.lat) && animationStarted && (
        <StyledCar
          lat={driverPosition.lat}
          lng={driverPosition.lng}
          src={brandedCarImageUrl || defaultCarImg}
          alt="driver"
          $degree={degree}
        />
      )}
    </GoogleMapReact>
  );
};

MapWithPubNub.propTypes = {
  pickupDetails: locationType,
  initialDriverPosition: locationType,
  brandedCarImageUrl: string,
  bookingState: string.isRequired,
  pubnubChannels: arrayOf(string),
};

MapWithPubNub.defaultProps = {
  pickupDetails: {
    lat: 0,
    lng: 0,
  },
  initialDriverPosition: {
    lat: 0,
    lng: 0,
  },
  brandedCarImageUrl: '',
  pubnubChannels: [],
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.pickupDetails, nextProps.pickupDetails) &&
  isEqual(prevProps.initialDriverPosition, nextProps.initialDriverPosition) &&
  prevProps.brandedCarImageUrl === nextProps.brandedCarImageUrl &&
  prevProps.bookingState === nextProps.bookingState &&
  isEqual(prevProps.pubnubChannels, nextProps.pubnubChannels);

export default memo(MapWithPubNub, areEqual);
