import React from 'react';
import { Row } from 'components/GridNew';
import { Text } from './styles';

const Copyrights = () => {
  const year = new Date()?.getFullYear?.() || 2021;

  return (
    <Row>
      <Text>© Riide {year}</Text>
    </Row>
  );
};

export default Copyrights;
