import React, { useState, memo } from 'react';
import { oneOfType, number, func, shape, string } from 'prop-types';

import AddUserForm from './AddUserForm';
import AddUserCSV from './AddUserCSV';

const AddUser = ({
  id,
  type,
  userRole,
  firstName,
  lastName,
  email,
  phoneCountry,
  phoneNumber,
  groupId,
  handleModalToggle,
  userRfbPaymentMethodId,
  userId,
  account,
}) => {
  const [isCSVOpen, setCSVOpen] = useState(false);
  const toggleCSV = () => setCSVOpen((isCSVOpen) => !isCSVOpen);

  return (
    <>
      {isCSVOpen ? (
        <AddUserCSV
          toggleCSV={toggleCSV}
          handleModalToggle={handleModalToggle}
        />
      ) : (
        <AddUserForm
          id={id}
          userId={userId}
          userRole={userRole}
          firstName={firstName}
          lastName={lastName}
          email={email}
          phoneCountry={phoneCountry}
          phoneNumber={phoneNumber}
          groupId={groupId}
          handleModalToggle={handleModalToggle}
          account={account}
          type={type}
          userRfbPaymentMethodId={userRfbPaymentMethodId}
          onClickCSV={toggleCSV}
        />
      )}
    </>
  );
};

AddUser.propTypes = {
  id: number,
  type: string.isRequired,
  userRole: string,
  firstName: string,
  lastName: string,
  phoneCountry: string,
  phoneNumber: string,
  handleModalToggle: func.isRequired,
  userRfbPaymentMethodId: number,
  userId: number,
  account: shape({}),
  groupId: oneOfType([string, number]),
};

AddUser.defaultProps = {
  id: null,
  userRole: '',
  firstName: '',
  lastName: '',
  phoneCountry: '',
  phoneNumber: '',
  userRfbPaymentMethodId: null,
  userId: null,
  account: null,
  groupId: null,
};

export default memo(AddUser);
