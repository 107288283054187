import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { bool, shape, string, func } from 'prop-types';

import AddUser from 'components/Modals/AddUser';
import DeleteUser from 'components/Modals/DeleteUser';
import PendingMemberNotice from 'components/Modals/PendingMemberNotice';
import Modal from 'components/Modal';
import { Row } from 'components/Grid';
import Select from 'components/Select';
import SearchInput from 'components/SearchInput';

import { accountRoleFilterType } from 'constants/propTypes';
import { ROLE_OPTIONS } from 'constants/accountUserRoles';
import { clearMemberInvited, setRoleFilter } from 'store/slices/accountsSlice';
import { setIsAddGroupView } from 'store/slices/groupsSlice';
import { StyledActionText } from 'views/Dashboard/styles';

import styles from './UserFilters.module.scss';
import messages from './messages';

const UserFilters = ({
  accountRoleFilter,
  isRoleDisabled,
  account,
  displaySearchInput,
  displayBulkActions,
  displayAddUserButton,
  displayAddGroupButton,
  displayRoleSelect,
  searchQuery,
  onSearch,
  minSearchWidth,
  isSearchDisabled,
}) => {
  const dispatch = useDispatch();
  const pendingMember = useSelector((state) => state.accounts.pendingMember);
  const invited = useSelector((state) => state.accounts.members.invited);
  const membersToDelete = useSelector(
    (state) => state.accounts.membersToDelete
  );

  const [bulkAction, selectBulkAction] = useState({ value: '' });
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (bulkAction.value === 'delete' && !!membersToDelete.length) {
      openDeleteModal();
      selectBulkAction({ value: '' });
    }
  }, [bulkAction.value, membersToDelete]);

  const clearAfterInvite = useCallback(() => {
    if (invited) {
      dispatch(clearMemberInvited());
      if (!pendingMember.id) {
        closeAddUserModal();
      }
    }
  }, [invited, pendingMember.id, dispatch]);

  useEffect(() => {
    clearAfterInvite();
  }, [clearAfterInvite]);

  const closeDeleteModal = () => setDeleteModalOpen(false);
  const openDeleteModal = () => setDeleteModalOpen(true);

  const closeAddUserModal = () => setAddUserModalOpen(false);
  const openAddUserModal = () => setAddUserModalOpen(true);

  const handleSetRoleFilter = (item) => {
    dispatch(setRoleFilter(item));
  };

  const onClickAddGroup = () => {
    dispatch(setIsAddGroupView(true));
  };

  return (
    <>
      <Row className={styles.UserFiltersWrapper}>
        {displayRoleSelect && (
          <div className={styles.UserFilterWrapper}>
            <Select
              placeholder={messages.usersFilter.placeholder}
              options={ROLE_OPTIONS}
              onChange={handleSetRoleFilter}
              value={
                isRoleDisabled
                  ? messages.usersFilter.placeholder
                  : accountRoleFilter
              }
              isDisabled={isRoleDisabled}
            />
          </div>
        )}
        {displaySearchInput && (
          <SearchInput
            defaultValue={searchQuery}
            onSearch={onSearch}
            minWidth={minSearchWidth}
            isDisabled={isSearchDisabled}
          />
        )}
        {displayBulkActions && (
          <>
            <div className={styles.UserFilterWrapper}>
              <div data-testid="user-dashboard-actions-dropdown">
                <Select
                  placeholder={messages.usersActions.placeholder}
                  options={[
                    {
                      value: 'delete',
                      label: messages.usersActions.delete,
                    },
                  ]}
                  onChange={(action) => selectBulkAction(action)}
                  value={bulkAction.value ? bulkAction : null}
                  isDisabled={!membersToDelete.length}
                />
              </div>
            </div>
            <Modal
              handleModalToggle={closeDeleteModal}
              isModalOpen={isDeleteModalOpen}
              modalStyle="addOrEditUserModal"
            >
              <DeleteUser
                membersToDelete={membersToDelete}
                handleModalToggle={closeDeleteModal}
              />
            </Modal>
          </>
        )}
        {displayAddUserButton && (
          <>
            <Row end>
              <StyledActionText $bold onClick={openAddUserModal}>
                {messages.addUserButton}
              </StyledActionText>
            </Row>
            <Modal
              handleModalToggle={closeAddUserModal}
              isModalOpen={isAddUserModalOpen}
              modalStyle="addOrEditUserModal"
            >
              {pendingMember?.id ? (
                <PendingMemberNotice
                  firstName={pendingMember.first_name}
                  onCancel={closeAddUserModal}
                />
              ) : (
                <AddUser
                  account={account}
                  type="add"
                  handleModalToggle={closeAddUserModal}
                />
              )}
            </Modal>
          </>
        )}
        {displayAddGroupButton && (
          <>
            <Row end>
              <StyledActionText $bold onClick={onClickAddGroup}>
                {messages.addGroupButton}
              </StyledActionText>
            </Row>
          </>
        )}
      </Row>
    </>
  );
};

UserFilters.propTypes = {
  isRoleDisabled: bool,
  accountRoleFilter: accountRoleFilterType,
  account: shape({}),
  searchQuery: string,
  displaySearchInput: bool,
  onSearch: func,
  displayBulkActions: bool,
  displayAddUserButton: bool,
  displayAddGroupButton: bool,
  displayRoleSelect: bool,
  minSearchWidth: string,
  isSearchDisabled: bool,
};
UserFilters.defaultProps = {
  isRoleDisabled: true,
  accountRoleFilter: ROLE_OPTIONS[0],
  account: null,
  searchQuery: null,
  onSearch: () => null,
  displaySearchInput: true,
  displayBulkActions: true,
  displayAddUserButton: true,
  displayAddGroupButton: false,
  displayRoleSelect: true,
  isSearchDisabled: false,
};

export default UserFilters;
