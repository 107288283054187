import React, { memo } from 'react';
import { arrayOf, shape } from 'prop-types';
import _isEqual from 'lodash/isEqual';

import DashboardEmptyStateTemplate from 'components/DashboardEmptyStateTemplate';
import noUsersImg from 'assets/dashboard/no-users.png';
import messages from './messages';
import ListLoader from 'components/ListLoader';
import { GROUP_COLUMNS } from 'utils/dashboardHeaders';
import GroupRow from './GroupRow';

const GroupList = ({ data, isLoading }) => {
  if (isLoading && !data.length) {
    return (
      <ListLoader
        length={2}
        cols={GROUP_COLUMNS}
        data={['General', '0 Users']}
      />
    );
  } else if (!data.length && !isLoading) {
    return (
      <DashboardEmptyStateTemplate
        imgSource={noUsersImg}
        imgAlt={messages.emptyHeader}
        header={messages.emptyHeader}
        text={messages.emptySubtitle}
      />
    );
  }

  return data?.map((group, idx) => (
    <GroupRow key={group.id} group={group} isDefault={idx === 0} />
  ));
};

GroupList.propTypes = {
  data: arrayOf(shape({})),
};

GroupList.defaultProps = {
  data: [],
};
const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.data, nextProps.data);

export default memo(GroupList, areEqual);
