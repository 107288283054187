import React, { createRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'components/Grid';
import Input from 'components/Input';
import parsePhoneNumber from 'libphonenumber-js';
import ResendCode from 'components/ResendCode';
import { codeStep as messages } from './messages';
import { StyledSubtitle, StyledHeader } from 'views/Login/styles';
import Root from './CodeStep.style';

const CodeStep = ({
  values,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  phone = '',
  onClickResend,
  userId,
  setFieldTouched,
  codeLength,
}) => {
  const inputReferenceArray = useMemo(() => {
    return new Array(codeLength).fill(null).map(() => createRef());
  }, [codeLength]);

  const {
    code_secret_1,
    code_secret_2,
    code_secret_3,
    code_secret_4,
    code_secret_5,
    code_secret_6,
  } = values;
  const valuesArray = [
    code_secret_1,
    code_secret_2,
    code_secret_3,
    code_secret_4,
    code_secret_5,
    code_secret_6,
  ];

  const onKeyPress = (e, codeIndex) => {
    let key = Number(e.key);
    if (e.key === 'Backspace') {
      codeIndex > 0 && inputReferenceArray[codeIndex - 1].current.focus();
      setFieldValue(e.target.name, '');
    } else if (Number.isNaN(key) || e.key === null || e.key === ' ') {
      return;
    } else {
      codeIndex < codeLength - 1 &&
        inputReferenceArray[codeIndex + 1].current.focus();
      setFieldValue(e.target.name, e.key);
    }
    setFieldTouched(e.target.name, false);
  };

  const formattedNumber = useMemo(
    () => (phone ? parsePhoneNumber(phone)?.formatInternational() : ''),
    [phone]
  );

  return (
    <Root>
      <StyledHeader>
        {messages.header}
        <br />
        {formattedNumber ? formattedNumber : '+44 12345 12340'}
      </StyledHeader>
      <StyledSubtitle>
        <ResendCode onClickResend={onClickResend} userId={userId} />
      </StyledSubtitle>
      <div>
        <Row between>
          {inputReferenceArray.map((inputRef, index) => {
            return (
              <Col key={index} col={2}>
                <Input
                  autoFocus={index === 0}
                  noErrorMessage
                  inputReference={inputRef}
                  bgWhite
                  big
                  errors={errors}
                  touched={touched}
                  onChange={() => null}
                  onKeyDown={(e) => onKeyPress(e, index)}
                  onBlur={handleBlur}
                  type="text"
                  name={`code_secret_${index + 1}`}
                  value={valuesArray[index]}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </Root>
  );
};

CodeStep.propTypes = {
  codeLength: PropTypes.number,
};

CodeStep.defaultProps = {
  codeLength: 6,
};

export default CodeStep;
