import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, generatePath } from 'react-router-dom';

import { string, array, bool } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import EditCard from 'components/EditCard';
import { routes } from 'routes/routes';
import {
  useGetActivePrimaryTabFromPath,
  useGetActiveSecondaryTabFromPath,
} from 'hooks';

import DashboardEmptyStateTemplate from 'components/DashboardEmptyStateTemplate';
import noPaymentMethodsImg from 'assets/dashboard/no-cards.png';
import BillingHeader from './BillingHeader';
import BillingRow from './BillingRow';
import PersonalCard from './PersonalCard';
import {
  BILLING,
  BILLING_DASHBOARD_TABS,
  INVOICE_BILLING_DASHBOARD_TABS,
} from 'utils/tabs';
import { PAYMENT_METHODS } from 'constants/paymentMethods';
import { messages } from './messages';
import {
  setDefaultPaymentMethod,
  getCards,
  updateAccountCard,
  setCardForBusinessUse,
  availablePaymentMethodOptionsSelector,
  getPaymentMethods,
  currentAccountSelector,
} from 'store/slices/accountsSlice';
import { companyEmailSelector } from 'store/slices/companySlice';
import {
  StyledDashboardItemWrapper,
  DashboardScrollView,
  StyledDashboardHeader,
  StyledDashboardHeaderItemSmall,
} from 'views/Dashboard/styles';

const Billing = ({
  appCompanyName,
  cards,
  paymentMethods,
  isCreatingAccount,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector(currentAccountSelector);
  const activePrimaryTab = useGetActivePrimaryTabFromPath();
  const activeSecondaryTab = useGetActiveSecondaryTabFromPath();
  const availablePaymentMethods = useSelector(
    availablePaymentMethodOptionsSelector
  );
  const companyEmail = useSelector(companyEmailSelector);
  const isNotRfbAccount = account?.rfbDefaultPayment?.isNotRfb;
  const accountPaymentMethod = account?.rfbDefaultPayment;
  const isCardAccount =
    accountPaymentMethod?.type === PAYMENT_METHODS.card && isNotRfbAccount;
  const [paymentId, setPaymentId] = useState(null);
  const [selectedCard, setSelectedCard] = useState({});
  const [editCard, setEditCard] = useState({});

  const isPaymentMethodTab = activeSecondaryTab === BILLING_DASHBOARD_TABS[0];
  const isPersonalCardsTab = activeSecondaryTab === BILLING_DASHBOARD_TABS[1];
  const accountCard = isNotRfbAccount
    ? accountPaymentMethod
    : paymentMethods.find((method) => !!method.card);
  const currentAccount = useSelector(currentAccountSelector);

  const isInvoiceAccount =
    currentAccount?.rfbDefaultPayment?.type === PAYMENT_METHODS.invoice;
  const billingTabs = isInvoiceAccount
    ? INVOICE_BILLING_DASHBOARD_TABS
    : BILLING_DASHBOARD_TABS;

  useEffect(() => {
    if (!isNotRfbAccount) {
      dispatch(getPaymentMethods({ account }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Redirect user to an available tab if current tab is not permitted
  useEffect(() => {
    if (
      activePrimaryTab === BILLING &&
      billingTabs.indexOf(activeSecondaryTab) < 0
    ) {
      const redirectPath = generatePath(
        routes[activePrimaryTab.toLowerCase()],
        {
          tab: billingTabs[0].replace(/\s/g, '-').toLowerCase(),
        }
      );

      history.replace(redirectPath);
    }
  }, [billingTabs, activeSecondaryTab, history, activePrimaryTab]);

  const dispatchGetCards = useCallback(() => {
    if (isPersonalCardsTab) {
      dispatch(getCards());
    }
  }, [dispatch, isPersonalCardsTab]);

  useEffect(() => {
    dispatchGetCards();
  }, [dispatchGetCards]);

  const handleSetPayment = (id) => {
    if (id === paymentId) {
      setPaymentId(null);
    } else {
      setPaymentId(id);
    }
  };

  const handleSetCard = (card) => {
    if (card.id === selectedCard.id) {
      setSelectedCard({});
    } else {
      setSelectedCard(card);
    }
  };

  const handleSetDefaultPaymentMethod = (accountId, paymentId) => {
    dispatch(setDefaultPaymentMethod({ accountId, paymentId }));
    setPaymentId(null);
  };

  const handleSelectCardForBusiness = (cardId, name) => {
    if (isCardAccount) {
      dispatch(updateAccountCard({ cardId, account }));
    } else {
      dispatch(setCardForBusinessUse({ cardId, account, name }));
    }
    setSelectedCard({});
  };

  if (
    BILLING_DASHBOARD_TABS.indexOf(activeSecondaryTab) === -1 ||
    !account.id
  ) {
    return null;
  }

  const renderPersonalCards = () => {
    if (paymentMethods && account) {
      if (cards?.length) {
        return (
          <>
            {cards.map((card) => (
              <PersonalCard
                onClickEdit={() => {
                  setEditCard(card);
                }}
                key={card.id}
                card={card}
                setSelectedCard={handleSetCard}
                selectedCardId={selectedCard.id}
                accountCardId={accountCard?.card?.id}
              />
            ))}
            {!!editCard.id && (
              <EditCard
                card={editCard}
                handleModalClose={() => setEditCard({})}
              />
            )}
          </>
        );
      } else {
        return (
          <DashboardEmptyStateTemplate
            imgSource={noPaymentMethodsImg}
            imgAlt={messages.emptyStateCardsHeader}
            header={messages.emptyStateCardsHeader}
          />
        );
      }
    }
    return null;
  };

  const renderBillingRows = () => {
    if (paymentMethods && account) {
      if (!isNotRfbAccount) {
        if (!paymentMethods.length) {
          return (
            <DashboardEmptyStateTemplate
              imgSource={noPaymentMethodsImg}
              imgAlt={messages.emptyStatePaymentMethodsHeader}
              header={messages.emptyStatePaymentMethodsHeader}
              text={messages.emptyStatePaymentMethodsText}
            />
          );
        }
        return paymentMethods.map((item) => {
          return (
            <BillingRow
              paymentId={paymentId}
              key={item.id}
              item={item}
              account={account}
              setPaymentId={handleSetPayment}
              companyEmail={companyEmail}
              companyName={appCompanyName}
              isCreatingAccount={isCreatingAccount}
            />
          );
        });
      } else {
        if (isCardAccount) {
          return (
            <BillingRow
              paymentId={paymentId}
              key={account.rfbDefaultPayment?.card?.id}
              item={account.rfbDefaultPayment}
              account={account}
              setPaymentId={handleSetPayment}
            />
          );
        } else {
          return !isEmpty(account) ? (
            <BillingRow
              paymentId={paymentId}
              item={account.rfbDefaultPayment}
              setPaymentId={handleSetPayment}
              account={account}
              companyEmail={companyEmail}
              companyName={appCompanyName}
              isCreatingAccount={isCreatingAccount}
            />
          ) : null;
        }
      }
    }
    return null;
  };

  return (
    <>
      <StyledDashboardHeader>
        {billingTabs.map((item) => {
          const isActive =
            item === activeSecondaryTab ||
            (!activeSecondaryTab && item === BILLING_DASHBOARD_TABS[0]);

          return (
            <StyledDashboardHeaderItemSmall
              to={generatePath(routes.billing, {
                tab: item.replace(/\s+/g, '-').toLowerCase(),
              })}
              key={item}
              $active={isActive}
            >
              {item}
            </StyledDashboardHeaderItemSmall>
          );
        })}
      </StyledDashboardHeader>
      <StyledDashboardItemWrapper
        $hasStaticHeight
        data-testid="billing-dashboard"
      >
        <BillingHeader
          account={account}
          paymentId={paymentId}
          availablePaymentMethods={availablePaymentMethods}
          isPersonalCardsTab={isPersonalCardsTab}
          setDefaultPayment={handleSetDefaultPaymentMethod}
          setCardForBusiness={handleSelectCardForBusiness}
          selectedCard={selectedCard}
          appCompanyName={appCompanyName}
          isCreatingAccount={isCreatingAccount}
        />
        <DashboardScrollView>
          {isPersonalCardsTab && renderPersonalCards()}
          {isPaymentMethodTab && renderBillingRows()}
        </DashboardScrollView>
      </StyledDashboardItemWrapper>
    </>
  );
};

Billing.propTypes = {
  cards: array,
  appCompanyName: string.isRequired,
  paymentMethods: array,
  isCreatingAccount: bool,
};
Billing.defaultProps = {
  cards: [],
  paymentMethods: [],
  isCreatingAccount: null,
};

export default Billing;
