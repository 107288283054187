import React, { useState, useMemo, memo } from 'react';
import { func, object } from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';

import { Column } from 'components/GridNew';
import { statusDictionary } from 'utils/statusDictionary';
import BookingForm from 'components/Modals/BookingForm';

import { getDateInTimeZoneWithFormat } from 'utils/dateTime';
import {
  STATE_COMPLETED,
  CANCELLED_BOOKING_STATES,
} from 'constants/bookingStates';
import { checkLastName } from 'constants/user';
import {
  ActivityItemWrapper,
  StyledDashboardRow,
} from 'views/Dashboard/styles';
import { ACTIVITY_DASHBOARD_COLUMNS } from 'utils/dashboardHeaders';
import ActivityExpanded from './ActivityExpanded';

import { currentBookingSelector } from 'store/slices/bookingsSlice';

const ActivityRow = ({ bookingDetails, onSetCurrentBooking }) => {
  const {
    state: bookingState,
    payment,
    company,
    options,
    created_at,
    pickup_at,
    pickup,
    user_cancelled_at,
    completed_at,
    username,
  } = useMemo(() => bookingDetails, [bookingDetails]);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const { data: currentBooking } = useSelector(currentBookingSelector);

  const onClickEditOrView = () => {
    onSetCurrentBooking();
    setIsBookingModalOpen(true);
  };

  const [isExpanded, setExpanded] = useState(false);
  const timezone = useMemo(() => company?.timezone, [company]);
  const passengerName = useMemo(
    () =>
      (payment?.account?.account_user?.first_name
        ? `${payment.account.account_user.first_name} ${checkLastName(
            payment.account.account_user.last_name
          )}`
        : username) || '-',
    [payment.account, username]
  );
  const { can_cancel: canCancel, can_update_payment: canEdit } = useMemo(
    () => options || {},
    [options]
  );
  const showEstimate = useMemo(
    () =>
      [STATE_COMPLETED, ...CANCELLED_BOOKING_STATES].indexOf(bookingState) ===
      -1,
    [bookingState]
  );

  const bookingDate = useMemo(() => {
    return user_cancelled_at || completed_at || pickup_at || created_at;
  }, [completed_at, created_at, pickup_at, user_cancelled_at]);

  const bookingTime = useMemo(
    () =>
      bookingDate
        ? getDateInTimeZoneWithFormat(bookingDate, timezone, 'HH:mm')
        : '--:--',
    [bookingDate, timezone]
  );
  const closeBookingModal = () => setIsBookingModalOpen(false);

  const handleToggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  const ACTIVITY_DASHBOARD_DATA = useMemo(
    () => [
      bookingDate
        ? getDateInTimeZoneWithFormat(bookingDate, timezone, 'dd-MMM-yyyy')
        : '-- / -- / ----',
      bookingTime,
      passengerName,
      pickup.address,
      statusDictionary(bookingState),
      !payment.total_price ? (
        '-.-'
      ) : (
        <>
          {showEstimate && 'est. '}
          {payment.currency?.label}
          {payment.total_price.toFixed(2)}
        </>
      ),
    ],
    [
      bookingDate,
      bookingState,
      bookingTime,
      passengerName,
      payment.currency.label,
      payment.total_price,
      pickup.address,
      showEstimate,
      timezone,
    ]
  );

  const ACTIVITY_DASHBOARD_ITEMS = useMemo(
    () =>
      ACTIVITY_DASHBOARD_COLUMNS.map((col, idx) => ({
        index: idx + 1,
        col,
        data: ACTIVITY_DASHBOARD_DATA[idx],
      })),
    [ACTIVITY_DASHBOARD_DATA]
  );

  return (
    <>
      <StyledDashboardRow
        data-testid="activity-row"
        onClick={handleToggleExpanded}
      >
        {ACTIVITY_DASHBOARD_ITEMS.map((item) => (
          <Column key={item.index} col={item.col}>
            <ActivityItemWrapper $hasTextAlignRight={item.index === 6}>
              {item.data}
            </ActivityItemWrapper>
          </Column>
        ))}
      </StyledDashboardRow>
      {isExpanded && (
        <ActivityExpanded
          bookingDetails={bookingDetails}
          passengerName={passengerName}
          bookingTime={bookingTime}
          timezone={timezone}
          canCancel={canCancel}
          canEdit={canEdit}
          onClickEditOrView={onClickEditOrView}
        />
      )}
      {isBookingModalOpen && (
        <BookingForm
          isModalOpen={isBookingModalOpen}
          bookingDetails={currentBooking}
          handleModalToggle={closeBookingModal}
        />
      )}
    </>
  );
};

ActivityRow.propTypes = {
  bookingDetails: object.isRequired,
  onSetCurrentBooking: func.isRequired,
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.bookingDetails, nextProps.bookingDetails);

export default memo(ActivityRow, areEqual);
