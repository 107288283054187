import React from 'react';
import { string } from 'prop-types';

import { Wrapper, Link, Text } from './styles';

const TextWithLink = ({ link, to, text, testId }) => (
  <Wrapper data-testid={testId}>
    <Text>{text}</Text>
    <Link to={to}>{link}</Link>
  </Wrapper>
);

TextWithLink.propTypes = {
  text: string,
  link: string,
  to: string,
  testId: string,
};
TextWithLink.defaultProps = {
  testId: 'text-with-link',
};

export default TextWithLink;
