import React from 'react';

import CodeStep from 'views/SignUp/SignupSteps/CodeStep';
import PasswordStep from 'views/SignUp/SignupSteps/PasswordStep';
import { passwordStep as messages } from './messages';

const PasswordOrCodeStep = ({
  values,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlur,
  errors,
  touched,
  userId,
  phone,
  onClickResend,
}) => {
  return values.is_email ? (
    <PasswordStep
      values={values}
      handleChange={handleChange}
      handleBlur={handleBlur}
      errors={errors}
      touched={touched}
      header={messages.enterHeader}
      email={values.email}
      isLogin
    />
  ) : (
    <CodeStep
      values={values}
      handleBlur={handleBlur}
      errors={errors}
      touched={touched}
      setFieldValue={setFieldValue}
      phone={phone}
      onClickResend={onClickResend}
      userId={userId}
      setFieldTouched={setFieldTouched}
    />
  );
};

export default PasswordOrCodeStep;
