import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _isEqual from 'lodash/isEqual';
import _orderBy from 'lodash/orderBy';
import {
  getGroupsRequest,
  createGroupRequest,
  updateGroupRequest,
  deleteGroupRequest,
} from 'api/groups';

export const initialState = {
  isLoading: null,
  isSaving: null,
  isAddGroupView: false,
  current: null,
  idsByAccountId: {},
  byAccountId: {},
};

export const getGroups = createAsyncThunk(
  'groups/getGroups',
  async (_accountId, { getState, rejectWithValue, dispatch }) => {
    try {
      const accountId = _accountId || getState().accounts.data.current.id;
      const { data } = await getGroupsRequest(accountId);

      return { data, accountId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createGroup = createAsyncThunk(
  'groups/createGroup',
  async (payload, { getState, rejectWithValue, dispatch }) => {
    try {
      const accountId = getState().accounts.data.current.id;
      const { data } = await createGroupRequest(accountId, payload);

      return { data, accountId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateGroup = createAsyncThunk(
  'groups/updateGroup',
  async ({ id, payload }, { getState, rejectWithValue, dispatch }) => {
    try {
      const accountId = getState().accounts.data.current.id;
      const { data } = await updateGroupRequest(id, payload);

      return { data, accountId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteGroup = createAsyncThunk(
  'groups/deleteGroup',
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const accountId = getState().accounts.data.current.id;
      await deleteGroupRequest(id);

      return { id, accountId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getGroupsLoading = (state) => {
  state.isLoading = true;
};
const createGroupLoading = (state) => {
  state.isSaving = true;
};
const createGroupFailed = (state) => {
  state.isSaving = false;
};
const getGroupsFailed = (state) => {
  state.isLoading = false;
};
const getGroupsFulfilled = (state, { payload }) => {
  state.isLoading = false;
  const { accountId, data } = payload;

  if (!accountId) return;

  const ids = _map(data, 'id');
  const byId = _keyBy(data, 'id');

  if (!_isEqual(state.idsByAccountId[accountId], ids)) {
    state.idsByAccountId[accountId] = ids;
  }
  if (!_isEqual(state.byAccountId[accountId], byId)) {
    state.byAccountId[accountId] = byId;
  }
};
const createGroupFulfilled = (state, { payload }) => {
  state.isSaving = false;
  const { accountId, data } = payload;

  state.byAccountId[accountId][data.id] = data;
  state.isAddGroupView = false;
  state.current = null;
};
const deleteGroupFulfilled = (state, { payload }) => {
  state.isLoading = false;
  const { accountId, id } = payload;
  delete state.byAccountId[accountId][id];
  state.idsByAccountId[accountId] = state.idsByAccountId[accountId].filter(
    (item) => item !== id
  );
};

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setIsAddGroupView: (state, { payload }) => {
      state.isAddGroupView = payload;
    },
    setCurrentGroup: (state, { payload }) => {
      state.current = payload;
    },
    setGroupState: (state, { payload }) => {
      state.current = payload.current;
      state.isAddGroupView = payload.isAddGroupView;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroups.pending, getGroupsLoading);
    builder.addCase(createGroup.pending, createGroupLoading);
    builder.addCase(updateGroup.pending, createGroupLoading);
    builder.addCase(deleteGroup.pending, getGroupsLoading);

    builder.addCase(getGroups.fulfilled, getGroupsFulfilled);
    builder.addCase(createGroup.fulfilled, createGroupFulfilled);
    builder.addCase(updateGroup.fulfilled, createGroupFulfilled);
    builder.addCase(deleteGroup.fulfilled, deleteGroupFulfilled);

    builder.addCase(getGroups.rejected, getGroupsFailed);
    builder.addCase(createGroup.rejected, createGroupFailed);
    builder.addCase(updateGroup.rejected, createGroupFailed);
    builder.addCase(deleteGroup.rejected, getGroupsFailed);
  },
});

export const {
  setIsAddGroupView,
  setCurrentGroup,
  setGroupState,
} = groupsSlice.actions;

export default groupsSlice.reducer;

export const groupsDataSelector = (state) => {
  const accountId = state.accounts.data.current.id;

  if (!accountId) return [];
  if (
    state.groups.idsByAccountId[accountId] &&
    state.groups.byAccountId[accountId]
  ) {
    return _orderBy(
      state.groups.idsByAccountId[accountId].map(
        (item) => state.groups.byAccountId[accountId][item]
      ),
      'id'
    );
  } else {
    return [];
  }
};

export const currentGroupSelector = (state) => state.groups?.current;
