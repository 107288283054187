import { createSlice } from '@reduxjs/toolkit';
import {
  ALL,
  ACTIVITY,
  TEAM,
  TEAMMATES,
  BILLING,
  PAYMENT_METHODS,
} from 'utils/tabs';

export const initialState = {
  activeSecondaryTab: ALL,
  activePrimaryTab: ACTIVITY,
};

const tabsSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveSecondaryTab(state, { payload }) {
      if (state.activeSecondaryTab !== payload) {
        state.activeSecondaryTab = payload;
      }
    },
    setActivePrimaryTab(state, { payload }) {
      if (state.activePrimaryTab !== payload) {
        state.activePrimaryTab = payload;
        switch (payload) {
          case ACTIVITY:
            state.activeSecondaryTab = ALL;
            break;
          case TEAM:
            state.activeSecondaryTab = TEAMMATES;
            break;
          case BILLING:
            state.activeSecondaryTab = PAYMENT_METHODS;
            break;
          default:
            break;
        }
      }
    },
  },
});

export const { setActiveSecondaryTab, setActivePrimaryTab } = tabsSlice.actions;

export default tabsSlice.reducer;
