import React from 'react';
import { number, bool, node, string, shape } from 'prop-types';

import { StyledColumn } from './styles';

const Column = ({
  col,
  children,
  withMarginRight,
  withMarginForInput,
  spaceBetween,
  margin,
  style,
}) => (
  <StyledColumn
    data-testid="column"
    $col={col}
    $spaceBetween={spaceBetween}
    $withMarginRight={withMarginRight}
    $withMarginForInput={withMarginForInput}
    $margin={margin}
    $style={style}
  >
    {children}
  </StyledColumn>
);

Column.propTypes = {
  col: number,
  withMarginRight: bool,
  withMarginForInput: bool,
  children: node,
  margin: string,
  style: shape({}),
};
Column.defaultProps = {
  col: 12,
  withMarginRight: false,
  withMarginForInput: false,
  margin: null,
  style: null,
};
export default Column;
