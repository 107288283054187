import React, { useState, useEffect, useMemo, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import parsePhoneNumber from 'libphonenumber-js';
import { string, number, bool, shape } from 'prop-types';
import _isEqual from 'lodash/isEqual';
import { Column } from 'components/GridNew';
import Checkbox from 'components/Checkbox';
import { accountType } from 'constants/propTypes';
import {
  ACCOUNT_ROLE_OWNER,
  ACCOUNT_ROLE_ADMIN,
} from 'constants/accountUserRoles';

import UserActionButtons from '../UserActionButtons';
import messages from './messages';
import { getResendInvitationEnabled } from './utils';
import { setMembersToDelete, inviteMember } from 'store/slices/accountsSlice';
import { StyledItemName } from 'views/Team/styles';
import { StyledDashboardRow, StyledActionText } from 'views/Dashboard/styles';
import { TEAM_COLUMNS } from 'utils/dashboardHeaders';
import { green } from 'utils/variables';

const UserRow = ({
  id,
  role,
  group,
  firstName,
  lastName,
  isPending,
  phoneCountry,
  phoneNumber,
  email,
  account,
  invitedAt,
  userRfbPaymentMethodId,
  userId,
}) => {
  const dispatch = useDispatch();
  const membersToDelete = useSelector(
    (state) => state.accounts.membersToDelete
  );

  const [hasResendInvitationEnabled, setHasResendInvitationEnabled] = useState(
    getResendInvitationEnabled(invitedAt)
  );

  useEffect(() => {
    setHasResendInvitationEnabled(getResendInvitationEnabled(invitedAt));
  }, [invitedAt]);

  const roleCapitalized = useMemo(
    () => (role ? role.charAt(0).toUpperCase() + role.slice(1) : 'User'),
    [role]
  );
  const isMemberSelected = useMemo(
    () =>
      membersToDelete?.findIndex((user) => user.id === id && !user.isSingle) >
      -1,
    [id, membersToDelete]
  );

  const canBeSelectedForDelete =
    role !== ACCOUNT_ROLE_OWNER && role !== ACCOUNT_ROLE_ADMIN;

  const TEAM_DATA = useMemo(() => {
    const handleResendInvitation = () =>
      dispatch(
        inviteMember({
          account: account,
          values: {
            first_name: firstName,
            last_name: lastName,
            phone_country: phoneCountry,
            phone_number: phoneNumber,
            rfb_payment_method_id: account.rfbDefaultPayment.id,
            type: role,
          },
        })
      ).then((res) => {
        setHasResendInvitationEnabled(res.status !== 201);
      });

    return [
      canBeSelectedForDelete ? (
        <Checkbox
          onChange={() => {
            dispatch(setMembersToDelete({ id, firstName, lastName }));
          }}
          checked={isMemberSelected}
        />
      ) : null,
      <StyledItemName
        $isPale={isPending}
        $hasBoldText
      >{`${firstName} ${lastName}`}</StyledItemName>,
      <StyledItemName $isPale={isPending}>{email || '-'}</StyledItemName>,
      <StyledItemName $isPale={isPending}>
        {phoneCountry && phoneNumber
          ? parsePhoneNumber(
              `+${phoneCountry}${phoneNumber}`
            )?.formatInternational()
          : '-'}
      </StyledItemName>,
      <StyledItemName $isPale={isPending}>{roleCapitalized}</StyledItemName>,
      <StyledItemName $isPale={isPending}>{group?.name || '-'}</StyledItemName>,
      isPending && invitedAt && (
        <>
          {hasResendInvitationEnabled ? (
            <StyledActionText onClick={() => handleResendInvitation()}>
              {messages.resendInviteMsg}
            </StyledActionText>
          ) : (
            <StyledActionText $color={green}>
              {messages.inviteSentMsg}
            </StyledActionText>
          )}
        </>
      ),
      <UserActionButtons
        id={id}
        role={role}
        userId={userId}
        firstName={firstName}
        lastName={lastName}
        phoneNumber={phoneNumber}
        phoneCountry={phoneCountry}
        groupId={group?.id}
        email={email}
        userRfbPaymentMethodId={userRfbPaymentMethodId}
        setMembersToDelete={(item) => dispatch(setMembersToDelete(item))}
        membersToDelete={membersToDelete}
        account={account}
      />,
    ];
  }, [
    account,
    canBeSelectedForDelete,
    dispatch,
    email,
    firstName,
    group,
    hasResendInvitationEnabled,
    id,
    invitedAt,
    isMemberSelected,
    isPending,
    lastName,
    membersToDelete,
    phoneCountry,
    phoneNumber,
    role,
    roleCapitalized,
    userId,
    userRfbPaymentMethodId,
  ]);

  const TEAM_ITEMS = useMemo(
    () =>
      TEAM_COLUMNS.map((col, idx) => ({
        index: idx + 1,
        col,
        data: TEAM_DATA[idx],
      })),
    [TEAM_DATA]
  );

  return (
    <StyledDashboardRow data-testid="user-row">
      {TEAM_ITEMS.map((item) => (
        <Column key={item.index} col={item.col}>
          {item.data}
        </Column>
      ))}
    </StyledDashboardRow>
  );
};

UserRow.propTypes = {
  id: number,
  role: string,
  group: shape({}),
  firstName: string,
  lastName: string,
  isPending: bool,
  phoneCountry: string,
  phoneNumber: string,
  account: shape(accountType).isRequired,
  invitedAt: string,
  userRfbPaymentMethodId: number,
  userId: number,
  email: string,
};

UserRow.defaultProps = {
  group: {},
  invitedAt: '',
  phoneCountry: '',
  phoneNumber: '',
  userRfbPaymentMethodId: null,
  id: null,
  role: '',
  firstName: '',
  lastName: '',
  isPending: false,
  userId: null,
  email: '',
};

const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.account, nextProps.account) &&
  _isEqual(prevProps.group, nextProps.group) &&
  prevProps.id === nextProps.id &&
  prevProps.role === nextProps.role &&
  prevProps.firstName === nextProps.firstName &&
  prevProps.lastName === nextProps.lastName &&
  prevProps.isPending === nextProps.isPending &&
  prevProps.phoneCountry === nextProps.phoneCountry &&
  prevProps.phoneNumber === nextProps.phoneNumber &&
  prevProps.invitedAt === nextProps.invitedAt &&
  prevProps.userRfbPaymentMethodId === nextProps.userRfbPaymentMethodId &&
  prevProps.userId === nextProps.userId &&
  prevProps.email === nextProps.email;

export default memo(UserRow, areEqual);
