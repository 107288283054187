import React from 'react';
import styles from './Grid.module.scss';
import cx from 'classnames';

const Row = ({
  start,
  center,
  between,
  around,
  end,
  alignItemsBaseline,
  alignItemsCenter,
  alignItemsEnd,
  withMarginLarge,
  withMarginMedium,
  withMarginSmall,
  children,
  withPaddingLarge,
  withPaddingMedium,
  withPaddingSmall,
  withMarginUpperSmall,
  withSpaceBetween,
  className,
  ...otherProps
}) => {
  let combinedClassName = cx(
    styles.row,
    {
      [styles.start]: start,
      [styles.center]: center,
      [styles.between]: between,
      [styles.around]: around,
      [styles.end]: end,
      [styles.alignItemsBaseline]: alignItemsBaseline,
      [styles.alignItemsCenter]: alignItemsCenter,
      [styles.alignItemsEnd]: alignItemsEnd,
      [styles.withMarginLarge]: withMarginLarge,
      [styles.withMarginMedium]: withMarginMedium,
      [styles.withMarginSmall]: withMarginSmall,
      [styles.withPaddingLarge]: withPaddingLarge,
      [styles.withPaddingMedium]: withPaddingMedium,
      [styles.withPaddingSmall]: withPaddingSmall,
      [styles.withMarginUpperSmall]: withMarginUpperSmall,
      [styles.withSpaceBetween]: withSpaceBetween,
    },
    className
  );
  return (
    <div className={combinedClassName} {...otherProps}>
      {children}
    </div>
  );
};

export default Row;
