import { useState } from 'react';

export function useModalState(initialState = false) {
  const [isModalOpen, setModalOpen] = useState(initialState);

  return {
    isModalOpen,
    openModal() {
      setModalOpen(true);
    },
    closeModal() {
      setModalOpen(false);
    },
    toggleModal() {
      setModalOpen((previousState) => !previousState);
    },
  };
}
