import React, { useEffect, useRef } from 'react';
import { components } from 'react-select';
import { HOUR_TYPE, MINUTE_TYPE } from 'constants/booking';

export const SingleValue = ({ selectProps, children, ...props }) => {
  if (
    selectProps?.value?.length === 2 &&
    selectProps?.value[0]?.type !== 'asap'
  ) {
    return (
      <components.SingleValue className="single-value" {...props}>
        {`${selectProps.value[0]?.label}:${selectProps.value[1]?.label}`}
      </components.SingleValue>
    );
  }
  return (
    <components.SingleValue className="single-value" {...props}>
      {children}
    </components.SingleValue>
  );
};

export const Group = (props) => {
  return <components.Group className={props.label} {...props} />;
};

export const Option = (props) => {
  const ref = useRef();
  const selectedMinute =
    props.isSelected && props.data.type === MINUTE_TYPE && ref.current;
  const selectedHour =
    props.isSelected && props.data.type === HOUR_TYPE && ref.current;

  useEffect(() => {
    selectedHour &&
      selectedHour.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    setTimeout(() => {
      selectedMinute &&
        selectedMinute.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
    }, 400);
  }, [selectedHour, selectedMinute, props.isSelected]);

  return (
    <components.Option className={props.data.value} {...props} innerRef={ref} />
  );
};
