import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  verifyUserRequest,
  authenticationCodeRequest,
  createUserRequest,
  checkUserByEmailRequest,
  resendVerificationRequest,
  checkUserByPhoneRequest,
} from 'api/users';
import { userErrors } from 'constants/errorMessages';
import { setAppError } from 'store/slices/appStatusSlice';
import { setMe } from 'store/slices/userSlice';
import { failed } from 'store/helpers/sliceHelpers';

export const initialState = {
  isLoading: null,
  error: null,
  stage: 0,
  user: {
    id: null,
    phone: '',
    phoneCountry: '',
    phoneNumber: '',
  },
};

export const getAuthenticationCode = createAsyncThunk(
  'signUpSteps/getAuthenticationCode',
  async (values, { getState, rejectWithValue, dispatch }) => {
    const { data } = await authenticationCodeRequest(values);
    return data;
  }
);

export const resendVerificationCode = createAsyncThunk(
  'signUpSteps/resendVerification',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { phoneCountry, phoneNumber } = getState().signUpSteps.user;
    const { data } = await resendVerificationRequest(phoneCountry, phoneNumber);
    return data;
  }
);

export const verifyUser = createAsyncThunk(
  'signUpSteps/verifyUser',
  async (values, { getState, dispatch, rejectWithValue }) => {
    try {
      const id = getState().signUpSteps?.user?.id;
      // eslint-disable-next-line max-len
      const code = `${values.code_secret_1}${values.code_secret_2}${values.code_secret_3}${values.code_secret_4}${values.code_secret_5}${values.code_secret_6}`;
      const { data } = await verifyUserRequest(code, id);
      if (data?.token) {
        await dispatch(setMe(data));
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const checkUserByEmail = createAsyncThunk(
  'signUpSteps/checkByEmail',
  async ({ email, isLogin = true }, { dispatch, rejectWithValue }) => {
    const { data } = await checkUserByEmailRequest(email);
    if (data === false && !isLogin) {
      dispatch(setAppError({ title: userErrors.emailExist, cta: 'Log in' }));
      return rejectWithValue(data);
    } else if (isLogin && data === true) {
      dispatch(
        setAppError({
          title: userErrors.emailNotFound,
          cta: 'Create new account',
        })
      );
      return rejectWithValue(data);
    }
    return data;
  }
);

export const checkUserByPhone = createAsyncThunk(
  'signUpSteps/checkByPhone',
  async (
    { phone_country, phone_number, isLogin = true },
    { dispatch, rejectWithValue }
  ) => {
    const { data } = await checkUserByPhoneRequest(phone_country, phone_number);

    if (!isLogin) {
      if (data === false) {
        dispatch(setAppError({ title: userErrors.phoneExist, cta: 'Log in' }));
        return rejectWithValue(data);
      } else {
        dispatch(
          getAuthenticationCode({
            phone_country,
            phone_number,
          })
        );
      }
    } else if (isLogin) {
      if (data === false) {
        dispatch(
          getAuthenticationCode({
            phone_country,
            phone_number,
          })
        );
      } else {
        dispatch(
          setAppError({
            title: userErrors.phoneNotFound,
            cta: 'Create new account',
          })
        );
        return rejectWithValue(data);
      }
    }
    return data;
  }
);

export const createUser = createAsyncThunk(
  'signUpSteps/createUser',
  async (values, { getState, dispatch }) => {
    const id = getState().signUpSteps?.user?.id;
    const { data } = await createUserRequest(values, id);
    if (data.token) {
      await dispatch(setMe(data));
    }
    return data;
  }
);

const signUpStepLoading = (state, { meta }) => {
  state.error = null;
  state.isLoading = true;
  if (meta.arg?.phone_country && meta.arg?.phone_number) {
    state.user.phoneCountry = meta.arg.phone_country;
    state.user.phoneNumber = meta.arg.phone_number;
    state.user.phone = `+${meta.arg.phone_country}${meta.arg.phone_number}`;
  }
};

function signUpStepFailed(state, action) {
  failed(state, action);
}

const loginCodePending = (state, { meta }) => {
  state.error = null;
  state.isLoading = true;
  state.user.phoneCountry = meta.arg?.phone_country;
  state.user.phoneNumber = meta.arg?.phone_number;
  state.user.phone = `+${meta.arg?.phone_country}${meta.arg?.phone_number}`;
};

const codeFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  state.user.id = payload.id;
};

const loginCodeFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  state.user.id = payload.id;
  state.stage++;
};

const codeFailed = (state, action) => {
  failed(state, action);
};
const createUserFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  state.user = payload;
};
const verifyUserFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  state.data = payload;
  if (!payload?.token) {
    state.stage++;
  }
};

const checkUserFulFilled = (state, { payload, meta }) => {
  state.error = null;
  if (!meta.arg?.phone_number) state.isLoading = false;
  if (!meta.arg?.isLogin && meta.arg?.email && payload) {
    state.stage++;
  }
};

const signUpStepsSlice = createSlice({
  name: 'signUpSteps',
  initialState,
  reducers: {
    incrementSignUpStage: (state) => {
      state.stage++;
    },
    decrementSignUpStage: (state) => {
      state.stage--;
    },
    resetSignUpSteps: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(getAuthenticationCode.pending, loginCodePending);
    builder.addCase(verifyUser.pending, signUpStepLoading);
    builder.addCase(createUser.pending, signUpStepLoading);
    builder.addCase(checkUserByEmail.pending, signUpStepLoading);
    builder.addCase(checkUserByPhone.pending, signUpStepLoading);

    builder.addCase(getAuthenticationCode.fulfilled, loginCodeFulfilled);
    builder.addCase(resendVerificationCode.fulfilled, codeFulfilled);
    builder.addCase(verifyUser.fulfilled, verifyUserFulfilled);
    builder.addCase(createUser.fulfilled, createUserFulfilled);
    builder.addCase(checkUserByEmail.fulfilled, checkUserFulFilled);
    builder.addCase(checkUserByPhone.fulfilled, checkUserFulFilled);

    builder.addCase(verifyUser.rejected, signUpStepFailed);
    builder.addCase(getAuthenticationCode.rejected, codeFailed);
    builder.addCase(resendVerificationCode.rejected, codeFailed);
    builder.addCase(createUser.rejected, signUpStepFailed);
    builder.addCase(checkUserByEmail.rejected, signUpStepFailed);
    builder.addCase(checkUserByPhone.rejected, signUpStepFailed);
  },
});

export const {
  incrementSignUpStage,
  decrementSignUpStage,
  resetSignUpSteps,
} = signUpStepsSlice.actions;

export default signUpStepsSlice.reducer;
