import { axiosGet, axiosDelete, axiosPatch, axiosPost } from './axiosHelpers';

import { ACCOUNT_TYPES } from 'constants/accountTypes';
import { PAYMENT_METHODS } from 'constants/paymentMethods';
import {
  ACCOUNT_ROLE_USER,
  resolveRoleByType,
} from 'constants/accountUserRoles';

const returnWithRfbDefaultPayment = (data) => {
  const { rfb_default_payment_method, type, role_type, ...rest } = data;
  const role = resolveRoleByType(rest.role, role_type);
  return {
    ...rest,
    type,
    role_type,
    role,
    rfbDefaultPayment: rfb_default_payment_method
      ? rfb_default_payment_method
      : {
          type,
          name: type,
          default: true,
          [type]: data[type],
          isNotRfb: true,
        },
  };
};
export const getAccountByIdRequest = (id, cancelToken) =>
  axiosGet({
    url: `/accounts/${id}`,
    cancelToken,
  }).then(({ data }) => {
    return returnWithRfbDefaultPayment(data.data);
  });

export const getCurrentAccountRequest = () =>
  axiosGet({
    url: '/accounts/current',
  }).then(({ data }) => {
    return returnWithRfbDefaultPayment(data.data);
  });

export const getAccountsRequest = async () =>
  axiosGet({
    url: '/accounts',
  }).then((res) => {
    const accounts = res.data.data
      .filter((item) => item.role !== ACCOUNT_ROLE_USER)
      .map((item) => {
        return returnWithRfbDefaultPayment(item);
      });
    return accounts;
  });

export const updateAccountRequest = async (payload, accountId) => {
  return axiosPatch({
    url: `/accounts/${accountId}`,
    data: payload,
  }).then(({ data }) => {
    return returnWithRfbDefaultPayment(data.data);
  });
};

export async function getPaymentMethodsRequest(accountId, cancelToken) {
  const { data } = await axiosGet({
    url: `/accounts/${accountId}/payment-methods`,
    cancelToken,
  });
  return data;
}
export const setDefaultPaymentRequest = (accountId, paymentId) => {
  return axiosPatch({
    url: `/accounts/${accountId}?rfb_default_payment_method_id=${paymentId}`,
  }).then(({ data }) => {
    return data;
  });
};

export const addPaymentMethodRequest = (
  paymentId,
  accountId,
  name,
  type = PAYMENT_METHODS.card
) => {
  const payload =
    type === PAYMENT_METHODS.card
      ? { name, type: PAYMENT_METHODS.card, card_id: paymentId }
      : { name, type: PAYMENT_METHODS.account, account_id: paymentId };

  return axiosPost({
    url: `/accounts/${accountId}/payment-methods`,
    data: payload,
  }).then(({ data }) => {
    return data;
  });
};

export const deletePaymentMethodRequest = async (account, paymentId) => {
  const { id: accountId, rfbDefaultPayment } = account;
  const isNotRfbAccount = rfbDefaultPayment.isNotRfb;

  try {
    await axiosDelete({
      url: `/accounts/${accountId}/payment-methods/${paymentId}`,
    });
    const { data } = await getPaymentMethodsRequest(accountId);
    if (!isNotRfbAccount) {
      const optionalMethod = data.find(
        (method) =>
          method.type === ACCOUNT_TYPES.card ||
          method.type === ACCOUNT_TYPES.account
      );
      if (optionalMethod) {
        await setDefaultPaymentRequest(accountId, optionalMethod.id);
      }
    }
    return data;
  } catch (error) {
    Promise.reject(error);
  }
};

export const createAccountRequest = (values, type) => {
  const {
    name,
    admin_email,
    id,
    company_address_region,
    company_address_country,
    company_address_tax_id,
    expected_monthly_spend,
    company_address_1,
    company_address_2,
    company_address_city,
    company_address_zip_code,
  } = values;

  const payload =
    type === ACCOUNT_TYPES.rfb
      ? {
          name,
          admin_email,
          type,
        }
      : {
          name,
          admin_email,
          id,
          company_name: name,
          company_address_region,
          company_address_country,
          company_address_tax_id,
          expected_monthly_spend,
          company_address_1,
          company_address_2,
          company_address_city,
          company_address_zip_code,
          type: ACCOUNT_TYPES.invoice,
        };

  return axiosPost({
    data: payload,
    url: `/accounts`,
  }).then(({ data, status }) => {
    if (type === ACCOUNT_TYPES.invoice && status === 202) {
      return { status };
    } else {
      return returnWithRfbDefaultPayment(data.data);
    }
  });
};

export const validateAccountRequest = (id, fields) =>
  axiosPost({
    url: `/accounts/${id}/validate`,
    data: fields,
  }).then(({ data }) => {
    return data;
  });

export const searchMembersRequest = (id, search, page, cancelToken) =>
  axiosGet({
    url: `/accounts/${id}/search-users`,
    params: {
      search,
      page,
    },
    cancelToken,
  }).then(({ data }) => {
    return data;
  });
