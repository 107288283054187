import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { oneOfType, func, number, string, shape } from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { BasicModalContent } from 'components/Modals/BasicModal';
import PaymentMethodsSelect from 'components/PaymentMethodsSelect';
import {
  ACCOUNT_ROLE_MANAGER,
  ACCOUNT_ROLE_USER,
  ACCOUNT_ROLE_FRONTDESK,
} from 'constants/accountUserRoles';
import { addOrEditUserSelectStyles } from 'constants/customSelectStyles';
import AddUserFormPhone from './AddUserFormPhone';
import AddUserFormEmail from './AddUserFormEmail';
import AddUserNotificationInfo from './AddUserNotificationInfo';
import {
  editAccountMember,
  inviteMemberByRole,
  clearAccountMemberStatus,
} from 'store/slices/accountsSlice';
import {
  addUserSchema,
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  PHONE_FIELD,
  PHONE_COUNTRY_FIELD,
  PHONE_NUMBER_FIELD,
  EMAIL_FIELD,
  PASSWORD_FIELD,
  TYPE_FIELD,
  PAYMENT_METHOD_FIELD,
  GROUP_FIELD,
} from './addUserSchema';
import { PAYMENT_METHODS } from 'constants/paymentMethods';
import { messages } from './messages';
import {
  getEditRoleOptions,
  resetUserFormNameFields,
  resetUserFormPhoneFields,
} from './utils';
import { isOwnerOrAdmin } from 'utils/isOwner';
import styles from './AddUser.module.scss';
import { StyledCSVButton } from './styles';

import { companyDataSelector } from 'store/slices/companySlice';
import { currentPaymentMethodsSelector } from 'store/slices/accountsSlice';
import { groupsDataSelector } from 'store/slices/groupsSlice';
import { commonText } from 'locales/en';
import {
  LabelWithInput,
  LabelContainer,
  LabelWithSelect,
} from 'components/Label';

const modalType = { edit: 'edit' };

const AddUserForm = ({
  type,
  id,
  userId,
  userRole,
  firstName,
  lastName,
  phoneCountry,
  phoneNumber,
  userRfbPaymentMethodId,
  groupId,
  handleModalToggle,
  email,
  account,
  onClickCSV,
}) => {
  const dispatch = useDispatch();
  const [roleFromForm, setRole] = useState(userRole);
  const { isLoading, isUpdated, isCreated } = useSelector(
    (state) => state.accounts.members
  );
  const company = useSelector(companyDataSelector);
  const appUserId = useSelector((state) => state.user.data.id);
  const paymentMethods = useSelector(currentPaymentMethodsSelector);
  const groups = useSelector(groupsDataSelector);
  const groupOptions = useMemo(
    () => groups.map((group) => ({ value: group.id, label: group.name })),
    [groups]
  );

  const isNotRfbAccount = account?.rfbDefaultPayment?.isNotRfb;

  const isOwner = isOwnerOrAdmin(userRole);
  const isManager = userRole === ACCOUNT_ROLE_MANAGER;
  const isFrontdesk = userRole === ACCOUNT_ROLE_FRONTDESK;
  const isUser = userRole === ACCOUNT_ROLE_USER;
  const roleOptions = getEditRoleOptions(userRole);
  const isEditMyself = userId === appUserId;
  const isAllowedToEditOwnProfile = isEditMyself && (isOwner || isManager);
  const isEditModal = type === 'edit';
  const isManagerEditHimself = isManager && isEditMyself;
  const isOwnerEditHimself = isOwner && isEditMyself;

  const isUserNameFieldDisabled = isEditModal && !isAllowedToEditOwnProfile;

  const header = useMemo(() => {
    if (type === modalType.edit && roleFromForm === ACCOUNT_ROLE_FRONTDESK) {
      return messages.editFrontdesk;
    } else if (
      type === modalType.edit &&
      roleFromForm !== ACCOUNT_ROLE_FRONTDESK
    ) {
      return messages.editTeammate;
    } else if (roleFromForm === ACCOUNT_ROLE_FRONTDESK) {
      return messages.createFrontdesk;
    } else {
      return messages.addTeammate;
    }
  }, [roleFromForm, type]);

  const hasSubtitle = useMemo(() => roleFromForm === ACCOUNT_ROLE_FRONTDESK, [
    roleFromForm,
  ]);

  useEffect(() => {
    setRole(userRole ? userRole : ACCOUNT_ROLE_USER);
  }, [userRole]);

  const dispatchClearStatus = useCallback(() => {
    if (isCreated || isUpdated) {
      dispatch(clearAccountMemberStatus());
      if (isUpdated) {
        handleModalToggle();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUpdated, isCreated]);

  useEffect(() => {
    dispatchClearStatus();
  }, [dispatchClearStatus]);
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    errors,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: {
      [FIRST_NAME_FIELD]: isEditModal ? firstName : '',
      [LAST_NAME_FIELD]: isEditModal ? lastName : '',
      [PHONE_COUNTRY_FIELD]: isEditModal && phoneCountry ? phoneCountry : '',
      [PHONE_NUMBER_FIELD]: isEditModal && phoneNumber ? phoneNumber : '',
      [PHONE_FIELD]:
        isEditModal && phoneCountry && phoneNumber
          ? `${phoneCountry}${phoneNumber}`
          : '',
      [PASSWORD_FIELD]: '',
      [EMAIL_FIELD]: isEditModal ? email : '',
      [TYPE_FIELD]: userRole ? userRole : ACCOUNT_ROLE_USER,
      [PAYMENT_METHOD_FIELD]:
        userRfbPaymentMethodId || account?.rfbDefaultPayment?.id || '',
      [GROUP_FIELD]: isEditModal ? groupId : groups[0]?.id,
    },
    validationSchema: addUserSchema(
      account?.rfbDefaultPayment?.isNotRfb,
      isEditModal,
      isAllowedToEditOwnProfile
    ),
    onSubmit: (values) => {
      if (type === 'edit') {
        dispatch(
          editAccountMember({
            id,
            values,
            account,
            isAllowedToEditOwnProfile,
          })
        );
      } else {
        dispatch(
          inviteMemberByRole({
            account,
            values,
            role: values.type,
          })
        );
      }
    },
  });

  const selectedRole = useMemo(
    () => roleOptions.find((option) => option.value === values.type),
    [roleOptions, values.type]
  );
  const selectedGroup = useMemo(
    () => groupOptions.find((option) => option.value === values.group_id),
    [groupOptions, values.group_id]
  );
  const isCreatingFrontdesk =
    !isEditModal && selectedRole?.value === ACCOUNT_ROLE_FRONTDESK;
  const isUserFromSelectValues = values.type === ACCOUNT_ROLE_USER;
  const isManagerFromSelectValues = values.type === ACCOUNT_ROLE_MANAGER;
  const hasPaymentMethodsSelect =
    isUser ||
    isManager ||
    isOwnerEditHimself ||
    isUserFromSelectValues ||
    isManagerFromSelectValues;
  const hasPhoneNumberInput =
    isOwner ||
    isUser ||
    isManager ||
    isUserFromSelectValues ||
    isManagerFromSelectValues;
  const hasUserFullNameProvided =
    values[FIRST_NAME_FIELD] &&
    touched[FIRST_NAME_FIELD] &&
    values[LAST_NAME_FIELD] &&
    touched[LAST_NAME_FIELD];

  const hasSmsNotification =
    !isEditModal &&
    (isUserFromSelectValues || isManagerFromSelectValues) &&
    hasUserFullNameProvided &&
    !isEmpty(touched) &&
    isEmpty(errors);
  const hasEmailNotification =
    (isFrontdesk || isCreatingFrontdesk) &&
    !!values.email &&
    !isEmpty(touched) &&
    isEmpty(errors);

  return (
    <BasicModalContent
      title={header}
      subtitle={hasSubtitle ? messages.createFrontdeskSubtitle : ''}
      isPrimaryDisabled={isLoading}
      isPrimaryLoading={isLoading}
      secondaryTitle={
        type === modalType.edit ? messages.discardChangesBtn : commonText.close
      }
      primaryTitle={
        type === modalType.edit ? messages.saveChangesBtn : messages.addUserBtn
      }
      onClickSecondary={handleModalToggle}
      onClickPrimary={handleSubmit}
      buttonType="submit"
      renderAsForm
    >
      <>
        {type !== modalType.edit && (
          <StyledCSVButton onClick={onClickCSV}>
            {messages.importCSVButton}
          </StyledCSVButton>
        )}
        <br />
        <LabelWithSelect
          label={messages.labelRole}
          value={selectedRole}
          onChange={(option) => {
            setFieldValue(TYPE_FIELD, option.value);
            if (option.value === ACCOUNT_ROLE_FRONTDESK) {
              resetUserFormNameFields(setFieldValue, setFieldTouched);
              resetUserFormPhoneFields(setFieldValue, setFieldTouched);
            }
          }}
          selectProps={{
            isDisabled: isOwner || isFrontdesk || isManagerEditHimself,
            styles: addOrEditUserSelectStyles,
            options: roleOptions,
          }}
        />
        {hasPhoneNumberInput && (
          <AddUserFormPhone
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            setFieldTouched={setFieldTouched}
            phone={values.phone}
            errors={errors}
            touched={touched}
            isEditModal={isEditModal}
            defaultCountry={company.country_code}
          />
        )}
        <LabelWithInput
          testId={FIRST_NAME_FIELD}
          label={messages.labelFirstName}
          error={errors[FIRST_NAME_FIELD]}
          name={FIRST_NAME_FIELD}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[FIRST_NAME_FIELD]}
          hasError={!!(errors[FIRST_NAME_FIELD] && touched[FIRST_NAME_FIELD])}
          isDisabled={isUserNameFieldDisabled}
        />
        <LabelWithInput
          testId={LAST_NAME_FIELD}
          label={messages.labelLastName}
          error={errors[LAST_NAME_FIELD]}
          name={LAST_NAME_FIELD}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[LAST_NAME_FIELD]}
          hasError={!!(errors[LAST_NAME_FIELD] && touched[LAST_NAME_FIELD])}
          isDisabled={isUserNameFieldDisabled}
        />
        {(isFrontdesk || isCreatingFrontdesk) && (
          <AddUserFormEmail
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            password={values.password}
            email={values.email}
          />
        )}
        {hasPaymentMethodsSelect && (
          <LabelContainer
            id={PAYMENT_METHOD_FIELD}
            label={messages.labelPaymentMethod}
            testId="payment-method-select"
            hasError={
              !!(touched[PAYMENT_METHOD_FIELD] && errors[PAYMENT_METHOD_FIELD])
            }
          >
            {isNotRfbAccount ? (
              <p className={styles.AddUserPaymentMethodText}>
                {account?.rfbDefaultPayment.type}
                {account?.rfbDefaultPayment.type === PAYMENT_METHODS.card && (
                  <span>
                    {/* eslint-disable-next-line max-len */}
                    {`: ${account?.rfbDefaultPayment.card.brand}, **** ${account?.rfbDefaultPayment.card.last4}`}
                  </span>
                )}
              </p>
            ) : (
              <PaymentMethodsSelect
                name={PAYMENT_METHOD_FIELD}
                selectedValue={values[PAYMENT_METHOD_FIELD]}
                options={paymentMethods}
                onChange={(item) =>
                  setFieldValue(PAYMENT_METHOD_FIELD, item.id)
                }
                selectStyles={addOrEditUserSelectStyles}
                error={errors[PAYMENT_METHOD_FIELD]}
              />
            )}
          </LabelContainer>
        )}
        <LabelWithSelect
          label={messages.labelGroup}
          value={selectedGroup}
          name={GROUP_FIELD}
          onChange={(option) => setFieldValue(GROUP_FIELD, option.value)}
          selectProps={{
            isDisabled: isManagerEditHimself || !groups.length,
            styles: addOrEditUserSelectStyles,
            options: groupOptions,
            placeholder: 'Select group',
          }}
        />
        {hasSmsNotification ||
          (hasEmailNotification && (
            <AddUserNotificationInfo
              userName={
                hasEmailNotification
                  ? values.email
                  : `${values[FIRST_NAME_FIELD]} ${values[LAST_NAME_FIELD]}`
              }
              notificationChannel={hasEmailNotification ? 'email' : 'sms'}
              accountName={account?.name}
              isVisible={hasSmsNotification || hasEmailNotification}
            />
          ))}
      </>
    </BasicModalContent>
  );
};

AddUserForm.propTypes = {
  type: string.isRequired,
  id: number,
  userId: number,
  userRole: string,
  firstName: string,
  lastName: string,
  phoneCountry: string,
  phoneNumber: string,
  handleModalToggle: func.isRequired,
  userRfbPaymentMethodId: number,
  account: shape({}),
  email: string,
  groupId: oneOfType([string, number]),
};

AddUserForm.defaultProps = {
  id: null,
  userRole: '',
  firstName: '',
  lastName: '',
  phoneCountry: '',
  phoneNumber: '',
  userRfbPaymentMethodId: null,
  userId: null,
  account: null,
  email: '',
  groupId: '',
};

export default memo(AddUserForm);
