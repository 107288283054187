import { customSelectStyles, optionStyle } from 'constants/customSelectStyles';
import {
  white,
  fontFamilyPrimary,
  fontSizeSm,
  DEFAULT_BORDER,
} from 'utils/variables';

export const pickerStyles = {
  menu: (base) => ({
    ...base,
    ...customSelectStyles.menu(base),
    marginTop: 0,
    zIndex: 9,
  }),
  option: (base, state) => ({
    ...base,
    ...optionStyle(state),
    fontSize: fontSizeSm,
    display: state.isDisabled ? 'none' : 'block',
  }),
  menuList: (base, state) => ({
    ...base,
    borderTop: DEFAULT_BORDER,
    borderBottom: DEFAULT_BORDER,
    borderLeft: DEFAULT_BORDER,
    display: 'flex',
    flexFlow: 'row wrap',
    background: white,
    paddingTop: 0,
    overflow: 'hidden',
  }),
  group: (base, state) => ({
    ...base,
    width: '50%',
    height: '200px',
    borderRadius: '2px',
    overflow: 'scroll',
    fontFamily: fontFamilyPrimary,
    fontSize: fontSizeSm,
    borderTop: DEFAULT_BORDER,
    borderRight: DEFAULT_BORDER,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  groupHeading: (base) => ({
    ...base,
    display: 'none',
  }),
  control: (base, state) => ({
    ...base,
    ...customSelectStyles.control(base, state),
    fontSize: fontSizeSm,
  }),
};
