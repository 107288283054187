import {
  PAYMENT_METHODS,
  ADD_CARD_OPTION_LABEL,
  ADD_CARD_OPTION_VALUE,
} from 'constants/paymentMethods';

const defaultPaymentMethods = {
  [PAYMENT_METHODS['pay-in-car']]: false,
  [PAYMENT_METHODS.account]: false,
  [PAYMENT_METHODS.card]: false,
  isAllPossibleMethodsAdded: false,
  isEmpty: false,
};

export const CARD_OPTION = {
  name: ADD_CARD_OPTION_LABEL,
  label: ADD_CARD_OPTION_LABEL,
  value: ADD_CARD_OPTION_VALUE,
  type: PAYMENT_METHODS.card,
};

export const checkAvailablePaymentMethodOptions = (paymentMethods) => {
  if (!paymentMethods) {
    return defaultPaymentMethods;
  } else if (!paymentMethods.length) {
    return {
      ...defaultPaymentMethods,
      isEmpty: true,
    };
  }
  const isPayInCarAdded = paymentMethods.some(
    (paymentMethod) => paymentMethod.type === PAYMENT_METHODS['pay-in-car']
  );
  const isAccountAdded = paymentMethods.some(
    (paymentMethod) => paymentMethod.type === PAYMENT_METHODS.account
  );
  const isCardAdded = paymentMethods.some(
    (paymentMethod) => paymentMethod.type === PAYMENT_METHODS.card
  );
  return {
    [PAYMENT_METHODS['pay-in-car']]: isPayInCarAdded,
    [PAYMENT_METHODS.account]: isAccountAdded,
    [PAYMENT_METHODS.card]: isCardAdded,
    isAllPossibleMethodsAdded: isPayInCarAdded && isAccountAdded && isCardAdded,
    isEmpty: false,
  };
};

export const getAcceptedPaymentMethods = (paymentMethods) =>
  paymentMethods?.filter(
    (method) =>
      !(
        method.type === PAYMENT_METHODS.account &&
        !method[PAYMENT_METHODS.account].is_accepted
      )
  );

export const getPaymentMethodOptions = (paymentMethods) =>
  getAcceptedPaymentMethods(paymentMethods).map((method) => ({
    ...method,
    value: method.id,
    label: method.name,
    hasImage: true,
  }));

export const findCardPaymentMethod = (paymentMethods) =>
  getAcceptedPaymentMethods(paymentMethods)?.find(
    ({ type }) => type === PAYMENT_METHODS.card
  );
