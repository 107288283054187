import React from 'react';
// TODO - uncomment when needed, hide loader requested in RIID-2391
//import styles from './Loader.module.scss';

// const Loader = () => (
//   <div className={styles.LoaderOverlay} data-testid="loader-component">
//     <div className={styles.LoaderWrapper}>
//       <div className={styles.Loader} />
//     </div>
//   </div>
// );

const Loader = () => <></>;

export default Loader;
