import * as Yup from 'yup';
import { errorMessages } from './messages';

import 'utils/yupMethods';

export const initialValues = {
  name: '',
  company_name: '',
  company_address_1: '',
  company_address_2: '',
  company_address_city: '',
  company_address_zip_code: '',
  company_address_country: '',
  company_address_region: '',
  admin_email: '',
  admin_phone: '',
  admin_phone_number: '',
  admin_phone_country: '',
  company_address_tax_id: '',
};

export const settingsSchema = Yup.object().shape({
  name: Yup.string().required(errorMessages.name),
  admin_email: Yup.string()
    .email('Email not valid')
    .required('Email is required'),
  admin_phone_country: Yup.string(),
  admin_phone_number: Yup.string(),
  admin_phone: Yup.string().notRequired().isPhoneValid(),
});
