import React, { useMemo } from 'react';
import { number, arrayOf, string } from 'prop-types';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { StyledDashboardRow } from 'views/Dashboard/styles';
import { StyledBlurryText, StyledLoadingIcon, StyledCenter } from './styles';
import { Column } from 'components/GridNew';
import { ACTIVITY_DASHBOARD_COLUMNS } from 'utils/dashboardHeaders';

const ListLoader = ({ length, cols, data }) => {
  const array = useMemo(() => {
    return Array.from({ length }, (_, i) => i + 1);
  }, [length]);
  const rows = useMemo(() => {
    return cols.map((item, idx) => {
      return { index: idx + 1, col: item, data: data[idx] };
    });
  }, [cols, data]);

  return (
    <>
      <StyledCenter>
        <StyledLoadingIcon icon={faSpinner} className="fa-spin" />
      </StyledCenter>
      {array.map((count, idx) => (
        <StyledDashboardRow data-testid="dashboard-loader-row" key={count}>
          {rows.map((item, idx2) => (
            <Column key={item.index} col={item.col}>
              <StyledBlurryText $alignRight={idx2 === rows.length - 1}>
                {item.data}
              </StyledBlurryText>
            </Column>
          ))}
        </StyledDashboardRow>
      ))}
    </>
  );
};

ListLoader.propTypes = {
  length: number,
  cols: arrayOf(number),
  data: arrayOf(string),
};
ListLoader.defaultProps = {
  length: 8,
  cols: ACTIVITY_DASHBOARD_COLUMNS,
  data: [
    '11-Jan-2021',
    '15:35',
    'Jane Doe',
    'Dublinia Viking Centre, Winetavern Street',
    'Booking requested',
    '€3.50',
  ],
};
export default ListLoader;
