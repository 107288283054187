import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
  memo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func, bool, object } from 'prop-types';
import AdyenCheckout from '@adyen/adyen-web';
import isEqual from 'lodash/isEqual';

import AddCard from 'components/AddCard';
import Modal from 'components/Modal';
import BlockedUser from 'components/Modals/BlockedUser';
import { NewPlace } from 'components/Place';
import BasicModal from 'components/Modals/BasicModal';

import 'react-day-picker/lib/style.css';
import { baseAdyenConfig } from 'api/config';
import t from 'locales/en';
import {
  currentAccountSelector,
  getPaymentMethods,
  setRedirectTo3DAuth,
  cancelRedirectTo3DAuth,
  defaultPaymentMethodSelector,
  currentPaymentMethodsSelector,
  hasCardPaymentMethodAddedSelector,
} from 'store/slices/accountsSlice';
import {
  setPendingBooking,
  setNewBookingFormValues,
  setBlockedUser,
  clearBookingCompanyInfo,
  bookingCompanyPreauthSelector,
  currentBookingSelector,
} from 'store/slices/bookingsSlice';
import { companyDataSelector } from 'store/slices/companySlice';

import { PAYMENT_METHODS } from 'constants/paymentMethods';
import { PICKUP_TYPES } from 'constants/booking';
import { ACCOUNT_ROLE_FRONTDESK } from 'constants/accountUserRoles';
import { DEFAULT_COMPANY_COUNTRY_CODE } from 'constants/companies';
import { STATE_REQUESTED } from 'constants/bookingStates';

import NewBookingForm from './NewBookingForm';
import EditOrViewBooking from './EditOrViewBooking';

const BookingForm = ({ isModalOpen, bookingDetails, handleModalToggle }) => {
  const dispatch = useDispatch();
  const redirectTo3DAuth = useSelector(
    (state) => state.accounts.redirectTo3DAuth
  );
  const { willContinueBooking } = redirectTo3DAuth;
  const hasCardPaymentMethodAdded = useSelector(
    hasCardPaymentMethodAddedSelector
  );
  const { data: currentBooking, hasFulfilled: hasStateFulfilled } = useSelector(
    currentBookingSelector
  );

  const blockedUser = useSelector((state) => state.bookings.blockedUser);
  const companyData = useSelector(companyDataSelector);
  const companyCountryCode =
    companyData.country_code || DEFAULT_COMPANY_COUNTRY_CODE;
  const driverMessages = useSelector((state) => state.bookings.driverMessages);
  const hasPreauth = useSelector(bookingCompanyPreauthSelector);

  const newBookingForm = useSelector((state) => state.bookings.newBookingForm);
  const {
    data: newBookingFormData,
    values: newBookingFormValues,
    isCreating,
    isCreated,
    isUpdating,
    isUpdated,
    serviceStatus,
    error: newBookingFormError,
  } = newBookingForm;
  const paymentMethods = useSelector(currentPaymentMethodsSelector) || [];
  const payInCarPaymentMethod = paymentMethods.find(
    (method) => method.type === PAYMENT_METHODS['pay-in-car']
  )?.id;
  const defaultPaymentMethod = useSelector(defaultPaymentMethodSelector);
  const defaultPaymentMethodId =
    defaultPaymentMethod?.id || payInCarPaymentMethod;

  const currentAccount = useSelector(currentAccountSelector);
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const isNotRfbAccount = !!currentAccount?.rfbDefaultPayment?.isNotRfb;
  const [isNewAddressModalVisible, setIsNewAddressModalVisible] = useState(
    false
  );
  const companyCoordinates = {
    lng: companyData?.lng,
    lat: companyData?.lat,
  };

  const formReference = useRef();
  const redirectReference = useRef();

  const hasAddCardOption =
    !hasCardPaymentMethodAdded &&
    currentAccount.role !== ACCOUNT_ROLE_FRONTDESK;

  const dispatchGetPaymentMethods = useCallback(() => {
    if (!isNotRfbAccount) {
      dispatch(getPaymentMethods({ account: currentAccount }));
    }
  }, [dispatch, currentAccount, isNotRfbAccount]);

  useEffect(() => {
    dispatchGetPaymentMethods();
  }, [dispatchGetPaymentMethods]);

  const handleAddCardModalToggle = () => {
    setIsAddCardModalOpen(false);
  };

  const clearForm = (resetForm) => {
    resetForm && resetForm({});
    dispatch(clearBookingCompanyInfo());
  };

  const clearBlockedUser = () => dispatch(setBlockedUser({}));

  const handleClickCreateNew = () => {
    clearBlockedUser();
    clearForm(formReference.current?.resetForm);
  };

  const isEditBooking = useMemo(() => {
    if (bookingDetails) {
      return (
        bookingDetails.type === PICKUP_TYPES.PRE_BOOKING &&
        bookingDetails.state === STATE_REQUESTED
      );
    } else if (willContinueBooking && isCreated && currentBooking) {
      return currentBooking.type === PICKUP_TYPES.PRE_BOOKING;
    } else {
      return false;
    }
  }, [bookingDetails, currentBooking, isCreated, willContinueBooking]);

  const selectedBooking = useMemo(() => {
    if (bookingDetails) {
      return bookingDetails;
    } else if (
      isCreated &&
      (currentBooking?.type === PICKUP_TYPES.ASAP || willContinueBooking)
    ) {
      return currentBooking;
    } else {
      return {};
    }
  }, [bookingDetails, currentBooking, isCreated, willContinueBooking]);

  const onClickRedirectAuth = () => {
    const configuration = baseAdyenConfig;
    const checkout = new AdyenCheckout(configuration);
    const {
      values,
      updatedFields,
      preauthId,
      action,
      last4,
      timezone,
    } = redirectTo3DAuth.isBooking;
    dispatch(
      setPendingBooking({
        values: { ...values, last4 },
        preauthId,
        timezone,
        updatedFields,
        isEdit: !!selectedBooking.id,
      })
    );

    dispatch(setRedirectTo3DAuth({ visible: false }));
    checkout.createFromAction(action).mount(redirectReference?.current);
  };

  const onCancelRedirectAuth = () => {
    dispatch(cancelRedirectTo3DAuth({}));
  };

  const handleSelectNewCardOption = () => {
    setIsAddCardModalOpen(true);
    const newBookingFormValues = Object.assign(
      {},
      formReference?.current?.values
    );
    delete newBookingFormValues.date;
    dispatch(setNewBookingFormValues(newBookingFormValues));
  };

  const companyPhoneNumber = selectedBooking.id
    ? selectedBooking.company?.configs?.general?.phone
    : '';

  return (
    <Modal isModalOpen={isModalOpen} modalStyle="bookingForm">
      {selectedBooking.id ? (
        <EditOrViewBooking
          isEdit={isEditBooking}
          editBookingProps={{
            formRef: formReference,
            redirectRef: redirectReference,
            bookingDetails: selectedBooking,
            handleModalToggle,
            onSelectNewCardOption: handleSelectNewCardOption,
            companyCoordinates,
            companyCountryCode,
            hasPreauth,
            isNotRfbAccount,
            currentAccount,
            serviceStatus,
            newBookingFormData,
            isUpdated,
            isUpdating,
            error: newBookingFormError,
            onClickAddNewAddress: () => setIsNewAddressModalVisible(true),
            clearForm,
            defaultPaymentMethodId,
            paymentMethods,
            hasAddCardOption,
          }}
          viewBookingProps={{
            paymentMethods,
            bookingDetails: selectedBooking,
            onClose: handleModalToggle,
            hasStateFulfilled,
            companyCoordinates,
            isCreated,
            companyName: companyData.name,
            hasPreauth,
            redirectRef: redirectReference,
            driverMessages,
            hasAddCardOption,
            companyPhoneNumber,
            isNotRfbAccount,
            defaultPaymentMethod,
            onSelectNewCardOption: handleSelectNewCardOption,
          }}
        />
      ) : (
        <NewBookingForm
          formRef={formReference}
          redirectRef={redirectReference}
          willContinueBooking={willContinueBooking}
          handleModalToggle={handleModalToggle}
          onSelectNewCardOption={handleSelectNewCardOption}
          companyCoordinates={companyCoordinates}
          companyCountryCode={companyCountryCode}
          hasPreauth={hasPreauth}
          isNotRfbAccount={isNotRfbAccount}
          currentAccount={currentAccount}
          serviceStatus={serviceStatus}
          newBookingFormData={newBookingFormData}
          newBookingFormValues={newBookingFormValues}
          isCreated={isCreated}
          isCreating={isCreating}
          onClickAddNewAddress={() => setIsNewAddressModalVisible(true)}
          clearForm={clearForm}
          defaultPaymentMethodId={defaultPaymentMethodId}
          paymentMethods={paymentMethods}
          hasAddCardOption={hasAddCardOption}
          error={newBookingFormError}
        />
      )}
      <Modal
        isModalOpen={isAddCardModalOpen}
        handleModalToggle={handleAddCardModalToggle}
        modalStyle="isAccount"
      >
        <AddCard
          handleModalClose={handleAddCardModalToggle}
          onSuccess={handleAddCardModalToggle}
          inBookingForm
          account={currentAccount}
        />
      </Modal>
      <BlockedUser
        blockedUser={blockedUser}
        onClickCreate={handleClickCreateNew}
        onCancel={clearBlockedUser}
      />
      <NewPlace
        isModalOpen={isNewAddressModalVisible}
        onClose={() => setIsNewAddressModalVisible(false)}
      />
      <div ref={redirectReference} />
      {redirectTo3DAuth.isBooking.action && redirectTo3DAuth?.visible && (
        <BasicModal
          isModalOpen={redirectTo3DAuth.visible}
          title={t.threeDAuth.createBooking.request.title}
          onClickPrimary={onClickRedirectAuth}
          onClickSecondary={onCancelRedirectAuth}
          handleModalToggle={onCancelRedirectAuth}
          secondaryTitle={t.common.close}
          primaryTitle={t.common.ok}
        />
      )}
    </Modal>
  );
};

BookingForm.propTypes = {
  handleModalToggle: func,
  bookingDetails: object,
  isModalOpen: bool.isRequired,
};

BookingForm.defaultProps = {
  handleModalToggle: func,
  bookingDetails: null,
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.bookingDetails, nextProps.bookingDetails) &&
  prevProps.isModalOpen === nextProps.isModalOpen;

export default memo(BookingForm, areEqual);
