import { ACCOUNT_ROLE_FRONTDESK } from 'constants/accountUserRoles';

const filterMembers = (accountMembers, filters) => {
  const members = accountMembers.map((member) => {
    const {
      first_name,
      last_name,
      user,
      type,
      state,
      id,
      invited_at,
      rfb_payment_method_id,
      group,
    } = member;
    if (!member) return {};
    const item = {
      userId: user.id,
      firstName: first_name,
      lastName: last_name,
      phoneCountry: user.phone_country,
      phoneNumber: user.phone_number,
      email: user.email || '',
      role: user.phone_number ? type : ACCOUNT_ROLE_FRONTDESK,
      state,
      id,
      invitedAt: invited_at,
      userRfbPaymentMethodId: rfb_payment_method_id,
      group,
    };
    return item;
  });

  const filterKeys = Object.keys(filters);

  const result = members.filter((user) => {
    return filterKeys.every((key) => {
      const value = filters[key]?.value;
      if (value === null) return true;
      if (Array.isArray(value)) {
        return value.includes(user[key]);
      } else {
        return value === user[key];
      }
    });
  });
  return result;
};

export default filterMembers;
