export const DEFAULT_MINUTE_STEP = 5;
export const MINUTE_TYPE = 'minutes';
export const HOUR_TYPE = 'hours';
export const ASAP_TYPE = 'asap';

export const PRE_BOOKING = 'pre-booking';

export const PICKUP_TYPES = {
  ASAP: ASAP_TYPE,
  PRE_BOOKING,
};
export const DEFAULT_TIME_ZONE = 'Europe/London';
export const TEXTAREA_LIMIT = 140;
