import styled, { css } from 'styled-components';
import * as v from 'utils/variables';

export const Wrapper = styled.div`
  z-index: ${(props) => props.$zIndex};
  position: relative;
  .intl-tel-booking-input.allow-dropdown.separate-dial-code .selected-dial-code {
    color: ${v.charCoal}};
    font-size: ${v.fontSizeSm};
  }
  .input-tel-booking::placeholder {
    color: ${v.palegray};
  }
  .input-tel-booking {
    color: ${v.charCoal};
    background: ${v.white};
    border-radius: ${v.borderRadiusBasic};
    font-size: ${v.fontSizeSm};
    border: 1px solid ${v.dark};
  }
  .intl-tel-booking-input:not(.flex) .country-list {
    max-width: 100%;
  }

  .intl-tel-booking-input .country-list .country-name,
  .intl-tel-booking-input .country-list .country .dial-code {
    color: ${v.mousegray};
    font-size: ${v.fontSizeSm};
  }

  .intl-tel-booking-input .country-list {
    border-radius: ${v.borderRadiusBasic};
    background: ${v.white};
    font-size: ${v.fontSizeSm};
    .country.highlight {
      background-color: ${v.primary};
      .country-name,
      .dial-code {
        color: ${v.white};
      }
    }
  }

  .intl-tel-booking-input .country-list .country:hover {
    background-color: ${v.primary};
  }

  .intl-tel-booking-input .flag-container:hover .selected-flag {
    background-color: ${v.dark};
  }

  .intl-tel-booking-input .country-list::-webkit-scrollbar {
    background: ${v.shadedgray};
  }

  .intl-tel-booking-input .country-list::-webkit-scrollbar-thumb {
    background: ${v.dark};
    height: 2em;
    border-radius: 0.3em;
  }

  .input-tel-tel.input-tel-booking {
    border: 1px solid ${v.dark};
    border-radius: 4px;
    ${(props) =>
      props.$hasError &&
      css`
        border: 1px solid ${v.red};
      `};
    ${(props) =>
      props.$isLarge &&
      css`
        height: 52px;
      `}
  }
  .intl-tel-booking-input.flex.allow-dropdown.separate-dial-code {
    display: flex;
    .flag-container {
      position: relative;
      width: initial;
      border: 1px solid ${v.dark};
      border-radius: 4px;
      margin-right: 20px;
      .selected-flag {
        padding: 0;
        width: 72px;
        .iti-flag {
          display: none;
        }
      }
      .selected-dial-code {
        padding-left: 15px;
      }
    }
    .input-tel-tel.input-tel-booking {
      padding-left: 15px;
    }
    .country-list {
      z-index: 10;
      width: ${(props) => (props.$width ? props.$width + 'px' : 'initial')};
      margin-top: 3px;
      .country.highlight {
        background-color: ${v.primary};
        .country-name,
        .dial-code {
          color: ${v.white};
        }
      }
    }
  }
`;
