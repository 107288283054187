import React from 'react';
import { func, string } from 'prop-types';

import { LabelWithCheckbox } from 'components/Label';
import { fontSizeMd, charCoal } from 'utils/variables';

const SetAccountItem = ({
  paymentType,
  paymentMethod,
  setPaymentType,
  label,
  message,
}) => {
  return (
    <LabelWithCheckbox
      labelStyle={{
        fontSize: fontSizeMd,
        color: charCoal,
        fontWeight: 'normal',
      }}
      type="radio"
      label={message}
      id={label}
      name={label}
      onChange={() => setPaymentType(paymentMethod)}
      checked={paymentType === paymentMethod}
    />
  );
};

SetAccountItem.defaultProps = {
  paymentType: '',
};

SetAccountItem.propTypes = {
  paymentType: string,
  paymentMethod: string.isRequired,
  setPaymentType: func.isRequired,
  message: string.isRequired,
  label: string.isRequired,
};

export default SetAccountItem;
