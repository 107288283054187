export default {
  cancelBooking: 'Cancel Booking',
  callHQ: 'Call HQ',
  callDriver: 'Call Driver',
  paymentMethod: 'Payment Method',
  messageDriver: {
    label: 'Message sent to driver',
    title: 'Message Driver',
    placeholder: 'Enter your message',
    send: 'Send',
  },
  callModal: {
    title: (companyName = '') => `Call ${companyName} about your booking`,
    subtitle: (phoneNumber, bookingId) =>
      `You can reach us on ${phoneNumber}<br>Your booking reference is ${bookingId}`,
  },
  journeySummary: {
    fare: 'Fare',
    tip: 'Tip',
  },
};
