import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginRequest } from 'api/users';
import { setMe } from 'store/slices/userSlice';
import { failed, fulfilled } from 'store/helpers/sliceHelpers';
import {
  checkUserByPhone,
  checkUserByEmail,
  verifyUser,
  resendVerificationCode,
  getAuthenticationCode,
} from 'store/slices/signUpStepsSlice';

export const initialState = {
  isLoading: null,
  error: null,
  stage: 0,
  user: {
    id: null,
    phone: '',
    phoneCountry: '',
    phoneNumber: '',
  },
};

export const login = createAsyncThunk(
  'loginSteps/login',
  async (
    { email, password, history },
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await loginRequest(email, password, history);
      await dispatch(setMe(data));
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const loginStepsLoading = (state, { meta }) => {
  state.error = null;
  state.isLoading = true;
  if (meta.arg?.phone_country && meta.arg?.phone_number) {
    state.user.phoneCountry = meta.arg.phone_country;
    state.user.phoneNumber = meta.arg.phone_number;
    state.user.phone = `+${meta.arg.phone_country}${meta.arg.phone_number}`;
  }
};

function loginStepsFailed(state, action) {
  failed(state, action);
}

const loginCodePending = (state, { meta }) => {
  state.error = null;
  state.isLoading = true;
  state.user.phoneCountry = meta.arg?.phone_country;
  state.user.phoneNumber = meta.arg?.phone_number;
  state.user.phone = `+${meta.arg?.phone_country}${meta.arg?.phone_number}`;
};

const resendCodeFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  state.user.id = payload.id;
};

const loginCodeFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  state.user.id = payload.id;
  state.stage++;
};

const codeFailed = (state, action) => {
  failed(state, action);
};
const verifyUserFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  state.data = payload;
};

const checkUserFulFilled = (state, { payload, meta }) => {
  state.error = null;
  if (!meta.arg?.phone_number) state.isLoading = false;
  if (meta.arg?.isLogin && meta.arg?.email && payload === false) {
    state.stage++;
  }
};

const loginStepsSlice = createSlice({
  name: 'loginSteps',
  initialState,
  reducers: {
    incrementLoginStage: (state) => {
      state.stage++;
    },
    decrementLoginStage: (state) => {
      state.stage--;
    },
    resetLoginSteps: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    builder.addCase(getAuthenticationCode.pending, loginCodePending);
    builder.addCase(verifyUser.pending, loginStepsLoading);
    builder.addCase(checkUserByEmail.pending, loginStepsLoading);
    builder.addCase(checkUserByPhone.pending, loginStepsLoading);
    builder.addCase(login.pending, loginStepsLoading);

    builder.addCase(getAuthenticationCode.fulfilled, loginCodeFulfilled);
    builder.addCase(resendVerificationCode.fulfilled, resendCodeFulfilled);
    builder.addCase(verifyUser.fulfilled, verifyUserFulfilled);
    builder.addCase(checkUserByEmail.fulfilled, checkUserFulFilled);
    builder.addCase(checkUserByPhone.fulfilled, checkUserFulFilled);
    builder.addCase(login.fulfilled, (state) => fulfilled(state));

    builder.addCase(verifyUser.rejected, loginStepsFailed);
    builder.addCase(getAuthenticationCode.rejected, codeFailed);
    builder.addCase(resendVerificationCode.rejected, codeFailed);
    builder.addCase(checkUserByEmail.rejected, loginStepsFailed);
    builder.addCase(checkUserByPhone.rejected, loginStepsFailed);
    builder.addCase(login.rejected, loginStepsFailed);
  },
});

export const {
  incrementLoginStage,
  decrementLoginStage,
  resetLoginSteps,
} = loginStepsSlice.actions;

export default loginStepsSlice.reducer;
