import React, { memo } from 'react';
import {
  oneOfType,
  func,
  shape,
  arrayOf,
  string,
  number,
  bool,
} from 'prop-types';

import { Col } from 'components/Grid';
import PaymentMethodsSelect from 'components/PaymentMethodsSelect';
import {
  ADD_CARD_OPTION_VALUE,
  PAYMENT_METHODS,
} from 'constants/paymentMethods';
import { bookingFormSelectStyles } from 'constants/customSelectStyles';
import { accountType } from 'constants/propTypes';
import { FORM_FIELDS_IDS } from 'components/Modals/BookingForm/BookingFormSchema';
import messages from './messages';
import styles from '../BookingForm.module.scss';

const BookingFormPaymentsSelect = ({
  setFieldValue,
  selectedValue,
  onSelectNewCard,
  isNotRfbAccount,
  account,
  error,
  hasTouched,
  onChange,
  isDisabled,
  paymentMethods,
  hasAddCardOption,
}) => {
  const handleOnChange = (item) => {
    if (item.value === ADD_CARD_OPTION_VALUE) {
      onSelectNewCard();
    } else {
      setFieldValue(FORM_FIELDS_IDS.rfb_payment_method, item.id);
      setFieldValue(FORM_FIELDS_IDS.payment_type, item.type);
      onChange && onChange(item);
    }
  };

  return (
    <>
      <Col col={5}>
        <label
          className={styles.BookingFormLabel}
          htmlFor={FORM_FIELDS_IDS.rfb_payment_method}
        >
          {messages.label}
        </label>
      </Col>
      <Col col={7}>
        {isNotRfbAccount ? (
          <div>
            {account.rfbDefaultPayment.type === PAYMENT_METHODS.card &&
              // eslint-disable-next-line max-len
              `Card: ${account.rfbDefaultPayment.card.brand}, **** ${account.rfbDefaultPayment.card.last4}`}
            {account.rfbDefaultPayment.type === PAYMENT_METHODS.invoice &&
              `Invoice`}
          </div>
        ) : (
          <div data-testid="booking-form-payment-select">
            <PaymentMethodsSelect
              error={error}
              account={account}
              hasTouched={hasTouched}
              onChange={handleOnChange}
              options={paymentMethods}
              name={FORM_FIELDS_IDS.rfb_payment_method}
              placeholder={messages.placeholder}
              selectStyles={bookingFormSelectStyles(
                hasTouched && !!error && !selectedValue
              )}
              hasAddCardOption={hasAddCardOption}
              selectedValue={selectedValue}
              isDisabled={isDisabled}
            />
          </div>
        )}
      </Col>
    </>
  );
};

BookingFormPaymentsSelect.propTypes = {
  setFieldValue: func.isRequired,
  values: shape({}),
  options: arrayOf(
    shape({
      type: string.isRequired,
      id: number,
      item: shape({}),
      default: bool,
      label: string,
    })
  ),
  onSelectNewCard: func.isRequired,
  onChange: func,
  isNotRfbAccount: bool.isRequired,
  account: shape(accountType).isRequired,
  hasTouched: bool,
  error: string,
  isDisabled: bool,
  hasAddCardOption: bool,
  selectedValue: oneOfType([string, number]),
};

BookingFormPaymentsSelect.defaultProps = {
  values: {},
  hasTouched: false,
  isDisabled: false,
  hasAddCardOption: true,
  selectedValue: null,
};

export default memo(BookingFormPaymentsSelect);
