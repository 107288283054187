import * as Yup from 'yup';
import 'utils/yupMethods';

const EmailOrPhoneSchema = Yup.object().shape({
  is_email: Yup.boolean(),
  email: Yup.string().when('is_email', {
    is: true,
    then: Yup.string().email('Email not valid').required('Email is required'),
  }),
  phone: Yup.string().when('is_email', {
    is: false,
    then: Yup.string().min(3).required().isPhoneValid(),
  }),
  phone_number: Yup.string(),
  phone_country: Yup.string(),
});

const PasswordOrCodeSchema = Yup.object().shape({
  is_email: Yup.boolean(),
  code_secret_1: Yup.string().when('is_email', {
    is: false,
    then: Yup.string()
      .matches(/^(\d{1})$/, 'Type one digit')
      .required('Type one digit'),
  }),
  code_secret_2: Yup.string().when('is_email', {
    is: false,
    then: Yup.string()
      .matches(/^(\d{1})$/, 'Type one digit')
      .required('Type one digit'),
  }),
  code_secret_3: Yup.string().when('is_email', {
    is: false,
    then: Yup.string()
      .matches(/^(\d{1})$/, 'Type one digit')
      .required('Type one digit'),
  }),
  code_secret_4: Yup.string().when('is_email', {
    is: false,
    then: Yup.string()
      .matches(/^(\d{1})$/, 'Type one digit')
      .required('Type one digit'),
  }),
  code_secret_5: Yup.string().when('is_email', {
    is: false,
    then: Yup.string()
      .matches(/^(\d{1})$/, 'Type one digit')
      .required('Type one digit'),
  }),
  code_secret_6: Yup.string().when('is_email', {
    is: false,
    then: Yup.string()
      .matches(/^(\d{1})$/, 'Type one digit')
      .required('Type one digit'),
  }),
  password: Yup.string().when('is_email', {
    is: true,
    then: Yup.string()
      .required('No password provided.')
      .min(8, 'Password must contain at least 8 characters'),
  }),
});

export const schemaArray = [EmailOrPhoneSchema, PasswordOrCodeSchema];

export const initialValues = {
  is_email: true,
  phone_country: '',
  phone_number: '',
  phone: '',
  email: '',
  code_secret_1: '',
  code_secret_2: '',
  code_secret_3: '',
  code_secret_4: '',
  code_secret_5: '',
  code_secret_6: '',
  password: '',
};
