import React from 'react';
import Cookies from 'js-cookie';
import { node } from 'prop-types';
import { useLocation } from 'react-router-dom';

import Modal from 'components/Modal';
import { useModalState } from 'hooks/useModalState';
import { routes } from 'routes/routes';
import {
  CookieConsentContentWrapper,
  CookieConsentHeader,
  CookieConsentSubheader,
  CookieConsentButtonsWrapper,
  CookieConsentPolicyLink,
  CookieConsentAcceptButton,
} from './styles';

import messages from './messages';

const cookieConsentName = 'cookieConsent';
const cookieExpDays = 365;
const cookiesAcceptedValue = 1;

const CookieConsent = ({ children }) => {
  const cookieConsentValue = Cookies.get(cookieConsentName);
  const cookieConsentAccepted =
    cookieConsentValue === String(cookiesAcceptedValue);
  const { isModalOpen, closeModal } = useModalState(
    !cookieConsentValue || cookieConsentValue !== String(cookiesAcceptedValue)
  );

  const location = useLocation();
  // cookie policy page available without cookie consent modal and overlay
  if (location?.pathname === routes.cookiePolicy || cookieConsentAccepted) {
    return children;
  }

  return (
    <div>
      <Modal isModalOpen={isModalOpen} modalStyle="enableCookies">
        <CookieConsentContentWrapper>
          <CookieConsentHeader>{messages.header}</CookieConsentHeader>
          <CookieConsentSubheader>{messages.subHeader}</CookieConsentSubheader>
          <CookieConsentButtonsWrapper>
            <CookieConsentPolicyLink
              renderAs="a"
              linkProperties={{
                href: routes.cookiePolicy,
                target: '_blank',
                rel: 'noopener noreferrer',
              }}
              to={routes.cookiePolicy}
              name={messages.policyLink}
            />
            <CookieConsentAcceptButton
              primary
              onClick={() => {
                Cookies.set(cookieConsentName, cookiesAcceptedValue, {
                  expires: cookieExpDays,
                });
                closeModal();
              }}
              name={messages.acceptButton}
              isRoundedMedium
            />
          </CookieConsentButtonsWrapper>
        </CookieConsentContentWrapper>
      </Modal>
      {children}
    </div>
  );
};

CookieConsent.propTypes = {
  children: node,
};

CookieConsent.defaultProps = {
  children: null,
};

export default CookieConsent;
