const messages = {
  userErrorTitle: 'We are unable to make a booking for this user',
  userErrorDescription: ({ company = '', userName = '' }) =>
    `Please contact your dispatch operator at <span>${
      company || 'Riide'
    }</span> in order to create a booking for <span>${
      userName || 'the user'
    }</span>.`,
  createNewBooking: 'Create new booking',
};

export default messages;
