import React from 'react';
import { string, func } from 'prop-types';
import Select from 'components/Select';
import { countrySelectStyles } from 'constants/customSelectStyles';
import { countries, countryOptions } from './countryOptions';
import { reactSelectTheme } from 'utils/selectTheme';
import { Divider } from './styles';
import InputError from 'components/InputError';

const formatGroupLabel = (data) =>
  data.label !== countryOptions[0].label && <Divider />;

const valueToOption = (value) =>
  countries.find((country) => country.value === value);

const CountrySelect = ({
  hasError,
  error,
  onChange,
  id,
  value,
  styles,
  height,
  ...selectProps
}) => (
  <div style={{ position: 'relative' }}>
    <Select
      theme={reactSelectTheme(hasError)}
      onChange={onChange}
      value={valueToOption(value)}
      id={id}
      options={countryOptions}
      isSearchable
      formatGroupLabel={formatGroupLabel}
      styles={countrySelectStyles(hasError)}
      height={height}
      {...selectProps}
    />
    <InputError errorCanFit hasError={hasError} error={error} />
  </div>
);

CountrySelect.propTypes = {
  error: string,
  onChange: func.isRequired,
  id: string,
  value: string,
  height: string,
};

CountrySelect.defaultProps = {
  id: 'country',
  value: null,
  error: '',
  height: '38px', // fallback for Invoicing modal
};

export default CountrySelect;
