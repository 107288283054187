import styled, { css } from 'styled-components';
import illustration from 'assets/landingPage/illustration-gps-2.svg';
import * as v from 'utils/variables';
import { Link } from 'react-router-dom';

export const LandingPageWrapper = styled.div`
  width: 100%;
  background: ${v.white};
  padding: ${v.spacingMd};
  display: flex;
  flex-direction: column;
  font-family: ${v.fontFamilySecondary};
`;

export const SectionWrapper = styled.section`
  padding: 0 ${v.spacingXmd};

  @media screen and (min-width: ${v.tablet}) {
    padding: 0;
    height: 80vh;
    background-image: url(${illustration});
    background-repeat: no-repeat;
    background-position: 104% 50%;
    background-size: 48% auto;
  }
  @media screen and (min-width: ${v.laptopLg}) {
    height: 110vh;
    background-position: 104% 0%;
  }
`;

export const TopPanelWrapper = styled.div`
  width: 100%;
  padding: ${v.spacingSm};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;
export const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;
export const Logo = styled.img`
  max-width: 110px;
  max-height: 50px;
  height: ${(props) => props.$height || 'auto'};
  width: ${(props) => props.$width || 'auto'};
`;
export const LogoText = styled.p`
  font-size: ${v.fontSizeSm};
  margin-left: ${v.spacingMd};
  color: ${v.sharkGray};
`;

const mdButton = css`
  margin: 0 ${v.spacingMd} ${v.spacingSm} 0;
  @media screen and (min-width: ${v.tablet}) {
    min-width: 156px;
  }
`;
const bigButton = css`
  min-width: 156px;
  @media screen and (min-width: ${v.tablet}) {
    min-width: 200px;
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const ButtonWrapper = styled.div`
  min-width: 117px;

  @media screen and (min-width: ${v.tablet}) {
    margin: 0 ${v.spacingMd} ${v.spacingSm} ${v.spacingXs};
  }
  ${(props) => props.$medium && mdButton};
  ${(props) => props.$big && bigButton};
  ${(props) =>
    props.$isUppercase &&
    css`
      text-transform: uppercase;
    `};
`;
export const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${v.pageMaxWidthMd};
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${v.spacingXmd} ${v.spacingXmd} ${v.spacingXmd};
  font-family: ${v.fontFamilySecondary};

  ${(props) =>
    props.$top &&
    css`
      min-height: 65vh;
      @media screen and (min-width: ${v.tablet}) {
        height: 100%;
      }
    `};

  @media screen and (min-width: ${v.laptopLg}) {
    padding: 0 0 ${v.spacingLg} 0;
  }
`;

export const ContentHeader = styled.h1`
  font-size: ${v.fontSizeXxl};
  font-weight: bold;
  color: ${v.sharkGray};
  margin: ${v.spacingMd} 0;

  @media screen and (min-width: ${v.laptopLg}) {
    padding: 0;
    font-size: ${v.fontSizeXxxl};
  }
`;
export const ContentSubheader = styled.p`
  font-size: ${v.fontSizeLg};
  margin-bottom: ${v.spacingLg};
  color: ${v.sharkGray};

  @media screen and (min-width: ${v.laptopLg}) {
    padding: 0;
    font-size: ${v.fontSizeXl};
  }
`;
export const SectionBoxWrapper = styled.div`
  padding: ${v.spacingXmd} 0;

  @media screen and (min-width: ${v.tablet}) {
    min-height: 500px;
    padding: calc(${v.spacingXl} * 0.75) 0 ${v.spacingMd} 0;
  }

  &:nth-of-type(odd) {
    background-color: rgba(34, 35, 38, 0.01);
  }
`;
export const LandingPageFooter = styled.footer`
  background-color: ${v.primary};
  padding: ${v.spacingLg} 0;
  font-family: ${v.fontFamilySecondary};

  @media screen and (min-width: ${v.laptopLg}) {
    padding: ${v.spacingXl} 0;
  }
`;
export const LandingPageFooterWrapper = styled.div`
  max-width: ${v.pageMaxWidthMd};
  margin: 0 auto;
  padding: ${v.spacingMd} calc(${v.spacingMd} * 1.5);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: ${v.laptopLg}) {
    padding: 0;
  }
`;
export const Copyrights = styled.span`
  opacity: 0.8;
  color: ${v.white};
  font-size: ${v.fontSizeSm};
`;
