import React, { useEffect, useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Prompt } from 'react-router-dom';
import { shape, bool, string } from 'prop-types';

import { routes } from 'routes/routes';
import { STEPS, schemaArray, initialValues } from './SignupSteps';
import ProgressBar from 'components/ProgressBar';
import { Container, Row } from 'components/Grid';
import Copyrights from 'components/Copyrights/Copyrights';
import logo from 'assets/riidelogo.png';
import styles from './SignUp.module.scss';

import {
  checkUserByEmail,
  verifyUser,
  checkUserByPhone,
  createUser,
  incrementSignUpStage,
  decrementSignUpStage,
  resendVerificationCode,
  resetSignUpSteps,
} from 'store/slices/signUpStepsSlice';
import { usePrevious } from 'hooks';
import { DEFAULT_COMPANY } from 'constants/companies';
import { StyledForm, StyledContainer } from 'views/Login/styles';
import Footer from './Footer';

const actionType = { pop: 'POP' };

const SignUp = ({
  history,
  hasToken,
  companyCountryCode,
  subdomain,
  appCompanyCode,
  appCompanyName,
  termsUrl,
  privacyUrl,
}) => {
  const { stage, isLoading, user } = useSelector((state) => state.signUpSteps);
  const { phone, id: userId } = user || {};
  const dispatch = useDispatch();
  const previousHasToken = usePrevious(hasToken);
  const fieldsForValidation = STEPS[stage].fields;
  const fieldsObject = fieldsForValidation.reduce((accumulator, value) => {
    return { ...accumulator, [value]: true };
  }, {});
  const isFirstStage = stage === 0;
  const isSecondStage = stage === 1;
  const isFirstOrSecondStage = isFirstStage || isSecondStage;

  useEffect(() => {
    if (!previousHasToken && hasToken) {
      setTimeout(() => {
        history.replace(routes.default);
      }, 0);
    }
  }, [history, hasToken, previousHasToken]);

  useEffect(() => {
    return dispatch(resetSignUpSteps());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickResend = useCallback(() => {
    dispatch(resendVerificationCode());
  }, [dispatch]);

  if (!subdomain) {
    const { protocol, host } = window.location;
    window.location = `${protocol}//${appCompanyCode}.${host}/signup`;
    return;
  }

  const validateStage = (_stage, values) => {
    if (_stage === 5) {
      return true;
    } else {
      return schemaArray[_stage].isValidSync(values);
    }
  };

  const handleStageDecrement = (resetForm, values) => {
    resetForm({
      values: {
        ...values,
        code_secret_1: '',
        code_secret_2: '',
        code_secret_3: '',
        code_secret_4: '',
        code_secret_5: '',
        code_secret_6: '',
      },
    });
    dispatch(decrementSignUpStage());
  };

  const handleSubmit = (values, { setTouched }) => {
    const isStageValid = validateStage(stage, values);
    setTouched(fieldsObject);

    const {
      code_secret_1,
      code_secret_2,
      code_secret_3,
      code_secret_4,
      code_secret_5,
      code_secret_6,
      first_name,
      last_name,
      phone_country,
      phone_number,
      iso2,
      email,
      password,
    } = values;
    if (isStageValid) {
      switch (stage) {
        case 0:
          dispatch(
            checkUserByPhone({
              phone_country,
              phone_number,
              isLogin: false,
            })
          );
          break;
        case 1:
          dispatch(
            verifyUser({
              code_secret_1,
              code_secret_2,
              code_secret_3,
              code_secret_4,
              code_secret_5,
              code_secret_6,
            })
          );
          break;
        case 2:
          dispatch(
            checkUserByEmail({
              email: values.email,
              isLogin: false,
            })
          );
          break;
        case 3:
        case 4:
          dispatch(incrementSignUpStage());
          break;
        case 5:
          dispatch(
            createUser({
              first_name,
              last_name,
              phone_country,
              phone_number,
              email,
              password,
              country_code: iso2?.toUpperCase() || 'GB',
            })
          );
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className={styles.SignUpWrapper}>
      <Prompt
        message={(location, action) => {
          if (action === actionType.pop) {
            // eslint-disable-next-line max-len
            return `Pressing OK will return you to the homescreen. You will lose any data saved to this point`;
          }
        }}
      />
      <Container className={styles.SignUpContainer}>
        <Row center withMarginLarge>
          <div className={styles.SignUpContent}>
            <img alt="riide logo" className={styles.SignUpLogo} src={logo} />
            <ProgressBar width={stage + 1} length={STEPS.length} />
            <Formik
              initialValues={initialValues}
              enableReinitialize={false}
              validationSchema={schemaArray[stage]}
              onSubmit={handleSubmit}
            >
              {({
                handleChange,
                handleBlur,
                values,
                errors,
                setTouched,
                touched,
                setFieldTouched,
                setFieldValue,
                resetForm,
              }) => {
                const isDisabled = !subdomain || isLoading;
                let props = {
                  values,
                  handleChange,
                  handleBlur,
                  errors,
                  touched,
                };
                if (stage === 0) {
                  props = {
                    values,
                    companyCountryCode,
                    isDisabled,
                  };
                } else if (stage === 1) {
                  props = {
                    values,
                    handleBlur,
                    errors,
                    touched,
                    setFieldValue,
                    phone,
                    onClickResend,
                    userId,
                    setFieldTouched,
                  };
                } else if (stage === 5) {
                  props = {
                    appCompanyName,
                    termsUrl,
                    privacyUrl,
                  };
                }
                return (
                  <StyledForm noValidate>
                    <StyledContainer $isLogin={isFirstOrSecondStage}>
                      {React.createElement(STEPS[stage].component, {
                        ...props,
                      })}
                    </StyledContainer>
                    <Footer
                      setTouched={setTouched}
                      resetForm={resetForm}
                      values={values}
                      isDisabled={isDisabled}
                      isLoading={isLoading}
                      stage={stage}
                      handleStageDecrement={handleStageDecrement}
                      handleSubmit={handleSubmit}
                      validateStage={validateStage}
                      isSecondStage={isSecondStage}
                      isFirstOrSecondStage={isFirstOrSecondStage}
                    />
                  </StyledForm>
                );
              }}
            </Formik>
          </div>
        </Row>
      </Container>
      <div className={styles.CopyrightsWrapper}>
        <Copyrights />
      </div>
    </div>
  );
};

SignUp.propTypes = {
  history: shape({}).isRequired,
  hasToken: bool.isRequired,
  companyCountryCode: string.isRequired,
  subdomain: string,
  appCompanyCode: string,
  appCompanyName: string,
  termsUrl: string,
  privacyUrl: string,
};
SignUp.defaultProps = {
  appCompanyCode: DEFAULT_COMPANY,
  appCompanyName: 'Riide',
  termsUrl: '',
  privacyUrl: '',
};

const areEqual = (prevProps, nextProps) =>
  prevProps.hasToken === nextProps.hasToken &&
  prevProps.companyCountryCode === nextProps.companyCountryCode &&
  prevProps.appCompanyCode === nextProps.appCompanyCode &&
  prevProps.appCompanyName === nextProps.appCompanyName &&
  prevProps.termsUrl === nextProps.termsUrl &&
  prevProps.privacyUrl === nextProps.privacyUrl &&
  prevProps.subdomain === nextProps.subdomain;

export default memo(SignUp, areEqual);
