import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  memo,
  useMemo,
} from 'react';
import { func, arrayOf, string, shape, bool, number } from 'prop-types';
import IntlTelInput from 'react-intl-tel-input';
import 'scss/overrides/intltelinput.scss';
import { Wrapper } from './styles';
import { usePrevious } from 'hooks';
import InputError from 'components/InputError';

const PhoneNumberInput = ({
  setFieldValue,
  setFieldTouched,
  preferredCountries,
  fieldNames: { phone, phoneCountry, phoneNumber, phoneCountryISO },
  defaultCountry,
  containerClass,
  onPhoneNumberBlur,
  isLarge,
  defaultValue,
  isDisabled,
  onChange,
  formatOnInit,
  error,
  hasError,
  errorAbsolute,
  zIndex,
  ...rest
}) => {
  const telRef = useRef();
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const prevValue = usePrevious(value);
  const prevIsValid = usePrevious(value);

  const reference = useRef();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(reference.current?.clientWidth);
  }, [width]);

  const moveCaretToEnd = useCallback(() => {
    setTimeout(() => {
      if (telRef.current?.tel && value?.length) {
        telRef.current.tel.setSelectionRange(value.length, value.length);
      }
    }, 0);
  }, [value]);

  useEffect(() => {
    if (
      prevValue !== undefined &&
      prevValue !== value &&
      prevIsValid !== isValid
    ) {
      moveCaretToEnd();
    }
  }, [value, prevValue, isValid, moveCaretToEnd, prevIsValid]);

  useEffect(() => {
    moveCaretToEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iso2 = useMemo(() => defaultCountry?.toLowerCase(), [defaultCountry]);

  const handlePhoneChange = ({ isValidInput, number, object, formatted }) => {
    if (isValid !== isValidInput) setIsValid(isValidInput);
    if (phoneCountryISO) setFieldValue(phoneCountryISO, object?.iso2);

    const formattedNumber = formatted
      .replace(`+${object?.dialCode}`, '')
      .trim();

    setValue(formattedNumber);

    const plainNumber = number.replace(/\s/g, '');

    setFieldValue(phoneCountry, object?.dialCode);
    setFieldValue(phoneNumber, plainNumber);
    setFieldValue(phone, `${object?.dialCode}${plainNumber}`);

    if (onChange) {
      onChange(plainNumber);
    }
    if (setFieldTouched) {
      setFieldTouched(phone, false);
      setFieldTouched(phoneNumber, false);
    }
  };
  const onPhoneNumberChange = (isValidInput, number, object, formatted) => {
    handlePhoneChange({ number, object, formatted, isValidInput });
  };

  const onSelectFlag = (number, object, formatted, isValidInput) => {
    handlePhoneChange({ number, object, formatted, isValidInput });
  };

  const onPhoneNumberFocus = () => {
    moveCaretToEnd();
  };

  const handleBlur = (...arguments_) => {
    if (onPhoneNumberBlur) onPhoneNumberBlur();

    if (setFieldTouched && arguments_[1]?.length) {
      setFieldTouched(phone, true);
      setFieldTouched(phoneNumber, true);
    }
  };

  return (
    <Wrapper
      $isLarge={isLarge}
      $hasError={hasError}
      ref={reference}
      $width={width}
      $zIndex={zIndex}
    >
      <IntlTelInput
        {...rest}
        ref={telRef}
        disabled={isDisabled}
        onSelectFlag={onSelectFlag}
        onPhoneNumberChange={onPhoneNumberChange}
        inputClassName="input-tel-tel input-tel-booking"
        containerClassName={`intl-tel-input intl-tel-booking-input ${containerClass}`}
        separateDialCode
        onPhoneNumberBlur={handleBlur}
        preferredCountries={preferredCountries}
        fieldName={phone}
        defaultCountry={iso2}
        defaultValue={defaultValue}
        value={value}
        onPhoneNumberFocus={onPhoneNumberFocus}
        formatOnInit={formatOnInit}
      />
      {hasError && (
        <InputError
          hasError={hasError}
          error={error}
          absolute={errorAbsolute}
        />
      )}
    </Wrapper>
  );
};

PhoneNumberInput.propTypes = {
  handleChange: func,
  setFieldValue: func,
  preferredCountries: arrayOf(string),
  fieldNames: shape({
    phone: string,
    phoneCountry: string,
    phoneNumber: string,
    phoneCountryISO: string,
  }),
  setFieldTouched: func,
  defaultCountry: string,
  defaultValue: string,
  containerClass: string,
  onPhoneNumberBlur: func,
  errorCanFit: bool,
  isLarge: bool,
  isDisabled: bool,
  onChange: func,
  formatOnInit: bool,
  error: string,
  hasError: bool,
  errorAbsolute: bool,
  zIndex: number,
};

PhoneNumberInput.defaultProps = {
  handleChange: () => {},
  setFieldValue: () => {},
  setFieldTouched: () => {},
  preferredCountries: ['gb', 'ie', 'us', 'ca', 'fi'],
  fieldNames: {
    phone: 'phone',
    phoneCountry: 'phone_country',
    phoneNumber: 'phone_number',
    phoneCountryISO: 'iso2',
  },
  defaultValue: '',
  containerClass: '',
  onPhoneNumberBlur: null,
  errorCanFit: false,
  isLarge: false,
  isDisabled: false,
  defaultCountry: 'GB',
  formatOnInit: true,
  hasError: false,
  errorAbsolute: false,
  zIndex: 2,
};

export default memo(PhoneNumberInput);
