import React from 'react';

import { routes } from 'routes/routes';
import messages from './messages';
import TextWithLink from 'components/TextWithLink';

const NewAccountLink = () => (
  <TextWithLink
    text={messages.text}
    link={messages.link}
    to={routes.signup}
    testId="new-account-link"
  />
);

export default NewAccountLink;
