import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  title: null,
  subtitle: null,
  cta: '',
  cta2: 'Close',
  type: null,
  meta: {
    url: '',
    status: null,
  },
};

const setStatus = (state, { payload }) => {
  const title = payload.title ?? payload.message;

  state.title = title;
  state.subtitle = payload.body || null;
  state.cta = payload.cta?.trim() || '';
  state.cta2 = payload.cta2?.trim() || initialState.cta2;
  state.type = payload.type || null;
  if (payload.meta) {
    state.meta = payload.meta;
  }
};
const appStatusSlice = createSlice({
  name: 'appStatus',
  initialState,
  reducers: {
    setAppStatus: setStatus,
    clearAppStatus(state, action) {
      return (state = initialState);
    },
    setAppError(state, { payload }) {
      setStatus(state, { payload: { ...payload, type: 'error' } });
    },
    setAppInfo(state, { payload }) {
      setStatus(state, { payload: { ...payload, type: 'info' } });
    },
    setAppSuccess(state, { payload }) {
      setStatus(state, { payload: { ...payload, type: 'success' } });
    },
  },
});

export const {
  setAppStatus,
  clearAppStatus,
  setAppSuccess,
  setAppWarning,
  setAppError,
} = appStatusSlice.actions;

export default appStatusSlice.reducer;
