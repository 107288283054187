export const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

export const getSubdomain = () => {
  const MAIN_DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;
  const mainDomainIndex = window.location.hostname.indexOf(MAIN_DOMAIN);
  return mainDomainIndex > 0
    ? window.location.hostname.slice(0, mainDomainIndex - 1)
    : null;
};

export const hasRelativePath = window.location.pathname !== '/';

const companyCodes = {
  lynk: 'lynk',
  skyline: 'skyline',
  citytaxis: 'citytaxi',
  alphataxis: 'alphacar',
  blueline: 'blueline',
  adt: 'adt',
  panther: 'panther',
  southside: 'ssiderc',
  gocars: 'gocars',
  clubcars: 'clubm',
  datacars: 'datacars',
  stationtaxis: 'stat',
  spearhead: 'spearhea',
  hull: 'hulc',
  '001': '001',
  '247carz': '247carz',
  a1taxis: 'a1taxis',
  starline: 'stln',
  fleet: 'fce',
  speedline: 'sdln',
  eastwood: 'ewm',
  deltataxis: 'dtcs',
  canihaveataxi: 'mxc',
  fonacab: 'fonacab',
  meneva: 'men',
};
export const companyCodeFromSubdomain = () => {
  const subdomain = getSubdomain();
  if (companyCodes[subdomain]) return companyCodes[subdomain];
  return subdomain;
};
