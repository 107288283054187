import styled from 'styled-components';
import * as v from 'utils/variables';

export const SearchWrapper = styled.div`
  position: relative;
  z-index: 3;
`;
export const SearchResults = styled.div`
  border: 1px solid ${v.palegray};
  border-top: none;
  padding: ${v.spacingSm} 0;
  color: ${v.charCoal};
  font-size: ${v.fontSizeSm};
  z-index: 2;
  position: absolute;
  width: 100%;
  background: ${v.white};
  top: 2.5em;
  left: 0;
  max-height: 300px;
  overflow-y: scroll;
`;
export const SearchResultsItem = styled.button`
  cursor: pointer;
  padding: ${v.spacingSm};
  display: flex;
  width: 100%;
  border: none;
  font-size: ${v.fontSizeSm};
  text-align: left;
  &:hover {
    background: ${v.dark};
  }
  outline: none;
`;
