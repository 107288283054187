import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { bool, string, func, shape, object } from 'prop-types';
import { StyledButton } from './styles';

const ButtonWithIcon = ({
  type,
  isDisabled,
  isLoading,
  onClick,
  title,
  dataTestId,
  icon,
  iconProps,
  ...otherProps
}) => {
  return (
    <StyledButton
      data-testid={dataTestId}
      type={type}
      disabled={isDisabled || isLoading}
      isLoading={isLoading}
      onClick={onClick}
      {...otherProps}
    >
      {isLoading ? (
        <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
      ) : (
        <>
          {icon && <FontAwesomeIcon icon={icon} {...iconProps} />}
          {title}
        </>
      )}
    </StyledButton>
  );
};

ButtonWithIcon.propTypes = {
  type: string,
  isDisabled: bool,
  isLoading: bool,
  onClick: func,
  title: string,
  dataTestId: string,
  icon: object,
  iconProps: shape({}),
};

ButtonWithIcon.defaultProps = {
  onClick: () => null,
  dataTestId: 'button',
  primary: false,
  isDisabled: false,
  isLoading: false,
  title: '',
  type: 'button',
  icon: null,
  iconProps: {
    color: 'white',
  },
};

export default ButtonWithIcon;
