import React from 'react';
import { number, shape, string, func, bool, oneOfType } from 'prop-types';

import LabelContainer from './LabelContainer';
import InputNew from 'components/InputNew';
import { COLUMN_INPUT_HEIGHT } from 'utils/variables';

const WithInput = ({
  name,
  id = name,
  value,
  onChange,
  onBlur,
  error,
  label,
  labelSize,
  inputSize,
  hasError,
  errorCanFit,
  isColumn,
  labelStyle,
  isDisabled,
  testId,
  type,
  style,
  inputStyle,
  errorPositionAbsolute,
  hasOffset,
  ...inputProps
}) => {
  return (
    <LabelContainer
      testId={testId}
      id={id}
      label={label}
      labelSize={labelSize}
      inputSize={inputSize}
      isColumn={isColumn}
      labelStyle={labelStyle}
      hasError={hasError}
      error={error}
      style={style}
      hasOffset={hasOffset}
    >
      <InputNew
        height={isColumn && COLUMN_INPUT_HEIGHT}
        value={value}
        name={name}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
        isDisabled={isDisabled}
        type={type}
        hasError={hasError}
        error={error}
        errorCanFit={errorCanFit}
        style={inputStyle}
        errorPositionAbsolute={errorPositionAbsolute}
        {...inputProps}
      />
    </LabelContainer>
  );
};

WithInput.propTypes = {
  value: oneOfType([number, string]),
  onChange: func,
  onBlur: func,
  id: string,
  name: string,
  label: string,
  labelSize: number,
  inputSize: number,
  error: string,
  hasError: bool,
  errorCanFit: bool,
  isColumn: bool,
  testId: string,
  isDisabled: bool,
  type: string,
  style: shape({}),
  labelStyle: shape({}),
  inputStyle: shape({}),
  errorPositionAbsolute: bool,
  hasOffset: bool,
};
WithInput.defaultProps = {
  labelSize: 4,
  inputSize: 8,
  hasError: false,
  error: null,
  isColumn: false,
  testId: 'label-with-input',
  isDisabled: false,
  type: 'text',
  style: {},
  labelStyle: {},
  inputStyle: {},
  errorPositionAbsolute: true,
  onChange: () => null,
  hasOffset: true,
};

export default WithInput;
