import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import _keyBy from 'lodash/keyBy';
import _compact from 'lodash/compact';
import _map from 'lodash/map';
import isEqual from 'lodash/isEqual';

import { setCompanyCode } from './companySlice';
import { setAppSuccess } from './appStatusSlice';
import {
  fetchAllBookings,
  fetchActiveBookings,
  fetchRequestedBookings,
  fetchHistoricalBookings,
  fetchBookingById,
  cancelBookingRequest,
  createBookingRequest,
  createGuestUserRequest,
  getPreauthIdRequest,
  updateBookingRequest,
  getBookingStateRequest,
  sendMessageToDriverRequest,
} from 'api/bookings';
import { setRedirectTo3DAuth } from './accountsSlice';

import { getMapRequest } from 'api/map';
import { searchAddressRequest } from 'api/address';
import { searchUsersRequest } from 'api/users';

import { ACTIVITY, ALL, ACTIVE, UPCOMING, HISTORICAL } from 'utils/tabs';
import {
  failed,
  loading,
  fulfilled,
  unshiftOrSet,
} from 'store/helpers/sliceHelpers';
import { setActivePrimaryTab } from 'store/slices/tabsSlice';
import { isInAirportArea } from 'utils/mapUtils';
import { MIN_PREBOOK_TIME } from 'constants/companies';
import { PICKUP_TYPES, DEFAULT_TIME_ZONE } from 'constants/booking';
import { ACCOUNT_ROLE_MANAGER } from 'constants/accountUserRoles';
import { PAYMENT_METHODS } from 'constants/paymentMethods';

const initialPassenger = {
  firstName: '',
  lastName: '',
  id: null,
  type: '',
  phone: '',
  userRfbPaymentMethodId: null,
};
const initialBookingForm = {
  isCreatedPrebooking: null,
  isCreated: null,
  isUpdated: null,
  isLoading: null,
  isCreating: null,
  isUpdating: null,
  error: null,
  serviceStatus: {
    isLoading: null,
    hasService: null,
  },
  values: {},
  data: {
    destination: {
      details: {
        lat: null,
        lng: null,
        address: null,
      },
      isLoading: null,
      error: null,
      result: [],
    },
    pickup: {
      details: {
        lat: null,
        lng: null,
        address: null,
      },
      isLoading: null,
      error: null,
      isInAirport: false,
      result: [],
      eta: {
        isLoading: false,
        value: null,
      },
    },
    other: {
      details: {
        lat: null,
        lng: null,
        address: null,
      },
      isLoading: null,
      error: null,
      result: [],
    },
    brandedCarImageUrl: '',
    vehicleTypes: [],
    timezone: DEFAULT_TIME_ZONE,
    driverLocations: [],
    searchUsersResult: [],
    selectedPassenger: initialPassenger,
    minPrebookTime: MIN_PREBOOK_TIME,
  },
};
export const initialState = {
  isLoading: false,
  isCancelling: false,
  error: null,
  currentBooking: {
    id: null,
    hasFulfilled: null,
    hasError: null,
    isLoading: null,
  },
  data: {
    byAccountId: {},
    all: {
      isLoading: null,
      error: null,
      idsByAccountIdAndPage: {},
      metaByAccountId: {},
    },
    active: {
      isLoading: null,
      error: null,
      idsByAccountIdAndPage: {},
      metaByAccountId: {},
    },
    upcoming: {
      isLoading: null,
      error: null,
      idsByAccountIdAndPage: {},
      metaByAccountId: {},
    },
    historical: {
      isLoading: null,
      error: null,
      idsByAccountIdAndPage: {},
      metaByAccountId: {},
    },
  },
  pendingBooking: {},
  newBookingForm: initialBookingForm,
  blockedUser: {
    error: false,
    userName: '',
    company: '',
  },
  bookingCompany: {
    extra: {
      company: {
        configs: {
          booking: {
            preauth: null,
          },
        },
      },
    },
  },
  driverMessages: {
    isLoading: null,
    messagesByBookingId: {},
  },
};

export const getPreauthId = createAsyncThunk(
  'bookings/getPreauthId',
  async (
    {
      values = {},
      updatedFields = null,
      timezone = null,
      card_id,
      last4,
      currentAccount = null,
    },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const {
        current,
        paymentMethods: _paymentMethods,
      } = getState().accounts.data;
      const account = currentAccount ?? current;
      const paymentMethods = _paymentMethods[account.id];
      const companyTimezone = timezone ?? getState().company.data.timezone;
      const payInCarPaymentMethod = paymentMethods?.find(
        (method) => method.type === PAYMENT_METHODS['pay-in-car']
      );
      const preauthData = {
        ...values,
        timezone: companyTimezone,
        card_id,
      };
      const { data } = await getPreauthIdRequest(
        preauthData,
        account,
        payInCarPaymentMethod
      );
      const preauthId = data?.id;
      const action = data?.authenticatable?.action;
      if (action) {
        dispatch(setNewBookingFormValues(values));
        dispatch(
          setRedirectTo3DAuth({
            willContinueBooking: values.type === PICKUP_TYPES.ASAP,
            isBooking: {
              values,
              updatedFields,
              last4,
              timezone: companyTimezone,
              preauthId,
              action,
            },
            visible: true,
          })
        );
        return { preauthId: undefined };
      } else {
        return { preauthId };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);
export const getMap = createAsyncThunk(
  'bookings/getMap',
  async (
    {
      topLeftLat,
      topLeftLng,
      bottomRightLat,
      bottomRightLng,
      markerLat,
      markerLng,
    },
    { rejectWithValue, dispatch, getState, signal }
  ) => {
    if (
      !(
        topLeftLat &&
        topLeftLng &&
        bottomRightLat &&
        bottomRightLng &&
        markerLat &&
        markerLng
      )
    )
      return;
    try {
      const source = axios.CancelToken.source();
      signal.addEventListener('abort', () => {
        source.cancel();
      });
      const { data } = await getMapRequest(
        topLeftLat,
        topLeftLng,
        bottomRightLat,
        bottomRightLng,
        markerLat,
        markerLng,
        source.token
      );
      const { has_service, company_code } = data;
      if (has_service) {
        dispatch(setCompanyCode(company_code));
      }
      return { data, location: { lat: markerLat, lng: markerLng } };
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const searchUsers = createAsyncThunk(
  'booking/searchUsers',
  async ({ name, accountId }, { rejectWithValue, getState, signal }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    try {
      const id = accountId ?? getState().accounts.data.current?.id;
      const { data } = await searchUsersRequest(name, id, source.token);
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const searchAddress = createAsyncThunk(
  'booking/searchAddress',
  async (
    { address, type, lat, lng },
    { rejectWithValue, getState, signal }
  ) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    try {
      const { data } = await searchAddressRequest(
        address,
        type,
        lat,
        lng,
        source.token
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAllBookings = createAsyncThunk(
  'bookings/all',
  async (
    { page, accountId, source, accountChanged = false, isAuto = false },
    { getState, rejectWithValue, signal }
  ) => {
    const axiosSource = source || axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      axiosSource.cancel();
    });
    try {
      const id = accountId ?? getState().accounts.data.current?.id;
      const currentPage =
        page ||
        getState().bookings.data.all.metaByAccountId[id]?.currentPage ||
        1;
      if (id && currentPage) {
        const { data, meta } = await fetchAllBookings(
          currentPage,
          id,
          axiosSource.token
        );
        return { data, meta, accountId: id };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getActiveBookings = createAsyncThunk(
  'bookings/active',
  async (
    { page, accountId, source, accountChanged = false },
    { getState, rejectWithValue, signal }
  ) => {
    const axiosSource = source || axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      axiosSource.cancel();
    });
    try {
      const id = accountId ?? getState().accounts.data.current?.id;
      const currentPage =
        page ||
        getState().bookings.data.active.metaByAccountId[id]?.currentPage ||
        1;
      if (id && currentPage) {
        const { data, meta } = await fetchActiveBookings(
          currentPage,
          id,
          axiosSource.token
        );
        return { data, meta };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getRequestedBookings = createAsyncThunk(
  'bookings/upcoming',
  async (
    { page, accountId, source, accountChanged = false },
    { getState, rejectWithValue, signal }
  ) => {
    const axiosSource = source || axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      axiosSource.cancel();
    });
    try {
      const id = accountId ?? getState().accounts.data.current?.id;
      const currentPage =
        page ||
        getState().bookings.data.upcoming.metaByAccountId[id]?.currentPage ||
        1;

      if (id && currentPage) {
        const { data, meta } = await fetchRequestedBookings(
          currentPage,
          id,
          axiosSource.token
        );
        return { data, meta };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getHistoricalBookings = createAsyncThunk(
  'bookings/historical',
  async (
    { page, accountId, source, accountChanged = false },
    { getState, rejectWithValue, signal }
  ) => {
    const axiosSource = source || axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      axiosSource.cancel();
    });
    try {
      const id = accountId ?? getState().accounts.data.current?.id;
      const currentPage =
        page ||
        getState().bookings.data.historical.metaByAccountId[id]?.currentPage ||
        1;
      if (id && currentPage) {
        const { data, meta } = await fetchHistoricalBookings(
          currentPage,
          id,
          axiosSource.token
        );
        return { data, meta };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getBookingById = createAsyncThunk(
  'bookings/byId',
  async ({ id, accountId }, { getState, rejectWithValue, extra }) => {
    try {
      const { data, meta } = await fetchBookingById(id);
      return { data, meta, accountId };
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const cancelBooking = createAsyncThunk(
  'bookings/cancel',
  async (id, { getState, rejectWithValue, extra, dispatch }) => {
    try {
      await cancelBookingRequest(id);
      dispatch(
        setAppSuccess({
          title: 'Booking is cancelled',
        })
      );
      const accountId = getState().accounts.data.current.id;
      return { id, accountId };
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const createBooking = createAsyncThunk(
  'bookings/create',
  async (
    { values, timezone, guestUserId, referenceElement },
    { getState, rejectWithValue, dispatch }
  ) => {
    const {
      current,
      paymentMethods: _paymentMethods,
    } = getState().accounts.data;
    const { id: accountId, rfbDefaultPayment } = current;
    const paymentMethods = _paymentMethods[accountId];
    const payInCarPaymentMethod = paymentMethods?.find(
      (method) => method.type === PAYMENT_METHODS['pay-in-car']
    );
    const isNotRfbAccount = rfbDefaultPayment?.isNotRfb;
    const {
      id,
      rfb_payment_method,
      user_id,
      pickup_at,
      preauthId,
      default_phone,
      validation_fields = [],
      ...rest
    } = values;
    const isAsapBooking = values.type === PICKUP_TYPES.ASAP;
    const isCard =
      paymentMethods?.find((method) => method.id === rfb_payment_method)
        ?.type === PAYMENT_METHODS.card ||
      current.type === PAYMENT_METHODS.card;

    let requestData = {
      ...rest,
      pickup_at: isAsapBooking ? '' : pickup_at,
      rfb_payment_method_id: isNotRfbAccount
        ? undefined
        : rfb_payment_method || payInCarPaymentMethod.id,
      user_id: guestUserId ? guestUserId : user_id,
      idempotency_key: uuidv4(),
      payment_type: PAYMENT_METHODS.account,
      account_id: accountId,
    };
    if (preauthId) {
      requestData = {
        ...requestData,
        preauth_id: preauthId,
      };
    }
    if (Object.keys(validation_fields).length) {
      requestData = {
        ...requestData,
        account_validation_fields: validation_fields,
      };
    }
    try {
      const response = await createBookingRequest(
        requestData,
        isCard,
        referenceElement
      );
      if (response?.data) {
        await dispatch(setActivePrimaryTab(ACTIVITY));
        return { data: response.data, accountId };
      }
    } catch (error) {
      if (error.response?.status === 447) {
        const appCompanyName = getState().company.appCompanyName;
        dispatch(
          setBlockedUser({
            error: true,
            userName: values.username,
            company: appCompanyName,
          })
        );
      }
      return rejectWithValue(error.response?.data);
    }
  }
);

export const updateBooking = createAsyncThunk(
  'bookings/update',
  async (
    { id, values, guestUserId, referenceElement },
    { getState, rejectWithValue, extra, dispatch }
  ) => {
    const {
      paymentMethods: _paymentMethods,
      current,
    } = getState().accounts.data;
    const { id: accountId } = current;
    const paymentMethods = _paymentMethods[accountId];
    const { rfb_payment_method, preauthId, ...rest } = values;
    let requestData = {
      ...rest,
      user_id: guestUserId ? guestUserId : values.user_id,
    };
    let isCard = false;

    if (values.rfb_payment_method) {
      requestData = {
        ...requestData,
        rfb_payment_method_id: values.rfb_payment_method,
        account_id: accountId,
        payment_type: PAYMENT_METHODS.account,
      };
      isCard =
        paymentMethods?.find((method) => method.id === rfb_payment_method)
          ?.type === PAYMENT_METHODS.card;

      if (preauthId) {
        requestData = {
          ...requestData,
          preauth_id: preauthId,
        };
      }
    }
    try {
      const response = await updateBookingRequest(
        id,
        requestData,
        isCard,
        referenceElement
      );
      if (response?.data) {
        return { data: response.data, accountId };
      }
    } catch (error) {
      if (error.response?.status === 447) {
        const appCompanyName = getState().company.appCompanyName;
        dispatch(
          setBlockedUser({
            error: true,
            userName: values.username,
            company: appCompanyName,
          })
        );
      }
      return rejectWithValue(error.response?.data);
    }
  }
);

export const createGuestBooking = createAsyncThunk(
  'bookings/createGuestBooking',
  async (
    { values, timezone, accountId, referenceElement },
    { getState, rejectWithValue, extra, dispatch }
  ) => {
    const id = accountId ?? getState().accounts.data.current?.id;

    const { data } = await createGuestUserRequest(values, id);
    dispatch(
      createBooking({
        values,
        timezone,
        guestUserId: data.id,
        referenceElement,
      })
    );
  }
);

export const createGuestAndUpdateBooking = createAsyncThunk(
  'bookings/createGuestAndUpdateBooking',
  async (
    { values, booking, accountId, referenceElement },
    { getState, rejectWithValue, extra, dispatch }
  ) => {
    const id = accountId ?? getState().accounts.data.current?.id;

    const { data } = await createGuestUserRequest(values, id);
    dispatch(
      updateBooking({
        id: values.id,
        values: booking,
        guestUserId: data.id,
        referenceElement,
      })
    );
  }
);

export const sendMessageToDriver = createAsyncThunk(
  'bookings/sendMessageToDriver',
  async ({ id, message }, { getState, rejectWithValue, extra, dispatch }) => {
    try {
      const { data } = await sendMessageToDriverRequest(id, message);
      if (data) {
        return { id, message, data };
      }
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getBookingState = createAsyncThunk(
  'bookings/state',
  async (
    { id, currentState },
    { getState, rejectWithValue, extra, dispatch }
  ) => {
    try {
      const { data } = await getBookingStateRequest(id);
      if (currentState !== data.state) {
        const accountId = getState().accounts.data.current?.id;
        dispatch(getBookingById({ id, accountId }));
      }
      return { currentState, state: data.state, id };
    } catch (error) {
      return rejectWithValue(error.response?.data);
    }
  }
);

const messageToDriverLoading = (state, { id, message }) => {
  state.driverMessages.isLoading = true;
};
const messageToDriverFulfilled = (state, { payload }) => {
  const { id, message } = payload || {};

  state.driverMessages.isLoading = false;

  if (!id || !message) return;
  state.driverMessages.messagesByBookingId[id] = message;
};
const messageToDriverFailed = (state, { id, message }) => {
  state.driverMessages.isLoading = false;
};
const bookingStateLoading = (state) => {
  state.currentBooking.isLoading = true;
  state.currentBooking.hasFulfilled = false;
  state.currentBooking.hasError = false;
};
const bookingStateFulfilled = (state, { payload }) => {
  state.currentBooking.isLoading = false;
  state.currentBooking.hasFulfilled = true;
  state.currentBooking.hasError = false;
};

const bookingStateFailed = (state) => {
  state.currentBooking.isLoading = false;
  state.currentBooking.hasFulfilled = false;
  state.currentBooking.hasError = true;
};

const bookingsLoading = (state, { meta }, key) => {
  const { accountId, isAuto = false, page } = meta.arg;
  if (!isAuto) state.data[key].isLoading = true;
  state.data[key].error = null;
  const currentPage =
    page || state.data[key].metaByAccountId[accountId]?.currentPage || 1;

  if (!state.data[key].idsByAccountIdAndPage?.[accountId]?.[currentPage]) {
    state.data[key].idsByAccountIdAndPage[accountId] = {
      ...(state.data[key].idsByAccountIdAndPage[accountId] || {}),
      [currentPage]: [],
    };
  }
  if (!state.data.byAccountId[accountId]) {
    state.data.byAccountId[accountId] = {};
  }
  if (!state.data[key].metaByAccountId[accountId]) {
    state.data[key].metaByAccountId[accountId] = {
      totalPages: 1,
      currentPage: 1,
      total: 1,
    };
  }
  if (!isAuto && page && state.data[key].metaByAccountId[accountId]) {
    state.data[key].metaByAccountId[accountId].currentPage = page;
  }
};

const bookingsFailed = (state, action, key) => {
  if (!action.meta.aborted) state.data[key].isLoading = false;
  if (action.payload) {
    state.data[key].error = action.payload.message;
  } else {
    state.data[key].error = action.error.message;
  }
};

const bookingsFulfilled = (state, { payload, meta }, key) => {
  state.data[key].isLoading = false;
  state.data[key].error = null;
  if (!payload && !meta) return;

  const { total_pages, total, current_page } = payload.meta?.pagination;
  const { accountId } = meta.arg;
  const ids = _map(payload.data, 'id');
  const byId = _keyBy(payload.data, 'id');

  if (
    state.data[key].idsByAccountIdAndPage[accountId]?.[current_page] &&
    !isEqual(
      state.data[key].idsByAccountIdAndPage[accountId]?.[current_page],
      ids
    )
  ) {
    state.data[key].idsByAccountIdAndPage[accountId][current_page] = ids;
  } else {
    state.data[key].idsByAccountIdAndPage[accountId] = {
      ...state.data[key].idsByAccountIdAndPage[accountId],
      [current_page]: ids,
    };
  }

  if (!isEqual(state.data.byAccountId[accountId], byId)) {
    state.data.byAccountId[accountId] = {
      ...state.data.byAccountId[accountId],
      ...byId,
    };
  }

  state.data[key].metaByAccountId[accountId] = {
    totalPages: total_pages,
    total,
    currentPage: current_page,
  };
};

const getBookingByIdFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  // state.currentBooking.data = payload.data;

  const { id } = payload.data;
  state.data.byAccountId[payload.accountId][id] = payload.data;
};

const cancelBookingPending = (state) => {
  state.isCancelling = true;
};
const cancelBookingFailed = (state) => {
  state.isCancelling = false;
};
const cancelBookingFulfilled = (state, { payload, meta }) => {
  state.isCancelling = false;
  state.error = null;

  const { id } = payload;
  if (id && state.data.byAccountId[payload.accountId][id]) {
    state.data.byAccountId[payload.accountId][id].state = 'cancelled-user';
  }
};

const searchAddressLoading = (state, { meta }) => {
  const { type } = meta?.arg;
  if (type) {
    loading(state.newBookingForm.data[type]);
  }
};
const searchAddressFulfilled = (state, { payload, meta }) => {
  const { type } = meta?.arg;
  if (!type) return;

  state.newBookingForm.data[type].isLoading = false;
  state.newBookingForm.data[type].error = null;

  if (!isEqual(state.newBookingForm.data[type].result, payload)) {
    state.newBookingForm.data[type].result = payload;
  }
};
const searchAddressFailed = (state, action) => {
  const { type } = action?.meta?.arg;

  state.newBookingForm.data[type].isLoading =
    action.error?.message === 'Aborted' ? null : false;
  if (action.payload) {
    state.newBookingForm.data[type].error = action.payload?.data?.message;
  } else {
    state.newBookingForm.data[type].error = action.error.message;
  }
};

const getMapLoading = (state) => {
  loading(state.newBookingForm);
  state.newBookingForm.serviceStatus = {
    isLoading: true,
    hasService: null,
  };
  state.newBookingForm.data.pickup.eta.isLoading = true;
};
const getMapFulfilled = (state, { payload }) => {
  state.newBookingForm.isLoading = false;
  state.newBookingForm.error = null;

  const {
    can_book_vehicle_type,
    extra,
    has_service,
    driver_locations,
    minimum_prebooking_time,
    eta,
    pois,
  } = payload?.data;
  state.newBookingForm.serviceStatus = {
    isLoading: false,
    hasService: has_service,
  };
  state.newBookingForm.isLoading = false;

  if (has_service) {
    if (pois.length && payload.location) {
      const flight_no_required_pre =
        extra?.company?.configs?.booking?.flight_no_required_pre || false;
      const isInAirport =
        (isInAirportArea(pois, payload.location)?.some((item) => !!item) &&
          flight_no_required_pre) ||
        false;
      state.newBookingForm.data.pickup.isInAirport = isInAirport;
    } else {
      state.newBookingForm.data.pickup.isInAirport = false;
    }
    state.newBookingForm.data.driverLocations = driver_locations;
    state.newBookingForm.data.timezone = extra?.company?.timezone;
    state.newBookingForm.data.brandedCarImageUrl =
      extra?.company?.theme?.default_pin?.image_url;

    if (can_book_vehicle_type && extra?.vehicle_types) {
      state.newBookingForm.data.vehicleTypes = extra.vehicle_types;
    }
    state.newBookingForm.data.minPrebookTime = minimum_prebooking_time;
    state.newBookingForm.data.pickup.eta.value = eta;
    state.newBookingForm.data.pickup.eta.isLoading = false;
    state.bookingCompany = payload.data;
  }
};
const getMapFailed = (state, action) => {
  failed(state.newBookingForm, action);
  state.newBookingForm.serviceStatus = {
    isLoading: action.error?.message === 'Aborted' ? null : false,
    hasService: false,
  };
  state.newBookingForm.data.pickup.eta.value = null;
  state.newBookingForm.data.pickup.eta.isLoading = false;
};
const searchUsersLoading = (state, action) => {
  loading(state.newBookingForm);
};
const searchUsersFulfilled = (state, { payload }) => {
  state.newBookingForm.isLoading = false;
  state.newBookingForm.error = null;
  const filteredPassengerSearchResults = payload?.filter(
    ({ type, user: { phone_number } }) => {
      const isFrontdesk = type === ACCOUNT_ROLE_MANAGER && !phone_number;
      return !isFrontdesk;
    }
  );
  const searchUsersResult = filteredPassengerSearchResults.map(
    ({ id, type, user, first_name, last_name, rfb_payment_method_id }) => ({
      firstName: first_name,
      lastName: last_name,
      phone: `${user.phone_country}${user.phone_number}`,
      id: user.id,
      type,
      userRfbPaymentMethodId: rfb_payment_method_id,
    })
  );
  state.newBookingForm.data.searchUsersResult = searchUsersResult;
};
const searchUsersFailed = (state, action) => {
  failed(state.newBookingForm, action);
};
const createBookingLoading = (state) => {
  state.newBookingForm.isCreated = null;
  state.newBookingForm.isCreatedPrebooking = null;
  state.newBookingForm.isCreating = true;
  state.currentBooking = initialState.currentBooking;
  loading(state.newBookingForm);
};
const createBookingFulfilled = (state, { payload }) => {
  state.newBookingForm.isCreated = true;
  state.newBookingForm.isCreating = false;
  fulfilled(state.newBookingForm);
  if (!payload?.data) return;

  const { data, accountId } = payload;
  state.currentBooking.id = data.id;
  state.data.byAccountId[accountId][data.id] = data;

  const { currentPage: activeCurrentPage } = state.data.active.metaByAccountId[
    accountId
  ];
  const { currentPage: allCurrentPage } = state.data.all.metaByAccountId[
    accountId
  ];

  state.data.active.idsByAccountIdAndPage[accountId][
    activeCurrentPage
  ] = unshiftOrSet(
    state.data.active.idsByAccountIdAndPage[accountId][activeCurrentPage],
    data.id
  );
  state.data.all.idsByAccountIdAndPage[accountId][
    allCurrentPage
  ] = unshiftOrSet(
    state.data.all.idsByAccountIdAndPage[accountId][allCurrentPage],
    data.id
  );

  if (data.type === PICKUP_TYPES.PRE_BOOKING) {
    state.newBookingForm.isCreatedPrebooking = true;
    const {
      currentPage: upcomingCurrentPage,
    } = state.data.upcoming.metaByAccountId[accountId];
    state.data.upcoming.idsByAccountIdAndPage[accountId][
      upcomingCurrentPage
    ] = unshiftOrSet(
      state.data.upcoming.idsByAccountIdAndPage[accountId][upcomingCurrentPage],
      data.id
    );
  }
  if (state.pendingBooking.preauthId) {
    state.pendingBooking = initialState.pendingBooking;
  }
  state.newBookingForm.data = initialBookingForm.data;
  state.newBookingForm.values = initialBookingForm.values;
};
const createBookingFailed = (state, action) => {
  state.newBookingForm.isCreated = false;
  state.newBookingForm.isCreatedPrebooking = false;
  state.newBookingForm.isCreating = false;
  state.currentBooking = initialState.currentBooking;
  if (action.payload?.code === 459) {
    state.newBookingForm.error = action.payload.code;
  }
};

const updateBookingLoading = (state) => {
  state.newBookingForm.isUpdated = null;
  state.newBookingForm.isUpdating = true;
  loading(state.newBookingForm);
};
const updateBookingFulfilled = (state, { payload, meta }) => {
  state.newBookingForm.isUpdated = true;
  state.newBookingForm.isUpdating = false;
  fulfilled(state.newBookingForm);

  const { data, accountId } = payload;
  const bookingId = meta?.arg?.id;

  state.data.byAccountId[accountId][bookingId] = {
    ...state.data.byAccountId[accountId][bookingId],
    ...data,
  };
  if (state.pendingBooking.preauthId) {
    state.pendingBooking = initialState.pendingBooking;
  }
};
const updateBookingFailed = (state, action) => {
  state.newBookingForm.isUpdated = false;
  state.newBookingForm.isUpdating = false;
  state.newBookingForm.isLoading = false;
  if (action.payload?.code === 459) {
    state.newBookingForm.error = action.payload.code;
  }
};
const getPreauthLoading = (state) => {
  state.newBookingForm.isCreating = true;
  state.newBookingForm.isUpdating = true;
};
const getPreauthFulfilled = (state) => {
  state.newBookingForm.isCreating = false;
  state.newBookingForm.isUpdating = false;
};
const getPreauthFailed = (state) => {
  state.newBookingForm.isCreating = false;
  state.newBookingForm.isUpdating = false;
};
const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    setPendingBooking: (state, action) => {
      state.pendingBooking = action.payload;
    },
    clearPendingBooking: (state, action) => {
      state.pendingBooking = initialState.pendingBooking;
    },
    clearBookingForm: (state) => {
      state.newBookingForm = initialBookingForm;
    },
    clearBookingCompanyInfo: (state) => {
      state.bookingCompany = initialState.bookingCompany;
    },
    clearUsersResult: (state, action) => {
      state.newBookingForm.data.searchUsersResult = [];
    },
    setAddressDetailsByType: (state, action) => {
      if (
        !isEqual(
          state.newBookingForm.data[action.payload.type].details,
          action.payload.details
        )
      ) {
        state.newBookingForm.data[action.payload.type].details =
          action.payload.details;
      }
    },
    clearAddressDetailsByType: (state, action) => {
      if (
        !isEqual(
          state.newBookingForm.data[action.payload].details,
          initialBookingForm.data[action.payload].details
        )
      ) {
        state.newBookingForm.data[action.payload].details =
          initialBookingForm.data[action.payload].details;
      }
    },
    clearAddressResultByType: (state, action) => {
      if (
        !isEqual(
          state.newBookingForm.data[action.payload].result,
          initialBookingForm.data[action.payload].result
        )
      ) {
        state.newBookingForm.data[action.payload].result = [];
      }
    },
    selectPassenger: (state, action) => {
      state.newBookingForm.data.searchUsersResult = [];
      if (
        !isEqual(state.newBookingForm.data.selectedPassenger, action.payload)
      ) {
        state.newBookingForm.data.selectedPassenger = action.payload;
      }
    },
    clearPassenger: (state, action) => {
      if (
        !isEqual(
          state.newBookingForm.data?.selectedPassenger,
          initialBookingForm.data?.selectedPassenger
        )
      ) {
        state.newBookingForm.data.selectedPassenger =
          initialBookingForm.data.selectedPassenger;
      }
    },
    resetAddressLoadingByType: (state, action) => {
      if (
        !isEqual(
          state.newBookingForm.data[action.payload].isLoading,
          initialBookingForm.data[action.payload].isLoading
        )
      ) {
        state.newBookingForm.data[action.payload].isLoading = null;
      }
    },
    clearUpdatedBookingStatus: (state) => {
      state.newBookingForm.isUpdated = null;
    },
    clearCreatedBookingStatus: (state) => {
      state.newBookingForm.isCreated = null;
    },
    clearCreatedPrebookingStatus: (state) => {
      state.newBookingForm.isCreatedPrebooking = null;
    },
    setNewBookingFormValues: (state, action) => {
      state.newBookingForm.values = action.payload;
    },
    setBlockedUser: (state, action) => {
      state.blockedUser = action.payload;
    },
    setCurrentBookingId: (state, action) => {
      state.currentBooking.id = action.payload;
    },
    clearCurrentBooking: (state, action) => {
      state.currentBooking = initialState.currentBooking;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllBookings.pending, (state, action) =>
      bookingsLoading(state, action, 'all')
    );
    builder.addCase(getActiveBookings.pending, (state, action) =>
      bookingsLoading(state, action, 'active')
    );
    builder.addCase(getRequestedBookings.pending, (state, action) =>
      bookingsLoading(state, action, 'upcoming')
    );
    builder.addCase(getHistoricalBookings.pending, (state, action) =>
      bookingsLoading(state, action, 'historical')
    );
    builder.addCase(getBookingById.pending, (state) => loading(state));
    builder.addCase(cancelBooking.pending, cancelBookingPending);
    builder.addCase(searchAddress.pending, searchAddressLoading);
    builder.addCase(getMap.pending, getMapLoading);
    builder.addCase(searchUsers.pending, searchUsersLoading);
    builder.addCase(createBooking.pending, createBookingLoading);
    builder.addCase(updateBooking.pending, updateBookingLoading);
    builder.addCase(createGuestBooking.pending, createBookingLoading);

    builder.addCase(getBookingState.pending, bookingStateLoading);
    builder.addCase(sendMessageToDriver.pending, messageToDriverLoading);
    builder.addCase(getPreauthId.pending, getPreauthLoading);

    builder.addCase(getAllBookings.fulfilled, (state, action) =>
      bookingsFulfilled(state, action, 'all')
    );
    builder.addCase(getActiveBookings.fulfilled, (state, action) =>
      bookingsFulfilled(state, action, 'active')
    );
    builder.addCase(getRequestedBookings.fulfilled, (state, action) =>
      bookingsFulfilled(state, action, 'upcoming')
    );
    builder.addCase(getHistoricalBookings.fulfilled, (state, action) =>
      bookingsFulfilled(state, action, 'historical')
    );
    builder.addCase(getBookingById.fulfilled, getBookingByIdFulfilled);
    builder.addCase(cancelBooking.fulfilled, cancelBookingFulfilled);
    builder.addCase(searchAddress.fulfilled, searchAddressFulfilled);
    builder.addCase(getMap.fulfilled, getMapFulfilled);
    builder.addCase(searchUsers.fulfilled, searchUsersFulfilled);
    builder.addCase(createBooking.fulfilled, createBookingFulfilled);
    builder.addCase(updateBooking.fulfilled, updateBookingFulfilled);

    builder.addCase(getBookingState.fulfilled, bookingStateFulfilled);
    builder.addCase(sendMessageToDriver.fulfilled, messageToDriverFulfilled);
    builder.addCase(getPreauthId.fulfilled, getPreauthFulfilled);

    builder.addCase(getAllBookings.rejected, (state, action) =>
      bookingsFailed(state, action, 'all')
    );
    builder.addCase(getActiveBookings.rejected, (state, action) =>
      bookingsFailed(state, action, 'active')
    );
    builder.addCase(getRequestedBookings.rejected, (state, action) =>
      bookingsFailed(state, action, 'upcoming')
    );
    builder.addCase(getHistoricalBookings.rejected, (state, action) =>
      bookingsFailed(state, action, 'historical')
    );
    builder.addCase(getBookingById.rejected, (state, action) =>
      failed(state, action)
    );
    builder.addCase(cancelBooking.rejected, cancelBookingFailed);
    builder.addCase(searchAddress.rejected, searchAddressFailed);
    builder.addCase(getMap.rejected, getMapFailed);
    builder.addCase(searchUsers.rejected, searchUsersFailed);
    builder.addCase(createBooking.rejected, createBookingFailed);
    builder.addCase(updateBooking.rejected, updateBookingFailed);
    builder.addCase(createGuestBooking.rejected, createBookingFailed);
    builder.addCase(getBookingState.rejected, bookingStateFailed);
    builder.addCase(sendMessageToDriver.rejected, messageToDriverFailed);
    builder.addCase(getPreauthId.rejected, getPreauthFailed);
  },
});

export default bookingsSlice.reducer;
export const {
  clearPendingBooking,
  setPendingBooking,
  clearBookingForm,
  setAddressDetailsByType,
  clearAddressDetailsByType,
  clearAddressResultByType,
  clearUsersResult,
  selectPassenger,
  clearPassenger,
  resetAddressLoadingByType,
  clearUpdatedBookingStatus,
  clearCreatedBookingStatus,
  clearCreatedPrebookingStatus,
  setIsCreatingBooking,
  setNewBookingFormValues,
  setBlockedUser,
  clearBookingCompanyInfo,
  setCurrentBookingId,
  clearCurrentBooking,
} = bookingsSlice.actions;

export const bookingsDataSelector = (state, accountId) => {
  const { activeSecondaryTab } = state.tabs;

  const activeTab = activeSecondaryTab
    ? activeSecondaryTab.toLowerCase()
    : 'all';

  const bookingData = state.bookings.data[activeTab];

  const currentPage =
    (bookingData && bookingData.metaByAccountId[accountId]?.currentPage) ??
    null;
  if (
    currentPage &&
    state.bookings.data.byAccountId[accountId] &&
    state.bookings.data[activeTab].idsByAccountIdAndPage[accountId]?.[
      currentPage
    ]
  ) {
    return _compact(
      state.bookings.data[activeTab].idsByAccountIdAndPage[accountId][
        currentPage
      ].map((item) => state.bookings.data.byAccountId[accountId][item])
    );
  } else {
    return [];
  }
};
export const bookingsMetaSelector = (state, accountId) => {
  const { activeSecondaryTab } = state.tabs;

  const activeTab = activeSecondaryTab
    ? activeSecondaryTab.toLowerCase()
    : 'all';

  const bookingData = state.bookings.data[activeTab];

  return (
    (bookingData && bookingData.metaByAccountId[accountId]) || {
      currentPage: 1,
      totalPages: 1,
      total: 1,
    }
  );
};
export const bookingsSelector = (state) => {
  const { activeSecondaryTab } = state.tabs;
  const activeTab = activeSecondaryTab
    ? activeSecondaryTab.toLowerCase()
    : 'all';

  return state.bookings.data[activeTab];
};

export const newBookingFormDataSelector = (state) =>
  state.bookings.newBookingForm.data;

export const handleGetBookings = {
  [ALL]: getAllBookings,
  [ACTIVE]: getActiveBookings,
  [UPCOMING]: getRequestedBookings,
  [HISTORICAL]: getHistoricalBookings,
};
export const selectorForOtherAddress = (state) => {
  return {
    isRequesting: state.accounts.favoritePlaces.isLoading,
    addressResult: state.bookings.newBookingForm.data.other.result,
    serviceStatus: state.bookings.newBookingForm.serviceStatus,
  };
};
export const bookingCompanyPreauthSelector = (state) =>
  state.bookings.bookingCompany.extra?.company?.configs?.booking?.preauth;

export const currentBookingSelector = (state) => {
  const accountId = state.accounts.data.current.id;
  const id = state.bookings.currentBooking.id;
  if (!accountId || !state.bookings.data.byAccountId[accountId]) return {};
  return {
    data: state.bookings.data.byAccountId[accountId][id],
    hasFulfilled: state.bookings.currentBooking.hasFulfilled,
    hasError: state.bookings.currentBooking.hasError,
    isLoading: state.bookings.currentBooking.isLoading,
  };
};
