import styled from 'styled-components';
import Button from 'components/Button';
import * as v from 'utils/variables';
import { addOrEditUserSelectStyles } from 'constants/customSelectStyles';
export const LABEL_STYLE = { color: '#000', opacity: 0.8, fontSize: '14px' };
export const TIME_STYLE = {
  container: (styles) => ({
    ...styles,
    minWidth: '90px',
  }),
};
export const FREQUENCY_STYLE = addOrEditUserSelectStyles;
export const StyledCancelButton = styled(Button)`
  text-align: left;
  width: 150px;
  font-size: ${v.fontSizeSm};
  color: ${v.mousegray};
  background-color: transparent;
  &:disabled {
    cursor: not-allowed;
  }
`;

export const SecondaryHeader = styled.h2`
  color: ${v.charCoal};
  opacity: 0.8;
  font-size: ${v.fontSizeMd};
  font-weight: bold;
  margin-top: 2em;
  margin-bottom: 1em;
`;

export const StyledField = styled.div`
  display: flex;
  padding: 1.5em 0;
  align-items: ${(props) => (props.$alignStart ? 'flex-start' : 'center')};
`;
export const StyledFieldRight = styled.div`
  display: flex;
  align-items: center;
  width: auto;
`;
export const StyledLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
`;
export const StyledTimeContainer = styled.div`
  display: grid;
  grid-row-gap: 0.5em;
`;
export const StyledTimeRow = styled.div`
  display: grid;
  grid-template-columns: 8em 10em auto auto auto;
  align-items: center;
  grid-column-gap: 4em;
`;
export const StyledFrequencyContainer = styled.div`
  display: grid;
  grid-template-columns: 12em 14em;
  grid-column-gap: 1em;
`;
export const StyledValueContainer = styled.div`
  display: grid;
  grid-template-columns: 12em;
`;
export const StyledSeparator = styled.hr`
  border-top: 1px solid black;
  opacity: 0.05;
`;
