import {
  fontSizeSm,
  fontFamilyPrimary,
  mousegray,
  lightblack,
} from 'utils/variables';

export const adyenStylesWhite = {
  base: {
    color: lightblack,
    fontSize: fontSizeSm,
  },
  error: {
    color: 'red',
  },
  placeholder: {
    color: mousegray,
    fontFamily: fontFamilyPrimary,
    fontSize: fontSizeSm,
    fontWeight: 400,
  },
  validated: {
    color: lightblack,
    fontWeight: 'bold',
  },
};
