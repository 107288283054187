import { axiosGet, axiosPost, axiosDelete, axiosPatch } from 'api/axiosHelpers';

export const searchAddressRequest = (address, type, lat, lng, cancelToken) => {
  return axiosGet({
    url: `/addresses/search`,
    params: {
      search: address,
      lat,
      lng,
    },
    cancelToken,
  }).then(({ data }) => {
    return data;
  });
};

export const createNewPlaceRequest = (
  address,
  name,
  driver_notes,
  lat,
  lng
) => {
  return axiosPost({
    url: `/favorite/places-rfb`,
    data: {
      address,
      lat,
      lng,
      name,
      driver_notes,
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => error);
};

export const getFavoritePlacesRequest = () => {
  return axiosGet({
    url: `/favorite/places-rfb`,
  }).then(({ data }) => {
    return data;
  });
};

export const updateFavoritePlacesRequest = (payload) => {
  return axiosPatch({
    url: `/favorite/places-rfb`,
    data: payload,
  }).then(({ data }) => {
    return data;
  });
};

export const deleteFavoritePlacesRequest = (id) => {
  return axiosDelete({
    url: `/favorite/places-rfb/${id}`,
  }).then(({ data }) => {
    return data;
  });
};
