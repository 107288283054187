export default {
  usersFilter: {
    placeholder: 'All',
    all: 'All',
    owner: 'Owner',
    managers: 'Managers',
    users: 'Users',
  },
  usersActions: {
    placeholder: 'Bulk actions',
    delete: 'Delete Teammates',
  },
  addUserButton: 'Add teammate',
  addGroupButton: 'Add group',
};
