import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as v from 'utils/variables';

const DISABLED_OPACITY = 0.7;

const disabled = css`
  opacity: ${DISABLED_OPACITY};
  cursor: not-allowed;
`;
const secondary = css`
  background: ${v.white};
  color: ${v.primary};
  &:disabled {
    ${disabled};
  }
`;
const primary = css`
  background: ${v.primary};
  color: ${v.white};
  font-weight: ${v.fontWeightBold};

  &:not(:disabled):hover {
    background: ${v.primary};
    color: ${v.light};
    transition: ${v.transitionTimeBasic};
  }
  &:disabled {
    ${disabled};
  }
`;
const withBlueBorder = css`
  border: 1px solid ${v.primary};
  border-radius: ${v.borderRadiusSmall};
  text-align: center;
`;
const withPinkBorder = css`
  border-radius: ${v.borderRadiusSmall};
  border: 1px solid ${v.pink};
  color: ${v.pink};
  text-align: center;
  &:not(:disabled):hover {
    background: ${v.lighter};
    transition: background ${v.transitionTimeBasic};
  }
`;
const pink = css`
  background: ${v.pink};
  color: ${v.white};
  &:not(:disabled):hover {
    background: ${v.pink};
    color: ${v.light};
    transition: background, color ${v.transitionTimeBasic};
  }
`;
const small = css`
  font-size: ${v.fontSizeXs};
`;
const light = css`
  background: ${v.dark};
  color: ${v.darkgray};
  &:not(:disabled):hover {
    background: ${v.palegray};
    transition: ${v.transitionTimeBasic};
  }
`;
const isAlert = css`
  background: ${v.melonRed};
  color: ${v.white};

  &:not(:disabled):hover {
    background: ${v.red};
    transition: ${v.transitionTimeBasic};
  }
  &:disabled {
    ${disabled};
  }
`;
const isRounded = css`
  border-radius: ${v.borderRadiusBasic};
`;
const isRoundedMedium = css`
  border-radius: ${v.borderRadiusButton};
`;
const isFullWidth = css`
  width: 100%;
`;
const wide = css`
  width: calc(${v.spacingLg} * 8);
  padding: ${v.spacingSm};
`;
const noPaddingLeft = css`
  padding-left: 0;
`;
const noPaddingLeftRight = css`
  padding: ${v.spacingMd} 0;
`;
const isLarge = css`
  padding: ${v.spacingMd} ${v.spacingXLg};
`;
const flex = css`
  flex: 1;
`;
const flexHalf = css`
  flex: 0 1 50%;
`;
const commonStyles = css`
  position: relative;
  min-width: 130px;
  box-sizing: border-box;
  border: none;
  padding: 10px ${v.spacingLg};
  font-size: ${v.fontSizeMd};
  transition: ${v.transitionTimeBasic};
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  &:focus {
    outline: none;
  }
  visibility: ${(props) => props.$hidden && 'hidden'};
  ${(props) => props.$secondary && secondary};
  ${(props) => props.$primary && primary};
  ${(props) => props.$withBlueBorder && withBlueBorder};
  ${(props) => props.$withPinkBorder && withPinkBorder};
  ${(props) => props.$pink && pink};
  ${(props) => props.$small && small};
  ${(props) => props.$light && light};
  ${(props) => props.$alert && isAlert};
  ${(props) => props.$isRounded && isRounded};
  ${(props) => props.$isRoundedMedium && isRoundedMedium};
  ${(props) => props.$wide && wide};
  ${(props) => props.$isFullWidth && isFullWidth};
  ${(props) => props.$noPaddingLeft && noPaddingLeft};
  ${(props) => props.$noPaddingLeftRight && noPaddingLeftRight};
  ${(props) => props.$isLarge && isLarge};
  ${(props) => props.$flex && flex};
  ${(props) => props.$flexHalf && flexHalf};
  ${(props) => props.$isLoading && disabled};
`;

export const StyledButton = styled.button`
  ${commonStyles}
`;
export const StyledLink = styled.a`
  ${commonStyles}
`;

export const StyledIcon = styled.span`
  margin-left: ${v.spacingMd};
  font-size: ${v.fontSizeSm};
`;
export const StyledText = styled.span`
  position: relative;
  opacity: ${(props) => (props?.$isLoading ? 0 : 1)};
`;
export const StyledSpinner = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
`;
