import React, { memo } from 'react';
import { func, string, bool, shape } from 'prop-types';
import parsePhoneNumber from 'libphonenumber-js';
import _isEqual from 'lodash/isEqual';

import { LabelWithInput, LabelWithPhoneNumber } from 'components/Label';

import { messages } from './messages';

const AddUserFormPhone = ({
  isEditModal,
  phone,
  defaultCountry,
  errors,
  touched,
  setFieldError,
  setFieldValue,
  setFieldTouched,
  onPhoneNumberBlur,
}) => {
  const value = parsePhoneNumber(`+${phone}`)?.formatInternational() || phone;

  return isEditModal ? (
    <LabelWithInput
      testId="user-phone-input"
      label={messages.labelPhoneNumber}
      name="phone"
      value={value}
      isDisabled
    />
  ) : (
    <LabelWithPhoneNumber
      testId="phone_number"
      label={messages.labelPhoneNumber}
      error={errors.phone}
      name="phone"
      hasError={!!(touched.phone && errors.phone)}
      phoneProps={{
        setFieldValue,
        setFieldTouched,
        onPhoneNumberBlur,
        defaultCountry,
      }}
    />
  );
};

AddUserFormPhone.propTypes = {
  isEditModal: bool,
  phone: string.isRequired,
  defaultCountry: string.isRequired,
  errors: shape({}),
  touched: shape({}),
  setFieldError: func.isRequired,
  setFieldValue: func.isRequired,
  setFieldTouched: func.isRequired,
  onPhoneNumberBlur: func,
};

AddUserFormPhone.defaultProps = {
  isEditModal: false,
  touched: {},
  errors: {},
  onPhoneNumberBlur: null,
};

const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.touched, nextProps.touched) &&
  _isEqual(prevProps.errors, nextProps.errors) &&
  prevProps.phone === nextProps.phone &&
  prevProps.isEditModal === nextProps.isEditModal &&
  prevProps.defaultCountry === nextProps.defaultCountry;

export default memo(AddUserFormPhone, areEqual);
