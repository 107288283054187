import React from 'react';
import { func, string, bool } from 'prop-types';

import {
  ModalActionButtonsWrapper,
  PrimaryButtonWrapper,
  StyledPrimaryButton,
  CancelButtonWrapper,
  StyledCancelButton,
} from './styles';
import { commonText } from 'locales/en';

const ModalDefaultActionButtons = ({
  onCancelButtonClick,
  onSubmitButtonClick,
  cancelButtonText,
  submitButtonText,
  submitButtonType,
  isSubmitButtonDisabled,
  isSubmitButtonLoading,
  isCancelButtonDisabled,
}) => {
  return (
    <ModalActionButtonsWrapper>
      <CancelButtonWrapper>
        <StyledCancelButton
          type="button"
          secondary
          name={cancelButtonText}
          onClick={onCancelButtonClick}
          isFullWidth
          noPaddingLeftRight
          disabled={isCancelButtonDisabled}
        />
      </CancelButtonWrapper>
      <PrimaryButtonWrapper>
        <StyledPrimaryButton
          type={submitButtonType}
          primary
          name={submitButtonText}
          isRoundedMedium
          isFullWidth
          onClick={submitButtonType !== 'submit' ? onSubmitButtonClick : null}
          disabled={isSubmitButtonDisabled}
          isLoading={isSubmitButtonLoading}
        />
      </PrimaryButtonWrapper>
    </ModalActionButtonsWrapper>
  );
};

ModalDefaultActionButtons.propTypes = {
  onCancelButtonClick: func.isRequired,
  cancelButtonText: string,
  submitButtonText: string,
  onSubmitButtonClick: func,
  submitButtonType: string,
  isSubmitButtonDisabled: bool,
  isCancelButtonDisabled: bool,
};

ModalDefaultActionButtons.defaultProps = {
  onSubmitButtonClick: () => null,
  submitButtonType: 'submit',
  isSubmitButtonDisabled: false,
  isCancelButtonDisabled: false,
  cancelButtonText: commonText.close,
  submitButtonText: commonText.save,
};

export default ModalDefaultActionButtons;
