const {
  REACT_APP_ADYEN_KEY,
  REACT_APP_ADYEN_ENV,
  REACT_APP_API_URL,
} = process.env;

export const apiUrl = REACT_APP_API_URL;

export const defaultCompanyServiceArea = {
  lat: 53,
  lng: 6,
};

export const headers = {
  Accept: 'application/vnd.nodes.v1+json',
  'Accept-Language': 'en-UK',
  'Access-Control-Allow-Origin': '*',
  'N-Meta': 'web-rfb;production;1;1;rfb',
};

const { navigator, location, screen } = window;
const browserInfo = {
  acceptHeader: '*/*',
  colorDepth: screen?.colorDepth || 24,
  language: navigator?.language || 'en-US',
  javaEnabled: navigator?.javaEnabled() || false,
  screenHeight: screen?.height || 0,
  screenWidth: screen?.width || 0,
  userAgent: navigator?.userAgent || {},
  timeZoneOffset: new Date().getTimezoneOffset() || 0,
};
export const extraInfo = {
  browserInfo,
  origin: location.origin,
  webAuthSuccessUrl: `${location.origin}?success=true`,
  webAuthFailUrl: `${location.origin}`,
};

export const baseAdyenConfig = {
  locale: 'en_US',
  environment: REACT_APP_ADYEN_ENV,
  clientKey: REACT_APP_ADYEN_KEY,
  loadingContext: `https://checkoutshopper-${REACT_APP_ADYEN_ENV}.adyen.com/checkoutshopper/`,
};
