import React from 'react';
import { func, string, object } from 'prop-types';

import { LabelWithInput } from 'components/Label';

import { messages } from './messages';
import { EMAIL_FIELD, PASSWORD_FIELD } from './addUserSchema';

const AddUserFormEmail = ({
  handleChange,
  handleBlur,
  errors,
  touched,
  email,
  password,
}) => {
  return (
    <>
      <LabelWithInput
        testId={EMAIL_FIELD}
        label={messages.labelEmail}
        type="email"
        error={errors[EMAIL_FIELD]}
        name={EMAIL_FIELD}
        onChange={handleChange}
        onBlur={handleBlur}
        value={email}
        hasError={!!(errors[EMAIL_FIELD] && touched[EMAIL_FIELD])}
      />
      <LabelWithInput
        testId={PASSWORD_FIELD}
        label={messages.labelPassword}
        type="password"
        error={errors[PASSWORD_FIELD]}
        name={PASSWORD_FIELD}
        onChange={handleChange}
        onBlur={handleBlur}
        value={password}
        hasError={!!(errors[PASSWORD_FIELD] && touched[PASSWORD_FIELD])}
      />
    </>
  );
};

AddUserFormEmail.propTypes = {
  handleChange: func.isRequired,
  handleBlur: func.isRequired,
  errors: object.isRequired,
  touched: object.isRequired,
  email: string,
  password: string,
};

AddUserFormEmail.defaultProps = {
  email: '',
  password: '',
};

export default AddUserFormEmail;
