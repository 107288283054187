import styled, { css } from 'styled-components';
import {
  fontSizeLg,
  fontSizeMd,
  fontWeightBold,
  fontSizeSm,
  mousegray,
  lightblack,
  spacingLg,
  modalActionButtonsSpacing,
  colorPrimaryLight,
  spacingMd,
} from 'utils/variables';

const wrapper = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0;
`;
export const Form = styled.form`
  ${wrapper};
`;
export const Container = styled.div`
  ${wrapper}
`;
export const Body = styled.div`
  width: 100%;
  margin-bottom: ${spacingLg};
  ${(props) =>
    props.$minHeight &&
    css`
      min-height: ${(props) => props.$minHeight};
      height: auto;
      margin-bottom: 0;
    `};
`;
export const Header = styled.h1`
  font-size: ${fontSizeLg};
  font-weight: ${fontWeightBold};
  color: ${lightblack};
`;
export const Subtitle = styled.p`
  font-size: ${fontSizeMd};
  color: ${mousegray};
  span {
    color: ${lightblack};
    font-weight: ${fontWeightBold};
  }
`;
export const Text = styled.p`
  font-size: ${fontSizeSm};
  color: ${mousegray};
  margin-top: ${spacingLg};
`;

/* eslint-disable max-len */
export const Footer = styled.div`
  flex: 1 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${colorPrimaryLight};
  padding: ${spacingLg} ${modalActionButtonsSpacing}
    ${modalActionButtonsSpacing} ${modalActionButtonsSpacing};
  margin: ${spacingMd} -${modalActionButtonsSpacing} -${modalActionButtonsSpacing} -${modalActionButtonsSpacing};
`;

// remove !important after switch styled-components on Button component
export const SecondaryButton = styled.div`
  margin-left: 0;
  max-width: 150px;
  min-width: 100px !important;
  color: ${mousegray};
  button {
    text-align: left;
    font-size: ${fontSizeSm};
    background-color: transparent !important;
    justify-content: flex-start;
    color: ${mousegray} !important;
    &:hover {
      background-color: transparent;
    }
  }
`;
export const List = styled.ul`
  list-style: initial;
`;
export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$centerTitle ? 'center' : 'flex-start')};
  text-align: ${(props) => (props.$centerTitle ? 'center' : 'left')};
  margin-bottom: ${spacingLg};
  svg {
    margin-right: 20px;
    margin-top: 5px;
  }
`;
export const PrimaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 0 auto;
`;
