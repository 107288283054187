import React from 'react';
import { func, string, bool } from 'prop-types';

import { StyledButton } from './styles';

const PrimaryButton = ({
  testId,
  type,
  title,
  onClick,
  isDisabled,
  isLoading,
  ...props
}) => (
  <StyledButton
    dataTestId={testId}
    name={title}
    onClick={onClick}
    type={type}
    primary
    isRoundedMedium
    disabled={isDisabled}
    isLoading={isLoading}
    {...props}
  />
);

PrimaryButton.propTypes = {
  title: string,
  onClick: func,
  testId: string,
  type: string,
  isDisabled: bool,
  isLoading: bool,
};

PrimaryButton.defaultProps = {
  title: '',
  onClick: () => {},
  testId: 'primary',
  type: 'button',
  isDisabled: bool,
  isLoading: false,
};

export default PrimaryButton;
