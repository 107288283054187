import React from 'react';
import { string, bool, oneOf } from 'prop-types';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { mousegray } from 'utils/variables';

import { getInviteUserNotificationInfo } from './messages';
import { StyledIcon, StyledContainer, StyledDescription } from './styles';
const AddUserNotificationInfo = ({
  userName,
  notificationChannel,
  accountName,
  hasPaddingTop,
  isVisible,
}) => {
  return (
    <StyledContainer $isVisible={isVisible}>
      <StyledDescription $isVisible={isVisible}>
        <StyledIcon className="fa-w-20" color={mousegray} icon={faInfoCircle} />
        {getInviteUserNotificationInfo(
          userName,
          notificationChannel,
          accountName
        )}
      </StyledDescription>
    </StyledContainer>
  );
};

AddUserNotificationInfo.propTypes = {
  userName: string.isRequired,
  accountName: string.isRequired,
  notificationChannel: oneOf(['email', 'sms']).isRequired,
  hasPaddingTop: bool,
};

AddUserNotificationInfo.defaultProps = {
  hasPaddingTop: false,
};

export default AddUserNotificationInfo;
