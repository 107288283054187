import {
  ACCOUNT_ROLE_FRONTDESK,
  ACCOUNT_ROLE_USER,
  ACCOUNT_ROLE_MANAGER,
  ACCOUNT_ROLE_OWNER,
} from 'constants/accountUserRoles';

export const FRONDESK_ROLE_OPTIONS = [
  {
    label: 'Frontdesk Login',
    value: ACCOUNT_ROLE_FRONTDESK,
  },
];

export const MANAGER_ROLE_OPTIONS = [
  {
    label: 'Manager',
    value: ACCOUNT_ROLE_MANAGER,
  },
];

export const MANAGER_AND_USER_ROLE_OPTIONS = [
  {
    label: 'Basic User',
    value: ACCOUNT_ROLE_USER,
  },
  ...MANAGER_ROLE_OPTIONS,
];

export const OWNER_AND_ADMIN_ROLE_OPTIONS = [
  {
    label: 'Owner',
    value: ACCOUNT_ROLE_OWNER,
  },
];

export const NEW_USER_ROLE_OPTIONS = [
  ...FRONDESK_ROLE_OPTIONS,
  ...MANAGER_AND_USER_ROLE_OPTIONS,
];
