import React, { memo, useRef } from 'react';
import cx from 'classnames';
import { bool, func, string, object, number, oneOfType } from 'prop-types';

import envelope from 'assets/icons/envelope.png';
import lock from 'assets/icons/lock-alt.png';
import search from 'assets/icons/search.png';
import times from 'assets/icons/times.png';
import styles from './Input.module.scss';
import TooltipWithIcon from '../TooltipWithIcon';
import capitalize from 'utils/capitalize';

const Input = ({
  touched,
  type,
  name,
  errors,
  value,
  onBlur,
  onChange,
  placeholder,
  maxLength,
  big,
  bgPrimary,
  bgWhite,
  borderBottom,
  mailInput,
  passInput,
  isSearchInput,
  hasSearchIcon,
  onInput,
  onFocus,
  inputReference,
  textSmall,
  dataTestId,
  disabled,
  isClearable,
  handleInputClear,
  checked,
  hasDisabledStyles,
  noErrorMessage,
  errorBgPrimary,
  isAyden,
  hasCheckboxMinHeight,
  hasTooltip,
  tooltipIcon,
  tooltipMessage,
  tooltipPosition,
  zIndex,
  isLarge,
  errorCanFit,
  autoComplete,
  transparentCaret,
  ...inputProps
}) => {
  const ref = inputReference ?? useRef();
  const isCheckboxInput = type === 'checkbox';
  const hasError = !!(touched?.[name] && errors?.[name]);
  let className = cx(styles.Input, {
    [styles.withError]: hasError,
    [styles.big]: big,
    [styles.bgPrimary]: bgPrimary,
    [styles.bgWhite]: bgWhite,
    [styles.isLarge]: isLarge,
    [styles.textSmall]: textSmall,
    [styles.borderBottom]: borderBottom,
    [styles.searchInput]: isSearchInput,
    [styles.checkbox]: isCheckboxInput,
    [styles.hasDisabledStyles]: disabled && hasDisabledStyles,
    'adyen-checkout__input': isAyden,
    'adyen-checkout__input--text': isAyden,
    'adyen-checkout__input--large': isAyden,
    [styles.transparentCaret]: transparentCaret,
  });
  const wrapperClassName = cx(styles.InputWrapper, {
    [styles.checkbox]: isCheckboxInput && hasCheckboxMinHeight,
    [styles.withTooltip]: hasTooltip,
  });
  const errorClassName = cx({
    [styles.InputCheckboxError]: isCheckboxInput,
    [styles.InputError]: bgPrimary && !errorBgPrimary,
    [styles.InputErrorLight]: !bgPrimary || errorBgPrimary,
    [styles.errorCanFit]: errorCanFit,
  });
  const style = {
    zIndex: !hasError ? zIndex : 'initial',
  };

  const handleChange = (e) => {
    if (type === 'text' && e.target.value && ref?.current) {
      const start = e.target.selectionStart;
      const end = e.target.selectionEnd;
      e.target.value = capitalize(e.target.value);
      ref.current.setSelectionRange(start, end);
    }
    onChange(e);
  };

  return (
    <div className={wrapperClassName} style={style}>
      {passInput && (
        <span>
          <img className={styles.Icon} src={lock} alt="lock" />
        </span>
      )}
      {mailInput && (
        <span>
          <img className={styles.Icon} src={envelope} alt="envelope" />
        </span>
      )}
      {isSearchInput && hasSearchIcon && (
        <span>
          <img className={styles.SearchIcon} src={search} alt="search" />
        </span>
      )}
      <input
        disabled={disabled}
        ref={ref}
        type={type}
        value={value ?? undefined}
        onBlur={onBlur}
        onChange={handleChange}
        placeholder={placeholder}
        className={className}
        name={name}
        maxLength={maxLength}
        onInput={onInput}
        onFocus={onFocus}
        data-testid={dataTestId}
        checked={checked}
        autoComplete={autoComplete}
        {...inputProps}
      />
      {isClearable && (
        <span
          className={styles.ClearInputIcon}
          onKeyDown={handleInputClear}
          tabIndex="-1"
          role="button"
          onClick={handleInputClear}
        >
          <img className={styles.TimesIcon} src={times} alt="search" />
        </span>
      )}
      {!noErrorMessage && touched[name] && errors[name] && (
        <p data-testid="error" className={errorClassName}>
          {errors[name]}
        </p>
      )}
      {hasTooltip && (
        <TooltipWithIcon
          icon={tooltipIcon}
          message={tooltipMessage}
          place={tooltipPosition}
        />
      )}
    </div>
  );
};

Input.propTypes = {
  isSearchInput: bool,
  hasSearchIcon: bool,
  isClearable: bool,
  handleInputClear: func,
  type: string,
  hasDisabledStyles: bool,
  hasCheckboxMinHeight: bool,
  hasTooltip: bool,
  tooltipIcon: object,
  tooltipPosition: string,
  tooltipMessage: string,
  zIndex: oneOfType([number, string]),
  isLarge: bool,
  errors: object,
  touched: object,
  value: oneOfType([string, number, bool]),
  onChange: func,
  errorCanFit: bool,
  autoComplete: string,
  transparentCaret: bool,
};

Input.defaultProps = {
  isSearchInput: false,
  hasSearchIcon: false,
  isClearable: false,
  handleInputClear: () => null,
  type: 'text',
  hasDisabledStyles: false,
  noErrorMessage: false,
  hasCheckboxMinHeight: true,
  hasTooltip: false,
  tooltipPosition: TooltipWithIcon.defaultProps.place,
  tooltipMessage: '',
  tooltipIcon: TooltipWithIcon.defaultProps.icon,
  zIndex: 'initial',
  isLarge: false,
  errors: {},
  touched: {},
  value: undefined,
  errorCanFit: false,
  autoComplete: 'new-password',
  transparentCaret: false,
};

export default memo(Input);
