import React, { useRef, useState, memo } from 'react';
import isEqual from 'lodash/isEqual';
import { string, arrayOf, shape, func, bool, number } from 'prop-types';

import Input from 'components/Input';
import { useOutsideClick } from 'hooks/useOutsideClick';
import { SearchWrapper, SearchResults, SearchResultsItem } from './styles';

const UsersSearch = ({
  searchResult,
  name,
  id,
  errors,
  touched,
  value,
  handleChange,
  handleSelectedOption,
  closeDropdownOnSelection,
  isClearable,
  handleClearSelection,
  hasDropdown,
  isDisabled,
  textSmall,
  ...inputProps
}) => {
  const usersSearchReference = useRef();
  const [isOpen, setIsOpen] = useState(false);
  useOutsideClick(usersSearchReference, () => setIsOpen(false));

  return (
    <SearchWrapper ref={usersSearchReference} data-testid="users-search">
      <Input
        textSmall={textSmall}
        id={id}
        dataTestId="users-search-input"
        handleInputClear={handleClearSelection}
        isClearable={isClearable}
        errors={errors}
        touched={touched}
        name={name}
        bgWhite
        isSearchInput
        value={value}
        onFocus={() => setIsOpen(true)}
        onChange={handleChange}
        disabled={isDisabled}
        hasDisabledStyles={isDisabled}
        autoComplete="new-password"
        {...inputProps}
      />
      {hasDropdown && isOpen && searchResult?.length > 0 && (
        <SearchResults>
          {searchResult.map((user) => {
            return (
              <SearchResultsItem
                key={user.id}
                onClick={() => {
                  handleSelectedOption(user);
                  closeDropdownOnSelection && setIsOpen(false);
                }}
                type="submit"
                data-testid="search-component"
              >
                {user.firstName} {user.lastName}
              </SearchResultsItem>
            );
          })}
        </SearchResults>
      )}
    </SearchWrapper>
  );
};

UsersSearch.propTypes = {
  searchResult: arrayOf(
    shape({
      id: number.isRequired,
      firstName: string.isRequired,
      lastName: string.isRequired,
    })
  ),
  name: string.isRequired,
  id: string.isRequired,
  errors: shape({}),
  touched: shape({}),
  value: string,
  handleChange: func.isRequired,
  handleSelectedOption: func.isRequired,
  closeDropdownOnSelection: bool,
  isClearable: bool,
  handleClearSelection: func,
  hasDropdown: bool,
  isDisabled: bool,
  textSmall: bool,
};

UsersSearch.defaultProps = {
  searchResult: [],
  errors: {},
  touched: {},
  value: '',
  isClearable: true,
  closeDropdownOnSelection: true,
  handleClearSelection: () => {},
  hasDropdown: true,
  isDisabled: false,
  textSmall: false,
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.searchResult, nextProps.searchResult) &&
  isEqual(prevProps.errors, nextProps.errors) &&
  isEqual(prevProps.touched, nextProps.touched) &&
  prevProps.name === nextProps.name &&
  prevProps.id === nextProps.id &&
  prevProps.value === nextProps.value &&
  prevProps.isClearable === nextProps.isClearable &&
  prevProps.hasDropdown === nextProps.hasDropdown &&
  prevProps.isDisabled === nextProps.isDisabled &&
  prevProps.textSmall === nextProps.textSmall;

export default memo(UsersSearch, areEqual);
