import React, { useMemo } from 'react';
import { number } from 'prop-types';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { Column } from 'components/GridNew';
import Icon from 'components/ItemIcon';
import { billingCardPropTypes } from 'constants/propTypes';
import checked from 'assets/icons/checked.png';
import styles from './PersonalCard.module.scss';
import Checkbox from 'components/Checkbox';
import {
  StyledDashboardRow,
  ActivityItemWrapper,
  EditIcon,
  StyledButtonsWrapper,
} from 'views/Dashboard/styles';
import { charCoal } from 'utils/variables';
import { BILLING_DASHBOARD_COLUMNS } from 'utils/dashboardHeaders';

const PersonalCard = ({
  card,
  accountCardId,
  selectedCardId,
  setSelectedCard,
  onClickEdit,
}) => {
  const CARDS_DASHBOARD_DATA = useMemo(() => {
    return [
      card.id !== accountCardId ? (
        <Checkbox
          onChange={() => setSelectedCard(card)}
          checked={card.id === selectedCardId}
        />
      ) : null,
      <Icon withCol={false} icon={card.icon} alt="card-icon" />,
      <ActivityItemWrapper $color={charCoal}>{card.last4}</ActivityItemWrapper>,
      <ActivityItemWrapper $color={charCoal}>
        {card?.exp_month}/{card?.exp_year}
      </ActivityItemWrapper>,
      <ActivityItemWrapper $color={charCoal}>
        {card?.card_holder_name}
      </ActivityItemWrapper>,
      card.id === accountCardId ? (
        <>
          <img
            className={styles.SelectedCardIcon}
            alt="checked"
            src={checked}
          />
          <span>Business Card</span>
        </>
      ) : null,
      <StyledButtonsWrapper>
        <EditIcon alt="edit" icon={faEdit} onClick={onClickEdit} />
      </StyledButtonsWrapper>,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountCardId, card, selectedCardId]);

  const CARDS_DASHBOARD_ITEMS = useMemo(
    () =>
      BILLING_DASHBOARD_COLUMNS.map((col, idx) => ({
        index: idx + 1,
        col,
        data: CARDS_DASHBOARD_DATA[idx],
      })),
    [CARDS_DASHBOARD_DATA]
  );

  return (
    <StyledDashboardRow data-testid="personal-card-row">
      {CARDS_DASHBOARD_ITEMS.map((item) => (
        <Column
          style={item.index === 2 ? { textAlign: 'center' } : {}}
          key={item.index}
          col={item.col}
        >
          {item.data}
        </Column>
      ))}
    </StyledDashboardRow>
  );
};

PersonalCard.propTypes = {
  card: billingCardPropTypes.card,
  accountCardId: number,
};
PersonalCard.defaultProps = {
  accountCardId: null,
  card: {},
};

export default PersonalCard;
