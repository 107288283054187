import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { string, number, shape, arrayOf, func } from 'prop-types';
import cn from 'classnames';

import AvatarStack from 'components/AvatarStack';
import { Row } from 'components/Grid';
import ModalWarningActionButtons from 'components/ModalWarningActionButtons';
import exclamationTriangle from 'assets/icons/exclamation-triangle.png';

import styles from './DeleteUser.module.scss';
import messages from './messages';
import {
  clearMembersToDelete,
  deleteMembers,
} from 'store/slices/accountsSlice';

const fullName = (item) => `${item.firstName} ${item.lastName}`;

const DeleteUser = ({ handleModalToggle, membersToDelete }) => {
  const dispatch = useDispatch();
  const [isMultiple, setIsMultiple] = useState(false);
  const { isLoading, isSuccess } = useSelector(
    (state) => state.accounts.deleteMembers
  );
  useEffect(() => {
    setIsMultiple(membersToDelete?.length > 1);
    return () => {
      dispatch(clearMembersToDelete());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      handleModalToggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <Row center withMarginLarge>
        <AvatarStack
          avatarsToShow={3}
          fullName={fullName}
          usersArray={membersToDelete}
        />
      </Row>
      <Row center withMarginMedium>
        <p className={styles.DeleteUserHeaderWrapper}>
          <span
            className={cn(styles.DeleteUsersHeaderText, {
              [styles.hasCustomTextStyling]: isMultiple,
            })}
          >
            {isMultiple ? messages.headerMoreUsers : messages.headerOneUser}
          </span>
          {membersToDelete.map((item) => {
            return (
              <span
                key={item.id}
                className={cn(styles.DeleteUserHeader, {
                  [styles.hasCustomTextStyling]: !isMultiple,
                })}
              >
                {fullName(item)}
                {!isMultiple && '?'}
              </span>
            );
          })}
        </p>
      </Row>
      <Row center withMarginLarge>
        <span className={styles.DeleteUserHeaderDisclaimer}>
          <img
            className={styles.DeleteUserIcon}
            src={exclamationTriangle}
            alt={messages.warningImgAlt}
          />
          {messages.warningMsg}
        </span>
      </Row>
      <ModalWarningActionButtons
        onCancelButtonClick={handleModalToggle}
        onSubmitButtonClick={() => {
          dispatch(deleteMembers({ members: membersToDelete }));
        }}
        isSubmitButtonDisabled={isLoading}
        isSubmitButtonLoading={isLoading}
        submitButtonText={
          isMultiple ? messages.deleteMoreBtn : messages.deleteOneBtn
        }
      />
    </>
  );
};

DeleteUser.propTypes = {
  handleModalToggle: func.isRequired,
  membersToDelete: arrayOf(
    shape({
      id: number.isRequired,
      firstName: string.isRequired,
      lastName: string.isRequired,
    })
  ),
};

DeleteUser.defaultProps = {
  membersToDelete: [],
};

export default DeleteUser;
