import React, { useState, useCallback, useMemo, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, shape, string } from 'prop-types';

import Modal from 'components/Modal';
import AddCard from 'components/AddCard';
import NewAccountForm from 'components/NewAccountForm';

import SetAccountPayment from './SetAccountPayment';
import PaymentSuccess from '../PaymentSuccess';
import Invoicing from '../Invoicing/Invoicing';
import PendingMemberNotice from '../PendingMemberNotice';
import AccountReady from '../AccountReady';
import AddUser from '../AddUser';

import SetAccountInvoiceSuccess from './SetAccountInvoiceSuccess';
import {
  accountType,
  availablePaymentMethodsPropTypes,
} from 'constants/propTypes';
import { PAYMENT_METHODS } from 'constants/paymentMethods';
import {
  setCurrentAccount,
  getSelectedAccountDetails,
  newAccountSelector,
  clearRedirectTo3DAuth,
  getPaymentMethods,
} from 'store/slices/accountsSlice';

const SetAccount = ({
  isModalOpen,
  account,
  fromNavbar,
  availablePaymentMethods,
  accounts,
  appCompanyName,
  closeModal,
  hasNoAccount,
  cardAuthSuccess,
  cardAuthFailed,
  afterNewBusinessAccount,
  willContinueAccountCreate,
  isCreatingAccount,
}) => {
  const dispatch = useDispatch();
  const {
    pendingMember,
    isInvoiceAccountPending: invoiceNeedsApproval,
  } = useSelector((state) => state.accounts);
  const newAccount = useSelector(newAccountSelector);

  const initialState = {
    isAccountFormVisible: false,
    isAccountPaymentVisible: false,
    isPaymentOptionsVisible: false,
    isPaymentSuccessVisible: false,
    isAccountReadyVisible: false,
    isPendingNoticeVisible: false,
    isAddingNewMember: false,
    isInvoiceSubmissionSuccessVisible: false,
  };
  const [state, setState] = useState({
    ...initialState,
    isAccountFormVisible: fromNavbar && !willContinueAccountCreate,
    isAccountPaymentVisible:
      !!availablePaymentMethods ||
      (fromNavbar && willContinueAccountCreate && cardAuthFailed),
    isPaymentSuccessVisible:
      fromNavbar && willContinueAccountCreate && cardAuthSuccess,
  });
  const [paymentType, setPaymentType] = useState(null);

  const clearPaymentType = () => {
    setPaymentType(null);
  };

  const resetState = useCallback(() => {
    setState(initialState);
    setPaymentType(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalClose = useCallback(() => {
    dispatch(clearRedirectTo3DAuth());
    resetState();
    closeModal();
  }, [closeModal, dispatch, resetState]);

  const {
    isAccountFormVisible,
    isAccountPaymentVisible,
    isAccountReadyVisible,
    isAddingNewMember,
    isPaymentSuccessVisible,
    isPendingNoticeVisible,
    isPaymentOptionsVisible,
    isInvoiceSubmissionSuccessVisible,
  } = state;

  const renderView = useMemo(() => {
    const onSelectPaymentMethod = () => {
      if (paymentType) {
        if (paymentType === PAYMENT_METHODS['pay-in-car']) {
          setState({
            ...initialState,
            isPaymentSuccessVisible: true,
          });
        } else {
          setState({
            ...initialState,
            isPaymentOptionsVisible: true,
          });
        }
      }
    };

    const displayPendingNotice = () => {
      clearPaymentType();
      setState({
        ...initialState,
        isPendingNoticeVisible: true,
      });
    };

    const handleAccountReady = () => {
      if (afterNewBusinessAccount) {
        setPaymentType(null);
        setState({
          ...initialState,
          isAccountReadyVisible: true,
        });
      } else {
        handleModalClose();
      }
    };

    const handleAddUserAction = () => {
      if (pendingMember?.id) {
        setState({
          ...initialState,
          isPendingNoticeVisible: true,
        });
      } else {
        handleAccountReady();
      }
    };

    const handleAddPaymentSuccess = () => {
      if (fromNavbar) {
        return setState({
          ...initialState,
          isPaymentSuccessVisible: true,
        });
      } else if (!fromNavbar && paymentType === PAYMENT_METHODS.invoice) {
        setPaymentType(null);
        return setState({
          ...initialState,
          isInvoiceSubmissionSuccessVisible: true,
        });
      } else {
        handleModalClose();
      }
    };

    const onClickAddNewMember = () => {
      dispatch(getPaymentMethods({ account: newAccount }));
      setState({
        ...initialState,
        isAddingNewMember: true,
      });
    };

    const handleGoToNewAccount = () => {
      dispatch(setCurrentAccount(newAccount));
      dispatch(getSelectedAccountDetails({ account: newAccount }));
      handleModalClose();
    };

    const handleCloseAccountForm = () => {
      setState({
        ...initialState,
        isAccountPaymentVisible: true,
      });
    };

    const handleStayOnCurrent = () => {
      dispatch(getPaymentMethods({ account }));
      handleModalClose();
    };

    if (isAccountFormVisible) {
      return (
        <NewAccountForm
          onCancel={handleModalClose}
          onCreateAccount={handleCloseAccountForm}
          hasNoAccount={hasNoAccount}
          isCreatingAccount={isCreatingAccount}
        />
      );
    } else if (isAccountPaymentVisible) {
      return (
        <SetAccountPayment
          handleModalToggle={handleModalClose}
          onClickNext={onSelectPaymentMethod}
          fromNavbar={fromNavbar}
          setPaymentType={setPaymentType}
          clearPaymentType={clearPaymentType}
          paymentType={paymentType}
          availablePaymentMethods={availablePaymentMethods}
        />
      );
    } else if (isPaymentOptionsVisible) {
      if (paymentType === PAYMENT_METHODS.invoice) {
        return (
          <Invoicing
            onSuccess={handleAddPaymentSuccess}
            handleModalClose={() => {
              setState({
                ...initialState,
                isAccountPaymentVisible: true,
              });
              setPaymentType(null);
            }}
            account={newAccount}
            companyName={appCompanyName}
            isCreatingAccount={isCreatingAccount}
          />
        );
      } else if (paymentType === PAYMENT_METHODS.card) {
        return (
          <AddCard
            onSuccess={handleAddPaymentSuccess}
            handleModalClose={() => {
              setState({
                ...initialState,
                isAccountPaymentVisible: true,
              });
              setPaymentType(null);
            }}
            account={fromNavbar ? newAccount : account}
            fromNavbar={fromNavbar}
          />
        );
      }
    } else if (isPaymentSuccessVisible) {
      return (
        <PaymentSuccess
          onCancel={handleAccountReady}
          handlePendingNotice={displayPendingNotice}
          account={newAccount}
          invoiceNeedsApproval={invoiceNeedsApproval}
          companyName={appCompanyName}
        />
      );
    } else if (isPendingNoticeVisible) {
      return (
        <PendingMemberNotice
          firstName={pendingMember.first_name}
          onClickAddMember={onClickAddNewMember}
          onCancel={handleAccountReady}
        />
      );
    } else if (isAddingNewMember) {
      return (
        <AddUser
          type="add"
          handleModalToggle={handleAddUserAction}
          account={newAccount}
        />
      );
    } else if (isAccountReadyVisible) {
      return (
        <AccountReady
          newAccountName={newAccount?.name}
          currentAccountName={account?.name}
          onClickTake={handleGoToNewAccount}
          onClickStay={handleStayOnCurrent}
        />
      );
    } else if (isInvoiceSubmissionSuccessVisible) {
      return (
        <SetAccountInvoiceSuccess
          handleInvoiceSubmissionSuccessClose={() => setState(initialState)}
          hasCardPaymentMethodAdded={availablePaymentMethods?.card}
          handlePaymentModalClose={handleModalClose}
          appCompanyName={appCompanyName}
          handleAddPaymentSuccess={handleAddPaymentSuccess}
          account={account}
        />
      );
    }
  }, [
    account,
    afterNewBusinessAccount,
    appCompanyName,
    availablePaymentMethods,
    dispatch,
    fromNavbar,
    handleModalClose,
    hasNoAccount,
    initialState,
    invoiceNeedsApproval,
    isAccountFormVisible,
    isAccountPaymentVisible,
    isAccountReadyVisible,
    isAddingNewMember,
    isCreatingAccount,
    isInvoiceSubmissionSuccessVisible,
    isPaymentOptionsVisible,
    isPaymentSuccessVisible,
    isPendingNoticeVisible,
    newAccount,
    paymentType,
    pendingMember.first_name,
    pendingMember.id,
  ]);

  return (
    <Modal
      handleModalToggle={handleModalClose}
      modalStyle="isAccount"
      isModalOpen={isModalOpen}
      shouldCloseOnEsc={!isPaymentSuccessVisible}
    >
      {renderView}
    </Modal>
  );
};

SetAccount.propTypes = {
  isModalOpen: bool,
  account: shape(accountType),
  fromNavbar: bool,
  closeModal: func.isRequired,
  availablePaymentMethods: availablePaymentMethodsPropTypes,
  appCompanyName: string,
  hasNoAccount: bool,
  isCreatingAccount: bool,
};

SetAccount.defaultProps = {
  fromNavbar: false,
  closeModal: () => {},
  account: null,
  isModalOpen: false,
  appCompanyName: '',
  availablePaymentMethods: null,
  hasNoAccount: false,
  isCreatingAccount: null,
};

export default memo(SetAccount);
