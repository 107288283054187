/* eslint-disable max-len */
export const commonText = {
  ok: 'Ok',
  save: 'Save',
  close: 'Close',
  errorTitle: 'Something went wrong',
};
export default {
  common: commonText,
  threeDAuth: {
    success: 'Authentication Successful!',
    createBooking: {
      request: {
        title: 'Please authenticate your card to continue with your booking',
      },
      error: {
        subtitle:
          'Please try again, or select another payment method to continue with your booking.',
        default: {
          title: 'Your card authentication has failed.',
        },
        cancelled: {
          title: 'Authentication Required!',
        },
        timeout: {
          title: 'Your card authentication has timed out.',
        },
        unknown: {
          title: 'Authentication Error.',
        },
        duplicated: (last4 = '') => ({
          title: `Card ending ${last4} has already been added`,
        }),
      },
      addNewCard: {
        request: {
          title: 'Please authenticate to continue adding your card',
        },
        error: {
          subtitle: 'Please select an alternative payment method, or try again',
          default: {
            title: 'Your card authentication has failed.',
          },
          cancelled: {
            title: 'Authentication Required!',
          },
          timeout: {
            title: 'Your card authentication has timed out.',
          },
          unknown: {
            title: 'Authentication Error.',
          },
          duplicated: (last4 = '') => ({
            title: `Card ending ${last4} has already been added`,
          }),
        },
      },
    },
    addNewCard: {
      request: {
        title: 'Please authenticate to continue adding your card',
      },
      error: {
        subtitle:
          'Please try again, or enter another card to continue adding your payment method',
        default: {
          title: 'Your card authentication has failed.',
        },
        cancelled: {
          title: 'Authentication Required!',
        },
        timeout: {
          title: 'Your card authentication has timed out.',
        },
        unknown: {
          title: 'Authentication Error.',
        },
        duplicated: (last4 = '') => ({
          title: `Card ending ${last4} has already been added`,
        }),
      },
    },
    finalPayment: {},
  },
};
export const customErrors = {
  post_users_450: {
    title: 'This email is already in use with a different mobile number',
    body:
      'Please continue to the next step to enter your password. Alternatively, you can change your chosen email address',
  },
  post_users_451: {
    title: 'This number is already in use',
    body: '',
  },
  get_accounts_450: {
    title: 'That company account already exists',
    body: (companyEmail = '') =>
      `Company name must be unique.  If this is your company name please log in, using the phone number associated with the account.  If you do not yet have an account please enter a unique name. ${
        companyEmail ? `Need help? Contact ${companyEmail}` : ''
      }`,
  },
  post_accounts_489: {
    title: 'Please try again',
    body: 'Account field validation failed. Please try again',
  },
  post_bookings_489: {
    title: 'Please try again',
    body: 'Account field validation failed. Please try again',
  },
  post_accounts_450: {
    title: 'That name is in use!',
    body: (companyEmail = '') =>
      `If you are the company owner please log in with the phone number associated with this account. Otherwise please choose a different name. ${
        companyEmail ? `Need help? Contact ${companyEmail}` : ''
      }`,
  },
};
