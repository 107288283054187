import React from 'react';
import Input from 'components/Input';
import { Row } from 'components/Grid';
import {
  StyledHeaderWrapper,
  StyledHeader,
  StyledCol,
} from 'views/Login/styles';
import { emailStep as messages } from './messages';

const EmailStep = ({ values, handleChange, handleBlur, errors, touched }) => (
  <>
    <StyledHeaderWrapper>
      <StyledHeader>{messages.header}</StyledHeader>
    </StyledHeaderWrapper>
    <Row>
      <StyledCol>
        <Input
          bgWhite
          isLarge
          errorBgPrimary
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={'Email address'}
          type="email"
          name="email"
          errors={errors}
          touched={touched}
        />
      </StyledCol>
    </Row>
  </>
);

export default EmailStep;
