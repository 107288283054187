export const appLinks = {
  ios: 'https://apps.apple.com/us/app/riide/id1131008247',
  android: 'https://play.google.com/store/apps/details?id=uk.riide&gl=UK',
  web: 'https://riide.co',
  policy: 'http://riide.co/privacypolicy/',
};

export const TERMS_AND_CONDITIONS_DEFAULT_LINK =
  'http://riide.co/termsandconditions/';

export const PRIVACY_POLICY_DEFAULT_LINK = 'http://riide.co/privacypolicy/';
