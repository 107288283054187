import React, { useState, useEffect, useCallback, useMemo, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { func, shape, bool, string } from 'prop-types';
import _isEqual from 'lodash/isEqual';

import { addOrEditUserSelectStyles } from 'constants/customSelectStyles';
import { Row } from 'components/GridNew';
import {
  inviteMemberByRole,
  clearInvoiceAccountPending,
} from 'store/slices/accountsSlice';

import AddUserCSV from 'components/Modals/AddUser/AddUserCSV';
import { BasicModalContent } from 'components/Modals/BasicModal';
import { accountType } from 'constants/propTypes';
import { NEW_USER_ROLE_OPTIONS } from 'constants/roleOptions';
import { ACCOUNT_ROLE_FRONTDESK } from 'constants/accountUserRoles';
import { DEFAULT_COMPANY_COUNTRY_CODE } from 'constants/companies';
import {
  LabelWithInput,
  LabelWithPhoneNumber,
  LabelWithSelect,
} from 'components/Label';
import { StyledBody, StyledBulkButton, LABEL_STYLE } from './styles';

import {
  addUserSchema,
  initialValues,
} from 'components/Modals/AddUser/addUserSchema';
import { messages } from './messages';

import { companyCountryCodeSelector } from 'store/slices/companySlice';

const PaymentSuccess = ({
  account,
  onCancel,
  handlePendingNotice,
  invoiceNeedsApproval,
  companyName,
}) => {
  const dispatch = useDispatch();
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);
  const { isLoading, invited } = useSelector((state) => state.accounts.members);
  const pendingMember = useSelector((state) => state.accounts.pendingMember);
  const countryCode =
    useSelector(companyCountryCodeSelector) || DEFAULT_COMPANY_COUNTRY_CODE;
  const {
    touched,
    errors,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema: addUserSchema(true),
    onSubmit: (values) => {
      dispatch(
        inviteMemberByRole({
          account,
          values,
          role: values.type,
        })
      );
    },
  });

  const title = useMemo(
    () => (
      <>
        {invoiceNeedsApproval ? messages.approvalHeader : messages.header}
        <br />
        {invoiceNeedsApproval
          ? messages.approvalHeader2(companyName)
          : messages.header2}
      </>
    ),
    [companyName, invoiceNeedsApproval]
  );
  const subtitle = useMemo(
    () =>
      invoiceNeedsApproval ? messages.approvalSubtitle : messages.subtitle,
    [invoiceNeedsApproval]
  );
  const clearAfterInvite = useCallback(() => {
    if (!invited) return;
    if (invited) {
      if (pendingMember.id) {
        handlePendingNotice();
      } else {
        onCancel();
      }
      if (invoiceNeedsApproval) {
        dispatch(clearInvoiceAccountPending());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    handlePendingNotice,
    invited,
    invoiceNeedsApproval,
    pendingMember.id,
  ]);

  useEffect(() => {
    clearAfterInvite();
  }, [clearAfterInvite]);

  const handleCancel = () => {
    onCancel();
    if (invoiceNeedsApproval) {
      dispatch(clearInvoiceAccountPending());
    }
  };

  const selectedRole = useMemo(
    () => NEW_USER_ROLE_OPTIONS.find((option) => option.value === values.type),
    [values.type]
  );
  const isFrontdesk = selectedRole?.value === ACCOUNT_ROLE_FRONTDESK;

  return isBulkUploadOpen ? (
    <AddUserCSV
      title={title}
      subtitle={subtitle}
      toggleCSV={() => setIsBulkUploadOpen(false)}
      handleModalToggle={handleCancel}
    />
  ) : (
    <BasicModalContent
      title={title}
      subtitle={subtitle}
      onClickPrimary={handleSubmit}
      onClickSecondary={handleCancel}
      secondaryTitle={messages.skip}
      primaryTitle={messages.submit}
      isSecondaryDisabled={isLoading}
      isPrimaryDisabled={isLoading}
      isPrimaryLoading={isLoading}
      centerTitle
      buttonType="submit"
      renderAsForm
    >
      <StyledBulkButton onClick={() => setIsBulkUploadOpen(!isBulkUploadOpen)}>
        {messages.bulkUpload}
      </StyledBulkButton>
      <StyledBody>
        <LabelWithSelect
          testId="role"
          labelStyle={LABEL_STYLE}
          labelSize={12}
          isColumn
          label={messages.role}
          id="role"
          name="role"
          onChange={(option) => setFieldValue('type', option.value)}
          value={selectedRole}
          selectProps={{
            options: NEW_USER_ROLE_OPTIONS,
            defaultValue: values.type,
            styles: addOrEditUserSelectStyles,
          }}
        />
        {isFrontdesk ? (
          <Row spaceBetween margin="1em 0">
            <LabelWithInput
              testId="email"
              labelStyle={LABEL_STYLE}
              label={messages.email}
              error={errors.email}
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              hasError={!!errors.email && !!touched.email}
              labelSize={5.8}
              inputSize={5.8}
              isColumn
            />
            <LabelWithInput
              testId="password"
              type="password"
              labelStyle={LABEL_STYLE}
              label={messages.password}
              error={errors.password}
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              hasError={!!errors.password && !!touched.password}
              labelSize={5.8}
              inputSize={5.8}
              isColumn
            />
          </Row>
        ) : (
          <Row margin="1em 0">
            <LabelWithPhoneNumber
              testId="phone"
              labelStyle={LABEL_STYLE}
              label={messages.phone}
              error={errors.phone}
              id="phone"
              hasError={!!errors.phone && !!touched.phone}
              labelSize={12}
              inputSize={12}
              isColumn
              phoneProps={{
                containerClass: 'flex',
                setFieldValue,
                setFieldTouched,
                defaultCountry: countryCode,
                autoPlaceholder: false,
              }}
            />
          </Row>
        )}
        <Row spaceBetween margin="1em 0">
          <LabelWithInput
            testId="first_name"
            labelStyle={LABEL_STYLE}
            label={messages.first_name}
            error={errors.first_name}
            name="first_name"
            onChange={handleChange}
            onBlur={() => setFieldTouched('first_name')}
            value={values.first_name}
            hasError={!!errors.first_name && !!touched.first_name}
            labelSize={5.8}
            inputSize={5.8}
            isColumn
          />
          <LabelWithInput
            testId="last_name"
            labelStyle={LABEL_STYLE}
            label={messages.last_name}
            error={errors.last_name}
            name="last_name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.last_name}
            hasError={!!errors.last_name && !!touched.last_name}
            labelSize={5.8}
            inputSize={5.8}
            isColumn
          />
        </Row>
      </StyledBody>
    </BasicModalContent>
  );
};

PaymentSuccess.propTypes = {
  account: shape(accountType),
  onCancel: func,
  handlePendingNotice: func,
  invoiceNeedsApproval: bool,
  companyName: string.isRequired,
};

PaymentSuccess.defaultProps = {
  account: null,
  onCancel: () => {},
  handlePendingNotice: () => {},
  invoiceNeedsApproval: null,
};

const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.account, nextProps.account) &&
  prevProps.invoiceNeedsApproval === nextProps.invoiceNeedsApproval &&
  prevProps.companyName === nextProps.companyName;

export default memo(PaymentSuccess, areEqual);
