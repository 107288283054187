import React, { useRef, useState, useEffect, useMemo, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { number, func, bool, string, shape, array } from 'prop-types';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import FlightDetails from './FlightDetails';
import BookingDayPicker from './BookingDayPicker';
import BookingFormPaymentsSelect from './BookingFormPaymentsSelect';
import Button from 'components/Button';
import TextArea from 'components/TextArea';
import { Row, Col } from 'components/Grid';
import BookingMap from './BookingMap';
import CCReceipt from './CCReceipt';
import BookingTimePicker from 'components/Modals/BookingForm/BookingTimePicker';
import BookingPassengerSearch from './BookingPassengerSearch';
import BookingFormPhone from './BookingFormPhone';
import BookingVehicleSelect from './BookingVehicleSelect';
import { BookingFormSchema, FORM_FIELDS_IDS } from './BookingFormSchema';
import AddressSearch from 'components/AddressSearch';
import SpacedRow from 'components/Grid/SpacedRow';

import { useOutsideClick } from 'hooks/useOutsideClick';

import styles from './BookingForm.module.scss';
import 'react-day-picker/lib/style.css';

import { getDateInTimeZoneWithFormat } from 'utils/dateTime';
import { PICKUP_TYPES, TEXTAREA_LIMIT } from 'constants/booking';
import { locationType } from 'constants/propTypes';
import { commonText } from 'locales/en';

import {
  setAddressDetailsByType,
  updateBooking,
  createGuestAndUpdateBooking,
  getPreauthId,
  selectPassenger,
  clearUpdatedBookingStatus,
  clearCurrentBooking,
} from 'store/slices/bookingsSlice';
import { FormCancelButton } from './styles';
import messages from './messages';
import { usePrevious } from 'hooks';

const EditBookingForm = ({
  bookingDetails,
  handleModalToggle,
  onSelectNewCardOption,
  companyCoordinates,
  companyCountryCode,
  hasPreauth,
  isNotRfbAccount,
  currentAccount,
  serviceStatus,
  onClickAddNewAddress,
  redirectRef,
  formRef,
  clearForm,
  defaultPaymentMethodId,
  paymentMethods,
  isUpdated,
  isUpdating,
  newBookingFormData,
  hasAddCardOption,
  error,
}) => {
  const dispatch = useDispatch();
  const { selectedPassenger } = newBookingFormData;
  const {
    result: destinationAddressResult,
    details: destinationDetails,
  } = newBookingFormData?.destination;
  const {
    result: pickupAddressResult,
    details: pickupDetails,
    eta: pickupETA,
    isInAirport,
  } = newBookingFormData?.pickup;

  const [isFlightDetailsModalOpen, setIsFlightDetailsModalOpen] = useState(
    false
  );
  const [disabledFields, setDisabledFields] = useState({});
  const [formValues, setFormValues] = useState({});
  const [toggle, setToggle] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const dayPickerRef = useRef();
  const { timezone } = useMemo(() => bookingDetails.company, [
    bookingDetails.company,
  ]);
  const [isASAP, setIsASAP] = useState(formValues.type === PICKUP_TYPES.ASAP);
  const prevIsASAP = usePrevious(isASAP);

  useOutsideClick(dayPickerRef, () => setToggle(false));
  const {
    can_update_destination,
    can_update_payment,
    can_update_pickup,
    can_update_pickup_date,
    can_update_user,
    can_update_vehicle_type,
    can_update_driver_notes,
  } = bookingDetails.options || {};

  useEffect(() => {
    if (error === 459 && !isFlightDetailsModalOpen) {
      setIsFlightDetailsModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (isUpdated) {
      dispatch(clearUpdatedBookingStatus());
    }
    const { payment, user } = bookingDetails;
    const accountUser = payment?.account?.account_user;
    if (accountUser) {
      dispatch(
        selectPassenger({
          firstName: accountUser.first_name,
          lastName: accountUser.last_name,
          id: accountUser.id,
          type: null,
          phone: user.phone.indexOf('+') > -1 ? user.phone : `+${user.phone}`,
          userRfbPaymentMethodId: bookingDetails.payment.rfb_payment_method?.id,
        })
      );
    } else {
      dispatch(
        selectPassenger({
          firstName: user.first_name,
          lastName: user.last_name,
          id: user.id,
          type: null,
          phone: user.phone.indexOf('+') > -1 ? user.phone : `+${user.phone}`,
          userRfbPaymentMethodId: bookingDetails.payment.rfb_payment_method?.id,
        })
      );
    }
    if (!formValues.id && user) {
      const formDetails = {
        ...bookingDetails,
        flight_number: bookingDetails.flight_number || '',
        user_id: user.id,
        pickup_at: getDateInTimeZoneWithFormat(
          bookingDetails.pickup_at,
          timezone
        ),
        date: bookingDetails.pickup_at
          ? new Date(bookingDetails.pickup_at)
          : new Date(bookingDetails.created_at),
        hour: getDateInTimeZoneWithFormat(
          bookingDetails.pickup_at || bookingDetails.created_at,
          timezone,
          'HH:mm'
        ),
        pickup_address: bookingDetails.pickup.address,
        destination_address: bookingDetails.destination?.address || '',
        destination_lat: bookingDetails.destination?.lat || '',
        destination_lng: bookingDetails.destination?.lng || '',
        pickup_lng: bookingDetails.pickup.lng,
        pickup_lat: bookingDetails.pickup.lat,
        rfb_payment_method:
          bookingDetails.payment.rfb_payment_method?.id ||
          defaultPaymentMethodId,
        username:
          (accountUser
            ? `${accountUser.first_name} ${accountUser.last_name}`
            : bookingDetails.username) || '-',
        phone_country: user.phone_country,
        phone_number: user.phone_number,
        phone: user.phone,
        driver_notes: bookingDetails.driver_notes || '',
      };
      setFormValues(formDetails);
    }
    dispatch(
      setAddressDetailsByType({
        type: 'pickup',
        details: {
          lat: bookingDetails.pickup.lat,
          lng: bookingDetails.pickup.lng,
          address: bookingDetails.pickup.address,
        },
      })
    );
    dispatch(
      setAddressDetailsByType({
        type: 'destination',
        details: {
          lat: bookingDetails.destination?.lat,
          lng: bookingDetails.destination?.lng,
          address: bookingDetails.destination?.address,
        },
      })
    );
    setDisabledFields({
      [FORM_FIELDS_IDS.destination_address]: !can_update_destination,
      [FORM_FIELDS_IDS.destination_lat]: !can_update_destination,
      [FORM_FIELDS_IDS.destination_lng]: !can_update_destination,
      [FORM_FIELDS_IDS.pickup_address]: !can_update_pickup,
      [FORM_FIELDS_IDS.pickup_lat]: !can_update_pickup,
      [FORM_FIELDS_IDS.pickup_lng]: !can_update_pickup,
      [FORM_FIELDS_IDS.flight_number]: !can_update_pickup,
      [FORM_FIELDS_IDS.username]: !can_update_user,
      [FORM_FIELDS_IDS.phone]: !can_update_user,
      [FORM_FIELDS_IDS.pickup_at]: !can_update_pickup_date,
      [FORM_FIELDS_IDS.type]: !can_update_pickup_date,
      [FORM_FIELDS_IDS.vehicle_type]: !can_update_vehicle_type,
      [FORM_FIELDS_IDS.driver_notes]: !can_update_driver_notes,
      [FORM_FIELDS_IDS.rfb_payment_method]: !can_update_payment,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickCancel = () => {
    handleModalToggle();
    dispatch(clearCurrentBooking());
  };

  const onFormSubmissionSuccess = (resetForm) => {
    clearForm(resetForm);
    handleModalToggle();
  };

  const handleSubmit = async (values) => {
    const { card_id, last4 } = selectedPaymentMethod;

    const fields = reduce(
      values,
      (result, value, key) =>
        isEqual(value, formValues[key]) ? result : result.concat(key),
      []
    );
    const includedFields = fields.filter(
      (item) => disabledFields[item] === false
    );

    const updatedFields = reduce(
      values,
      (result, value, key) =>
        includedFields.indexOf(key) > -1 ? { ...result, [key]: value } : result,
      {}
    );

    if (isEmpty(updatedFields)) {
      onClickCancel();
      return;
    }
    if (isInAirport && !values.flight_number) {
      return setIsFlightDetailsModalOpen(true);
    } else if (hasPreauth && updatedFields.rfb_payment_method && card_id) {
      const { payload } = await dispatch(
        getPreauthId({
          updatedFields,
          values,
          timezone,
          card_id,
          last4,
          currentAccount,
        })
      );
      if (payload?.preauthId) {
        if (values.user_id) {
          dispatch(
            updateBooking({
              id: values.id,
              values: {
                ...updatedFields,
                preauthId: payload.preauthId,
              },
              guestUserId: null,
              referenceElement: redirectRef,
            })
          );
        } else {
          createGuestAndUpdateBooking({
            values,
            booking: {
              ...updatedFields,
              preauthId: payload.preauthId,
            },
            accountId: currentAccount.id,
            referenceElement: redirectRef,
          });
        }
      }
    } else {
      if (values.user_id) {
        dispatch(
          updateBooking({
            id: values.id,
            values: {
              ...updatedFields,
              rfb_payment_method: values.rfb_payment_method,
            },
            guestUserId: null,
            referenceElement: redirectRef,
          })
        );
      } else {
        dispatch(
          createGuestAndUpdateBooking({
            values,
            booking: {
              ...updatedFields,
              rfb_payment_method: values.rfb_payment_method,
            },
            accountId: currentAccount.id,
            referenceElement: redirectRef,
          })
        );
      }
    }
    if (isFlightDetailsModalOpen) setIsFlightDetailsModalOpen(false);
  };

  useEffect(() => {
    if (isUpdated) {
      onFormSubmissionSuccess(formRef.current?.resetForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  const setBookingTime = (field, value, setFieldValue) => {
    setFieldValue(field, value);

    const type = field === 'type' && value;
    if (type && type !== prevIsASAP) {
      setIsASAP(type === PICKUP_TYPES.ASAP);
    }
  };

  return formValues.id ? (
    <Formik
      validationSchema={BookingFormSchema(isNotRfbAccount)}
      initialValues={formValues}
      enableReinitialize
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({
        handleChange,
        values,
        setFieldValue,
        errors,
        touched,
        setFieldError,
        setFieldTouched,
        handleBlur,
      }) => {
        const notChanged = isEqual(formValues, values);

        return (
          <Form aria-autocomplete="none" autoComplete="off">
            <Row className={styles.RowWrapper}>
              <Col
                col={6}
                withMarginRight
                className={styles.BookingFormLeftColumn}
              >
                <SpacedRow withMarginLarge>
                  <h2 className={styles.BookingFormHeader}>
                    {messages.editTitle}
                  </h2>
                </SpacedRow>
                <SpacedRow withMarginSmall>
                  <Col col={5}>
                    <label className={styles.BookingFormLabel} htmlFor="pickup">
                      {messages.pickupAddress}
                    </label>
                  </Col>
                  <Col col={7}>
                    <AddressSearch
                      zIndex={9}
                      setFieldValue={setFieldValue}
                      id="pickup"
                      searchAddressResult={pickupAddressResult}
                      addressType="pickup"
                      errors={errors}
                      touched={touched}
                      name={FORM_FIELDS_IDS.pickup_address}
                      value={values.pickup_address}
                      onBlur={handleBlur}
                      serviceStatus={serviceStatus}
                      setFieldTouched={setFieldTouched}
                      onClickNewAddress={onClickAddNewAddress}
                      isDisabled={
                        disabledFields[FORM_FIELDS_IDS.pickup_address]
                      }
                    />
                  </Col>
                </SpacedRow>
                <SpacedRow withMarginSmall>
                  <Col col={5}>
                    <label
                      className={styles.BookingFormLabel}
                      htmlFor="destination"
                    >
                      {messages.dropOffAddress}
                    </label>
                  </Col>
                  <Col col={7}>
                    <AddressSearch
                      zIndex={7}
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      id="destination"
                      searchAddressResult={destinationAddressResult}
                      addressType="destination"
                      errors={errors}
                      touched={touched}
                      name={FORM_FIELDS_IDS.destination_address}
                      value={values.destination_address}
                      serviceStatus={serviceStatus}
                      setFieldTouched={setFieldTouched}
                      onClickNewAddress={onClickAddNewAddress}
                      isDisabled={
                        disabledFields[FORM_FIELDS_IDS.destination_address]
                      }
                    />
                  </Col>
                </SpacedRow>
                <SpacedRow withMarginSmall>
                  <Col col={5}>
                    <label className={styles.BookingFormLabel} htmlFor="date">
                      {messages.bookingDateTime}
                    </label>
                  </Col>
                  <Col col={7}>
                    <SpacedRow>
                      <Col col={6}>
                        <BookingDayPicker
                          setToggle={setToggle}
                          toggle={toggle}
                          errors={errors}
                          touched={touched}
                          date={values.date}
                          reference={dayPickerRef}
                          setFieldValue={setFieldValue}
                          isDisabled={disabledFields[FORM_FIELDS_IDS.pickup_at]}
                        />
                      </Col>
                      <Col col={6}>
                        <BookingTimePicker
                          minPrebookTime={newBookingFormData.minPrebookTime}
                          setFieldValue={(field, value) =>
                            setBookingTime(field, value, setFieldValue)
                          }
                          selectedDate={values.date}
                          isEditBooking
                          isDisabled={disabledFields[FORM_FIELDS_IDS.pickup_at]}
                          type={values.type}
                          timezone={timezone}
                        />
                      </Col>
                    </SpacedRow>
                  </Col>
                </SpacedRow>
                <hr className={styles.BookingFormSeparator} />
                <SpacedRow withMarginSmall>
                  <Col col={5}>
                    <label
                      className={styles.BookingFormLabel}
                      htmlFor="passenger"
                    >
                      {messages.passengerName}
                    </label>
                  </Col>
                  <Col col={7}>
                    <BookingPassengerSearch
                      isGuestUser={!values.user_id}
                      value={values.username}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      selectedPassenger={selectedPassenger}
                      isDisabled={disabledFields[FORM_FIELDS_IDS.username]}
                    />
                  </Col>
                </SpacedRow>
                <SpacedRow withMarginSmall>
                  <BookingFormPhone
                    isDisabled={disabledFields[FORM_FIELDS_IDS.phone]}
                    hasTooltip={!disabledFields[FORM_FIELDS_IDS.phone]}
                    setFieldError={setFieldError}
                    setFieldValue={setFieldValue}
                    userPhone={selectedPassenger?.phone}
                    errors={errors}
                    touched={touched}
                    setFieldTouched={setFieldTouched}
                    defaultCountry={companyCountryCode}
                  />
                </SpacedRow>
                <CCReceipt
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
                <hr className={styles.BookingFormSeparator} />
                <SpacedRow withMarginMedium>
                  <BookingVehicleSelect
                    setFieldValue={setFieldValue}
                    value={values.vehicle_type}
                    vehicleTypes={newBookingFormData.vehicleTypes}
                    destinationLat={values.destination_lat}
                    destinationLng={values.destination_lng}
                    pickupLat={values.pickup_lat}
                    pickupLng={values.pickup_lng}
                    pickupDate={values.pickup_at}
                    isDisabled={disabledFields[FORM_FIELDS_IDS.vehicle_type]}
                    timezone={timezone}
                  />
                </SpacedRow>
                <SpacedRow withMarginLarge alignItemsCenter>
                  <TextArea
                    onChange={handleChange}
                    name={FORM_FIELDS_IDS.driver_notes}
                    value={values.driver_notes}
                    label={messages.noteForDriver}
                    limit={TEXTAREA_LIMIT}
                    textareaCol={7}
                    errors={errors}
                    touched={touched}
                    onBlur={handleBlur}
                    autoComplete="new-password"
                    withBoldLabel
                    isDisabled={disabledFields[FORM_FIELDS_IDS.driver_notes]}
                  />
                </SpacedRow>
                {paymentMethods && (
                  <SpacedRow withMarginLarge>
                    <BookingFormPaymentsSelect
                      error={errors[FORM_FIELDS_IDS.rfb_payment_method]}
                      hasTouched={touched[FORM_FIELDS_IDS.rfb_payment_method]}
                      isNotRfbAccount={isNotRfbAccount}
                      account={currentAccount}
                      setFieldValue={setFieldValue}
                      selectedValue={values.rfb_payment_method}
                      paymentMethods={paymentMethods}
                      hasAddCardOption={hasAddCardOption}
                      onSelectNewCard={onSelectNewCardOption}
                      isDisabled={
                        disabledFields[FORM_FIELDS_IDS.rfb_payment_method]
                      }
                      onChange={setSelectedPaymentMethod}
                      defaultPaymentMethodId={defaultPaymentMethodId}
                    />
                  </SpacedRow>
                )}
                <input
                  type="hidden"
                  value={currentAccount.id}
                  name="account_id"
                />
                <input type="hidden" value={values.date} name="pickup_at" />
                <hr className={styles.BookingFormSeparator} />
                <SpacedRow around>
                  <FormCancelButton
                    name={commonText.close}
                    onClick={onClickCancel}
                    type="button"
                  />
                  <Button
                    type="submit"
                    primary
                    name={messages.update}
                    isRounded
                    disabled={isUpdating || notChanged}
                    isLoading={isUpdating}
                    isLarge
                  />
                </SpacedRow>
                {isFlightDetailsModalOpen && (
                  <FlightDetails
                    name="flight_number"
                    value={values.flight_number}
                    handleChange={setFieldValue}
                    isDisabled={!values.flight_number}
                    isLoading={isUpdating}
                    onClose={() => setIsFlightDetailsModalOpen(false)}
                  />
                )}
              </Col>
              <Col col={6} className={styles.BookingFormMapWrapper}>
                <BookingMap
                  pickupDetails={pickupDetails}
                  destinationDetails={destinationDetails}
                  companyCoordinates={companyCoordinates}
                  pickupETA={pickupETA}
                  driverLocations={newBookingFormData?.driverLocations}
                  brandedCarImageUrl={newBookingFormData?.brandedCarImageUrl}
                  displayETA={isASAP}
                />
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  ) : null;
};

EditBookingForm.propTypes = {
  bookingDetails: shape({}),
  handleModalToggle: func.isRequired,
  companyCountryCode: string.isRequired,
  hasPreauth: bool,
  isNotRfbAccount: bool.isRequired,
  currentAccount: shape({}).isRequired,
  serviceStatus: shape({}).isRequired,
  newBookingFormData: shape({}).isRequired,
  isUpdated: bool,
  isUpdating: bool,
  onClickAddNewAddress: func.isRequired,
  companyCoordinates: locationType.isRequired,
  onSelectNewCardOption: func.isRequired,
  redirectRef: shape({}).isRequired,
  formRef: shape({}).isRequired,
  clearForm: func.isRequired,
  defaultPaymentMethodId: number,
  paymentMethods: array.isRequired,
  hasAddCardOption: bool,
};

EditBookingForm.defaultProps = {
  bookingDetails: {},
  hasPreauth: null,
  isUpdated: null,
  isUpdating: null,
  hasAddCardOption: null,
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.bookingDetails, nextProps.bookingDetails) &&
  isEqual(prevProps.newBookingFormData, nextProps.newBookingFormData) &&
  isEqual(prevProps.serviceStatus, nextProps.serviceStatus) &&
  isEqual(prevProps.companyCoordinates, nextProps.companyCoordinates) &&
  isEqual(prevProps.currentAccount, nextProps.currentAccount) &&
  isEqual(prevProps.paymentMethods, nextProps.paymentMethods) &&
  prevProps.companyCountryCode === nextProps.companyCountryCode &&
  prevProps.hasPreauth === nextProps.hasPreauth &&
  prevProps.defaultPaymentMethodId === nextProps.defaultPaymentMethodId &&
  prevProps.isUpdated === nextProps.isUpdated &&
  prevProps.isUpdating === nextProps.isUpdating &&
  prevProps.hasAddCardOption === nextProps.hasAddCardOption;

export default memo(EditBookingForm, areEqual);
