import React from 'react';
import styled from 'styled-components';
import * as v from 'utils/variables';

const HEIGHT = '5px';
const BAR_WIDTH = '20%';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${HEIGHT};
  background-color: ${v.lightgray};
  overflow: hidden;
  border: solid 1px ${v.lightgray};
  border-radius: ${HEIGHT};

  .progress {
    position: absolute;
    height: 100%;
    background-color: ${v.green};
    animation: progressIndeterminate 2s linear infinite;
    border: solid 1px ${v.green};
    border-radius: ${HEIGHT};
  }

  @keyframes progressIndeterminate {
    from {
      left: -${BAR_WIDTH};
      width: ${BAR_WIDTH};
    }
    to {
      left: 100%;
      width: ${BAR_WIDTH};
    }
  }
`;

const ProgressBar = () => {
  return (
    <Wrapper data-testid="progressBar">
      <div className="progress"></div>
    </Wrapper>
  );
};

export default ProgressBar;
