import React from 'react';
import Row from './Row';

const SpacedRow = ({ children, ...props }) => (
  <Row withSpaceBetween alignItemsCenter {...props}>
    {children}
  </Row>
);

export default SpacedRow;
