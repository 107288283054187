import React from 'react';
import { StyledDiv, StyledItem } from './ActivityHeader.styles';

const ActivityHeader = ({ labels, isLabel, hasTopMargin }) => {
  return (
    <StyledDiv $hasTopMargin={hasTopMargin}>
      {labels.map((item, index) => {
        return (
          <StyledItem key={`${item}-${index}`} $isLabel={isLabel}>
            {item}
          </StyledItem>
        );
      })}
    </StyledDiv>
  );
};

export default ActivityHeader;
