import fileDownload from 'js-file-download';

import { axiosGet, axiosPost, axiosPatch } from './axiosHelpers';
import {
  ACCOUNT_ROLE_FRONTDESK,
  ACCOUNT_ROLE_MANAGER,
} from 'constants/accountUserRoles';
import { isOwnerOrAdmin } from 'utils/isOwner';

export const getActiveMembersRequest = (accountId, page = 1, cancelToken) => {
  if (!accountId) return;

  return axiosGet({
    url: `/accounts/${accountId}/members?page=${page}`,
    cancelToken,
  }).then(({ data }) => {
    return data;
  });
};

export const getPendingMembersRequest = (accountId, page = 1, cancelToken) => {
  if (!accountId) return;

  return axiosGet({
    url: `/accounts/${accountId}/pendings?page=${page}`,
    cancelToken,
  }).then(({ data }) => {
    return data;
  });
};

export const addManagerRequest = (account, values) => {
  if (!account) return;

  const { id: accountId, rfbDefaultPayment } = account;
  const isNotRfb = rfbDefaultPayment?.isNotRfb;

  const {
    password,
    email,
    first_name,
    last_name,
    phone_number,
    phone_country,
    rfb_payment_method_id,
    type,
    group_id,
  } = values;
  const isFrontdesk = type === ACCOUNT_ROLE_FRONTDESK;
  const common = {
    first_name,
    last_name,
    group_id,
  };

  return axiosPost({
    url: `/accounts/${accountId}/manager`,
    data: isFrontdesk
      ? {
          ...common,
          email,
          password,
        }
      : {
          ...common,
          phone_number,
          phone_country,
          rfb_payment_method_id: isNotRfb ? undefined : rfb_payment_method_id,
        },
  }).then(({ data }) => {
    return data;
  });
};

export const inviteMemberRequest = (account, values) => {
  const isNotRfb = account?.rfbDefaultPayment?.isNotRfb;
  const accountId = account?.id;
  if (!accountId) return;

  const {
    first_name,
    last_name,
    phone_country,
    phone_number,
    rfb_payment_method_id,
    group_id,
  } = values;

  return axiosPost({
    data: {
      first_name,
      last_name,
      group_id,
      phone_number,
      phone_country,
      rfb_payment_method_id: isNotRfb ? undefined : rfb_payment_method_id,
    },
    url: `/accounts/${accountId}/invite`,
  })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteMembersRequest = (ids) => {
  return axiosPatch({
    url: `/accounts/users/kick`,
    data: { account_users: ids },
  }).then(({ data }) => {
    return data;
  });
};

export const editAccountMemberRequest = (id, values, account) => {
  if (!id) return;

  const { type, rfb_payment_method_id, group_id } = values;

  const isNotRfb = account.rfbDefaultPayment?.isNotRfb;

  const isUpdatingManager =
    type === ACCOUNT_ROLE_FRONTDESK || type === ACCOUNT_ROLE_MANAGER;

  const isUpdatingOwner = isOwnerOrAdmin(type);

  let updatedType = type;

  if (isUpdatingManager) {
    updatedType = ACCOUNT_ROLE_MANAGER;
  } else if (isUpdatingOwner) {
    updatedType = undefined;
  }

  return axiosPatch({
    url: `/accounts/users/${id}`,
    data: {
      type: updatedType,
      rfb_payment_method_id: isNotRfb ? undefined : rfb_payment_method_id,
      group_id,
    },
  }).then(({ data }) => data);
};

export const csvImportRequest = (
  accountId,
  file,
  dryRun = 1,
  getAccountMembers
) => {
  if (!accountId) return;

  const formData = new FormData();
  formData.append('dry_run', dryRun);
  formData.append('members_list', file[0]);
  return axiosPost({
    url: `/accounts/${accountId}/members/import`,
    data: formData,
  }).then(({ data }) => {
    return data;
  });
};

export const getCSVImportTemplateRequest = () => {
  const CSV_TEMPLATE_FILE_NAME = 'members_import_template.csv';

  return axiosGet({
    url: 'accounts/members/export-template',
    responseType: 'blob',
  }).then(({ data }) => {
    fileDownload(data, CSV_TEMPLATE_FILE_NAME);
  });
};
