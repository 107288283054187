import React from 'react';
import styled from 'styled-components';
import { green, fontSizeSm } from 'utils/variables';

const Wrapper = styled.span`
  background-color: ${green};
  width: 150px;
  height: 30px;
  color: white;
  text-align: center;
  font-size: ${fontSizeSm};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Promo = ({ text }) => {
  return (
    <Wrapper data-testid="promo">
      <p>{text}</p>
    </Wrapper>
  );
};

export default Promo;
