import { palegray, lightblack, mousegray, dark } from 'utils/variables';

const common = {
  textColor: lightblack,
  disabledColor: mousegray,
  border: `1px solid ${dark}`,
  errorBorder: '1px solid red',
  white: '#FFFFFF',
  fontSize: '14px',
  smallFont: '12px',
};
const defaultBorders = {
  border: common.border,
  boxShadow: 'none',
  outline: 'none',
  '&:hover': {
    border: common.border,
  },
};
export const optionStyle = ({ isSelected }) => ({
  cursor: 'pointer',
  color: isSelected ? common.white : common.textColor,
  fontSize: common.smallFont,
  outline: 'none',
});
const controlWithError = (styles, { isDisabled, selectProps }, isError) => ({
  ...styles,
  outline: 'none',
  boxShadow: 'none',
  fontSize: common.fontSize,
  border: isError ? common.errorBorder : common.border,
  '&:hover': {
    border: isError ? common.errorBorder : common.border,
  },
  height: selectProps?.height ?? '38px',
  color: common.textColor,
  cursor: isDisabled ? 'not-allowed' : 'pointer',
});

export const customSelectStyles = {
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? common.disabledColor : common.textColor,
  }),
  container: (styles) => ({
    ...styles,
    pointerEvents: undefined,
  }),
  control: (styles, { isDisabled, selectProps }) => ({
    ...styles,
    ...defaultBorders,
    fontSize: common.fontSize,
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    color: isDisabled ? common.disabledColor : common.textColor,
    height: selectProps?.height ?? '38px',
  }),
  placeholder: (styles, { isDisabled }) => ({
    ...styles,
    width: '100%',
    color: isDisabled ? common.disabledColor : common.textColor,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      ...optionStyle(state),
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      ...defaultBorders,
    };
  },
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    svg: {
      color: palegray,
    },
    '&:hover svg': {
      color: isDisabled ? palegray : mousegray,
    },
  }),
};

export const addOrEditUserSelectStyles = {
  ...customSelectStyles,
  control: (styles, state) => {
    return {
      ...styles,
      ...customSelectStyles.control(styles, state),
      fontSize: common.fontSize,
    };
  },
  option: (styles, state) => {
    return {
      ...styles,
      ...optionStyle(state),
      fontSize: common.fontSize,
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      ...defaultBorders,
      zIndex: 3,
    };
  },
};

export const countrySelectStyles = (isError) => ({
  ...customSelectStyles,
  group: (styles) => ({
    ...styles,
    paddingTop: 0,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      ...optionStyle(state),
      fontSize: common.fontSize,
    };
  },
  control: (styles, state) => controlWithError(styles, state, isError),
});

export const bookingFormSelectStyles = (isError) => ({
  ...customSelectStyles,
  control: (styles, state) => controlWithError(styles, state, isError),
  option: (styles, state) => {
    return {
      ...styles,
      ...optionStyle(state),
      fontSize: common.fontSize,
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      fontSize: common.fontSize,
      ...defaultBorders,
    };
  },
});

export const navbarStyles = {
  ...customSelectStyles,
  control: (styles, state) => ({
    ...customSelectStyles.control(styles, state),
    fontSize: common.smallFont,
  }),
};
