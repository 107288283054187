import React, { memo } from 'react';
import { number, shape, string, func, bool } from 'prop-types';

import { addOrEditUserSelectStyles } from 'constants/customSelectStyles';
import InputError from 'components/InputError';
import Select from 'components/Select';
import LabelContainer from './LabelContainer';
import { DEFAULT_INPUT_HEIGHT, COLUMN_INPUT_HEIGHT } from 'utils/variables';

const WithSelect = ({
  name,
  id = name,
  value,
  onChange,
  onBlur,
  error,
  label,
  labelSize,
  inputSize,
  hasError,
  errorCanFit,
  isColumn,
  labelStyle,
  testId,
  style,
  selectProps,
}) => {
  return (
    <>
      <LabelContainer
        testId={testId}
        id={id}
        label={label}
        labelSize={labelSize}
        inputSize={inputSize}
        isColumn={isColumn}
        labelStyle={labelStyle}
        hasError={hasError}
        error={error}
        style={style}
      >
        <Select
          {...selectProps}
          onChange={onChange}
          value={value}
          height={isColumn ? COLUMN_INPUT_HEIGHT : DEFAULT_INPUT_HEIGHT}
        />
        <InputError
          errorCanFit={errorCanFit}
          hasError={hasError}
          error={error}
          absolute
        />
      </LabelContainer>
    </>
  );
};

WithSelect.propTypes = {
  value: shape({}),
  onChange: func,
  onBlur: func,
  id: string,
  name: string,
  label: string,
  labelSize: number,
  inputSize: number,
  error: string,
  hasError: bool,
  errorCanFit: bool,
  isColumn: bool,
  testId: string,
  style: shape({}),
  labelStyle: shape({}),
  selectProps: shape({}),
};
WithSelect.defaultProps = {
  labelSize: 4,
  inputSize: 8,
  hasError: false,
  error: null,
  isColumn: false,
  testId: 'label-with-select',
  style: {},
  labelStyle: {},
  selectProps: {
    styles: addOrEditUserSelectStyles,
  },
};

export default memo(WithSelect);
