import React, { useMemo, memo } from 'react';
import isEqual from 'lodash/isEqual';
import { func, shape, number, bool } from 'prop-types';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Row } from 'components/Grid';
import UnstyledButton from 'components/UnstyledButton';
import LogInWithEmail from './LogInWithEmail';
import styles from './SignUp.module.scss';
import { StyledButton } from 'views/Login/styles';

const Footer = ({
  stage,
  setTouched,
  values,
  isDisabled,
  isLoading,
  handleStageDecrement,
  handleSubmit,
  validateStage,
  resetForm,
  isFirstOrSecondStage,
  isSecondStage,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isStageValid = useMemo(() => validateStage(stage, values), [
    values,
    stage,
  ]);

  return (
    <Row between>
      <div className={styles.BottomSectionWrapper}>
        {isSecondStage && (
          <UnstyledButton
            isDisabled={isLoading}
            onClick={() => handleStageDecrement(resetForm, values)}
            className={styles.BackButtonWrapper}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            <span className={styles.BackButtonText}>Go back</span>
          </UnstyledButton>
        )}
        {isFirstOrSecondStage ? <LogInWithEmail /> : <div />}
        <StyledButton
          disabled={isDisabled || !isStageValid}
          isLoading={isLoading}
          type={stage === 5 ? 'button' : 'submit'}
          onClick={
            stage === 5 ? () => handleSubmit(values, { setTouched }) : undefined
          }
          primary
          name={stage === 5 ? 'Finish' : 'Next'}
          withArrow
          isRounded
          className={styles.SignUpNextButton}
        />
      </div>
    </Row>
  );
};

Footer.propTypes = {
  stage: number.isRequired,
  setTouched: func.isRequired,
  values: shape({}).isRequired,
  isDisabled: bool,
  isLoading: bool,
  handleStageDecrement: func.isRequired,
  handleSubmit: func.isRequired,
  validateStage: func.isRequired,
  resetForm: func.isRequired,
  isFirstOrSecondStage: bool.isRequired,
  isSecondStage: bool.isRequired,
};
Footer.defaultProps = {
  isDisabled: false,
  isLoading: false,
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.values, nextProps.values) &&
  prevProps.stage === nextProps.stage &&
  prevProps.isDisabled === nextProps.isDisabled &&
  prevProps.isLoading === nextProps.isLoading &&
  prevProps.isFirstOrSecondStage === nextProps.isFirstOrSecondStage &&
  prevProps.isSecondStage === nextProps.isSecondStage;

export default memo(Footer, areEqual);
