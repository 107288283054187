import React, { useState } from 'react';
import { func, bool, string } from 'prop-types';

import Modal from 'components/Modal';
import { BasicModalContent } from 'components/Modals/BasicModal';
import BookingForm from 'components/Modals/BookingForm/BookingForm';
import AddCard from 'components/AddCard';
import messages from './messages';

const SetAccountInvoiceSuccess = ({
  handleInvoiceSubmissionSuccessClose,
  hasCardPaymentMethodAdded,
  handlePaymentModalClose,
  handleAddPaymentSuccess,
  appCompanyName,
  account,
}) => {
  const [isBookingFormVisible, setBookingFormVisible] = useState(false);
  const [isAddCardFormVisible, setAddCardFormVisible] = useState(false);

  const handleBookingFormClose = () => {
    setBookingFormVisible(false);
    handleInvoiceSubmissionSuccessClose();
    handlePaymentModalClose();
  };

  const handleAddCardFormClose = () => {
    setAddCardFormVisible(false);
    handleInvoiceSubmissionSuccessClose();
    handlePaymentModalClose();
  };

  return (
    <>
      <BasicModalContent
        title={messages.invoiceSubmittedHeader}
        subtitle={`${appCompanyName} ${messages.invoiceSubmittedSubHeader}`}
        secondaryTitle={messages.invoiceSubmittedCloseBtn}
        type="success"
        onClickSecondary={() => {
          handleInvoiceSubmissionSuccessClose();
          handlePaymentModalClose();
        }}
        primaryTitle={
          hasCardPaymentMethodAdded
            ? messages.invoiceSubmittedCreateBookingBtn
            : messages.invoiceSubmittedAddCardBtn
        }
        onClickPrimary={() => {
          hasCardPaymentMethodAdded
            ? setBookingFormVisible(true)
            : setAddCardFormVisible(true);
        }}
      />
      {isBookingFormVisible && (
        <BookingForm
          isModalOpen={isBookingFormVisible}
          handleModalToggle={handleBookingFormClose}
        />
      )}
      {isAddCardFormVisible && (
        <Modal isModalOpen={isAddCardFormVisible} modalStyle="isAccount">
          <AddCard
            handleModalClose={handleAddCardFormClose}
            onSuccess={handleAddPaymentSuccess}
            account={account}
          />
        </Modal>
      )}
    </>
  );
};

SetAccountInvoiceSuccess.propTypes = {
  handleInvoiceSubmissionSuccessClose: func.isRequired,
  handlePaymentModalClose: func.isRequired,
  handleAddPaymentSuccess: func.isRequired,
  hasCardPaymentMethodAdded: bool.isRequired,
  appCompanyName: string,
};

SetAccountInvoiceSuccess.defaultProps = {
  appCompanyName: '',
};

export default SetAccountInvoiceSuccess;
