import messages from './messages';

export const ACTIVITY_DASHBOARD_COLUMNS = [2, 1, 2, 4, 2, 1];
export const TEAM_COLUMNS = [1, 2, 2, 2, 2, 1, 1, 1];
export const GROUP_COLUMNS = [2, 8, 2];

export const BILLING_DASHBOARD_COLUMNS = [0.5, 1, 2, 2, 3.5, 2, 1];
export const FAVORITE_PLACES_COLUMNS = [3, 8, 1];

const ACTIVITY_DASHBOARD_TITLES = [
  messages.date,
  messages.time,
  messages.name,
  messages.location,
  messages.status,
  messages.amount,
];
export const ACTIVITY_DASHBOARD_HEADERS = ACTIVITY_DASHBOARD_COLUMNS.map(
  (col, idx) => ({
    index: idx + 1,
    col,
    data: ACTIVITY_DASHBOARD_TITLES[idx],
  })
);

const TEAM_TITLES = [
  null,
  messages.name,
  messages.email,
  messages.phoneNumber,
  messages.accessLevel,
  messages.group,
  null,
  null,
];
export const TEAM_HEADERS = TEAM_COLUMNS.map((col, idx) => ({
  index: idx + 1,
  col,
  data: TEAM_TITLES[idx],
}));

const FAVORITE_PLACES_TITLES = [messages.nickname, messages.address, null];
export const FAVORITE_PLACES_HEADERS = FAVORITE_PLACES_COLUMNS.map(
  (col, idx) => ({
    index: idx + 1,
    col,
    data: FAVORITE_PLACES_TITLES[idx],
  })
);
