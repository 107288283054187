import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LoaderIcon from 'components/LoaderIcon';
import { MAP_MARKER_TYPES, ETA_TIME_UNIT } from 'constants/map';
import Root, {
  LabelWrapper,
  Label,
  LabelCornerArrow,
  LabelBottomArrowWrapper,
  LabelBottomArrow,
  EstimatedTime,
  EstimatedTimeValue,
  EstimatedTimeUnit,
  TryLaterIcon,
  Address,
  PickupIcon,
  DropOffIcon,
  VehicleImage,
} from './MapMarker.style';

const MapMarker = (props) => {
  const {
    type,
    lat,
    lng,
    isLoading,
    estimatedTime,
    address,
    vehicleImageSrc,
    vehicleImageAlt,
  } = props;
  const displayETA = !!estimatedTime;
  const isLabel = useMemo(
    () =>
      [MAP_MARKER_TYPES.PICKUP, MAP_MARKER_TYPES.DROP_OFF].indexOf(type) >= 0,
    [type]
  );
  const isVehicle = type === MAP_MARKER_TYPES.VEHICLE;

  // @todo: This is a temporary solution for positioning a car on the map
  // should be removed as soon as the dynamic car positions will be displayed on the map
  const vehicleStyle = useMemo(
    () =>
      isVehicle && {
        transform: `rotate(${Math.floor(360 * Math.random())}deg)`,
      },
    [isVehicle]
  );

  return (
    <Root lat={lat} lng={lng}>
      {isLabel && (
        <>
          <LabelWrapper>
            <Label>
              {type === MAP_MARKER_TYPES.PICKUP && (
                <EstimatedTime>
                  {displayETA && (
                    <>
                      {isLoading && <LoaderIcon variant="secondary" />}
                      {!isLoading && (
                        <>
                          <EstimatedTimeValue>
                            {estimatedTime}
                          </EstimatedTimeValue>
                          <EstimatedTimeUnit>{ETA_TIME_UNIT}</EstimatedTimeUnit>
                        </>
                      )}
                    </>
                  )}
                  {!displayETA && <TryLaterIcon />}
                </EstimatedTime>
              )}
              <Address>{address}</Address>
              <LabelCornerArrow />
            </Label>
            <LabelBottomArrowWrapper>
              <LabelBottomArrow />
            </LabelBottomArrowWrapper>
          </LabelWrapper>
          {type === MAP_MARKER_TYPES.PICKUP && <PickupIcon />}
          {type === MAP_MARKER_TYPES.DROP_OFF && <DropOffIcon />}
        </>
      )}

      {isVehicle && (
        <VehicleImage
          src={vehicleImageSrc}
          alt={vehicleImageAlt}
          style={vehicleStyle}
          data-testid="booking-map-car-img"
        />
      )}
    </Root>
  );
};

MapMarker.propTypes = {
  type: PropTypes.oneOf(Object.values(MAP_MARKER_TYPES)),
  lat: PropTypes.number,
  lng: PropTypes.number,
  isLoading: PropTypes.bool,
  estimatedTime: PropTypes.any,
  address: PropTypes.string,
  vehicleImageSrc: PropTypes.string,
  vehicleImageAlt: PropTypes.string,
};

MapMarker.defaultProps = {
  type: MAP_MARKER_TYPES.VEHICLE,
  displayETA: false,
  isLoading: false,
};

export default MapMarker;
