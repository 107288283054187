import { axiosGet, axiosPost, axiosPatch } from 'api/axiosHelpers';
import { extraInfo } from 'api/config';
import { PAYMENT_METHODS } from 'constants/paymentMethods';

export const getCardsRequest = (cancelToken) =>
  axiosGet({
    url: `/cards`,
    cancelToken,
  }).then(({ data }) => data);

export const setCardForBusinessUseRequest = (
  cardId,
  accountId,
  name,
  cardPaymentMethod
) => {
  if (!cardPaymentMethod) {
    return axiosPost({
      url: `/accounts/${accountId}/payment-methods`,
      data: { name, type: PAYMENT_METHODS.card, card_id: cardId },
    }).then(({ data }) => {
      return data;
    });
  } else {
    const paymentId = cardPaymentMethod.id;
    return axiosPatch({
      url: `/accounts/${accountId}/payment-methods/${paymentId}`,
      data: {
        type: PAYMENT_METHODS.card,
        card_id: cardId,
      },
    }).then(({ data }) => {
      return data;
    });
  }
};

export const initCardRequest = () =>
  axiosPost({
    url: `/cards/init`,
    data: { provider: 'adyen' },
  }).then(({ data }) => {
    return data;
  });

export const addCardRequest = (values, zipcode, accountId) => {
  return axiosPost({
    url: `/cards`,
    data: {
      zip: zipcode,
      provider: 'adyen',
      paymentMethod: {
        encryptedCardNumber: values['encryptedCardNumber'],
        encryptedExpiryMonth: values['encryptedExpiryMonth'],
        encryptedExpiryYear: values['encryptedExpiryYear'],
        encryptedSecurityCode: values['encryptedSecurityCode'],
        type: values['type'],
        holderName: values['holderName'],
      },
      ...extraInfo,
    },
  }).then(({ data }) => {
    return data;
  });
};

export const editCardRequest = (id, data) => {
  return axiosPatch({
    url: `/cards/${id}`,
    data,
  }).then(({ data }) => {
    return data;
  });
};
