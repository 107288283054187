export default {
  passenger: 'Passenger',
  print: 'Print receipt',
  editOrView: 'Edit or View booking',
  cancel: 'Cancel booking',
  iCabbiID: 'Trip ID',
  driverName: 'Driver Name',
  rating: 'Rating Given',
  vehicleMake: 'Vehicle',
  paymentMethod: 'Payment Method',
};
