import React, { useRef } from 'react';
import { oneOfType, number, shape, string, func, bool } from 'prop-types';

import capitalize from 'utils/capitalize';
import { StyledInput } from './styles';
import InputError from 'components/InputError';

const InputNew = ({
  hasError,
  value,
  name,
  id,
  onChange,
  onBlur,
  isDisabled,
  type,
  errorCanFit,
  error,
  style,
  displayError,
  height,
  zIndex,
  errorPositionAbsolute,
  ...props
}) => {
  const ref = useRef();

  const handleChange = (e) => {
    if (type === 'text' && e.target.value && ref?.current) {
      const start = e.target.selectionStart;
      const end = e.target.selectionEnd;
      e.target.value = capitalize(e.target.value);
      ref.current.setSelectionRange(start, end);
    }
    onChange(e);
  };

  return (
    <>
      <StyledInput
        $height={height}
        $hasError={hasError}
        $style={style}
        $zIndex={zIndex}
        value={value}
        name={name}
        id={id}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={isDisabled}
        type={type}
        ref={ref}
        {...props}
      />
      {displayError && (
        <InputError
          errorCanFit={errorCanFit}
          hasError={hasError}
          error={error}
          absolute={errorPositionAbsolute}
        />
      )}
    </>
  );
};

InputNew.propTypes = {
  value: oneOfType([number, string]),
  onChange: func,
  onBlur: func,
  name: string,
  isDisabled: bool,
  type: string,
  displayError: bool,
  zIndex: oneOfType([number, string]),
  style: shape({}),
  errorPositionAbsolute: bool,
  errorCanFit: bool,
  hasError: bool,
};
InputNew.defaultProps = {
  labelSize: 4,
  inputSize: 8,
  isDisabled: false,
  type: 'text',
  displayError: true,
  zIndex: 'initial',
  style: null,
  errorPositionAbsolute: true,
  errorCanFit: false,
  hasError: false,
};

export default InputNew;
