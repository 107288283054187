import styled, { css } from 'styled-components';
import * as v from 'utils/variables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: ${v.spacingMd};
  color: ${v.mousegray};
  height: 18px;
`;
export const StyledIconWrapper = styled.span`
  padding-right: ${v.spacingXmd};
`;
export const StyledContainer = styled.div`
  position: relative;
  visibility: hidden;
  height: 20px;
  padding: 1em 0;
  box-sizing: border-box;
  ${(props) =>
    props.$isVisible &&
    css`
      visibility: visible;
    `};
`;
export const StyledDescription = styled.p`
  font-size: ${v.fontSizeSm};
  color: ${v.mousegray};
  display: none;
  ${(props) =>
    props.$isVisible &&
    css`
      display: flex;
      align-items: center;
    `};
`;
