import isNil from 'lodash/isNil';

export const distanceTo = ([lat1, lng1], [lat2, lng2]) => {
  const toRadian = (angle) => (Math.PI / 180) * angle;
  const distance = (a, b) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_M = 6371000;

  const dLat = distance(lat2, lat1);
  const dLon = distance(lng2, lng1);

  lat1 = toRadian(lat1);
  lat2 = toRadian(lat2);

  // Haversine Formula
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  let finalDistance = RADIUS_OF_EARTH_IN_M * c;
  return finalDistance;
};

export const isInAirportArea = (pois, location) =>
  pois
    .filter((poi) => poi.type === 'airport')
    .map((airport) => {
      if (
        distanceTo([location.lat, location.lng], [airport.lat, airport.lng]) <
        airport.radius
      ) {
        return true;
      }
      return false;
    });

export const degreeToRadian = (val) => (Math.PI * val) / 180;
export const DEFAULT_ZOOM = 11;

export const isLatitude = (lat) => !isNil(lat) && Math.abs(lat) <= 90;
export const isLongitude = (lng) => !isNil(lng) && Math.abs(lng) <= 180;
