import useGetActivePrimaryTabFromPath from './useGetActivePrimaryTabFromPath';
import { useParams } from 'react-router-dom';
import {
  ACTIVITY,
  ACTIVITY_TABS,
  TEAM,
  TEAM_TABS,
  BILLING_DASHBOARD_TABS,
  BILLING,
} from 'utils/tabs';

const useGetActiveSecondaryTabFromPath = () => {
  const params = useParams();
  const primaryTab = useGetActivePrimaryTabFromPath();

  let tabs = [];

  if (primaryTab === ACTIVITY) {
    tabs = ACTIVITY_TABS;
  } else if (primaryTab === TEAM) {
    tabs = TEAM_TABS;
  } else if (primaryTab === BILLING) {
    tabs = BILLING_DASHBOARD_TABS;
  }

  if (tabs.length) {
    return (
      tabs.find(
        (tab) => tab.replace(/\s+/g, '-').toLowerCase() === params?.tab
      ) || tabs[0]
    );
  }

  return null;
};

export default useGetActiveSecondaryTabFromPath;
