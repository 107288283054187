import React from 'react';
import { string, object, bool } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Root from './styles';

const TooltipWithIcon = ({
  dataTestId,
  id,
  icon,
  place,
  message,
  multiline,
  inline,
}) => (
  <Root data-testid={dataTestId} $inline={inline}>
    <FontAwesomeIcon data-tip data-for={id} icon={icon} />
    <ReactTooltip id={id} multiline={multiline} place={place}>
      <span dangerouslySetInnerHTML={{ __html: message }} />
    </ReactTooltip>
  </Root>
);

TooltipWithIcon.propTypes = {
  id: string,
  dataTestId: string,
  icon: object,
  place: string,
  multiline: bool,
  inline: bool,
  message: string.isRequired,
};

TooltipWithIcon.defaultProps = {
  id: 'input-tooltip',
  dataTestId: 'tooltip-with-icon',
  place: 'bottom',
  multiline: true,
  inline: false,
  icon: faQuestionCircle,
};

export default TooltipWithIcon;
