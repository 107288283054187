const messages = {
  newTitle: 'Save new address',
  editTitle: 'Edit address',
  address: 'Address',
  name: 'Address Nickname',
  namePlaceholder: 'Office, or Favourite Client Address',
  driver_notes: 'Note For Driver',
  save: 'Save address',
  update: 'Update address',
};

export default messages;
