import React, { useEffect, useState, useRef, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';

import cx from 'classnames';
import { func, bool, shape } from 'prop-types';
import AdyenCheckout from '@adyen/adyen-web';

import { adyenStylesWhite } from 'constants/adyenStyles';
import { baseAdyenConfig } from 'api/config';

import { Row } from 'components/Grid';
import Input from 'components/Input';
import ModalDefaultActionButtons from 'components/ModalDefaultActionButtons';
import BasicModal from 'components/Modals/BasicModal';

import 'scss/overrides/adyen.scss';
import './AddCard.scss';
import styles from './AddCard.module.scss';
import messages from './messages';
import t from 'locales/en';
import {
  initCard,
  setPendingPaymentMethod,
  addCard,
  setRedirectTo3DAuth,
  cancelRedirectTo3DAuth,
  clearCardStatus,
} from 'store/slices/accountsSlice';

const AddCard = ({
  handleModalClose,
  onSuccess,
  inBookingForm,
  fromNavbar,
  account,
}) => {
  const dispatch = useDispatch();
  const redirectTo3DAuth = useSelector(
    (state) => state.accounts.redirectTo3DAuth
  );
  const { isLoading: isCreating, isCardAdded, error } = useSelector(
    (state) => state.accounts
  );
  const cardPaymentMethodsResponse = useSelector(
    (state) => state.accounts.cardPaymentMethodsResponse
  );
  const accountId = account?.id;
  const [state, setState] = useState([{ isValid: false }]);
  const [additionalData, setAdditionalData] = useState({
    zipcode: '',
  });
  const { addNewCard } = inBookingForm
    ? t.threeDAuth.createBooking
    : t.threeDAuth;
  const cardReference = useRef();
  const redirectReference = useRef();

  const handleOnChange = (_state) => {
    setState(_state);
  };

  useEffect(() => {
    if (isCardAdded && !error) {
      onSuccess();
      dispatch(clearCardStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardAdded, error]);

  useEffect(() => {
    if (cardPaymentMethodsResponse.paymentMethods) {
      const configuration = {
        ...baseAdyenConfig,
        paymentMethodsResponse: cardPaymentMethodsResponse,
        onChange: handleOnChange,
        hasHolderName: true,
        holderNameRequired: true,
        showPayButton: false,
        showBrandIcon: false,
        brands: ['visa', 'mc', 'amex'],
      };

      const checkout = new AdyenCheckout(configuration);
      checkout
        .create('card', {
          placeholders: {
            encryptedCardNumber: '',
            encryptedExpiryDate: '',
            encryptedSecurityCode: '',
            holderName: ' ',
          },
          styles: adyenStylesWhite,
        })
        .mount(cardReference?.current);
    } else {
      dispatch(initCard());
    }
  }, [cardPaymentMethodsResponse, dispatch]);

  const handleSubmit = (values, zipcode) => {
    if (state.isValid) {
      dispatch(
        addCard({
          values,
          zipcode,
          accountId,
          inBookingForm,
          willContinueAccountCreate: fromNavbar,
        })
      );
    }
  };

  const onInputChange = (event) => {
    setAdditionalData({
      ...additionalData,
      [event.target.name]: event.target.value,
    });
  };

  const onClickRedirect3DAuth = () => {
    const configuration = baseAdyenConfig;
    const checkout = new AdyenCheckout(configuration);
    const { data, accountId } = redirectTo3DAuth.isCard;
    dispatch(setPendingPaymentMethod({ data, accountId }));
    dispatch(
      setRedirectTo3DAuth({
        visible: false,
        willContinueBooking: inBookingForm,
        willContinueAccountCreate: fromNavbar,
      })
    );

    checkout
      .createFromAction(data?.authenticatable?.action)
      .mount(redirectReference?.current);
  };

  const onCancelRedirect3DAuth = () => {
    dispatch(
      cancelRedirectTo3DAuth({
        inBookingForm,
        isAddingCard: true,
      })
    );
  };

  return (
    <>
      {redirectTo3DAuth?.visible && (
        <BasicModal
          isModalOpen
          title={addNewCard.request.title}
          onClickPrimary={onClickRedirect3DAuth}
          onClickSecondary={onCancelRedirect3DAuth}
          secondaryTitle={t.common.close}
          primaryTitle={t.common.ok}
        />
      )}
      <div className={cx(styles.AddCardWrapper)}>
        <div className={styles.AddCardContainer}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit(state.data?.paymentMethod, additionalData.zipcode);
            }}
          >
            <Row center>
              <div className={styles.AddCardContainer}>
                <div className={styles.AddCardContentWrapper}>
                  <p className={styles.AddCardLabel}>{messages.header}</p>
                  <div className={styles.AddCardInputsWrapper}>
                    <div className={styles.AddCardSpacer}>
                      <div ref={cardReference} />
                      <div ref={redirectReference} />
                    </div>
                  </div>
                  <Row>
                    <div className={styles.AddCardZipcode}>
                      <label
                        className={styles.AddCardZipcodeLabel}
                        htmlFor="zipcode"
                      >
                        {messages.postalCodeLabel}
                      </label>
                      <div className={styles.AddCardZipcodeInputWrapper}>
                        <Input
                          onChange={onInputChange}
                          name="zipcode"
                          id="zipcode"
                        />
                      </div>
                    </div>
                  </Row>
                  <ModalDefaultActionButtons
                    onCancelButtonClick={handleModalClose}
                    isSubmitButtonDisabled={isCreating}
                    isCancelButtonDisabled={isCreating}
                    isSubmitButtonLoading={isCreating}
                  />
                </div>
              </div>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
};

AddCard.propTypes = {
  handleModalClose: func,
  onSuccess: func,
  inBookingForm: bool,
  fromNavbar: bool,
  account: shape({}),
};

AddCard.defaultProps = {
  handleModalClose: () => null,
  onSuccess: () => null,
  inBookingForm: false,
  fromNavbar: false,
  account: {},
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.account, nextProps.account) &&
  prevProps.inBookingForm === nextProps.inBookingForm &&
  prevProps.fromNavbar === nextProps.fromNavbar;

export default memo(AddCard, areEqual);
