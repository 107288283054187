export const ACTIVITY = 'ACTIVITY';
export const TEAM = 'TEAM';
export const BILLING = 'BILLING';
export const REPORTS = 'REPORTS';
export const SETTINGS = 'SETTINGS';

export const ALL = 'All';
export const ACTIVE = 'Active';
export const UPCOMING = 'Upcoming';
export const HISTORICAL = 'Historical';

export const SIDEBAR_TABS = [ACTIVITY, TEAM, BILLING, REPORTS, SETTINGS];

export const ACTIVITY_TABS = [ALL, ACTIVE, UPCOMING, HISTORICAL];
export const TEAMMATES = 'Teammates';
export const PENDING_INVITES = 'Pending Users';
export const GROUPS = 'Groups';
export const TEAM_TABS = [TEAMMATES, GROUPS, PENDING_INVITES];
export const PAYMENT_METHODS = 'Payment Methods';
export const PERSONAL_CARDS = 'Personal Cards';
// TODO: uncomment when this feature will be implemented
// export const BILLING_SETTINGS = 'Billing Settings';

export const BILLING_DASHBOARD_TABS = [
  PAYMENT_METHODS,
  PERSONAL_CARDS,
  // TODO: uncomment when this feature will be implemented
  // BILLING_SETTINGS,
];

export const INVOICE_BILLING_DASHBOARD_TABS = [
  PAYMENT_METHODS,
  // TODO: uncomment when this feature will be implemented
  // BILLING_SETTINGS,
];
