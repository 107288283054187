import styled, { css } from 'styled-components';
import { spacingMd, mobile, tablet } from 'utils/variables';

export const StyledContainer = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
  @media screen and (min-width: ${mobile}) and (max-width: ${tablet}) {
    padding: 2em;
  }
`;
export const StyledColumn = styled.div`
  margin: 0;
  box-sizing: border-box;
  width: ${(props) => ((props.$col || 12) / 12) * 100}%;

  @media screen and (min-width: ${mobile}) and (max-width: ${tablet}) {
    width: 100%;
  }
  ${(props) =>
    props.$withMarginForInput &&
    css`
      margin-right: 2%;
    `};

  ${(props) =>
    props.$withMarginRight &&
    css`
      margin-right: ${spacingMd};
    `};
  ${(props) =>
    props.$spaceBetween &&
    css`
      justify-content: space-between;
      display: flex;
      flex-direction: column;
    `};
  ${(props) =>
    props.$margin &&
    css`
      margin: ${props.$margin};
    `}
  ${(props) => props.$style && props.$style};
`;

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${(props) =>
    props.$spaceBetween &&
    css`
      justify-content: space-between;
    `}
  ${(props) =>
    props.$withMarginSmall &&
    css`
      margin-bottom: 0.5em;
    `}
      ${(props) =>
    props.$withMarginLarge &&
    css`
      margin-bottom: 2em;
    `}
    ${(props) =>
    props.$padding &&
    css`
      padding: ${props.$padding};
    `}
    ${(props) =>
    props.$margin &&
    css`
      margin: ${props.$margin};
    `}

  @media screen and (min-width: ${mobile}) and (max-width: ${tablet}) {
    flex-direction: column;
    max-width: 90vw;
  }
  ${(props) =>
    props.$withMarginForInput &&
    css`
      margin-right: 2%;
    `};

  ${(props) =>
    props.$withMarginRight &&
    css`
      margin-right: ${spacingMd};
    `};
`;
