/* eslint-disable max-len */
export const getMessages = (companyName) => ({
  header: 'Cookie Policy',
  subHeader: `${companyName} – WEBSITE COOKIES POLICY`,
  par01: `${companyName} (“we”, “us” and “our”) are committed to providing internet users with information about our use of cookies. This policy sets out details of our use of cookies on our website Cookies Policy Website”).`,
  par02:
    'Our Website uses cookies to distinguish you from other users of our Website. This helps us to provide you with a good experience when you browse our Website and also allows us to improve our Website. By continuing to browse our website, you are agreeing to our use of cookies.',
  par03:
    'Cookies provide information regarding the computer used by a visitor. We may use cookies where appropriate to gather information about your computer in order to assist us in improving our Website.',
  par04:
    'We may gather information about your general internet use by using cookies. Where used, these cookies are downloaded to your computer and stored on the computer’s hard drive. Such information will not identify you personally. It is statistical data. This statistical data does not identify any personal details whatsoever. You can adjust the settings on your computer to decline any cookies if you wish. This can easily be done by activating the “reject cookies” setting on your computer. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our Website.',
  listHeader: 'We use the following cookies:',
  listEl01: {
    header: 'Strictly necessary cookies.',
    content:
      'These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website and make use of e-billing services.',
  },
  listEl02: {
    header: 'Analytical/performance cookies.',
    content:
      'They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.',
  },
  listEl03: {
    header: 'Functionality cookies.',
    content:
      'These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).',
  },
  par05:
    'You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of the websites. Except for essential cookies, all cookies will expire after 12 months.',
  logoAlt: 'logo',
});
