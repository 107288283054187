import React, { useMemo, memo } from 'react';
import { shape, string, bool, func } from 'prop-types';
import isEqual from 'lodash/isEqual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import PaymentName from 'components/Modals/ViewBooking/PaymentName';
import Circle from 'components/Circle';
import { Row } from 'components/GridNew';
import { printReceipt } from 'api/bookings';
import CancelBooking from 'components/Modals/CancelBooking';
import { useModalState } from 'hooks/useModalState';
import { getDateInTimeZoneWithFormat } from 'utils/dateTime';
import { STATE_COMPLETED } from 'constants/bookingStates';

import { StyledActionText } from 'views/Dashboard/styles';

import ActivityHeader from './ActivityHeader';
import messages from './ActivityExpanded.messages.js';
import {
  StyledContainer,
  StyledFlex,
  StyledRow,
  StyledLabel,
  StyledName,
  StyledAddress,
  StyledTime,
  StyledTrip,
} from './ActivityExpanded.styles';

const ActivityExpanded = ({
  bookingDetails,
  bookingTime,
  passengerName,
  timezone,
  canCancel,
  canEdit,
  onClickEditOrView,
}) => {
  const {
    pickup,
    state: bookingState,
    completed_at,
    destination,
    id,
    external_id,
    driver,
    rating,
    payment,
  } = useMemo(() => bookingDetails, [bookingDetails]);

  const {
    isModalOpen: isCancelBookingOpen,
    toggleModal: toggleCancelModal,
  } = useModalState();

  return bookingDetails ? (
    <StyledContainer>
      <StyledFlex>
        <StyledRow $onLeft>
          <Row withMarginSmall>
            <StyledLabel>{messages.passenger}</StyledLabel>
          </Row>
          <Row withMarginLarge>
            <StyledName>{passengerName}</StyledName>
          </Row>
          <Row withMarginLarge>
            <StyledTrip>
              <Circle green />
              <StyledTime>{bookingTime}</StyledTime>
              <StyledAddress>{pickup.address}</StyledAddress>
            </StyledTrip>
          </Row>
          <Row withMarginLarge>
            <StyledTrip>
              <Circle orange />
              <StyledTime>
                {bookingState === STATE_COMPLETED &&
                  getDateInTimeZoneWithFormat(completed_at, timezone, 'HH:mm')}
              </StyledTime>
              <StyledAddress>{destination?.address}</StyledAddress>
            </StyledTrip>
          </Row>
          <Row padding="0 1em 2em 0">
            {bookingState === STATE_COMPLETED && (
              <StyledActionText
                $paddingRight="1em"
                onClick={() => printReceipt(id)}
              >
                {messages.print}
              </StyledActionText>
            )}
            {(canEdit || bookingState === STATE_COMPLETED) && (
              <StyledActionText $paddingRight="1em" onClick={onClickEditOrView}>
                {messages.editOrView}
              </StyledActionText>
            )}
            {canCancel && (
              <>
                <StyledActionText onClick={toggleCancelModal}>
                  {messages.cancel}
                </StyledActionText>
              </>
            )}
          </Row>
        </StyledRow>
        <StyledRow>
          <ActivityHeader isLabel labels={[messages.iCabbiID]} />
          <ActivityHeader labels={[external_id]} />
          <ActivityHeader
            hasTopMargin
            isLabel
            labels={[
              messages.driverName,
              messages.vehicleMake,
              messages.rating,
            ]}
          />
          <ActivityHeader
            labels={[
              driver ? driver.name : '-',
              driver?.vehicle
                ? `${driver.vehicle.brand} ${driver.vehicle.model}`
                : '-',
              rating
                ? [...Array(rating)].map((_, idx) => (
                    <FontAwesomeIcon key={idx} icon={faStar} />
                  ))
                : '-',
            ]}
          />
          <ActivityHeader
            hasTopMargin
            isLabel
            labels={[messages.paymentMethod]}
          />
          <ActivityHeader
            labels={[
              payment?.rfb_payment_method ? (
                <PaymentName
                  isXSmall
                  paymentMethod={payment?.rfb_payment_method}
                />
              ) : (
                '-'
              ),
            ]}
          />
        </StyledRow>
      </StyledFlex>
      {isCancelBookingOpen && (
        <CancelBooking
          id={id}
          closeModal={toggleCancelModal}
          onSuccess={toggleCancelModal}
        />
      )}
    </StyledContainer>
  ) : null;
};

ActivityExpanded.propTypes = {
  bookingDetails: shape({}).isRequired,
  bookingTime: string.isRequired,
  passengerName: string.isRequired,
  timezone: string.isRequired,
  canCancel: bool.isRequired,
  canEdit: bool.isRequired,
  onClickEditOrView: func.isRequired,
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.bookingDetails, nextProps.bookingDetails) &&
  prevProps.bookingTime === nextProps.bookingTime &&
  prevProps.passengerName === nextProps.passengerName &&
  prevProps.timezone === nextProps.timezone &&
  prevProps.canCancel === nextProps.canCancel &&
  prevProps.canEdit === nextProps.canEdit;

export default memo(ActivityExpanded, areEqual);
