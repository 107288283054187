export default {
  date: 'Date',
  time: 'Time',
  name: 'Name',
  location: 'Location',
  status: 'Status',
  amount: 'Amount',
  emptyStateHeader: 'No bookings yet',
  emptyStateText: "When you make bookings, you'll see them here.",
  emptyStateButton: 'Book your first trip now!',
};
