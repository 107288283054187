import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AdyenCheckout from '@adyen/adyen-web';
import { LAST_NAME_NOT_AVAILABLE } from 'constants/user';

import { extraInfo } from 'api/config';
import { axiosGet, axiosPatch, axiosPost } from './axiosHelpers';

import { baseAdyenConfig } from 'api/config';

import { PICKUP_TYPES } from 'constants/booking';
import { getAsapBookingDate } from 'utils/dateTime';

const CancelToken = axios.CancelToken;
let estimateSource = CancelToken.source();

export const cancelEstimateRequest = (
  message = 'Request cancelled manually'
) => {
  estimateSource.cancel(message);

  estimateSource = CancelToken.source();
};

export const fetchAllBookings = (page = 1, accountId, cancelToken) =>
  axiosGet({
    url: `/accounts/${accountId}/bookings?page=${page}`,
    cancelToken,
  }).then(({ data }) => data);

export const fetchActiveBookings = (page = 1, accountId, cancelToken) =>
  axiosGet({
    url: `/bookings/active?account_id=${accountId}&page=${page}`,
    cancelToken,
  }).then(({ data }) => data);

export const fetchRequestedBookings = (page = 1, accountId, cancelToken) =>
  axiosGet({
    url: `/bookings/requesting?account_id=${accountId}&page=${page}`,
    cancelToken,
  }).then(({ data }) => data);

export const fetchHistoricalBookings = (page = 1, accountId, cancelToken) =>
  axiosGet({
    url: `/bookings/history?account_id=${accountId}&page=${page}`,
    cancelToken,
  }).then(({ data }) => data);

export async function fetchBookingById(id) {
  const { data } = await axiosGet({
    url: `/bookings/${id}`,
  });
  return data;
}

export async function cancelBookingRequest(id) {
  const { data } = await axiosPatch({
    url: `/bookings/${id}/cancel`,
  });
  return data;
}

export const getBookingReport = (id, from, to) =>
  axiosGet({
    url: `/bookings/report?account_id=${id}&from=${from}&to=${to}`,
  }).then(({ data }) => data);

export const getPreauthIdRequest = (values, account, payInCarPaymentMethod) => {
  const {
    rfb_payment_method,
    pickup_at,
    timezone,
    pickup_lat,
    pickup_lng,
    pickup_address,
    destination,
    destination_lat,
    destination_lng,
    destination_address,
    card_id,
    type,
  } = values;
  const isAsapBooking = type === PICKUP_TYPES.ASAP;
  const isNotRfbAccount = account?.rfbDefaultPayment?.isNotRfb;
  let preauthData = {
    card_id,
    pickup_lat,
    pickup_lng,
    pickup_address,
    destination,
    destination_lat,
    destination_lng,
    destination_address,
    type,
    pickup_at: isAsapBooking ? getAsapBookingDate(timezone) : pickup_at,
    rfb_payment_method_id: isNotRfbAccount
      ? undefined
      : rfb_payment_method || payInCarPaymentMethod.id,
    idempotency_key: uuidv4(),
    payment_type: 'account',
    account_id: account?.id,
  };

  return axiosPost({
    url: '/preauth',
    data: { ...preauthData, extras: extraInfo },
  }).then(({ data }) => {
    return data;
  });
};

export const createBookingRequest = (
  values,
  isCard = false,
  referenceElement
) => {
  const body = isCard ? { ...values, payment_extras: extraInfo } : values;

  return axiosPost({
    url: 'bookings',
    data: body,
  }).then(({ data }) => {
    const { payment } = data?.data;
    if (payment?.authenticatable) {
      const configuration = baseAdyenConfig;
      const checkout = new AdyenCheckout(configuration);
      checkout
        .createFromAction(payment?.authenticatable.action)
        .mount(referenceElement?.current);
    }
    return data;
  });
};

export const updateBookingRequest = (
  id,
  values,
  isCard = false,
  referenceElement
) => {
  if (!id) return;
  const body = isCard ? { ...values, payment_extras: extraInfo } : values;
  return axiosPatch({
    url: `/bookings/${id}`,
    data: body,
  }).then(({ data }) => {
    const { payment } = data?.data;
    if (payment?.authenticatable) {
      const configuration = baseAdyenConfig;
      const checkout = new AdyenCheckout(configuration);
      checkout
        .createFromAction(payment?.authenticatable.action)
        .mount(referenceElement?.current);
    }
    return data;
  });
};

export const createGuestUserRequest = (values, account_id) => {
  const { username, phone_country, phone_number } = values;
  const [first_name, last_name = LAST_NAME_NOT_AVAILABLE] = username.match(
    /("[^"]+"|[^"\s]+)/g
  );
  return axiosPost({
    url: '/users/create_guest',
    data: {
      first_name,
      last_name,
      phone_country,
      phone_number,
      account_id,
    },
  }).then((res) => {
    if (res.status >= 200 && res.status <= 400) {
      return res.data;
    }
  });
};

export const getEstimatedFare = ({
  destinationLat,
  destinationLng,
  pickupLat,
  pickupLng,
  pickupDate,
  vehicleKey,
}) => {
  return axiosGet({
    url: '/quotes/estimate',
    params: {
      destination: `${destinationLat},${destinationLng}`,
      pickup: `${pickupLat},${pickupLng}`,
      pickup_datetime: pickupDate,
      vehicle_type: vehicleKey,
    },
    cancelToken: estimateSource.token,
  });
};

export const printReceipt = (id) => {
  return axiosGet({
    url: `/bookings/${id}/export-receipt`,
    responseType: 'arraybuffer',
  }).then((res) => {
    if (res.status === 200) {
      let blob = new Blob([res.data], { type: 'application/pdf' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${id}-booking-receipt.pdf`;
      link.click();
    }
  });
};

export const getBookingStateRequest = (id) => {
  return axiosGet({
    url: `/bookings/${id}/state`,
  }).then(({ data }) => {
    return data;
  });
};

export const sendMessageToDriverRequest = (bookingId, message) => {
  return axiosPost({
    url: `/drivers/messages`,
    data: {
      booking_id: bookingId,
      message,
    },
  }).then(({ data }) => data);
};
