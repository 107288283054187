import React, { useState } from 'react';
import { number, shape, bool } from 'prop-types';

import SetAccount from 'components/Modals/SetAccount';
import Select from 'components/Select';
import { customSelectStyles } from 'constants/customSelectStyles';
import {
  accountType,
  availablePaymentMethodsPropTypes,
} from 'constants/propTypes';
import { messages } from './messages';

import styles from './BillingHeader.module.scss';
import {
  DashboardHeaderWrapper,
  StyledActionText,
} from 'views/Dashboard/styles';

const BillingHeader = ({
  paymentId,
  selectedCard,
  account,
  availablePaymentMethods,
  isPersonalCardsTab,
  setDefaultPayment,
  setCardForBusiness,
  appCompanyName,
  isCreatingAccount,
}) => {
  const isNotRfbAccount = account?.rfbDefaultPayment?.isNotRfb;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSetDefaultPayment = (item) => {
    if (item.value) {
      setDefaultPayment(account.id, paymentId);
    }
  };
  const handleSelectCardForBusiness = (option) => {
    setCardForBusiness(selectedCard.id, selectedCard.card_holder_name);
  };
  return (isNotRfbAccount && isPersonalCardsTab) || !isNotRfbAccount ? (
    <DashboardHeaderWrapper $isBilling>
      <div className={styles.BillingHeaderFilterWrapper}>
        <Select
          placeholder="Choose action"
          options={
            isPersonalCardsTab
              ? [{ value: 'default', label: 'Set as business card' }]
              : [{ value: 'default', label: 'Set as default' }]
          }
          value={null}
          onChange={
            isPersonalCardsTab
              ? handleSelectCardForBusiness
              : handleSetDefaultPayment
          }
          styles={customSelectStyles}
          isDisabled={isPersonalCardsTab ? !selectedCard.id : !paymentId}
        />
      </div>
      {isModalOpen && (
        <SetAccount
          isModalOpen={isModalOpen}
          closeModal={handleModalToggle}
          account={account}
          availablePaymentMethods={availablePaymentMethods}
          appCompanyName={appCompanyName}
          isCreatingAccount={isCreatingAccount}
        />
      )}
      {!isNotRfbAccount &&
        !availablePaymentMethods?.isAllPossibleMethodsAdded &&
        !availablePaymentMethods?.isEmpty && (
          <StyledActionText $bold onClick={handleModalToggle}>
            {messages.addPayment}
          </StyledActionText>
        )}
    </DashboardHeaderWrapper>
  ) : null;
};

BillingHeader.propTypes = {
  paymentId: number,
  account: shape(accountType).isRequired,
  availablePaymentMethods: availablePaymentMethodsPropTypes.isRequired,
  isPersonalCardsTab: bool.isRequired,
  isCreatingAccount: bool,
};

BillingHeader.defaultProps = {
  paymentId: null,
  isCreatingAccount: null,
};

export default BillingHeader;
