import styled, { css } from 'styled-components';
import * as v from 'utils/variables';

export const StyledFlex = styled.div`
  display: flex;
`;

export const StyledRow = styled.div`
  flex: 1;
  ${(props) =>
    props.$onLeft &&
    css`
      border-right: 1px solid ${v.dark};
      margin-right: ${v.spacingLg};
      height: 100%;
    `}
`;

export const StyledContainer = styled.div`
  background: ${v.aliceBlue};
  width: 100%;
  font-size: ${v.fontSizeXs};
  padding: ${v.spacingLg};
`;

export const StyledLabel = styled.span`
  color: ${v.mousegray};
`;

export const StyledName = styled.span`
  font-size: ${v.fontSizeLg};
  color: ${v.charCoal};
`;

export const StyledAddress = styled.p`
  color: ${v.mousegray};
  padding-right: ${v.spacingMd};
  padding-left: ${v.spacingXs};
  flex: 1;
`;

export const StyledTime = styled.span`
  width: calc(${v.spacingXl} * 1.5);
  display: block;
`;

export const StyledTrip = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
