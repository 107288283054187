import React from 'react';
import { number, shape, string, bool } from 'prop-types';

import PhoneNumberInput from 'components/PhoneNumberInput';
import LabelContainer from './LabelContainer';

const WithPhoneNumber = ({
  id,
  error,
  label,
  labelSize,
  inputSize,
  hasError,
  errorAbsolute,
  isColumn,
  labelStyle,
  testId,
  style,
  phoneProps,
}) => {
  return (
    <LabelContainer
      testId={testId}
      id={id}
      label={label}
      labelSize={labelSize}
      inputSize={inputSize}
      isColumn={isColumn}
      labelStyle={labelStyle}
      hasError={hasError}
      error={error}
      style={style}
    >
      <PhoneNumberInput
        errorAbsolute={errorAbsolute}
        hasError={hasError}
        error={error}
        {...phoneProps}
      />
    </LabelContainer>
  );
};

WithPhoneNumber.propTypes = {
  id: string,
  label: string,
  labelSize: number,
  inputSize: number,
  error: string,
  hasError: bool,
  errorAbsolute: bool,
  isColumn: bool,
  testId: string,
  style: shape({}),
  labelStyle: shape({}),
};
WithPhoneNumber.defaultProps = {
  labelSize: 4,
  inputSize: 8,
  hasError: false,
  error: null,
  errorAbsolute: true,
  isColumn: false,
  testId: 'label-with-input',
  style: {},
  labelStyle: {},
};

export default WithPhoneNumber;
