import React, { useEffect, useState, memo } from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import isEmpty from 'lodash/isEmpty';
import { bool, func, shape, string } from 'prop-types';

import { Col } from 'components/Grid';
import Input from 'components/Input';
import PhoneNumberInput from 'components/PhoneNumberInput';
import messages from './messages';
import { LabelCol, Label, BookingPhoneContainer } from './styles';

const BookingFormPhone = ({
  setFieldValue,
  setFieldError,
  setFieldTouched,
  userPhone,
  errors,
  touched,
  defaultCountry,
  defaultValue,
  hasTooltip,
  label,
  isDisabled,
}) => {
  const isUserPhone = !isEmpty(userPhone);
  const [formattedPhone, setFormattedPhone] = useState(userPhone);

  useEffect(() => {
    if (isUserPhone) {
      const formattedUserPhone =
        userPhone.indexOf('+') > -1
          ? parsePhoneNumber(userPhone)
          : parsePhoneNumber(`+${userPhone}`);
      if (formattedUserPhone) {
        setFieldValue(
          'phone',
          `${formattedUserPhone.countryCallingCode}${formattedUserPhone.nationalNumber}`
        );
        setFieldValue('phone_number', formattedUserPhone.nationalNumber);
        setFieldValue('phone_country', formattedUserPhone.countryCallingCode);
        setFieldTouched('phone', false);
        setFieldTouched('phone_number', false);
        setFormattedPhone(formattedUserPhone.formatInternational());
      }
    } else {
      setFieldValue('phone', '');
      setFieldValue('phone_number', '');
      setFieldValue('phone_country', '');
      setFieldTouched('phone', false);
      setFieldTouched('phone_number', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPhone, isUserPhone]);

  return (
    <BookingPhoneContainer>
      <LabelCol col={5}>
        <Label>{label}</Label>
      </LabelCol>
      <Col col={7}>
        {isUserPhone ? (
          <Input
            textSmall
            disabled
            hasDisabledStyles
            name="phone"
            value={formattedPhone}
            bgWhite
            hasTooltip={hasTooltip}
            tooltipMessage={messages.tooltip}
          />
        ) : (
          <PhoneNumberInput
            isDisabled={isDisabled}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={errors.phone}
            hasError={!!(touched.phone && errors.phone)}
            defaultCountry={defaultCountry}
            defaultValue={defaultValue}
          />
        )}
      </Col>
    </BookingPhoneContainer>
  );
};

BookingFormPhone.propTypes = {
  setFieldTouched: func,
  setFieldValue: func.isRequired,
  setFieldError: func,
  errors: shape({}),
  touched: shape({}),
  userPhone: string,
  defaultCountry: string.isRequired,
  defaultValue: string,
  hasTooltip: bool,
  label: string,
  isDisabled: bool,
};

BookingFormPhone.defaultProps = {
  setFieldTouched: () => {},
  setFieldError: () => {},
  errors: {},
  touched: {},
  userPhone: '',
  defaultValue: '',
  hasTooltip: true,
  label: 'Passenger Mobile Number',
  isDisabled: false,
};

export default memo(BookingFormPhone);
