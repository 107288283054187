/* eslint-disable max-len */
const messages = {
  tooltip:
    'The phone number of an existing teammate<br/>cannot be edited. To use a different number,<br/>please enter Passenger name and do not select<br/>this teammate from the options.',
  newTitle: 'Book a trip',
  editTitle: 'Edit booking',
  pickupAddress: 'Pickup Address',
  dropOffAddress: 'Drop Off Address',
  bookingDateTime: 'Booking Date/Time',
  passengerName: 'Passenger Name',
  noteForDriver: 'Note For Driver',
  update: 'Update Booking',
  confirm: 'Confirm Booking',
  flightDetails: {
    title: 'Flight Details',
    placeholder: 'Type Flight number and details of arrival',
    send: 'Send',
  },
  ccEmailTitle: 'CC Booking Email?',
  ccEmail: 'CC Booking Email',
};

export default messages;
