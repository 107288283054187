import React, { memo, useRef, useCallback, useMemo } from 'react';
import _isEqual from 'lodash/isEqual';

import Select from 'components/Select';
import { func, number, oneOfType, string, bool, instanceOf } from 'prop-types';
import { useTimePickerInput } from 'hooks/useTimePickerInput';
import { pickerStyles } from './styles';
import { SingleValue, Group, Option } from './customSelectComponents';
import { DEFAULT_TIME_ZONE } from 'constants/booking';

const BookingTimePicker = ({
  minPrebookTime,
  setFieldValue,
  selectedDate,
  type,
  timezone,
  isEditBooking,
  hasDefaultValue,
  isDisabled,
}) => {
  const timePickerInputProps = useTimePickerInput({
    bookingType: type,
    timezone,
    minPrebookTime,
    selectedDate,
    setFieldValue,
    isEditBooking,
    hasDefaultValue,
    isDisabled,
  });

  const selectRef = useRef(null);

  // Focus selected option when menu is getting opened
  const onMenuOpen = useCallback(() => {
    if (selectRef.current) {
      const option = selectRef.current?.select?.state?.selectValue?.[0];
      setTimeout(() => {
        if (option) {
          const selectedIndex = selectRef.current.select.state.menuOptions.focusable.indexOf(
            option
          );
          if (selectedIndex >= 0) {
            selectRef.current.select.setState({
              focusedValue: null,
              focusedOption: option,
            });
          }
        }
      });
    }
  }, []);

  const { onChange, options, value } = useMemo(() => timePickerInputProps, [
    timePickerInputProps,
  ]);

  return (
    <div data-testid="booking-time-picker">
      <Select
        selectRef={selectRef}
        minMenuHeight={300}
        styles={pickerStyles}
        components={{ SingleValue, Option, Group }}
        isDisabled={isDisabled}
        closeMenuOnSelect={false}
        captureMenuScroll={false}
        isMulti={false}
        hideSelectedOptions={false}
        onMenuOpen={onMenuOpen}
        onChange={onChange}
        placeholder={null}
        value={value}
        options={options}
      />
    </div>
  );
};

BookingTimePicker.propTypes = {
  setFieldValue: func,
  minPrebookTime: oneOfType([number, string]),
  selectedDate: oneOfType([instanceOf(Date), string]),
  isEditBooking: bool,
  hasDefaultValue: bool,
  isDisabled: bool,
  type: string,
  timezone: string,
};

BookingTimePicker.defaultProps = {
  setFieldValue: () => {},
  minPrebookTime: 0,
  selectedDate: '',
  isEditBooking: false,
  hasDefaultValue: false,
  type: null,
  isDisabled: false,
  timezone: DEFAULT_TIME_ZONE,
};

const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.selectedDate, nextProps.selectedDate) &&
  prevProps.minPrebookTime === nextProps.minPrebookTime &&
  prevProps.timezone === nextProps.timezone &&
  prevProps.type === nextProps.type &&
  prevProps.isEditBooking === nextProps.isEditBooking &&
  prevProps.hasDefaultValue === nextProps.hasDefaultValue &&
  prevProps.isDisabled === nextProps.isDisabled;

export default memo(BookingTimePicker, areEqual);
