import React from 'react';
import { node } from 'prop-types';

import { StyledContainer } from './styles';

const Container = ({ children }) => (
  <StyledContainer data-testid="column">{children}</StyledContainer>
);

Container.propTypes = {
  children: node,
};

Container.defaultProps = {
  children: null,
};

export default Container;
