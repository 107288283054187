import React from 'react';
import check from 'assets/icons/check.png';

import styles from './Confirmation.module.scss';

const Confirmation = ({ handleModalToggle, isBookingUpdated }) => {
  return (
    <div className={styles.Confirmation}>
      <img className={styles.ConfirmationIcon} alt="check" src={check} />
      <p>
        Your booking {isBookingUpdated ? 'update' : 'request'} was successful.{' '}
        <span
          role="button"
          onClick={handleModalToggle}
          className={styles.ConfirmationLink}
          onKeyDown={() => {}}
          tabIndex={0}
        >
          {!isBookingUpdated && 'Create new booking'}
        </span>
      </p>
    </div>
  );
};

export default Confirmation;
