import styled from 'styled-components';
import Button from 'components/Button';
import * as v from 'utils/variables';

export const CookieConsentContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 280px;
  position: relative;
  z-index: 1;
`;

export const CookieConsentHeader = styled.span`
  font-size: ${v.fontSizeLg};
  font-weight: ${v.fontWeightBold};
  color: ${v.lightblack};
  padding-bottom: ${v.spacingMd};
`;

export const CookieConsentSubheader = styled.p`
  color: ${v.mousegray};
  font-size: ${v.fontSizeSm};
  text-align: center;
`;

export const CookieConsentButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: calc(${v.spacingLg} * 1.5);
  a {
    text-decoration: none;
  }
`;

export const CookieConsentPolicyLink = styled(Button)`
  color: ${v.mousegray};
  font-size: ${v.fontSizeSm};
  padding: 0 calc(${v.spacingMd} * 1.5);
  margin-right: ${v.spacingLg};
`;

export const CookieConsentAcceptButton = styled(Button)`
  min-height: 49px;
`;
