import { css } from 'styled-components';

// color palette
export const lightblack = 'rgba(0, 0, 0, 0.8)';
export const gray = 'rgba(0, 0, 0, 0.6)';
export const darkgray = 'rgba(0, 0, 0, 0.5)';
export const mousegray = 'rgba(0, 0, 0, 0.4)';
export const palegray = 'rgba(0, 0, 0, 0.2)';
export const dark = 'rgba(0, 0, 0, 0.1)';
export const solidDark = '#e5e5e5';
export const dimmed = 'rgba(0, 0, 0, 0.05)';
export const shadedgray = 'rgba(0, 0, 0, 0.03)';
export const mediumgray = '#878787';
export const lightgray = '#eff4fa';
export const primary = '#4176ff';
export const primaryDarken = '#345ecc';
export const lightPrimary = 'rgba(65,118,255,0.4)';
export const colorPrimaryLight = 'rgba(65, 119, 255, 0.02)';
export const colorLightGreyBlue = 'rgba(239, 244, 250, 0.9)';
export const light = 'rgba(255, 255, 255, 0.4)';
export const lighter = 'rgba(255, 255, 255, 0.9)';
export const aliceBlue = '#f7fafd';
export const lightBlue = '#cceeff';
export const charCoal = '#000';
export const sharkGray = '#222326';
export const white = '#fff';
export const whitesmoke = '#dcdcdc';
export const red = 'red';
export const melonRed = '#e96e6e';
export const pink = '#eb008d';
export const green = '#3dbca3';
export const orange = '#fb9154';
export const brownish = '#bc603d';

// font family
export const fontFamilyPrimary =
  "-apple-system,BlinkMacSystemFont,Lato,'Segoe UI',Roboto,'Helvetica Neue',Ubuntu,sans-serif";
export const fontFamilySecondary =
  "-apple-system,BlinkMacSystemFont,'Nunito Sans', sans-serif";

// font sizes
export const fontSizeXxxl = '48px';
export const fontSizeXxl = '36px';
export const fontSizeXl = '24px';
export const fontSizeLg = '20px';
export const fontSizeMd = '16px';
export const fontSizeSm = '14px';
export const fontSizeXs = '12px';

// font weights
export const fontWeightBold = '600';
export const fontWeightMedium = '500';

// borders
export const borderLight = light;
export const borderRadiusButton = '6px';
export const borderRadiusBasic = '4px';
export const borderRadiusSmall = '2px';
export const borderRadiusMedium = '5px';
export const borderRadiusLarge = '10px';

export const inputHeight = '52px';

// spacing
export const spacingXl = '4em';
export const spacingLg = '2em';
export const spacingXLg = '1.5em';
export const spacingMd = '1em';
export const spacingXmd = '0.75em';
export const spacingSm = '0.5em';
export const spacingXs = '0.25em';
export const modalActionButtonsSpacing = '2.5em';

// animations
export const transitionTimeBasic = '0.3s';

// content wrappers
export const pageMaxWidthLg = '1440px';
export const pageMaxWidthMd = '1160px';

// breakpoints
export const desktop = '2560px';
export const laptopLg = '1440px';
export const laptopMd = '1024px';
export const tablet = '768px';
export const mobileLg = '425px';
export const mobile = '320px';

export const DEFAULT_BORDER = '1px solid #e9e9e9';

// slidedown animation
const ANIMATION_DURATION = '.2s';
const ANIMATION_TIMING = 'ease-out';

export const slidedown = css`
  animation-name: slidedown;
  animation-duration: ${ANIMATION_DURATION};
  animation-timing-function: ${ANIMATION_TIMING};

  @keyframes slidedown {
    0% {
      transform: translateY(-50%);
    }

    100% {
      transform: translateY(0%);
    }
  }
`;
export const COLUMN_INPUT_HEIGHT = '43px';
export const DEFAULT_INPUT_HEIGHT = '35px';
