import { axiosGet, axiosPost } from 'api/axiosHelpers';

export const updateNameRequest = (first_name, last_name) =>
  axiosPost({
    data: {
      first_name,
      last_name,
    },
    url: '/users/update',
  }).then(({ data }) => data);

export const fetchMe = () =>
  axiosGet({
    url: `/users/me`,
  }).then(({ data }) => {
    return data;
  });

export const searchUsersRequest = (name, accountId, cancelToken) =>
  axiosGet({
    url: `/accounts/${accountId}/search-users`,
    params: {
      search: name,
    },
    cancelToken,
  }).then(({ data }) => data);

export const searchUsersByPhoneRequest = (phone) =>
  axiosGet({
    url: '/users/search',
    params: {
      phone,
    },
  }).then(({ data }) => {
    return data;
  });

export const checkUserByEmailRequest = (email) =>
  axiosGet({
    url: `/users/check?email=${encodeURIComponent(email)}`,
  }).then(({ data }) => {
    return data;
  });

export const checkUserByPhoneRequest = (phoneCountry, phoneNumber) =>
  axiosGet({
    url: `/users/check?phone_country=${phoneCountry}&phone_number=${phoneNumber}`,
  }).then(({ data }) => {
    return data;
  });

export const resetPasswordRequest = (email) =>
  axiosPost({
    url: '/users/password/reset',
    data: { email },
  }).then(({ data }) => {
    return data;
  });

export const verifyUserRequest = (code, id) => {
  return axiosPost({
    url: `/verifications/${id}/verify`,
    data: {
      code,
    },
  }).then(({ data }) => {
    return data;
  });
};

export const authenticationCodeRequest = (values) => {
  const { phone_country, phone_number } = values;

  const parsedPhoneNumber = phone_number.replace(/[^0-9.]/g, '');
  return axiosPost({
    url: '/users/login/code',
    params: {
      verification_length: 6,
    },
    data: {
      phone_country,
      phone_number: parsedPhoneNumber,
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const createUserRequest = (values, verification_id) => {
  const {
    first_name,
    last_name,
    phone_country,
    phone_number,
    email,
    password,
    country_code,
  } = values;
  return axiosPost({
    url: '/users/create_verified',
    data: {
      avatar: null, // we don't allow users to add avatar yet
      verification_id,
      first_name,
      last_name,
      phone_country,
      country_code,
      phone_number,
      email,
      password,
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const loginRequest = (email, password, history) =>
  axiosPost({
    url: '/users/login-manager',
    data: {
      email,
      password,
    },
  }).then(({ data }) => {
    return data;
  });

export const logoutRequest = (token) =>
  axiosGet({
    url: '/users/logout',
    headers: { Authorization: token },
  }).then(({ data }) => {
    return data;
  });

export const resendVerificationRequest = (phoneCountry, phoneNumber) => {
  return axiosPost({
    url: `/verifications/resend`,
    params: {
      verification_length: 6,
    },
    data: {
      phone_country: phoneCountry,
      phone_number: phoneNumber,
    },
  }).then(({ data }) => {
    return data;
  });
};
