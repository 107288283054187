import React from 'react';
import cx from 'classnames';
import styles from './Circle.module.scss';

const Circle = ({ orange, green }) => {
  let className = cx(styles.Circle, {
    [styles.orange]: orange,
    [styles.green]: green,
  });
  return <div className={className} />;
};

export default Circle;
