import { BILLING, TEAM, ACTIVITY, SIDEBAR_TABS } from 'utils/tabs';
import {
  ACCOUNT_ROLE_ADMIN,
  ACCOUNT_ROLE_MANAGER,
  ACCOUNT_ROLE_OWNER,
  ACCOUNT_ROLE_FRONTDESK,
  ACCOUNT_ROLE_USER,
} from 'constants/accountUserRoles';

const SIDEBAR_ITEMS_BY_ROLE = {
  [ACCOUNT_ROLE_OWNER]: SIDEBAR_TABS,
  [ACCOUNT_ROLE_ADMIN]: SIDEBAR_TABS,
  [ACCOUNT_ROLE_MANAGER]: [ACTIVITY, TEAM, BILLING],
  [ACCOUNT_ROLE_FRONTDESK]: [ACTIVITY, TEAM],
  [ACCOUNT_ROLE_USER]: [],
};

export const getSidebarItemsByRole = (role) => SIDEBAR_ITEMS_BY_ROLE[role];
