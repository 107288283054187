import styled from 'styled-components';
import styles from 'components/Input/Input.module.scss';

const Root = styled.div`
  .${styles.InputWrapper} input {
    width: 100%;
  }
`;

export default Root;
