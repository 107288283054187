import { useMemo } from 'react';
import { getSidebarItemsByRole } from 'components/DashboardSidebar/utils';
import useGetCurrentAccount from './useGetCurrentAccount';

const useGetDashboardItems = () => {
  const account = useGetCurrentAccount();
  const currentUserRole = account?.role;

  return useMemo(() => getSidebarItemsByRole(currentUserRole) || [], [
    currentUserRole,
  ]);
};

export default useGetDashboardItems;
