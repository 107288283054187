import * as Yup from 'yup';

export const FIELDS = {
  address: 'address',
  lat: 'lat',
  lng: 'lng',
  name: 'name',
  driver_notes: 'driver_notes',
};
export default Yup.object().shape({
  lat: Yup.string().required('Address is required'),
  lng: Yup.string().required('Address is required'),
  address: Yup.string()
    .required('Address is required')
    .test('lat_test', 'Address is not valid', function () {
      const { lat } = this.parent;
      return !!lat;
    }),
  driver_notes: Yup.string().matches(
    /^[a-z0-9\s]+$/i,
    'No special characters allowed'
  ),
  name: Yup.string().required('Address Nickname is required'),
});

export const initialValues = {
  lat: '',
  lng: '',
  address: '',
  name: '',
  driver_notes: '',
};
