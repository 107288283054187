import React, { useState, useRef, useEffect, memo, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { useSelector, useDispatch } from 'react-redux';
import {
  shape,
  arrayOf,
  string,
  bool,
  func,
  number,
  oneOfType,
} from 'prop-types';

import AddressSearchItem from './AddressSearchItemNew';
import Input from 'components/InputNew';
import { useOutsideClick } from 'hooks/useOutsideClick';
import {
  addressFieldsType,
  addressFieldsDefaultType,
  addressSearchPropTypes,
} from 'constants/propTypes';

import NewAddressButton from 'components/NewAddressButton';
import {
  searchAddress,
  setAddressDetailsByType,
  newBookingFormDataSelector,
  resetAddressLoadingByType,
} from 'store/slices/bookingsSlice';
import { companyDataSelector } from 'store/slices/companySlice';
import { defaultCompanyServiceArea } from 'api/config';
import { StyledItem, StyledResults } from './styles';

const AddressSearchNew = ({
  name,
  id = name,
  addressResult,
  addressType,
  value,
  fields,
  serviceStatus,
  displayError,
  error,
  showSaveAddress,
  zIndex,
  testId,
  isDisabled,
  hasError,
  setFieldValue,
  setFieldTouched,
  onBlur,
  onClickNewAddress,
}) => {
  const promiseRef = useRef();
  const [isListVisible, setIsListVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const companyData = useSelector(companyDataSelector);
  const newBookingForm = useSelector(newBookingFormDataSelector);
  const { isLoading: isSearching } = newBookingForm[addressType];

  const latitude = companyData.lat ?? defaultCompanyServiceArea.lat;
  const longitude = companyData.lng ?? defaultCompanyServiceArea.lng;

  const listRef = useRef();
  useOutsideClick(listRef, () => setIsListVisible(false));
  const [searchBoxHeight, setSearchBoxHeight] = useState(0);

  useEffect(() => {
    if (!searchValue) return;
    if (searchValue.length >= 3) {
      if (promiseRef.current) {
        promiseRef.current.abort();
      }
      promiseRef.current = dispatch(
        searchAddress({
          address: searchValue,
          type: addressType,
          lat: latitude,
          lng: longitude,
        })
      );
    }
  }, [searchValue, addressType, dispatch, latitude, longitude]);
  useEffect(() => {
    setSearchBoxHeight(listRef?.current?.offsetHeight);
  }, [addressResult]);

  const inputProps = useMemo(() => {
    const onChange = (event) => {
      setSearchValue(event.target.value);
      setIsListVisible(true);
      setFieldTouched(fields.address, false);
      if (!event.target.value) {
        setFieldValue(fields.address, '');
        setFieldValue(fields.lat, '');
        setFieldValue(fields.lng, '');
      } else {
        setFieldValue(fields.address, event.target.value);
      }
    };
    return {
      id,
      name,
      error,
      hasError,
      value,
      onChange,
      autoComplete: 'new-password',
      zIndex,
      onBlur,
      displayError,
      isDisabled,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addressType,
    displayError,
    error,
    hasError,
    id,
    isDisabled,
    name,
    value,
    zIndex,
  ]);

  const dispatchSetAddressDetails = ({ addressType, item }) => {
    dispatch(setAddressDetailsByType({ type: addressType, details: item }));
  };

  const handleSelectInput = () => {
    setIsListVisible(false);
    dispatch(resetAddressLoadingByType(addressType));
    setSearchValue('');
    setFieldTouched(fields.address, false);
  };

  const hasSaveAddressButton = useMemo(
    () => !!addressResult.length && showSaveAddress,
    [addressResult.length, showSaveAddress]
  );

  return (
    <>
      <Input {...inputProps} />
      {isListVisible && searchValue.length >= 3 && (
        <>
          <StyledResults
            $top="32px"
            ref={listRef}
            style={{ zIndex: zIndex - 1 }}
          >
            {isSearching === false && addressResult.length === 0 ? (
              <StyledItem onClick={() => null} $noResult>
                No results found
              </StyledItem>
            ) : (
              addressResult.map((item, idx) => {
                const key = `${item.name}-${idx}`;
                return (
                  <AddressSearchItem
                    key={key}
                    isFavorite={
                      item.provider === 'favorite' ||
                      item.provider === 'suggested_place'
                    }
                    item={item}
                    addressType={addressType}
                    setSelectedItem={handleSelectInput}
                    setFieldValue={setFieldValue}
                    setAddressDetailsByType={dispatchSetAddressDetails}
                    fields={fields}
                  />
                );
              })
            )}
          </StyledResults>
          {hasSaveAddressButton && (
            <NewAddressButton
              style={{
                top: searchBoxHeight ? `${searchBoxHeight + 34}px` : '214px',
              }}
              onClick={() => {
                setFieldTouched(fields.address, false);
                setIsListVisible(false);
                onClickNewAddress();
              }}
            />
          )}
        </>
      )}
    </>
  );
};

AddressSearchNew.propTypes = {
  id: string,
  name: string.isRequired,
  addressResult: arrayOf(shape(addressSearchPropTypes)),
  addressType: string.isRequired,
  value: string,
  serviceStatus: shape({
    isLoading: bool,
    hasService: bool,
  }).isRequired,
  hasError: bool,
  displayError: bool,
  setFieldValue: func.isRequired,
  setFieldTouched: func.isRequired,
  onClickNewAddress: func,
  onBlur: func,
  showSaveAddress: bool,
  zIndex: oneOfType([number, string]),
  testId: string,
  isDisabled: bool,
  error: string,
  fields: addressFieldsType,
};

AddressSearchNew.defaultProps = {
  addressResult: [],
  value: '',
  hasError: false,
  onClickNewAddress: () => {},
  onBlur: () => {},
  showSaveAddress: true,
  zIndex: 'initial',
  testId: 'address-search',
  isDisabled: false,
  error: null,
  displayError: true,
  fields: addressFieldsDefaultType,
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.addressResult, nextProps.addressResult) &&
  isEqual(prevProps.serviceStatus, nextProps.serviceStatus) &&
  isEqual(prevProps.fields, nextProps.fields) &&
  prevProps.id === nextProps.id &&
  prevProps.name === nextProps.name &&
  prevProps.addressType === nextProps.addressType &&
  prevProps.value === nextProps.value &&
  prevProps.displayError === nextProps.displayError &&
  prevProps.showSaveAddress === nextProps.showSaveAddress &&
  prevProps.zIndex === nextProps.zIndex &&
  prevProps.hasError === nextProps.hasError &&
  prevProps.error === nextProps.error &&
  prevProps.isDisabled === nextProps.isDisabled;

export default memo(AddressSearchNew, areEqual);
