import React from 'react';
import { shape, string, bool, number } from 'prop-types';

import { StyledError } from './styles';

const InputError = ({
  hasError,
  error,
  errorCanFit,
  width,
  marginTop,
  testId,
  absolute,
  style,
}) => {
  return (
    hasError && (
      <StyledError
        $absolute={absolute}
        data-testid={testId}
        $marginTop={marginTop}
        $width={width}
        $errorCanFit={errorCanFit}
        $style={style}
      >
        {error}
      </StyledError>
    )
  );
};

InputError.propTypes = {
  hasError: bool,
  error: string,
  errorCanFit: bool,
  width: number,
  testId: string,
  absolute: bool,
  style: shape({}),
};

InputError.defaultProps = {
  hasError: false,
  error: '',
  errorCanFit: false,
  width: null,
  testId: 'input-error',
  absolute: false,
  style: {},
};
export default InputError;
