import React from 'react';

import { Field } from 'formik';
import { shape, string, bool } from 'prop-types';
import PhoneNumberInput from 'components/PhoneNumberInput';
import { Row } from 'components/Grid';
import { preferredCountries } from './const';
import styles from './SignUpSteps.module.scss';
import {
  StyledHeaderWrapper,
  StyledSubtitle,
  StyledHeader,
} from 'views/Login/styles';
import { phoneNumberStep as messages } from './messages';

const PhoneNumberStep = ({ values, companyCountryCode, isDisabled }) => {
  return (
    <>
      <StyledHeaderWrapper>
        <StyledHeader>{messages.header}</StyledHeader>
        <StyledSubtitle>{messages.subtitle}</StyledSubtitle>
      </StyledHeaderWrapper>
      <Row between className={styles.PhoneNumberStepWrapper}>
        <Field value={values.phone} name="phone">
          {({
            form: {
              errors,
              setFieldValue,
              setFieldError,
              setFieldTouched,
              touched,
            },
          }) => {
            return (
              <PhoneNumberInput
                containerClass="flex"
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                error={errors.phone}
                hasError={!!(touched.phone && errors.phone)}
                defaultCountry={values.iso2 || companyCountryCode}
                preferredCountries={preferredCountries}
                placeholder="Phone number"
                isLarge
                isDisabled={isDisabled}
              />
            );
          }}
        </Field>
      </Row>
    </>
  );
};

PhoneNumberStep.propTypes = {
  values: shape({}).isRequired,
  companyCountryCode: string.isRequired,
  isDisabled: bool,
};
PhoneNumberStep.defaultProps = {
  isDisabled: false,
};

export default PhoneNumberStep;
