import styled, { css } from 'styled-components';
import { Col } from 'components/Grid';
import {
  spacingLg,
  red,
  borderRadiusBasic,
  dark,
  fontSizeSm,
  lightblack,
  spacingMd,
  fontSizeXs,
  palegray,
  mousegray,
  dimmed,
  melonRed,
  darkgray,
  fontWeightBold,
} from 'utils/variables';

const hasError = css`
  border-color: ${red};
`;
const isRed = css`
  color: ${melonRed};
`;
const isBold = css`
  font-size: ${fontSizeXs};
  font-weight: ${fontWeightBold};
`;

export const StyledCol = styled(Col)`
  position: relative;
`;
export const StyledTextArea = styled.textarea`
  padding-bottom: ${spacingMd};
  height: calc(${spacingLg} * 2);
  min-height: 74px;
  border-radius: ${borderRadiusBasic};
  width: 100%;
  max-width: 100%;
  border-color: ${dark};
  font-size: ${fontSizeSm};
  color: ${lightblack};
  padding: ${spacingMd} 8px;
  resize: none;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: ${fontSizeSm};
    color: ${palegray};
  }
  &:disabled {
    cursor: not-allowed;
    color: ${mousegray};
    background-color: ${dimmed};
    &::placeholder {
      color: ${mousegray};
    }
  }
  ${(props) => props.$hasError && hasError};
`;

export const StyledLabel = styled.label`
  display: block;
  margin-top: ${spacingMd};
  font-size: ${fontSizeSm};
  color: ${mousegray};
  ${(props) => props.$isBold && isBold};
`;

export const StyledCounter = styled.span`
  color: ${darkgray};
  display: block;
  font-size: ${fontSizeXs};
  position: absolute;
  bottom: -15px;
  right: 2px;
  ${(props) => props.$isRed && isRed};
`;
