import React, { useState, useMemo } from 'react';
import { shape, string, bool, array, func } from 'prop-types';
import { faComment, faPhone } from '@fortawesome/free-solid-svg-icons';

import { STATE_EN_ROUTE, STATE_ARRIVED } from 'constants/bookingStates';
import { ADD_CARD_OPTION_VALUE } from 'constants/paymentMethods';
import ProfileImage from './ProfileImage';
import {
  Column,
  Row,
  Info,
  BoldText,
  Text,
  ButtonWithIcon,
  HorizontalLine,
  Label,
  SmallText,
  PaymentMethodsSelect,
} from './styles';
import messages from './messages';
import selectStyles from './selectStyles';
import PaymentName from 'components/Modals/ViewBooking/PaymentName';

const DriverInfoWithPayment = ({
  driver,
  companyName,
  paymentMethods,
  paymentMethod,
  bookingState,
  updatePaymentMethod,
  onClickCall,
  onClickMessage,
  driverMessage,
  hasAddCardOption,
  onSelectNewCardOption,
  isNotRfbAccount,
  defaultPaymentMethod,
}) => {
  const [paymentId, setPaymentId] = useState(paymentMethod?.id);
  const onChange = (item) => {
    if (paymentId === paymentMethod) return;

    if (item.value === ADD_CARD_OPTION_VALUE) {
      onSelectNewCardOption();
    } else {
      updatePaymentMethod(item);
      setPaymentId(item.id);
    }
  };
  const carLabel = useMemo(
    () =>
      driver.vehicle
        ? `${driver.vehicle.brand} ${driver.vehicle.model} ${driver.vehicle.color}`
        : '',
    [driver.vehicle]
  );

  const callButtonTitle = useMemo(() => {
    if ([STATE_EN_ROUTE, STATE_ARRIVED].indexOf(bookingState) > -1) {
      return messages.callDriver;
    } else {
      return messages.callHQ;
    }
  }, [bookingState]);

  return (
    <Column data-testid="driverInfoWithPayment">
      <Row>
        <ProfileImage src={driver?.image_url} />
        <Info>
          <BoldText>{driver?.vehicle?.plate}</BoldText>
          <Text>{carLabel}</Text>
          <Text>{companyName}</Text>
        </Info>
      </Row>
      <Row $buttonWrapper>
        <ButtonWithIcon
          testId="call"
          onClick={onClickCall}
          title={callButtonTitle}
          icon={faPhone}
        />
        <ButtonWithIcon
          testId="message"
          onClick={onClickMessage}
          title={messages.messageDriver.title}
          icon={faComment}
        />
      </Row>
      <HorizontalLine />
      {!!driverMessage && (
        <>
          <Row>
            <Label>{messages.messageDriver.label}</Label>
            <SmallText $flex>{driverMessage}</SmallText>
          </Row>
          <HorizontalLine />
        </>
      )}
      <Row>
        <Label>{messages.paymentMethod}</Label>
        {isNotRfbAccount ? (
          <PaymentName paymentMethod={defaultPaymentMethod} />
        ) : (
          <PaymentMethodsSelect
            name="paymentMethod"
            options={paymentMethods}
            onChange={onChange}
            selectedValue={paymentId}
            selectStyles={selectStyles}
            hasAddCardOption={hasAddCardOption}
          />
        )}
      </Row>
    </Column>
  );
};

DriverInfoWithPayment.propTypes = {
  companyName: string.isRequired,
  paymentMethod: shape({}),
  bookingState: string.isRequired,
  updatePaymentMethod: func.isRequired,
  onClickCall: func.isRequired,
  onClickMessage: func.isRequired,
  driverMessage: string,
  paymentMethods: array,
  hasAddCardOption: bool,
  isNotRfbAccount: bool,
  defaultPaymentMethod: shape({}),
};
DriverInfoWithPayment.defaultProps = {
  paymentMethods: [],
  driverMessage: null,
  hasAddCardOption: false,
  isNotRfbAccount: false,
  defaultPaymentMethod: {},
};
export default DriverInfoWithPayment;
