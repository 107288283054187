import React, { useCallback, memo } from 'react';
import DayPicker from 'react-day-picker';
import format from 'date-fns/format';
import _isEqual from 'lodash/isEqual';

import Input from 'components/Input';
import styles from './BookingForm.module.scss';

const BookingDayPicker = ({
  setToggle,
  toggle,
  errors,
  touched,
  date = new Date(),
  reference,
  setFieldValue,
  isDisabled,
}) => {
  const onDayClick = useCallback(
    (_date, { disabled }) => {
      if (!disabled) {
        setFieldValue('date', _date);
        setToggle(false);
      }
    },
    [setFieldValue, setToggle]
  );

  const onFocus = useCallback(() => {
    setToggle(true);
  }, [setToggle]);

  return (
    <>
      <Input
        value={format(date, 'dd.MM.yyyy')}
        onFocus={onFocus}
        bgWhite
        textSmall
        name="date"
        errors={errors}
        touched={touched}
        disabled={isDisabled}
        hasDisabledStyles={isDisabled}
        readOnly
      />
      {toggle && (
        <div ref={reference} className={styles.BookingFormCalendar}>
          <DayPicker
            onDayClick={onDayClick}
            disabledDays={{ before: new Date() }}
            selectedDays={date}
          />
        </div>
      )}
    </>
  );
};

const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.errors, nextProps.errors) &&
  _isEqual(prevProps.touched, nextProps.touched) &&
  _isEqual(prevProps.date, nextProps.date) &&
  prevProps.toggle === nextProps.toggle &&
  prevProps.isDisabled === nextProps.isDisabled;

export default memo(BookingDayPicker, areEqual);
