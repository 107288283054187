import React, { useState } from 'react';
import { oneOfType, string, number, shape } from 'prop-types';

import Modal from 'components/Modal';
import AddUser from 'components/Modals/AddUser';
import DeleteUser from 'components/Modals/DeleteUser';
import { accountType } from 'constants/propTypes';
import { isOwnerOrAdmin } from 'utils/isOwner';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import {
  USER_ROLES_DISABLED_FOR_DELETE,
  USER_ROLES_DISABLED_FOR_EDIT_BY_MANAGERS,
} from './const';
import { StyledButtonsWrapper, EditIcon } from 'views/Dashboard/styles';

const UserActionButtons = ({
  id,
  role,
  firstName,
  lastName,
  email,
  phoneNumber,
  phoneCountry,
  groupId,
  userRfbPaymentMethodId,
  account,
  userId,
  setMembersToDelete,
  membersToDelete,
}) => {
  const currentUserRole = account?.role;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
    setMembersToDelete({ id, firstName, lastName, isSingle: true });
  };
  const handleDeleteModalToggle = () =>
    setIsDeleteModalOpen(!isDeleteModalOpen);

  const handleEditModalToggle = () => setIsEditModalOpen(false);
  const hasDeleteDisabled = USER_ROLES_DISABLED_FOR_DELETE.includes(role);
  const isEditDisabled = USER_ROLES_DISABLED_FOR_EDIT_BY_MANAGERS.includes(
    role
  );
  const isOwner = isOwnerOrAdmin(currentUserRole);

  return (
    <>
      <StyledButtonsWrapper>
        {(isOwner || !isEditDisabled) && (
          <EditIcon
            data-testid="user-dashboard-item-edit-btn"
            icon={faEdit}
            alt="edit"
            onClick={() => setIsEditModalOpen(true)}
          />
        )}
        {!hasDeleteDisabled && (
          <>
            <EditIcon
              data-testid="user-dashboard-item-delete-btn"
              alt="delete"
              icon={faTrashAlt}
              onClick={openDeleteModal}
            />
            <Modal
              isModalOpen={isDeleteModalOpen}
              handleModalToggle={handleDeleteModalToggle}
              modalStyle="addOrEditUserModal"
            >
              <DeleteUser
                membersToDelete={membersToDelete}
                handleModalToggle={handleDeleteModalToggle}
                id={id}
                isSingleUser
              />
            </Modal>
          </>
        )}
      </StyledButtonsWrapper>
      <Modal
        isModalOpen={isEditModalOpen}
        handleModalToggle={handleEditModalToggle}
        modalStyle="addOrEditUserModal"
      >
        <AddUser
          type="edit"
          userId={userId}
          userRole={role}
          firstName={firstName}
          lastName={lastName}
          phoneNumber={phoneNumber}
          email={email}
          phoneCountry={phoneCountry}
          groupId={groupId}
          handleModalToggle={handleEditModalToggle}
          id={id}
          userRfbPaymentMethodId={userRfbPaymentMethodId}
          account={account}
        />
      </Modal>
    </>
  );
};

UserActionButtons.propTypes = {
  id: number.isRequired,
  role: string.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  phoneCountry: string,
  phoneNumber: string,
  userRfbPaymentMethodId: number,
  account: shape(accountType),
  userId: number,
  groupId: oneOfType([number, string]),
};

UserActionButtons.defaultProps = {
  phoneCountry: '',
  phoneNumber: '',
  userRfbPaymentMethodId: null,
  account: null,
  userId: null,
  groupId: null,
};

export default UserActionButtons;
