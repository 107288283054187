import React, { memo } from 'react';
import { func, bool, string } from 'prop-types';

import BasicModal from 'components/Modals/BasicModal';
import { Textarea, CharCounter } from 'components/Modals/ViewBooking/styles';
import { TEXTAREA_LIMIT } from 'constants/booking';
import { commonText } from 'locales/en';
import messages from './messages';

const FlightDetails = ({
  isModalOpen,
  isDisabled,
  isLoading,
  name,
  value,
  onClose,
  handleChange,
}) => {
  const onChange = (e) => {
    handleChange(name, e.target.value);
  };

  return (
    <BasicModal
      testId="flight-details-modal"
      isModalOpen={isModalOpen}
      title={messages.flightDetails.title}
      onClickPrimary={onClose}
      onClickSecondary={onClose}
      secondaryTitle={commonText.close}
      primaryTitle={messages.flightDetails.send}
      customFooterComponent={
        <CharCounter>
          {value.length}/{TEXTAREA_LIMIT}
        </CharCounter>
      }
      isPrimaryDisabled={isDisabled}
      isPrimaryLoading={isLoading}
    >
      <Textarea
        placeholder={messages.flightDetails.placeholder}
        data-testid="textArea"
        maxLength={TEXTAREA_LIMIT}
        rows="6"
        value={value}
        onChange={onChange}
      />
    </BasicModal>
  );
};

FlightDetails.propTypes = {
  isModalOpen: bool,
  isDisabled: bool,
  isLoading: bool,
  name: string,
  value: string,
  onClose: func.isRequired,
  handleChange: func.isRequired,
};

FlightDetails.defaultProps = {
  isModalOpen: true,
  isDisabled: false,
  isLoading: false,
};

export default memo(FlightDetails);
