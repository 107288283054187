import { ENABLE_RESEND_INVITATION_TIMEFRAME } from 'constants/user';

// getDateDiffFromCurrentInHours helper function to calculate time difference in hours
// between current date and date in the past
const MILLIS_IN_HOUR = 60 * 60 * 1000;
export const getTimeDiffTillCurrentDateInHours = (date2) => {
  const currentDate = new Date(Date.now());
  return (currentDate - new Date(date2)) / MILLIS_IN_HOUR;
};

export const getResendInvitationEnabled = (invitedAt) => {
  const invitedAtDateDiffInHours =
    invitedAt && getTimeDiffTillCurrentDateInHours(invitedAt);
  if (invitedAtDateDiffInHours) {
    return invitedAtDateDiffInHours > ENABLE_RESEND_INVITATION_TIMEFRAME;
  }
  return false;
};
