import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import isEqual from 'lodash/isEqual';

import { DEFAULT_TIME_ZONE } from 'constants/booking';
import {
  DEFAULT_COMPANY_COUNTRY_CODE,
  DEFAULT_COMPANY,
} from 'constants/companies';
import { fetchDefaultCompany, fetchCompanyByCode } from 'api/company';
import { companyCodeFromSubdomain } from 'utils/subdomain';
import {
  TERMS_AND_CONDITIONS_DEFAULT_LINK,
  PRIVACY_POLICY_DEFAULT_LINK,
} from 'constants/appLinks';

import fallbackHeaderLogo from 'assets/riidelogo.png';
import fallbackFooterLogo from 'assets/riidelogowhite.png';

export const initialState = {
  isLoading: false,
  error: null,
  companyCode: DEFAULT_COMPANY,
  appCompanyCode: DEFAULT_COMPANY,
  data: {
    lat: 0,
    lng: 0,
    country_code: DEFAULT_COMPANY_COUNTRY_CODE,
    configs: {
      general: {
        terms_url: TERMS_AND_CONDITIONS_DEFAULT_LINK,
        privacy_url: PRIVACY_POLICY_DEFAULT_LINK,
      },
      booking: {},
      payment: {},
      rfb: {
        logo_url: fallbackHeaderLogo,
        logo_footer_url: fallbackFooterLogo,
      },
    },
    timezone: DEFAULT_TIME_ZONE,
  },
};

export const getDefaultCompany = createAsyncThunk(
  'company/default',
  async (_, { rejectWithValue, getState }) => {
    const companyCode = companyCodeFromSubdomain();

    try {
      const { data } = await fetchDefaultCompany(
        companyCode || DEFAULT_COMPANY
      );
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCompanyByCode = createAsyncThunk(
  'company/view',
  async (companyCode, { rejectWithValue, getState }) => {
    try {
      const { data } = await fetchCompanyByCode(companyCode || DEFAULT_COMPANY);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
function companyLoading(state) {
  state.isLoading = true;
  state.error = null;
}

function companyFailed(state, action) {
  state.isLoading = false;
  if (action.payload) {
    state.error = action.payload.message;
  } else {
    state.error = action.error.message;
  }
}

const companyFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.error = null;
  const nextData = {
    ...payload,
    lng: payload?.territory_center_lng,
    lat: payload?.territory_center_lat,
  };
  if (!isEqual(state.data, nextData)) {
    state.data = nextData;
  }
  if (payload.code !== state.appCompanyCode)
    state.appCompanyCode = payload.code;
  if (payload.code !== state.companyCode) state.companyCode = payload.code;
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyCoordinates: (state, action) => {
      if (
        state.data.lng !== action.payload.lng ||
        state.data.lat !== action.payload.lat
      ) {
        state.data.lng = action.payload.lng;
        state.data.lat = action.payload.lat;
      }
    },
    setCompanyCode(state, { payload }) {
      if (state.companyCode !== payload) {
        state.companyCode = payload || DEFAULT_COMPANY;
      }
    },
    setAppCompanyCode(state, { payload }) {
      if (state.appCompanyCode !== payload || state.companyCode !== payload) {
        state.appCompanyCode = payload || DEFAULT_COMPANY;
        state.companyCode = payload || DEFAULT_COMPANY;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDefaultCompany.pending, companyLoading);
    builder.addCase(getCompanyByCode.pending, companyLoading);

    builder.addCase(getDefaultCompany.fulfilled, companyFulfilled);
    builder.addCase(getCompanyByCode.fulfilled, companyFulfilled);

    builder.addCase(getDefaultCompany.rejected, companyFailed);
    builder.addCase(getCompanyByCode.rejected, companyFailed);
  },
});

export const {
  setCompanyCode,
  setAppCompanyCode,
  setCompanyCoordinates,
} = companySlice.actions;
export default companySlice.reducer;

export const generalCompanyConfig = (state) =>
  state.company.data.configs?.general;
export const companyDataSelector = (state) => state.company.data;
export const companyEmailSelector = (state) =>
  state.company.data.configs?.general?.email;
export const companyCountryCodeSelector = (state) =>
  state.company.data.country_code;
export const defaultCompanyPreauthSelector = (state) =>
  state.company.data.configs?.booking?.preauth;
export const companyTimezone = (state) => state.company.data.timezone;
export const appCompanyName = (state) => state.company.data.name;

export const currencySelector = (state) => {
  if (state.company.data.configs.general.currency) {
    return state.company.data.configs.general.currency.label;
  } else {
    return '€';
  }
};
