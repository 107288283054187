import styled from 'styled-components';
import * as v from 'utils/variables';
import { fontSizeXs } from 'utils/variables';
import arrowIcon from 'assets/booking/map-marker-arrow-right.svg';
import tryLaterIcon from 'assets/booking/bookForLater.png';
import pickupIcon from 'assets/booking/pickup.svg';
import dropOffIcon from 'assets/booking/drop-off.svg';

export const LabelWrapper = styled.div`
  display: flex;
  color: ${v.white};
  justify-content: flex-start;
  position: absolute;
  top: -5rem;
  left: calc(50% + 3px);
  transform: translateX(-50%);
  box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.3);
`;

export const Label = styled.div`
  color: ${v.charCoal};
  background: ${v.white};
  font-size: ${v.fontSizeMd};
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-radius: ${v.borderRadiusMedium};
  height: 40px;
  width: 14em;
  max-width: 250px;
  position: relative;
  z-index: 2;
`;

export const LabelBottomArrowWrapper = styled.div`
  width: 30px;
  height: 12px;
  position: absolute;
  overflow: hidden;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
`;

export const LabelBottomArrow = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background: #fff;
  transform: rotate(45deg);
  top: -7px;
  left: 9px;
  box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.8);
`;

export const LabelCornerArrow = styled.span`
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  flex-shrink: 0;
  margin-right: 0.75rem;
  background: url(${arrowIcon}) no-repeat center center;
`;

export const EstimatedTime = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${v.spacingSm};
  background-color: ${v.primary};
  color: ${v.white};
  border-bottom-left-radius: ${v.borderRadiusMedium};
  border-top-left-radius: ${v.borderRadiusMedium};
  font-size: ${fontSizeXs};
  min-width: 40px;
  min-height: 40px;
  height: 100%;
  font-family: ${v.fontFamilyPrimary};
`;

export const EstimatedTimeValue = styled.span`
  font-size: ${v.fontSizeMd};
  font-weight: bold;
  line-height: 0.75rem;
  margin-top: 0.1875rem;
`;

export const EstimatedTimeUnit = styled.span``;

export const TryLaterIcon = styled.span`
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: url(${tryLaterIcon}) no-repeat center;
`;

export const Address = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  padding: ${v.spacingXmd} ${v.spacingXs} ${v.spacingXmd} ${v.spacingSm}};
`;

const PointIcon = styled.span`
  position: absolute;
  top: -1.625rem;
  left: -0.375rem;
  width: 1.25rem;
  height: 1.9375rem;
  background: no-repeat center;
`;

export const PickupIcon = styled(PointIcon)`
  background-image: url(${pickupIcon});
`;

export const DropOffIcon = styled(PointIcon)`
  background-image: url(${dropOffIcon});
`;

export const VehicleImage = styled.img`
  max-width: 35px;
  max-height: 35px;
`;

const Root = styled.div`
  position: relative;
  z-index: 9;
`;

export default Root;
