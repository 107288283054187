import React from 'react';
import { Row } from 'components/Grid';
import { Container, Bar } from './styles';

const ProgressBar = ({ width, length }) => (
  <Row withMarginMedium>
    <Container>
      <Bar $width={width} $length={length}></Bar>
    </Container>
  </Row>
);

export default ProgressBar;
