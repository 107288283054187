import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { bool, node, object, func, string, oneOfType } from 'prop-types';

import {
  customModalStyles,
  customBigModalStyles,
  setAccountModalStyles,
  bookingFormModalStyles,
  addOrEditUserModalStyles,
  warningModalStyles,
  enableCookiesModalStyles,
} from 'constants/modalStyles';

const styles = {
  isBig: customBigModalStyles,
  isAccount: setAccountModalStyles,
  addOrEditUserModal: addOrEditUserModalStyles,
  bookingForm: bookingFormModalStyles,
  warningModal: warningModalStyles,
  default: customModalStyles,
  enableCookies: enableCookiesModalStyles,
};

const Modal = ({
  modalStyle,
  children,
  handleModalToggle,
  isModalOpen,
  shouldCloseOnOverlayClick,
  appElement,
  ...restProps
}) => {
  // prevents body scroll when modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, [isModalOpen]);

  return (
    <>
      <ReactModal
        appElement={appElement}
        style={modalStyle ? styles[modalStyle] : styles['default']}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        isOpen={isModalOpen}
        onRequestClose={handleModalToggle}
        onAfterOpen={() => {
          document.body.style.overflow = 'hidden';
        }}
        onAfterClose={() => {
          document.body.removeAttribute('style');
        }}
        {...restProps}
      >
        {children}
      </ReactModal>
    </>
  );
};

Modal.propTypes = {
  modalStyle: string,
  children: node,
  handleModalToggle: func,
  isModalOpen: bool.isRequired,
  shouldCloseOnOverlayClick: bool,
  appElement: oneOfType([node, object]),
};

Modal.defaultProps = {
  children: null,
  modalStyle: 'default',
  isBig: false,
  shouldCloseOnOverlayClick: false,
  handleModalToggle: () => {},
  appElement: document.body,
};

export default Modal;
