const authMiddleware = (store) => (next) => (action) => {
  if (
    (action.type.startsWith('bookings') ||
      action.type.startsWith('accounts')) &&
    !store.getState().user.data.token
  )
    return;
  next(action);
};

export default authMiddleware;
