import React, { memo, useMemo } from 'react';
import _isEqual from 'lodash/isEqual';
import {
  shape,
  arrayOf,
  string,
  bool,
  func,
  number,
  oneOfType,
} from 'prop-types';

import AddressSearchNew from 'components/AddressSearch/AddressSearchNew';
import { LabelContainer } from 'components/Label';
import {
  addressFieldsType,
  addressFieldsDefaultType,
} from 'constants/propTypes';

const WithAddressSearch = ({
  id,
  name,
  hasError,
  error,
  addressResult,
  addressType,
  value,
  serviceStatus,
  showSaveAddress,
  zIndex,
  testId,
  isDisabled,
  label,
  labelSize,
  inputSize,
  setFieldValue,
  setFieldTouched,
  onBlur,
  onClickNewAddress,
  fields,
}) => {
  const errorText = useMemo(() => {
    if (serviceStatus.isLoading === false && !serviceStatus.hasService) {
      return 'There is no taxi service between specified locations, please select a valid route.';
    } else {
      return error;
    }
  }, [error, serviceStatus.hasService, serviceStatus.isLoading]);

  return (
    <LabelContainer
      testId={testId}
      labelSize={labelSize}
      inputSize={inputSize}
      label={label}
      id={id}
      name={name}
      hasError={!!(hasError && errorText)}
    >
      <AddressSearchNew
        id={id}
        name={name}
        zIndex={zIndex}
        setFieldValue={setFieldValue}
        addressResult={addressResult}
        addressType={addressType}
        value={value}
        onBlur={onBlur}
        serviceStatus={serviceStatus}
        setFieldTouched={setFieldTouched}
        showSaveAddress={showSaveAddress}
        isDisabled={isDisabled}
        onClickNewAddress={onClickNewAddress}
        hasError={!!(hasError && errorText)}
        error={errorText}
        fields={fields}
      />
    </LabelContainer>
  );
};

WithAddressSearch.propTypes = {
  id: string,
  name: string.isRequired,
  addressResult: arrayOf(shape({})),
  addressType: string.isRequired,
  setFieldValue: func.isRequired,
  value: string,
  serviceStatus: shape({
    isLoading: bool,
    hasService: bool,
  }).isRequired,
  setFieldTouched: func.isRequired,
  hasError: bool,
  displayError: bool,
  onClickNewAddress: func,
  showSaveAddress: bool,
  zIndex: oneOfType([number, string]),
  testId: string,
  isDisabled: bool,
  onBlur: func,
  label: string,
  labelSize: number,
  inputSize: number,
  fields: addressFieldsType,
};
WithAddressSearch.defaultProps = {
  addressResult: [],
  value: '',
  hasError: false,
  onClickNewAddress: () => {},
  onBlur: () => {},
  showSaveAddress: true,
  zIndex: 'initial',
  isDisabled: false,
  error: null,
  testId: 'address-search-with-label',
  labelSize: 4,
  inputSize: 8,
  fields: addressFieldsDefaultType,
};
const areEqual = (prevProps, nextProps) =>
  _isEqual(prevProps.serviceStatus, nextProps.serviceStatus) &&
  _isEqual(prevProps.addressResult, nextProps.addressResult) &&
  _isEqual(prevProps.fields, nextProps.fields) &&
  prevProps.id === nextProps.id &&
  prevProps.zIndex === nextProps.zIndex &&
  prevProps.addressType === nextProps.addressType &&
  prevProps.name === nextProps.name &&
  prevProps.error === nextProps.error &&
  prevProps.hasError === nextProps.hasError &&
  prevProps.value === nextProps.value &&
  prevProps.showSaveAddress === nextProps.showSaveAddress &&
  prevProps.isDisabled === nextProps.isDisabled &&
  prevProps.testId === nextProps.testId &&
  prevProps.label === nextProps.label &&
  prevProps.labelSize === nextProps.labelSize &&
  prevProps.inputSize === nextProps.inputSize;

export default memo(WithAddressSearch, areEqual);
