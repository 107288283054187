import styled from 'styled-components';
import Button from 'components/Button';
import {
  fontSizeMd,
  dark,
  borderRadiusBasic,
  white,
  palegray,
  lightblack,
  DEFAULT_INPUT_HEIGHT,
} from 'utils/variables';

export const StyledButton = styled(Button)`
  flex: 0 1 auto;
  min-width: 136px;
  font-size: ${fontSizeMd};
`;
export const StyledInput = styled.input`
  background: ${white};
  border: 1px solid ${dark};
  border-radius: ${borderRadiusBasic};
  color: ${lightblack};
  height: ${DEFAULT_INPUT_HEIGHT};
  padding-left: 10px;
  &::placeholder {
    color: ${palegray};
  }
  &:focus {
    outline: none;
  }
`;
export const StyledPickerWrapper = styled.div`
  flex: 1 0 auto;
  .DayPickerInput:nth-child(2) {
    margin-left: 20px;
  }
`;
